import { Injectable, inject } from '@angular/core';
import { 
  CanActivate,
   ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../service/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonService } from './../service/common.service';
import { SiteDomainService } from '../service/sitedomain.service';
import { DataService } from '../service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,
    private jwtHelper: JwtHelperService , private commonService : CommonService , private domain : SiteDomainService,private dataService: DataService) { }

  user;
  userid;
  myRawToken: any;
  themestyle: any;
  userFullName : any

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.user = JSON.parse(localStorage.getItem('loggedUser'))
    this.themestyle = JSON.parse(localStorage.getItem('theme_style'))
    this.myRawToken = localStorage.getItem('jwt')

    if(this.myRawToken && this.user){
      this.checkPermission()

      setTimeout(() => {
        if(JSON.parse(localStorage.getItem('change_password')) == 1){
          this.commonService.openChangePasswordDialog()
        }
      }, 1000);
    }

    if (!this.user) {
      this.getLocoalStorageClear()
    }

    this.userid = this.user['id'];
    if (!this.myRawToken) {
      this.getLocoalStorageClear()
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.myRawToken);
    if (decodedToken['id'] == this.userid) {
      return true
    } else {
     this.getLocoalStorageClear()
    }

  }
  
  checkPermission() {
    this.user = JSON.parse(localStorage.getItem('loggedUser'))
    const details = {
      method: "updateIfRescuewWordDataUpdate",
      username: this.user.username,
      email: this.user.email,
      id: this.commonService.getUserId(),
      container_id : this.commonService.getDomainContainerId(),
      change_password : localStorage.getItem('change_password')
    }

    this.authService.updateUserLoginStatus(details).subscribe(res => {  
      console.log("updateUserLoginStatus",res);
          
     if(res['status']){
      localStorage.setItem('change_password',res['change_password'])
      if(res['logout_status']){
        console.log("switch case");
    
        console.log("switch case",res['rw_logout_reason']);    
        switch ( res['rw_logout_reason']) {
          case "1":
            this.commonService.openLogoutReasonDialog("permission_removed");
            break;
          case "2":
            this.commonService.openLogoutReasonDialog("password_changed");
            break;
          case "3":
            this.commonService.openLogoutReasonDialog("email_changed");
            break;
          case "4":
            this.commonService.openLogoutReasonDialog("user_deactive");
            break;
        }      
      }else{
        if(res['profile_status']){
          const loggedUserString = localStorage.getItem('loggedUser');
          let loggedUser = JSON.parse(loggedUserString);
          loggedUser.first_name = res['first_name'];
          loggedUser.last_name = res['last_name'];
          localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
             
          this.getProfile();
          return true
        }
        return true
      }
     }else{
      this.commonService.openSessionExpireDialog()
     }
    })
  }
  getLocoalStorageClear(){
    this.commonService.redirectToLogin() 
  }

  getProfile() {
    const data = {
      method: 'notificationsCount',
      id: this.commonService.getUserId()
    };

     this.domain.getUnreadNotificationCount(data).subscribe(res => {
      if (res['data']) {
        this.dataService.changeNotificationId(res['data']);
        this.domain.notificationCount = res['count'];
        this.userFullName = res['userFullName'];
        localStorage.setItem('headerFullName', this.userFullName);
        this.dataService.changeHeaderUserName(this.userFullName);
      }
    });

  }
}




// export const authGuard: CanActivateFn = ( next: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot) => {
//   let authService = inject(AuthService);
//   let router = inject(Router);
//   let jwtHelper = inject(JwtHelperService);
//   let commonService = inject(CommonService);

//   let user
//   let userid;
//   let myRawToken: any;
//   let  themestyle: any;
  
//   user = JSON.parse(localStorage.getItem('loggedUser'))
//   themestyle = JSON.parse(localStorage.getItem('theme_style'))

//     if (!user) {
//       getLocoalStorageClear()
//     }

//     userid = user['id'];
//     myRawToken = localStorage.getItem('jwt')
//     if (!myRawToken) {
//       getLocoalStorageClear()
//     }
//     const helper = new JwtHelperService();
//     const decodedToken = helper.decodeToken(myRawToken);
//     if (decodedToken['id'] == userid) {
//       return true
//     } else {
//      getLocoalStorageClear()
//     }

//   } 

// const getLocoalStorageClear = () => {
//   let commonService = inject(CommonService);
//   commonService.redirectToLogin() 
// }


