import {
  Component, OnInit, Renderer2
} from '@angular/core';
import { CourseService } from './../../../service/course.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from './../../../service/data.service';
import { CommonService } from './../../../service/common.service';
import { ApiService } from './../../../service/api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-course-details-preview',
  templateUrl: './course-details-preview.component.html',
  styleUrls: ['./course-details-preview.component.scss'],
})
export class CourseDetailsPreviewComponent implements OnInit {
  postData: any;
  courseDetail: any;
  isLoading = true;
  courseimg: any;
  sitePath: any;
  getCourseDocuments: any;
  courseDescriptionWords: any;
  readMoreStatus: any;
  launchURL: any;
  quizIdSubject: any;
  quizSubjectData: any;
  charLimit = 270;

  showTitle = false;
  courseId: any;
  noRecord: boolean;

  constructor(private courseService: CourseService, private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, private apiService: ApiService, private renderer: Renderer2, private sanitizer: DomSanitizer,private titleService: Title) {
    this.courseId = atob(this.route.snapshot.paramMap.get('courseId'));
    console.log(this.courseId);
  }

  ngOnInit() {
    this.sitePath = this.apiService.getSiteFolderPath();
    this.getCourseDetail();
  }

  resetPanelCollapse() {
    setTimeout(() => {
      const getHeaderPan = document.querySelectorAll('.pc-header');
      const getContentPan = document.querySelectorAll('.pc-content');
      for (let i = 0; i < getHeaderPan.length; i++) {
        this.renderer.addClass(getHeaderPan[i], 'active');
        this.renderer.addClass(getContentPan[i], 'open');
        this.renderer.setStyle(getContentPan[i], 'height', 'auto');
      }
    }, 200);
  }

  getCourseDetail() {
    this.isLoading = true;
    this.postData = {
      courseId: this.courseId,
      method: 'courseDetailsBySlugs'
    };

    this.courseService.getCourseDetailPreview(this.postData).subscribe(res => {
      this.isLoading = false;
      if (res['status']) {
      
        this.noRecord = false;
        this.courseDetail = res['data'];
        this.setFavicon(this.courseDetail.name);
        if (this.courseDetail != undefined) {
          this.resetPanelCollapse();
          // read more
          this.courseDescriptionWords = this.courseDetail.description.split('').length;
          if (this.courseDescriptionWords > 270) {
            this.readMoreStatus = true;
          }
          this.getCourseDocuments = res['data']['documents'];
          this.courseimg = this.courseDetail.course_img != 'undefined' ? this.courseDetail.course_img : null;
        }
      
      } else {
        this.noRecord = true;
      }
    }, error => error);
   
  }

  setFavicon(tabName) {
    this.titleService.setTitle(tabName);
  }

  refresh() {
    this.ngOnInit();
  }

}
