import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from './../../service/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MarketPlaceService } from './../../service/market-place.service';
import { CommonService } from './../../service/common.service';

@Component({
  selector: 'app-redeem-course',
  templateUrl: './redeem-course.component.html',
  styleUrls: ['./redeem-course.component.scss']
})
export class RedeemCourseComponent implements OnInit {
  sitePath: any;
  redeemForm: FormGroup;
  redeemCode: FormControl;
  redeemVal: String;
  msg: string;
  showMsg: boolean = false;
  showFormBtn: boolean = true;
  lang: any;
  placeHolderRedeem: any;
  shopLink: any;
  constructor(public dialogRef: MatDialogRef<RedeemCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private formBuilder: FormBuilder, private marketPlaceService: MarketPlaceService, private commonService: CommonService) {
    this.lang = localStorage['locale'];
    dialogRef.disableClose = true;
    this.shopLink = this.data.shop_link;
  }

  ngOnInit() {
    if (this.lang == this.commonService.lang['en']) {
      this.msg = 'Please enter redeem code in order to get this course available.';
    } else if (this.lang == this.commonService.lang['ger']) {
      this.msg = 'Bitte hier den Code eingeben um den Kurs zu aktivieren.';
    }

    if (this.lang == this.commonService.lang['en']) {
      this.placeHolderRedeem = 'Enter Redeem Code here.';
    } else if (this.lang == this.commonService.lang['ger']) {
      this.placeHolderRedeem = 'Hier Code eingeben.';
    }

    this.sitePath = this.apiService.getSiteFolderPath();
    this.redeemCode = this.formBuilder.control('', [Validators.required]);
    this.redeemForm = this.formBuilder.group({
      container_id: this.data.container_id,
      course_id: this.data.course_id,
      id: this.data.user_id,
      section_id: this.data.section_id,
      redeem_code: this.redeemCode,
      method: 'verifyRedeemCodeAndAssignCourse',
    });
  }

  redeemCodeSubmit() {
    this.marketPlaceService.getVerifyRedeemCode(this.redeemForm.value).subscribe(res => {
      switch (res['message_code']) {
        case '1':
          if (this.lang == this.commonService.lang['en']) {
            this.msg = 'Course has been assigned to you sucessfully.';
          } else if (this.lang == this.commonService.lang['ger']) {
            this.msg = 'Kurs wurde erfoglreich aktiviert und ist nun verfügbar.';
          }
          this.showFormBtn = false;
          break;
        case '2':
          if (this.lang == this.commonService.lang['en']) {
            this.msg = 'The code entered seems to be expired.';
          } else if (this.lang == this.commonService.lang['ger']) {
            this.msg = 'Der Code wurde bereits genutzt oder ist abgelaufen.';
          }
          this.redeemVal = '';
          break;
        case '3':
          if (this.lang == this.commonService.lang['en']) {
            this.msg = 'The code entered seems to be incorrect.';
          } else if (this.lang == this.commonService.lang['ger']) {
            this.msg = 'Der eingegebene Code scheint nicht korrekt zu sein.';
          }
          this.redeemVal = '';
          break;
      }
    });
  }


}
