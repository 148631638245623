// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel.panel-course {
  border: solid 1px rgba(0, 0, 0, 0.38);
}

.panel.panel-course .panel-header {
  padding: 12px 12px 12px 12px;
  color: #ffffff;
  text-align: center;
  background-color: #333;
  min-height: 50px;
  border-radius: initial;
}

.panel.panel-course .panel-header h2 {
  margin: 0 0 10%;
}

.panel.panel-course .panel-body h2 {
  margin: 0px 0px px 4px;
  color: #ffffff;
}

.panel.panel-course .panel-body {
  padding: 12px 12px 12px 12px;
  color: #ffffff;
  text-align: center;
  height: fit-content;
  background-color: #990000;
}

.panel.panel-course .panel-body a {
  min-height: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
