import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../service/common.service';
import { ApiService } from '../../../service/api.service';
import { VideoService } from '../../../service/video.service';
import { Subscription } from 'rxjs';
import { DataService } from './../../../service/data.service';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit, AfterViewInit , OnDestroy {

  /** @var videUrlParam merge video url with other path and value  */
  videUrlParam: any;

  /** @var videopageId Get video id from url in base64 formate  */
  videopageId: any;

  /** @var isLoading For loading when data featching  */
  isLoading: boolean = false;

  /** @var noDataFound For error when data is not found  */
  noDataFound: boolean = false;

  /** @var videoDetailsData featch data and store  */
  videoDetailsData: any;

  /** @var videoFilePath video file path merge with api url */
  videoFilePath: any;

  /** @var getVideoThumb video thumb image merge with api url */
  getVideoThumb: any;

  /** @var relVideoContainer store related video data */
  relVideoContainer: any;

  /** @var noRelatedDataFound For error when data is not found  */
  noRelatedVideoDataFound: boolean = false;

  /** @var isLoading For loading when data featching  */
  isLoadingRelatedVideo: boolean = false;

  /** @var getVideoUploadPath store video image path  */
  getVideoUploadPath: any;

  /** @var getCategoryId store video category id  */
  getCategoryId: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  fileType:any;
  docPreview:any;
  docUploadPath:any = this.apiService.getVideoUploadPath();
  downloadDisabled:Boolean = true;
  downloadBtnTxt:String = 'Loading...'

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private renderer: Renderer2, private elementRef: ElementRef, private videoService: VideoService, private commonService: CommonService, private apiService: ApiService , private dataService: DataService) {
    this.videUrlParam = this.activatedRoute.snapshot.paramMap.get('videoId');
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  /** @constant playVideoElement vide element object handler for event hadling */
  @ViewChild('playVideoElement', { static: false }) playVideoElement: ElementRef;

  /** @constant videoOverlyElement vide overlay element object hadling overlay view */
  @ViewChild('videoOverlyElement', { static: false }) videoOverlyElement: ElementRef;

  ngOnInit() {
    this.videopageId = atob(this.videUrlParam);
    this.videopageId = this.videopageId.split(',')
    this.getVideoDetails(this.videopageId[0])
    this.getRelatedVideo(this.videopageId[1] , this.videopageId[0])
    this.getVideoUploadPath = this.apiService.getCourseImgPath()
    setTimeout(() => {
      this.downloadDisabled = false
      this.downloadBtnTxt = "Download"
    }, 3000)
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }

  /**
 * @description Get video details by id
 * @date 2020-05-22
 * @param videoId
 * @method  getVideoDetails
 * @memberof VideoDetailComponent
 */
  getVideoDetails(videoId) {
    this.isLoading = true;
    const videoDetailParam = {
      method: "videoDetailByVideoId",
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      video_id: videoId
    }
    this.videoService.getVideoListData(videoDetailParam).subscribe((res) => {
      this.isLoading = false;
      if (res['status']) {
        this.videoDetailsData = res['data'][0];
        this.fileType = this.videoDetailsData.file_type
        this.docPreview = this.docUploadPath + this.videoDetailsData.video_file_path
        console.log(this.docPreview , 'pdf path data')
        if (this.videoDetailsData.video_thumb != undefined) {
          this.getVideoThumb = this.apiService.getCourseImgPath() + 'media_images/' + this.videoDetailsData.video_thumb;
        } else {
          this.getVideoThumb = null
        }
        if (this.videoDetailsData.video_file_path) {
          this.videoFilePath = this.apiService.getCourseImgPath() + this.videoDetailsData.video_file_path
        } else {
          this.videoFilePath = null
        }
        setTimeout(() => {
          this.initVideoFunctions();
        }, 500)
      } else {
        this.noDataFound = true
      }
    })
  }

  /**
* @description Navigate to caregory page
* @date 2020-05-22
* @method  gotoCategoryWiseView
* @memberof VideoDetailComponent
*/
  gotoCategoryWiseView() {
    this.router.navigate(['video-category/' + btoa(this.videoDetailsData.category_id)]);
  }

  ngAfterViewInit() {
    if (this.videoOverlyElement !== undefined) {
      this.videoOverlyElement.nativeElement.focus();
    }
  }

  /**
 * @description Initialize function event for video controle
 * @date 2020-05-22
 * @method  initVideoFunctions
 * @memberof VideoDetailComponent
 */
  initVideoFunctions() {
    console.log(this.playVideoElement , 'playVideoElement')
    if(this.playVideoElement !== undefined){
      this.playVideoElement.nativeElement.load();
      this.renderer.removeClass(this.videoOverlyElement.nativeElement, 'hide');
      this.renderer.listen(this.playVideoElement.nativeElement, 'playing', () => {
        this.renderer.addClass(this.videoOverlyElement.nativeElement, 'hide');
      })
      this.renderer.listen(this.playVideoElement.nativeElement, 'pause', () => {
        this.renderer.removeClass(this.videoOverlyElement.nativeElement, 'hide');
      })
    }
  
  }


  /**
* @description Play video function
* @date 2020-05-22
* @method  videoPlay
* @memberof VideoDetailComponent
*/
  videoPlay() {
    this.playVideoElement.nativeElement.play();
    this.renderer.addClass(this.videoOverlyElement.nativeElement, 'hide');
  }

  /**
* @description Play video function
* @date 2020-05-22
* @method  videoPlay
* @memberof VideoDetailComponent
*/
  getRelatedVideo(catId , mediaId) {
    this.isLoadingRelatedVideo = true;
    const videoDetailParam = {
      method: "relatedCategoryVideoListWithLimit",
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      category_id: catId,
      mediaId
    }
    this.videoService.getVideoListData(videoDetailParam).subscribe((res) => {
      this.isLoadingRelatedVideo = false;
      if (res['status']) {
        this.getCategoryId = res['category'][0]['id']
        if (res['data']) {
          this.relVideoContainer = res['data'];
        } else {
          this.noRelatedVideoDataFound = true
        }
      } else {
        this.noRelatedVideoDataFound = true
      }
    })
  }


  /**
 * @description Navigate to the details page
 * @date 2020-05-22
 * @method  videoDetails
 * @memberof VideoDetailComponent
 */
  videoDetails(video_id, categoryId) {
    const paraData = video_id + ',' + categoryId;
    //this.router.navigateByUrl('../', { skipLocationChange: true });
    this.router.navigate(['../', btoa(paraData)], { relativeTo: this.activatedRoute });
    this.getVideoDetails(video_id);
    this.getRelatedVideo(this.videopageId[1] , video_id)
  }

  getImgIcon(file , fileType){
    return this.videoService.getImgIcon(file , fileType)
  }

  downloadDocument(){

  }
  
}
