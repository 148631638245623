import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../service/common.service';
import { DataService } from 'src/app/service/data.service';
import { Router } from '@angular/router';
import { SiteDomainService } from 'src/app/service/sitedomain.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  siteDetail: any;
  address: any;
  email: any;
  phone: any;
  isLoading: boolean = true;
  image: string;
  siteHostName: any;
  previewLayoutPathArr: any = [
    // tslint:disable-next-line: max-line-length
    'course-detail-preview', 'module-detail-preview', 'lesson-detail-preview'
  ];
  urlPath: any;

  constructor(private commonService: CommonService,
    private dataService: DataService,
    private router: Router,
    private domainName: SiteDomainService
  ) { }

  ngOnInit() {
    this.siteDetail = this.commonService.getCurrentSiteDetail()
    this.address = this.siteDetail.address;
    this.email = this.siteDetail.email;
    this.phone = this.siteDetail.pri_phone;
    this.siteHostName = this.domainName.getDomainName();
    this.verifySiteDomain(this.siteHostName);
  }


  verifySiteDomain(domain : any) {
    let postDomainData = {
      // tslint:disable-next-line: object-literal-shorthand
      domain: domain,
      method: 'verifySiteDomain'
    };
    this.dataService.verifySiteDomain(postDomainData).subscribe(res => {
      this.isLoading = false;
      if (res['status']) {
        if (res['data'].contact_img_name) {
          this.image = `https://static.weberrescueworld.com/academy-uploads/${res['data'].contact_img_name}`
          console.log(this.image);
        } else {
          this.image = `../../../assets/images/contactbg.jpg`
        }
      } else {
        this.router.navigate(['not-found'])
      }
    });
  }
}
