// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/fonts/calibri/stylesheet.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-title {
  margin-bottom: 15px;
}

.mat-mdc-card {
  padding: 16px;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
  border-radius: 6px;
}
.mat-mdc-card .mat-mdc-card-content {
  color: #777;
}
.mat-mdc-card .mat-mdc-card-subtitle {
  color: #333;
}
.mat-mdc-card .mat-mdc-card-subtitle h4 {
  position: relative;
  min-height: 20px;
  padding-right: 200px;
  font-family: "calibriregular";
  font-size: 25px;
}
.mat-mdc-card .mat-mdc-card-subtitle h4 .date {
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  font-weight: normal;
  font-size: 14px;
  color: #777;
}
.mat-mdc-card .mat-mdc-card-subtitle h4 .date i {
  margin-right: 5px;
}

.feedback-heading {
  padding: 4px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
  min-height: 50px;
}
.feedback-heading h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 0 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
