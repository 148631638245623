import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-logout-confirmation',
  templateUrl: './change-password-logout-confirmation.component.html',
  styleUrl: './change-password-logout-confirmation.component.scss',
})
export class ChangePasswordLogoutConfirmationComponent implements OnInit{
  
  constructor() {}

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit(): void {}

}
