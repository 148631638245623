import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-permission',
  templateUrl: './not-permission.component.html',
})
export class NotPermissionComponent implements OnInit {

  constructor() { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
  }

}
