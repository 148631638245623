// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primarycolor: #3180da;
    --primarytwocolor: #2460be;
    --darkcolor: #424242;
    --colorWhite: #ffffff;
    --lightGrey: #777777;
    --heading: #3b3b3b;
    --blackcolor: #000000;
    --poppins: "Poppins";
    --flaticon: "Flaticon";
    --opensans: "Open Sans";
    --f14: 14px;
    --yellow: #fdce1c;
    --redcolor: #ea4141;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
