// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-description-container {
  max-height: 115px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  display: block;
  width: 100%;
}
.course-description-container.open {
  max-height: none;
}

.readMoreBtn {
  text-align: right;
}
.readMoreBtn .btn {
  text-align: right;
  font-size: 13px;
  padding-bottom: 0;
}

.module-document-item {
  display: inline-block;
  min-width: 120px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.module-document-item a {
  position: relative;
  font-size: 17px;
  font-weight: 500;
}

.quiz-list-container button {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
  width: 100%;
  border: 0;
  text-align: left;
  background: transparent;
}
.quiz-list-container button .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container button.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container button.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}
.quiz-list-container button.is-complete .circle-item {
  border: 3px solid #fff;
}
.quiz-list-container button.is-complete .circle-item:before {
  position: absolute;
  top: -2px;
  content: "\\f121";
  font-size: 7px;
  color: #fff;
  font-family: var(--flaticon);
  background: #1f9c3a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -3px;
  padding: 3px 5px;
}
.quiz-list-container a {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
}
.quiz-list-container a .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container a.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container a.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}

.course-welcome-panel {
  box-shadow: none !important;
}
.course-welcome-panel .cwp-img {
  min-height: 250px;
  max-height: 250px;
  height: 250px;
}
.course-welcome-panel .cwp-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
