import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GroupLeaderService {
  jwt: any;

  private _refreshNeeded$ = new Subject<void>();
  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getInstituteLevelGroupSet(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getCourseLevelGroupSet(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getCourseGroups(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getGroupMembersByGroupId(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getGroupLeadersByGroupId(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  sendGroupLeaderNotification(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }



  removeSelectedGroupMembers(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  /**
  * @description Get Discussion List
  * @date 2019-12-09
  * @method getDiscussionsList
  * @memberof GroupLeaderService
  */
  getDiscussionsList(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }

  /**
  * @description Get Discussion Details
  * @date 2019-12-11
  * @method getDiscussionsDetails
  * @memberof GroupLeaderService
  */
  getDiscussionsDetails(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }

  /**
* @description Add Commnet
* @date 2019-12-11
* @method addUserCommnet
* @memberof GroupLeaderService
*/
  addUserCommnet(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }

  /**
* @description All Commnet List
* @date 2019-12-11
* @method allCommentDataHandler
* @memberof GroupLeaderService
*/
  allCommentDataHandler(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }
  /**
* @description Add Discussion
* @date 2019-12-12
* @method addDiscussionByUser
* @memberof GroupLeaderService
*/
  addDiscussionByUser(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }


  getGroupLeaderDropDowndata(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }


  getGroupLeaderGradBookData(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }


  checkIfUserAssignedGroupSet(data: any) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getGroupLeader(),
      data, {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
    )
  }






}
