// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glossaryDataWrapper {
  position: absolute;
  width: 400px;
  height: 300px;
  z-index: 999999;
}
.glossaryDataWrapper .btn {
  position: absolute;
  top: -11px;
  right: -10px;
  z-index: 1;
  font-size: 8px;
  font-weight: bolder;
  padding: 0;
  background: #dc3545 !important;
  border-color: #dc3545 !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.glossaryDataWrapper .btn:hover {
  background: #8a2d36 !important;
  border-color: #8a2d36 !important;
}
.glossaryDataWrapper:before {
  content: "";
  position: absolute;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #efefef transparent;
  top: -16px;
  left: 20px;
  z-index: 1;
}

.glossaryPopoverContainer {
  position: absolute;
  width: 400px;
  height: 300px;
  overflow: auto;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.glossaryPopoverContainer h2 {
  background: #efefef;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--poppins);
  padding: 14px;
}
.glossaryPopoverContainer .glossary-content {
  padding: 15px;
}
.glossaryPopoverContainer .glossary-content h5 {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
}
.glossaryPopoverContainer .glossary-content .lesson-img {
  margin-bottom: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
