import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { courseAssignedList, allCourses, courseDetail } from './../model/course';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StudentTimeService {

 // config = new Config;
 logUser = localStorage.getItem('loggedUser');
 jwt;

 constructor(private http: HttpClient, private apiService: ApiService) { }

 // tslint:disable-next-line: variable-name
 private _refreshNeeded$ = new Subject<void>();

 // tslint:disable-next-line: max-line-length
 moduleTimedata: { id: any; method: string; container_id: any; course_slug: any; section_slug: string; module_slug: any; module_time: string; page: string; };
 moduleStudentTimeStamp: any;

 saveStudentTime(data) {
   this.jwt = this.apiService.getJWT();
   return this.http.post(this.apiService.getApiURL() + this.apiService.getStudentTime(), JSON.stringify(data),
     { headers: this.apiService.setHeaderValJWT(this.jwt) }
   ).pipe(
     tap(() => {
       this._refreshNeeded$.next();
     })
   );
 }

}
