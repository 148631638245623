import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GradeBookService {
    jwt: any;
    constructor(private http: HttpClient, private apiservice: ApiService) { }
    getGradbookData(data: any) {
        this.jwt = localStorage.getItem('jwt');
        return this.http.post(
            this.apiservice.getApiURL() + this.apiservice.getGradeBook(),
            data, {
            headers: this.apiservice.setHeaderValJWT(this.jwt)
        }
        )
    }

    getInstructorGradeBookCourses(instData: any) {
        this.jwt = localStorage.getItem('jwt');
        return this.http.post(
            this.apiservice.getApiURL() + this.apiservice.getCourse(),
            instData, {
            headers: this.apiservice.setHeaderValJWT(this.jwt)
        }
        )
    }

    getInstructorArchivedGradeBookCourses(instData: any) {
        this.jwt = localStorage.getItem('jwt');
        return this.http.post(
            this.apiservice.getApiURL() + this.apiservice.getCourse(),
            instData, {
            headers: this.apiservice.setHeaderValJWT(this.jwt)
        }
        )
    }

    getInstructorGradBookData(instFetchParam: any) {
        this.jwt = localStorage.getItem('jwt');
        return this.http.post(
            this.apiservice.getApiURL() + this.apiservice.getGradeBook(),
            instFetchParam, {
            headers: this.apiservice.setHeaderValJWT(this.jwt)
        }
        )
    }

}
