import {AfterViewInit, Component, ViewChild ,  OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
// import {MatTableDataSource} from '@angular/material/table';
import { LiveStreamApiService } from './../../service/live-stream-api.service';
import { CommonService } from './../../../service/common.service';


export interface PeriodicElement {
  id:any
  first_name: string;
  last_name: string;
  email: string;
}

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})


export class ShareLinkComponent implements OnInit , AfterViewInit {
  @ViewChild(MatSort , { static: false }) sort: MatSort;
  @ViewChild(MatPaginator , {static:false}) paginator: MatPaginator;
  filterInput: any;

  constructor(private liveStream:LiveStreamApiService , private commonService: CommonService){

  }

  public members:PeriodicElement[] ;
  dataSource: MatTableDataSource<PeriodicElement>;
  selectedValues:any = [];

 link:any =  'http://localhost:4200/join-room/ffe671c2cbf16cee7401e2b17a458fac243628feff8c37eb6e786fa3debc76b7'
 roomName:any = 'apple'

  ngOnInit(){
    const postData = {
      id:this.commonService.getUserId(),
      containerId:this.commonService.getDomainContainerId(),
      method:'getAllUsers',
      roomName:this.roomName,
      link:this.link
    }
    this.liveStream.getAllUsers(postData).subscribe((data) => {
      console.log(data['data'])
      this.members = data['data']
    })

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.members);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    },500)

  }
  displayedColumns: string[] = ['select' ,  'id', 'first_name', 'last_name', 'email'];

  ngAfterViewInit() {
   this.filterInput = document.querySelector('#filter')
  }

  applyFilter(filterValue:any){
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clear(){
    this.filterInput.value  = ''
    this.applyFilter(this.filterInput.value)
  }

  tbodyChkSelected(event) {
    const checked = event.checked
    const value = event.source.value
    console.log(checked , value)
      if (checked) {
        this.selectedValues.push(value)
      } else {
        const index: number = this.selectedValues.indexOf(value);
        if (index !== -1) {
          this.selectedValues.splice(index, 1);
        }
      }
      console.log(this.selectedValues , 'selected values')
  }

  isSelected(val: any) {
      return this.selectedValues.indexOf(val) >= 0;
  }

  shareLink(){
    console.log(this.selectedValues)
    const postData = {
      id:this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      users:this.selectedValues,
      roomName:this.roomName,
      link:this.link,
      method:'shareRoomLink'
    }
    this.liveStream.shareRoomLink(postData).subscribe((data) => {
      console.log(data)
      this.ngOnInit()
    })

  }

}
