import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MethodService {

  constructor() { }

  // rot13(str:any) {
  //   var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  //   var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
  //   var index     = x => input.indexOf(x);
  //   var translate = x => index(x) > -1 ? output[index(x)] : x;
  //   return str.split('').map(translate).join('');
  // }
  
  str_rot13(str:any){
      return (str+'').replace(/[a-zA-Z]/gi,function(s){
          return String.fromCharCode(s.charCodeAt(0)+(s.toLowerCase()<'n'?13:-13))
      })
  }

  getDecodedDataFromString(string:any){
   const data:any =  atob(this.str_rot13(string));
   const decodeddata = data.split('**').shift();
   return decodeddata;
  }
}
