import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from './../../../../service/data.service';
import { PollService } from './../../../../service/poll.service';
import { CommonService } from './../../../../service/common.service';
@Component({
  selector: 'app-wiki-poll',
  templateUrl: './wiki-poll.component.html',
  styleUrls: ['./wiki-poll.component.scss']
})
export class WikiPollComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() pollId: any;
  @Output() output = new EventEmitter();
  
  pollQuestionForm: FormGroup;
  poll_id: FormControl;
  pollexist: boolean = false;
  poloption: FormControl;
  buttonTheme: any;
  currentBtnThemeContainer: Subscription;
  lang: number;
  currentLangContainer: Subscription;
  results: any;
  pollResultQues: any = [];
  poll_submited: boolean;
  pollDetails: any;
  pollDetailsOptions: any;
  optionStatus: any;
  userId: any;
  errorMessage: string;
  isLoading:Boolean = true
  pollTitle: any;
  noRecordErr: boolean;

  constructor(private fb: FormBuilder, private dataService: DataService, private pollService: PollService, private commonService: CommonService) {

    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.getMainLangHeader();
    }, error => error);
    this.getMainLangHeader();

    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  getMainLangHeader() {
    if (localStorage.getItem('locale') == 'ger') {
      this.lang = 1
    } else if (localStorage.getItem('locale') == 'en') {
      this.lang = 2
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   if (this.pollId > 0) {
    //     this.getPollDetails(this.pollId);
    //     this.getResultCount(this.pollId);
    //     this.checkPollSubmited(this.pollId, this.userId);
    //   } else {
    //     this.pollexist = false;
    //     this.pollDetails = null;
    //   }
    // }, 1000)

  }

  ngOnInit() {
    this.userId = this.commonService.getUserId()
    this.poll_submited = false;
    this.poll_id = this.fb.control('', Validators.required)
    this.poloption = this.fb.control('', Validators.required)

    this.pollQuestionForm = this.fb.group({
      poll_id: this.poll_id,
      poloption: this.poloption
    });

    this.checkPollSubmited(this.pollId, this.userId);
  }

  getPollDetails(poll) {
    const data = { method: "getPollByLang", poll_id: poll, lang: this.lang }
    this.pollService.getPollByid(poll).subscribe(res => {
      this.pollDetails = res['data'];
      this.pollTitle = this.pollDetails.title
      res['optionStatus'] == 1 ? this.pollexist = true : this.pollexist = false
      this.pollDetailsOptions = this.pollDetails.opts;
      this.optionStatus = res['optionStatus']
      this.isLoading = false;
    },
      error => error);
  }

  addPoll(event) {
    event.preventDefault()
    if (this.poloption.status == 'VALID') {
      const addPollData = {
        id: this.commonService.getUserId(),
        method: 'submitWikiPoll',
        contain_id: this.pollQuestionForm.value.poll_id,
        contain_type: this.commonService.containTypeArr['poll'],
        contain_answer: this.pollQuestionForm.value.poloption,
        container_id: this.commonService.getDomainContainerId()
      }

      this.pollService.submitWikiPoll(addPollData).subscribe(res => {
        this.poll_submited = true;
        this.getResultCount(this.pollQuestionForm.value.poll_id);
      });
    } else {
      this.errorMessage = 'Please select atleast one option';
    }

  }

  checkPollSubmited(poll_id, userId) {
    this.isLoading = true;
    this.pollService.checkPollSubmited(poll_id, userId).subscribe(res => {
      if (res['status'] == true) {
        this.poll_submited = true;
        this.getResultCount(this.pollId);  
      } else {
        this.poll_submited = false;
        this.getPollDetails(this.pollId);  
      }
    });
    // setTimeout(()=> {
    //   this.isLoading = false;
    // },1000)
   
  }

  getResultCount(poll_id) {
      // const data = {
      //   method: "getPollResultCountByLang",
      //   poll_id,
      //   lang: this.lang

      // }
      const data = { 
        method: "getPollResultCount", 
        poll_id
      }
      this.pollService.getPollCount(poll_id).subscribe(res => {
        if (res) {
          this.results = res['data'];
          this.pollTitle = res['pollTitle'];
          if (this.results[0] != undefined) {
            this.noRecordErr = false
            this.pollResultQues = this.results[0].question
            this.isLoading = false;
          }else{
            this.noRecordErr = true
            this.isLoading = false;
          }
        }
      });
    
  }

  ngOnDestroy() {
    this.currentLangContainer.unsubscribe();
  }

}
