import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketService {
  prop1 = 'Hello service';
  roomOn = false;

  constructor(private socket: Socket ) {  
    console.log('hellooooo')
  }

  setRoomStatus(value) {
    this.roomOn = value;
  }

  listen(eventName: string) {
    console.log('listen called')
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        subscriber.next(data)
      })
    })
  }

  emit(eventName:any , data:any){
    this.socket.emit(eventName , data)
  }

  socketConnect(){
    console.log('soccket service conn called')
    this.socket
  }
}
