import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../service/auth.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordLogoutConfirmationComponent } from 'src/app/dialogBox/change-password-logout-confirmation/change-password-logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss']
})
export class NewpasswordComponent implements OnInit {
  forgot_code: any;
  postData: any;
  userId: any;
  themestyle: any;
  siteDetail: any;
  newPasswordForm: FormGroup;
  newPassword: FormControl;
  cnfPassword: FormControl;
  method: string;
  message: string;
  alertClass: string = 'alert alert-danger';
  messageCode: any;
  language: string;
  dialogRef: any;
  logoutStatus : string = 'no';


  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService,
    private formBuilder: FormBuilder, private commonService: CommonService, private toastr: ToastrService,  private dialog: MatDialog ) {
    this.forgot_code = this.route.snapshot.paramMap.get('forgotCode')
    if (this.forgot_code == '') {
      this.router.navigate(['user-message'])
    }
  }

  ngOnInit() {
    this.language = localStorage.getItem('locale')
    this.checkForgotPasswordUser(this.forgot_code)
    this.siteDetail = localStorage.getItem('theme_style')
    this.themestyle = JSON.parse(this.siteDetail)

    this.newPassword = this.formBuilder.control('', [Validators.required])
    this.cnfPassword = this.formBuilder.control('', Validators.required)
    this.newPasswordForm = this.formBuilder.group({
      code: this.forgot_code,
      newPassword: this.newPassword,
      cnfPassword: this.cnfPassword,
      method: 'newPassword',
      lang_id: this.commonService.setSelectedValueByLang(),
      logout : this.logoutStatus
    });
    this.checkToken()
  }

  openSnackBar(message) {
    this.authService.openSnackBar(message);
  }

  /**
   * @description Check if forgot password code assigned to correct user present in database
   * @date 2019-09-16
   * @param {*} code
   * @memberof NewpasswordComponent
   */
  checkForgotPasswordUser(code) {
    this.postData = {
      code: code,
      method: 'checkForgotPasswordUser'
    }

    this.authService.checkForgotPasswordUser(this.postData).subscribe(res => {
      if (res['status']) {
        this.userId = res['userData'].id;
      } else {
        localStorage.setItem('userMessage', '1')
        // this.router.navigate(['user-message'])
      }

    })
  }


  /**
   * @description update new password for user
   * @memberof NewpasswordComponent
   */
  newPasswordEvent() {
    this.messageCode = null;
    this.message = '';
    if (this.newPasswordForm.status == 'VALID') {
      this.authService.newPassword(this.newPasswordForm.value).subscribe(res => {

        if (res['status']) {
          console.log("password updated successfully");
          localStorage.setItem('userMessage', '2')
          this.router.navigate(['user-message'])
        } else {
          this.messageCode = res['messageCode'];
          this.openSnackBar(res['message'])
        }
      })
    } else {
      this.message = 'Please fill all fields';   
    }
  }


  openChangePasswordLogoutConfirmation() {
    this.dialogRef = this.dialog.open(ChangePasswordLogoutConfirmationComponent, {
      disableClose: true
    });

    
    this.dialogRef.afterClosed().subscribe((res: any) => {
      if (res === '') {
        return;
      }
      
      this.logoutStatus = res ? 'yes' : 'no';
      this.newPasswordForm.patchValue({ logout: this.logoutStatus });
      this.newPasswordEvent();
    });
  }

  /**
   * @description Route to Login Page
   * @date 2019-09-16
   * @param {*} event
   * @memberof NewpasswordComponent
   */
  loginRoute(event) {
    event.preventDefault()
    this.router.navigate(['login'])
  }

  checkToken() {
    const data = {
      "code": this.forgot_code,
      "lang_id": this.commonService.setSelectedValueByLang(),
      "method": "checkRwToken"
    }

    this.authService.checkRawToken(data).subscribe(res => {
      console.log(res);
      
      console.log(res);
      if (!res['status']) {
        this.router.navigate(['user-message'])
      }
    })
  }

}
