import { Injectable, inject } from '@angular/core';
import {
  CanDeactivate, 
  CanDeactivateFn } from '@angular/router';
import { UserRoomComponent } from '../component/user-room/user-room.component';
import { SocketService } from '../stream/service/socket.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveStreamConfirmGuard implements CanDeactivate<UserRoomComponent>{
  constructor(private socketService: SocketService) {

  }
  canDeactivate(component: UserRoomComponent){
    if(this.socketService.roomOn == true) {
    return window.confirm('You will lost this session. Are you sure to leave ?')
    } else {
      return true;
  }
    //return component.canDeactivate ? component.canDeactivate() : true;
}
}


// export const LeaveStreamConfirmGuard : CanDeactivateFn<UserRoomComponent> = (component : UserRoomComponent) => {
//   const socketService = inject(SocketService) ; 

//   if(socketService.roomOn == true) {
//     return window.confirm('You will lost this session. Are you sure to leave ?')
//     } else {
//       return true;
//   }
// }
