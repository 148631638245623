import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteHeaderComponent , SitefooterComponent } from './../index';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
// import { MatMenuModule} from '@angular/material/menu';
import { MatMenuModule} from '@angular/material/menu';


import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LeaveStreamConfirmGuard } from '../guard/leave-stream-confirm.guard';
import { HostRoomLeaveGuard } from '../guard/host-room-leave.guard';
import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// export const createTranslateLoader = (http: HttpClient) => {
// 	return new TranslateHttpLoader(http, './assets/i18n/', '.json')
// }


export class CustomLoader implements TranslateLoader {

  constructor(private http: HttpClient , private apiService:ApiService) {}

  public getTranslation(lang: any): Observable<any> {
    let apiUrl:any = environment.BASE_API_URL;
    let langId:any = (lang=='ger') ? 1 : 2;
    let data =  {
      method:'getLangugeVariables',
      lang:langId
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': 'LMSAPI@123#'
    });

     return this.http.post(
      apiUrl + 'Language' , data, { headers: httpHeaders }
    ).pipe(
      map(
        (res:any) => {
          //console.log(res[0] , 'response')
          return res[0]
}
      )
    )
  }
}

//const liveStreamConfig: SocketIoConfig = { url: 'ws://localhost:3000', options: { transports: ['websocket'], allowUpgrades: true ,  reconnection: true } };
//const liveStreamConfig: SocketIoConfig = { url: 'wss://original-node.herokuapp.com/', options: { transports: ['websocket'], allowUpgrades: true ,  reconnection: true } };
const liveStreamConfig: SocketIoConfig = { url: 'wss://video-lms.azurewebsites.net/', options: {reconnection: true } };






@NgModule({
  declarations: [
    SiteHeaderComponent , SitefooterComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    SocketIoModule.forRoot(liveStreamConfig),
    TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				//useFactory: createTranslateLoader,
				 useClass: CustomLoader,
				deps: [HttpClient]
			}
		}),
  ],
  exports:[
    SiteHeaderComponent , SitefooterComponent , RouterModule , TranslateModule , ReactiveFormsModule, FormsModule ,
    SocketIoModule,MatCheckboxModule
  ],
  providers:[]
})
export class SharedModule {

 }
