import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import SignaturePad from "signature_pad";
import { CommonService } from 'src/app/service/common.service';
import { PresafetyService } from 'src/app/service/presafety.service';

@Component({
  selector: 'app-pre-safety-detail',
  templateUrl: './pre-safety-detail.component.html',
  styleUrl: './pre-safety-detail.component.scss'
})
export class PreSafetyDetailComponent implements OnInit, AfterViewInit {

  preSafe_id: any;
  lang: any;
  preSafeData: any;
  title: string;
  content: string;
  img_url: string;
  is_image: boolean = false;
  signStatus: any;
  hide: boolean = false;
  isSignPadEmpty: boolean = false;
  useremail: any;
  userid: any;
  presafetyCompleteStatus: any ;
  sitemanagerStatus : any ; 
  show : boolean = false ; 

  constructor(private preSafeService: PresafetyService, private route: ActivatedRoute, private commonService: CommonService, private router: Router, private sanitizer: DomSanitizer) {
    route.params.subscribe(res => {
      this.preSafe_id = atob(res.id);
    });
  }

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  sign: SignaturePad;

  ngOnInit(): void {
    if (localStorage.getItem('locale') === 'ger') {
      this.lang = 1;
    } else {
      this.lang = 2;
    }
    this.viewPreSafe();

    setTimeout(() => {
      this.presafetyCompleteStatus = localStorage.getItem('complete');
      this.show = true ; 
    }, 1500);

    this.preSafeService.briefingComplete.subscribe(res => {
      this.signStatus = res;
    })


    this.preSafeService.userId.subscribe(res => {
      this.userid = res;
    })

    this.preSafeService.userEmail.subscribe((res: any) => {
      this.useremail = res;
      this.preSafeService.emailReturn.next(this.useremail);
    })
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.canvas && this.canvas.nativeElement) {
        this.sign = new SignaturePad(this.canvas.nativeElement);
      }
    }, 2000);
  }

  viewPreSafe() {
    let containerData = JSON.parse(localStorage.getItem('theme_style'));
    console.log("theme style container...", containerData.id);

    const postData = {
      method: "viewBriefingDetail",
      record_id: this.preSafe_id,
      lang_id: this.lang,
      id: this.commonService.getUserId(),
      user_id: localStorage.getItem("uid"),
      container_id: containerData.id
    };

    this.preSafeService.viewBriefingDetail(postData).subscribe((res: any) => {
      if (res['jwt_status']) {
        if (res['status']) {
          this.preSafeData = res['data'];
          this.title = this.preSafeData.title;
          this.content = this.preSafeData.briefings_content;
          this.img_url = this.preSafeData.img_url;
          this.is_image = true;
          this.sitemanagerStatus = this.preSafeData.site_manage_status;
          if(this.sitemanagerStatus == 1){
            localStorage.setItem("complete", "complete");
            this.presafetyCompleteStatus == 'complete'
          }else{
            localStorage.setItem("complete", "pending");
            this.presafetyCompleteStatus == 'pending'
          }

          
          if (this.signStatus == 'complete') {
            this.hide = true;
          } else {
            this.hide = false;
          }
        }else{
          this.router.navigate(['pre-safety-list'])
        }
      } else {
        this.commonService.openSessionExpireDialog();
      }

      console.log("check the condition of image and complete status => ", this.img_url == '' && this.presafetyCompleteStatus == 'complete');

    });
  }

  get sanitizedContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  submitSignIfNotEmpty() {
    if (!this.sign) {
      console.error('Sign pad is not initialized.');
      return;
    }

    if (this.sign.isEmpty()) {
      this.isSignPadEmpty = true;
      setTimeout(() => {
        this.isSignPadEmpty = false;
      }, 2000);
    } else {
      this.submitSign();
    }
  }

  submitSign() {
    const postData = {
      method: "saveBriefingDetail",
      record_id: this.preSafe_id,
      lang_id: this.lang,
      id: this.commonService.getUserId(),
      user_id: localStorage.getItem("uid"),
      signature_image: this.sign.toDataURL()
    }
    this.preSafeService.saveBriefing(postData).subscribe((res: any) => {
      this.router.navigate(['/pre-safety-list'])
    })
    this.clearSign()
  }

  clearSign() {
    this.sign.clear()
  }


  routeToPreSafetyList() {
    this.router.navigate(['/pre-safety-list'])
  }
}
