import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { pollData } from './../model/poll';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class PollService {
  headers: any;
  jwt:any;
  constructor(private http: HttpClient, private apiService: ApiService) {

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': 'LMSAPI@123#'
    })
  }

  getPollByid(id): Observable<pollData[]> {
    return this.http.post<pollData[]>(this.apiService.getApiURL() + this.apiService.getPoll(),
     { "method": "getPoll", "poll_id":id}, { headers: this.headers }
    )
  }
  savePollResult(data): Observable<pollData[]> {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post<pollData[]>(this.apiService.getApiURL() + this.apiService.getCourse(), data,  {headers: this.apiService.setHeaderValJWT(this.jwt)}
    )
  }
  getPollCount(poll_id: number): Observable<pollData[]> {
    return this.http.post<pollData[]>(this.apiService.getApiURL() + this.apiService.getPoll(), { "method": "getPollResultCount", 'poll_id': poll_id }, { headers: this.headers }
    )
  }

  checkPollSubmited(poll_id: number, user_id: number, ): Observable<pollData[]> {
    return this.http.post<pollData[]>(this.apiService.getApiURL() + this.apiService.getPoll(), { "method": "ifPollSubmitted", 'poll_id': poll_id, 'user_id': user_id }, { headers: this.headers }
    )
  }

  submitWikiPoll(data:any) {
    return this.http.post<pollData[]>(this.apiService.getApiURL() + this.apiService.getPoll(), data, { headers: this.headers }
    )
  }

}
