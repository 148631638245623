// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardRight {
  padding-top: 25px;
}
.dashboardRight .noti-detail h3 {
  margin: 0;
  padding: 0 110px 0 0;
  font-size: 16px;
  color: #2f5784;
  position: relative;
  line-height: 22px;
  font-weight: bold;
}
.dashboardRight .noti-detail h3 a {
  color: #2f5784;
}
.dashboardRight .noti-detail h3 span {
  font-size: 13px;
  color: #7d7d7d;
  font-weight: normal;
  position: absolute;
  right: 0;
  top: 0;
}
.dashboardRight .noti-detail p {
  margin: 8px 0 0;
  padding: 0;
  font-size: 14px;
  font-family: var(--poppins);
  line-height: 22px;
  text-align: justify;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
