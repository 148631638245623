// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-section .heading {
  background: #445161;
  color: #fff;
  font-family: var(--poppins);
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.purchase-section .purchase-table .table th,
.purchase-section .purchase-table .table td {
  font-family: var(--poppins);
}
.purchase-section .purchase-table .table td.bgnone {
  background: none;
}
.purchase-section .purchase-table .table tfoot td {
  font-weight: 500;
}
.purchase-section .purchase-table .bg {
  font-weight: 500;
  background: #f2f2f2;
}
.purchase-section .purchase-table .bg-default {
  color: #000;
  background: #e9ecef;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
