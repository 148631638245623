import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html'
})
export class SessionExpiredComponent implements OnInit {

  constructor() { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
  }

}
