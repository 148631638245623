import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { Socket } from 'ngx-socket-io';
// import * as Socket from 'socket.io-client';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as SimplePeer from 'simple-peer/simplepeer.min';
import * as dateFormat from 'dateformat';
import { CommonService } from './../../../service/common.service';
import { StreamingService } from './../../service/streaming.service';
import { SocketService } from './../../service/socket.service';
import { LiveStreamApiService } from './../../service/live-stream-api.service'
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ShareLinkComponent } from './../../dialog-box/share-link/share-link.component';
import { DeleteNotificationComponent } from 'src/app/dialogBox/delete-notification/delete-notification.component';
import { GeneralMessageComponent } from 'src/app/dialogBox/general-message/general-message.component';


@Component({
  selector: 'app-host-room',
  templateUrl: './host-room.component.html',
  styleUrls: ['./host-room.component.scss']
})
export class HostRoomComponent implements OnInit , OnDestroy {
  newRoomName: string;
  canDeactivate: any;
  //   @HostListener('window:beforeunload', ['$event'])
  //   onWindowClose(event: any): void {
  //    // Do something
  //   this.apple()

  //     event.preventDefault();
  //     event.returnValue = false;
  //  }

  // @HostListener('window:beforeunload', ['$event'])
  // onWindowClose(event: any): void {
  //   this.callEndBtn.nativeElement.click();
  // }


  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    (<any>$("#callEndButton")).trigger('click')
  }

  dynamicPeerUser: HTMLElement;
  encryptedRoom: any;
  dialogRef: any;
  hostRoomId: any;

  //@HostListener('window:beforeunload')


  @ViewChild("userVideo", { static: true }) userVideo: ElementRef<HTMLVideoElement>;

  @ViewChild('callEndBtn', { static: false }) callEndBtn: ElementRef<HTMLElement>;

  @ViewChild('joinButton' , { static:false }) joinButton:ElementRef<HTMLElement>;



  displayedColumns = ["seqNo", "description", "duration"];
  public localStream: MediaStream;
  hostRoomForm: FormGroup;
  username: FormControl;
  roomName: FormControl;
  chatWindowForm: FormGroup;
  chatMessage: FormControl;
  lobbyStatus: any = 0
  getUserName: any;
  validatefields: Boolean = false
  socket: any;
  peers: any = {}
  levelStatus: Boolean = true;
  audioToggle: boolean = false;
  videoToggle: boolean = true;
  hostUserId:any;
  showJoinBtn:Boolean = true

  roomCount:number = 1

  constraints = {
    // audio: {
    //   sampleRate: 48000,
    //   channelCount: {
    //     ideal: 2,
    //     min: 1
    //   },
    //   volume: 1,
    //   echoCancellation: true,
    //   noiseSuppression: false,
    //   googAutoGainControl: false
    // },
    // video: {
    //     width: { min: 640, ideal: 720, max: 1920 },
    //     height: { min: 480, ideal: 510, max: 1080 },
    //     frameRate: {
    //         ideal: 50,
    //         min: 10
    //     }
    // },
    audio: true,
    video: true
  };
  iceServers = {
    // trickle: false,
    // reconnectTimer: 100,
    // iceTransportPolicy : 'relay',
    iceServers: [
      // {
      //   urls: 'turn:turn.retteracademy.com',
      // },
      // {
      //   urls: 'turn:numb.viagenie.ca:5389',
      //   credential: 'W{sndp@6600}R',
      //   username: 'sandeep.bansod.007@gmail.com'
      // },
      {
        url: 'turn:numb.viagenie.ca',
        credential: 'muazkh',
        username: 'webrtc@live.com'
      },
      { urls:'stun:stun.retteracademy.com' },
      { urls: "stun:stun.services.mozilla.com" },
      { urls: "stun:stun1.l.google.com:19302" },
    ],

//gkc xirsys
    // iceServers: [
    //   {  
    //    urls: [ "stun:bn-turn1.xirsys.com" ]
    //   },
    //   {   username: "WYKBwTRD_upUkS1i9z37Ij4e0HvGB7rbtIGyrDaQ0X8mnvts8mXTmb89ciFG6Q-jAAAAAGB5OLNjaGdhdjAwNw==",  
    //    credential: "01eb8a56-9e83-11eb-8bbf-0242ac140004",
    //    urls: [       "turn:bn-turn1.xirsys.com:80?transport=udp",       "turn:bn-turn1.xirsys.com:3478?transport=udp",       "turn:bn-turn1.xirsys.com:80?transport=tcp",       "turn:bn-turn1.xirsys.com:3478?transport=tcp",       "turns:bn-turn1.xirsys.com:443?transport=tcp",       "turns:bn-turn1.xirsys.com:5349?transport=tcp"  
    //    ]
    //   }
    // ],


    sdpSemantics: 'unified-plan',
//sand xissys
    // iceServers: [{
    //   urls: ["stun:bn-turn1.xirsys.com"]
    // }, {
    //   username: "wzRMdRj6FprYWpkNBVhrlJNSoNUcJ_8xCpDBYxWtxLVIR1LGPpjtyJA8wKLP9lz5AAAAAF_1TdJzYW5kZWVwMTIzNA==",
    //   credential: "febb10a8-4fe1-11eb-be88-0242ac140004",
    //   urls: [
    //     "turn:bn-turn1.xirsys.com:80?transport=udp",
    //     "turn:bn-turn1.xirsys.com:3478?transport=udp",
    //     "turn:bn-turn1.xirsys.com:80?transport=tcp",
    //     "turn:bn-turn1.xirsys.com:3478?transport=tcp",
    //     "turns:bn-turn1.xirsys.com:443?transport=tcp",
    //     "turns:bn-turn1.xirsys.com:5349?transport=tcp"
    //   ]
    // }]
  };
  localStreamIdName: HTMLElement;
  urlGenShare: HTMLElement;
  hostUser: any;
  peerDynamicElement: HTMLElement;
  outputContainer: HTMLElement;
  videoChatWindow: HTMLElement;
  chatOption: HTMLElement;
  callEndButton: HTMLElement;

  showURLGen:Boolean = true


  constructor(private commonService: CommonService, private socketContainer: Socket, private _fb: FormBuilder, private changeDetector: ChangeDetectorRef, private renderer: Renderer2, private streamingService: StreamingService, private socketService: SocketService, private liveStream: LiveStreamApiService, private dialog: MatDialog) { }

  apple() {
    console.log(this.callEndBtn.nativeElement.click(), 'apple')
  }


  ngOnInit() {
    const verifyData = {
      method:"verifyHostLogin" , id: this.commonService.getUserId()
    }
    this.liveStream.verifyHostLogin(verifyData).subscribe((res) => {
      console.log(res , 'res in stream module')
      if(!res['jwt_status']){
        this.commonService.openSessionExpireDialog()
      }
    })
    this.hostUserId = this.commonService.getUserId()
    this.socket = this.socketContainer.connect()
    this.getUserName = this.commonService.getUserFullName()
    console.log(this.getUserName)
    this.username = this._fb.control('', Validators.required)
    this.roomName = this._fb.control('', Validators.required)
    this.chatMessage = this._fb.control('', Validators.required)
    this.hostRoomForm = this._fb.group({
      username: this.username,
      roomName: this.roomName
    })
    this.chatWindowForm = this._fb.group({
      chatMessage: this.chatMessage
    })
    this.username.setValue(this.getUserName)
    this.initConnections();

  //  document.getElementById('testClick').addEventListener('click' , () => {
  //     this.socket.emit('receive event' , 'Tokyo is hosting olympic 2021 ! yahoo !')
  //   })


    // setInterval(() => {
    //   (<any>$("#testClick")).trigger('click')
    // },5000)

  //   window.addEventListener('beforeunload', (event) => {
  //     // event.returnValue = `You have unsaved changes, leave anyway?`;
  //     console.log('tokypo');
  //     (<any>$("#testClick")).trigger('click')
  //     //event.preventDefault()
  //     //this.apple()
  //     //event.returnValue = false;
  // });
  }

  // testClick(){
  //  this.socket.emit('receive event' , 'Tokyo is hosting olympic 2021 ! yahoo !')
  // }


  ngAfterViewInit() {
    this.localStreamIdName = document.getElementById("localStreamIdName");
    this.urlGenShare = document.getElementById('urlgen-share');
    this.peerDynamicElement = document.getElementById("peerDynamicElement");
    this.outputContainer = document.getElementById("output-container");
    this.videoChatWindow = document.getElementById("video-chat-window")
    this.chatOption = document.getElementById("chatOption");
    this.callEndButton = document.getElementById('callEndButton')
    this.dynamicPeerUser = document.getElementById('dynamic-peer-user')
    console.log(this.userVideo, 'userVideo')
    console.log(this.joinButton , 'joinbutton')
  }

  createRoom(event) {

    if (this.hostRoomForm.status == 'VALID') {
      this.validatefields = false
      this.startRoom()
    } else {
      this.validatefields = true
    }
  }

  startRoom() {
    console.log('I am called');
    console.log('roomOn : ', this.socketService.roomOn);
    this.socketService.setRoomStatus(true);
    console.log('roomOn : ', this.socketService.roomOn);

    this.showJoinBtn = false
    let newDateTime = (new Date()).getTime();
    this.newRoomName = this.hostRoomForm.value.roomName + '_' + newDateTime;
    //let roomURL = this.hostRoomForm.value.roomName + '_' + newDateTime + 'lms@r0man21' + this.socket.id;
    const app = formatDate(new Date(), 'yyyy/MM/dd ,  h:mm a', 'en');
    this.socketService.emit('join', this.newRoomName);
  }

  openShareLinkQuizDialog(link, hostRoomId) {
    this.dialogRef = this.dialog.open(ShareLinkComponent, {
      data: {
        roomName: this.hostRoomForm.value.roomName,
        link,
        hostRoomId
      },
      width: '70%',
      height: '100%',


    });
    this.dialogRef.afterClosed().subscribe(res => {

    });
  }


  openGeneralDialogBox(message) {
    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: message
      }
    })
    this.dialogRef.afterClosed().subscribe(res => {
      if (res == 'true') {
       window.location.reload()
      }
    })
  }

  initConnections() {
    let userVideoID = this.userVideo.nativeElement;
    this.socketService.listen('created').subscribe((data) => {
      this.encryptedRoom = data['encrypted']
      this.levelStatus = data['level'];
      navigator.mediaDevices.getUserMedia(this.constraints)
        .then((stream: MediaStream) => {
          this.lobbyStatus = 1
          //this.localStreamIdName.innerHTML = data['socketId']
          this.localStreamIdName.innerHTML = this.getUserName
          userVideoID.srcObject = stream;
          this.localStream = stream;
          this.initFunction()
          this.socketInit()
          const postData = {
            id: this.commonService.getUserId(),
            container_id: this.commonService.getDomainContainerId(),
            room: this.hostRoomForm.value.roomName,
            method: 'createHostRoom',
            encryptedRoom: this.encryptedRoom,
            socketId: data['socketId']
          }
          this.liveStream.createHostRoom(postData).subscribe((res) => {
            if (!res['jwt_status']) {
              this.commonService.openSessionExpireDialog();
              return
            }
            this.hostRoomId = res['host_room_id']
            console.log(res, 'service returned')
            //this.showURLGen = false
          })
        }).catch((error) => {
          let videoSrcErr = "%c Error " + "%c " + error +  ' , color:#fff;background:#f00' +  ', color:#f00;background:#ffeded'
          this.openGeneralDialogBox(error + '.' + 'Please check your device and try again.')
          console.log(videoSrcErr)
        })
    })


    this.socketService.listen('urlgen').subscribe((urldata) => {
      if (urldata != null) {
        let hostParam = window.location.origin;
        let genPath = hostParam + '/join-room/' + urldata;
        console.log(genPath)
        console.log(urldata)
        this.urlGenShare.innerHTML = genPath;
        setTimeout(() => {
          this.showURLGen = false
          },500)

      }
    })



    this.socket.on('clientJoined', (socketId) => {
      console.log('clientJoined', socketId)
      console.log(this.peers)
    })

    this.socket.on("clientName", (name, id, webID) => {
      //let flag = 0
      console.log(this.levelStatus, 'levelStatus on event clientName')
      if (this.levelStatus) {
        console.log("Host name", name)
        console.log("client id", id)
        // clientRemoteName = name;
        setTimeout(() => {
          let getRemoteElm = document.querySelectorAll("[client-id]");
          console.log(getRemoteElm)
          getRemoteElm.forEach(element => {
            let getElmId = element.getAttribute("client-id");
           // if (flag === 1) return false
            if (getElmId === id) {
             // flag = 1
              console.log(element)
              $(element).show()
              console.log(element.children[0])
              console.log(element.children[0].innerHTML = name)
            }
          });
        }, 800)
        this.socket.emit('getRoomCount' , this.socket.id)
      } else {
        console.log("Host Name", name, webID)
        if (webID == 'HID') {
          localStorage.setItem("HIDNAME", name);
        }
        // setTimeout( ()=>{
        //     let getRemoteElm = document.querySelector('#'+id);
        //     console.log(getRemoteElm)
        // }, 500)
      }
    })

    this.socket.on('hostConnection', (socket_id) => {
      console.log('hostConnection called in host !')
      if (!this.levelStatus) {
        this.hostUser = socket_id;
        console.log('hostConnection', socket_id)
      }
    });

    this.socketContainer.on("broadcastMessages", (msgData) => {
      console.log(msgData, 'in broadcast')

      if(msgData.adminMsg){
        this.outputContainer.innerHTML += '<div class="adminMsg"><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
      }else{
        if (msgData.username !== this.getUserName) {
          this.outputContainer.innerHTML += '<div class="message-left"><div class="messgae-user">' + msgData.username + '</div><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
        } else {
          this.outputContainer.innerHTML += '<div class="message-right"><div class="messgae-user">' + msgData.username + '</div><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
        }
      }


      if (!this.videoChatWindow.classList.contains('open')) {
        this.chatOption.classList.add('highlight');
      }else{
        this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
      }
    })
  }

  shareLinkToUsers(){
    console.log(this.urlGenShare.innerHTML)
    this.openShareLinkQuizDialog(this.urlGenShare.innerHTML, this.hostRoomId)
    }

  getUrlCopy() {
    if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(this.urlGenShare);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }

  }

  socketInit() {
    console.log('socket init called')
    this.socketService.socketConnect()
  }

  initFunction() {
    console.log('init Called')
    this.socket = this.socketContainer.connect()
    console.log('initStream called')
    this.socket.on('initReceive', socket_id => {
      console.log('INIT RECEIVE ' + socket_id + 'levelStatus ' + this.levelStatus)
      console.log(this.peers, 'peers')
      this.addPeer(socket_id, false)
      if (this.levelStatus) {
        this.socket.emit('hostConnection', this.socket.id);
        this.socket.emit('clientName', this.getUserName, this.socket.id, 'HID');
      }
      this.socket.emit('initSend', socket_id)
    })

    this.socket.on('initSend', socket_id => {
      console.log('INIT SEND ' + socket_id + ' levelstatus ' + this.levelStatus)
      if (!this.levelStatus) {
        console.log('yoyo')
        this.socket.emit("clientName", this.getUserName, this.socket.id, 'UID');
      }
      this.addPeer(socket_id, true)
    })


    this.socket.on('disconnect', () => {
      console.log('GOT DISCONNECTED')
      for (let socket_id in this.peers) {
        this.removePeer(socket_id)
      }
      this.callEndButtonFn()
    })

    this.socket.on('signal', data => {
      console.log('host signal called  data.socket.id ' + data.socket_id)
      console.log('host signal called  data.signal ' + data.signal)
      this.peers[data.socket_id].signal(data.signal)
    })

    this.socket.on('removePeer', socket_id => {
      console.log('removing peer ' + socket_id)
      this.removePeer(socket_id)
    })

    this.socket.on('removeAllPeer', socket_id => {
      console.log('removing peer ' + socket_id)
      this.removeAllPeerConnections(socket_id)
    })

    this.socket.on('sendRoomCount' , (roomCount) => {
            console.log(roomCount + '  in host room count')
            this.roomCount = roomCount
    })

    this.socket.on('sendLeftMsg' , (socketUserName) => {
      let currentDateTime = new Date();
      let hostMsg = ' ' + socketUserName + ' has left'
      this.socket.emit("sendingMessage", {
        message: hostMsg,
        dateTime: dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"),
        roomName: this.newRoomName,
        adminMsg:true
      });
    })
  }

  removeAllPeerConnections(socket_id) {
    let videoEl = document.getElementById(socket_id)
    this.localStream.getTracks().forEach(function (track) {
      track.stop();
    });
    console.log(this.localStream.getTracks())
    this.lobbyStatus = 0

    // $(clientButtonGroup).hide()
    $(this.dynamicPeerUser).html('')
    localStorage.removeItem('clientId');
    localStorage.removeItem('HIDNAME');
    if (this.peers[socket_id]) this.peers[socket_id].destroy()
    delete this.peers[socket_id]

  }

  chatOptionFn(event) {
    event.target.classList.toggle('off')
    event.target.classList.remove('highlight');
    this.videoChatWindow.classList.toggle('open');
    if (this.videoChatWindow.classList.contains('open')) {
    this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
    }
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      console.log(e.target.value);
      let data = e.target.value;
      if (data.replaceAll(/\s/g, '') != '') {
        this.sendMessage();
      } else {
        this.chatWindowForm.patchValue({ chatMessage: '' });
      }
    }
  }

  callEndButtonFn() {
    console.log('callEndButtonFn')
    //this.socket.emit('receive event', 'pine pine pine pine ')
    this.socketService.setRoomStatus(false);
    this.socket.emit('disconnectAllPeers');
    let getHostId = localStorage.getItem('hostId');
    this.removePeer(getHostId)
    if (this.localStream != undefined) {
      console.log(this.localStream.getTracks())
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.hostRoomForm.value.roomName = '';
    this.hostRoomForm.value.username = this.getUserName

    // $(clientButtonGroup).hide()
    $(this.dynamicPeerUser).html('')
    localStorage.removeItem('hostId');
    localStorage.removeItem('HIDNAME');
    const postData = {
      id: this.commonService.getUserId(),
      method: 'expireHostRoom',
      hostRoom: this.encryptedRoom
    }
    this.liveStream.expireHostRoom(postData).subscribe((res) => {
      window.location.reload();
    })
  }
  switchCameraForMobileFn(event) { }
  closeChatWindowFn(event) {
    this.chatOption.classList.add('off');
    this.videoChatWindow.classList.remove('open');
  }
  sendMessage() {
    let currentDateTime = new Date();
    console.log(dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"));
    console.log(this.chatWindowForm.value.chatMessage)
    if (this.chatWindowForm.value.chatMessage != '') {

      this.socket.emit("sendingMessage", {
        message: this.chatMessage.value,
        username: this.getUserName,
        dateTime: dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"),
        roomName: this.newRoomName
      });
    }
    this.chatMessage.setValue('')
    this.streamingService.getMessage().subscribe((msgData) => { console.log(msgData) })
  }
  audioButtonFn(event) {
    this.audioToggle = !this.audioToggle;
    this.localStream.getAudioTracks()[0].enabled = this.audioToggle
    event.target.classList.toggle("off");
  }
  videoButtonFn(event) {
    this.videoToggle = !this.videoToggle;
    console.log(this.localStream)
    this.localStream.getVideoTracks()[0].enabled = this.videoToggle
    event.target.classList.toggle("off");
    this.socket.emit("hostCameraOff", this.videoToggle);
    $(".no-local-camera-data").css({ display: this.videoToggle ? 'none' : 'block' })
  }


  addPeer(socket_id, am_initiator) {
    this.peers[socket_id] = new SimplePeer({
      initiator: am_initiator,
      stream: this.localStream,
      config: this.iceServers
    })

    this.peers[socket_id].on('signal', data => {
      console.log('signal yaha se hai')
      this.socket.emit('signal', {
        signal: data,
        socket_id: socket_id
      })
    })
    console.log(this.peers)
    console.log("hostUser", this.hostUser, socket_id)
      console.log(this.peers);
      console.log(this.peers[socket_id]);
      let newVid = document.createElement('div')
      let divElm = document.createElement('div');
      let spanremoteElm = document.createElement('span');
      let discButton = document.createElement('button');
      spanremoteElm.innerHTML = socket_id;
      discButton.innerHTML = '<i class="fa"></i>';
      discButton.className = 'client-end-call-button';
      discButton.setAttribute('id', socket_id);
      console.log(discButton, 'discButton')
      discButton.addEventListener("click", (event: Event) => {
        let $thisId = socket_id
        console.log($thisId)
        console.log(event)
        console.log(event.target)
        var target = event.target;
        var idAttr = target;
        console.log(idAttr, 'idAttr')
        // var value = idAttr.nodeValue;
        console.log(this)
        this.socket.emit("closeSinglePeer", $thisId);
      });
      console.log(discButton, 'discButton')

      divElm.className = 'item';
      newVid.className = 'icon';
      divElm.setAttribute('client-id', socket_id);
      divElm.append(spanremoteElm)
      divElm.append(discButton)
      divElm.append(newVid)
      this.peerDynamicElement.append(divElm)
      console.log(this.peerDynamicElement, 'peerDynamicElement')

  }

  removePeer(socket_id) {
    let videoEl: any = document.getElementById(socket_id)
    if (videoEl) {
      console.log($(videoEl)[0].nodeName)
      if ($(videoEl)[0].nodeName != "BUTTON") {
        let tracks = videoEl.srcObject.getTracks();
        tracks.forEach(function (track) {
          track.stop()
        })
        videoEl.srcObject = null
      }
      $(videoEl).parent('div.item').remove();
    }
    if (this.peers[socket_id]) this.peers[socket_id].destroy()
    delete this.peers[socket_id]
  }


  ngOnDestroy(){
    this.socketService.setRoomStatus(false);
    //this.socket.disconnect()
    //this.socket.close()
    this.socket.disconnect(true)
    //this.socket.emit('forceDisconnect' , 'data');
  }

}
