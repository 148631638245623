import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { UserService } from './../../service/user.service';
import { CommonService } from './../../service/common.service';
import { SiteDomainService } from './../../service/sitedomain.service';
import { DataService } from './../../service/data.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeleteNotificationComponent } from './../../dialogBox/delete-notification/delete-notification.component';



@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {
  notificaitonId: any;
  currentNotificationContainer: Subscription;
  isLoading: boolean = true
  dialogRef: any;

  totalLengthOfPage: any[] = [];
  pagingLimit: number = 20;
  increasCount: number = 0;
  paginationClick: boolean = false;
  initialData: { id: any; method: string; pageNumber: number; };

  constructor(private userService: UserService, private commonService: CommonService,
    private siteDomainService: SiteDomainService, private dataService: DataService, private router: Router,
    private dialog: MatDialog, private renderer: Renderer2) {

    this.initialData = {
      id: this.commonService.getUserId(),
      method: 'notificationsList',
      pageNumber: 0,
    }

    this.currentNotificationContainer = this.dataService.currentNotificationId.subscribe(res => {
      this.notificaitonId = res;
    });
  }

  userNotificationPostData: any;
  notificationList: any;
  p: number = 1;

  ngOnInit() {
    this.updateNotificationReadStatus();
    localStorage.setItem('userNotificationCounter', '0');
    this.getUserNotifications(this.initialData, 0);

  }

  ngOnDestroy() {
    localStorage.removeItem('userNotificationCounter');
  }


  /**
   * @description update notification read status
   * @date 2019-09-16
   * @memberof NotificationListComponent
   */
  updateNotificationReadStatus() {
    if (this.notificaitonId[0] != 0) {
      const updateNotificationData = {
        method: 'updateReadStatus',
        notification_id: this.notificaitonId,
        id: this.commonService.getUserId()
      };
      this.siteDomainService.updateNotificationReadStatus(updateNotificationData).subscribe(res => {
        if (res['status']) {
          this.siteDomainService.notificationCount = 0;
        }
      });
    }

  }



  /**
   * @description get user notification details
   * @date 2019-09-16
   * @memberof NotificationListComponent
   */
  getUserNotifications(data, pageCount = 0) {
    this.totalLengthOfPage = [];
    this.increasCount = 0;
    if (pageCount > 0) {
      data.pageNumber = pageCount;
    }

    this.userService.getUserNotifications(data).subscribe(res => {
      this.isLoading = false
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog()
      } else {
        if (res['status']) {
          this.notificationList = res['data'];
          const getTotalCount = parseInt(res['recordsCount']);
          const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);
          for (let i = 1; i <= roundOffCount; i++) {
            const pageItenData = {
              pageItem: this.increasCount
            }
            this.totalLengthOfPage.push(pageItenData);
            this.increasCount = this.increasCount + 20;
          }
          setTimeout(() => {
            const getPagingId = localStorage.getItem('userNotificationCounter');
            let pagingElement: any = '';
            pagingElement = document.getElementById('paging_link_' + getPagingId);
            if (pagingElement != null) {
              this.renderer.addClass(pagingElement, 'active');
            }
          }, 100);
        } else {

        }

      }
    })
    this.paginationClick = false;
  }

  setCounterLink(counterId: any) {
    localStorage.setItem('userNotificationCounter', counterId);
  }

  getDataById(getCurrentPageCount: any) {
    this.paginationClick = true;
    const formPostData: any = {
      id: this.commonService.getUserId(),
      method: 'notificationsList',
      pageNumber: 0,
    }
    this.getUserNotifications(formPostData, getCurrentPageCount)
    this.setCounterLink(getCurrentPageCount)
  }



  deleteUserNotification(notificationId) {
    this.openGeneralDialogBox(notificationId)
  }

  openGeneralDialogBox(notificationId) {
    this.dialogRef = this.dialog.open(DeleteNotificationComponent)
    this.dialogRef.afterClosed().subscribe(res => {
      if (res == 'true') {
        const data = {
          id: this.commonService.getUserId(),
          method: 'deleteUserNotification',
          notification_id: notificationId
        }

        this.userService.deleteUserNotification(data).subscribe(res => {
          // if (res['status']) {
          //   this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
          //     this.router.navigate(['notification-list']);
          //   });
          // }
          if (res['status']) {
            // Refresh the current route
            const currentUrl = this.router.url;
            this.router.navigate(['notification-detail'], { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          } else {
            console.error('Failed to delete notification.');
          }
        })
        // const currentUrl = this.router.url;
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([currentUrl]);
        // });
      }
    })
  }

  showNotificationDetail(){
  }

}
