import { Component, OnInit } from '@angular/core';
import { UserService } from './../../service/user.service';
import { CommonService } from './../../service/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {
  messageDetailData: any;
  messageId: any;
  messageDetail: any;
  readMessageData: any;

  constructor(private userService: UserService, private commonService: CommonService, private router: Router,
    private route: ActivatedRoute) {
    this.messageId = this.route.snapshot.paramMap.get('messageId')
  }

  ngOnInit() {
    this.updateMessageReadStatus()
    this.getUserMessageDetail()
  }

  /**
   * @description update message read status 
   * @date 2019-09-16
   * @memberof MessageDetailComponent
   */
  updateMessageReadStatus() {
    this.readMessageData = {
      id: this.commonService.getUserId(),
      method: 'updateReadStatusofMessage',
      messageId: this.messageId
    }
    this.userService.updateMessageReadStatus(this.readMessageData).subscribe(res => {
    })
  }


  /**
   * @description get user message detail
   * @date 2019-09-16
   * @memberof MessageDetailComponent
   */
  getUserMessageDetail() {
    this.messageDetailData = {
      id: this.commonService.getUserId(),
      method: 'getMessageByMessageId',
      messageId: this.messageId
    }
    this.userService.getUserMessageDetail(this.messageDetailData).subscribe(res => {
      this.messageDetail = res['list']
    })
  }

}
