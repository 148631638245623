import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as dateFormat from 'dateformat';
import { CommonService } from './../../../service/common.service';
import { LiveStreamApiService } from './../../service/live-stream-api.service'
import { formatDate } from '@angular/common'

@Component({
  selector: 'app-create-host-room',
  templateUrl: './create-host-room.component.html',
  styleUrls: ['./create-host-room.component.scss']
})
export class CreateHostRoomComponent implements OnInit {
  hostRoomForm: FormGroup;
  username: FormControl;
  roomName: FormControl;
  getUserName: any;
  validatefields: Boolean = false
  constructor(private _fb: FormBuilder, private commonService: CommonService , private liveStream:LiveStreamApiService) { }

  ngOnInit() {
    this.username = this._fb.control('', Validators.required)
    this.roomName = this._fb.control('', Validators.required)
    this.getUserName = this.commonService.getUserName()
    this.hostRoomForm = this._fb.group({
      username: this.username,
      roomName: this.roomName
    })
    this.username.setValue(this.getUserName)
  }

  createRoom(event) {
    if (this.hostRoomForm.status == 'VALID') {
      this.validatefields = false
      this.startRoom()
    } else {
      this.validatefields = true
    }
  }

  startRoom() {
    let newDateTime = (new Date()).getTime();
    console.log(newDateTime, 'newDate')
    let newRoomName = this.hostRoomForm.value.roomName + '_' + newDateTime;
    const app = formatDate(new Date(), 'yyyy/MM/dd ,  h:mm a', 'en');
    console.log(app, 'app')
    console.log(formatDate(1621874456489, 'yyyy/MM/dd ,  h:mm a', 'en'))
    console.log(newRoomName, 'newRoomName')
  }


}
