// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-library-filter {
  padding: 6px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
}
.video-library-filter h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px 7px;
}
.video-library-filter h4 .badge {
  color: #212529;
  background-color: #ffc107;
  margin-left: 5px;
  font-size: 13px;
  padding: 4px 12px;
}
.video-library-filter .form-group {
  display: inline-flex;
  min-width: 90%;
  margin: 0;
  position: relative;
}
.video-library-filter .form-group .select-small {
  width: auto !important;
}
.video-library-filter .form-group .error {
  position: absolute;
  bottom: auto;
  width: 50%;
  top: 50px;
  left: 0;
  text-align: left;
  background: #fffafa;
  padding: 5px 15px;
  color: #c12d2d;
  border: 1px dashed #fba3a3;
  border-radius: 5px;
  z-index: 5;
}
.video-library-filter .form-group .error::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 6px;
  border-color: transparent transparent #c76a6a transparent;
  top: -12px;
}
.video-library-filter .form-group .input-group-text {
  cursor: pointer;
}
.video-library-filter .form-group + .form-group {
  margin-left: 15px;
}

.video-category-heading {
  margin-bottom: 15px !important;
}
.video-category-heading h4 {
  font-size: 19px;
  font-weight: bold;
  margin: 5px 0;
}
.video-category-heading .btn {
  padding: 0px 15px;
  font-size: 14px;
}

.video-library-list {
  margin-top: 30px !important;
  position: relative;
}
.video-library-list:after {
  content: "";
  position: absolute;
  width: 97%;
  bottom: -14px;
  left: 0;
  height: 2px;
  background: #ececec;
  right: 0;
  margin: auto;
}
.video-library-list .card {
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.125);
  margin-bottom: 20px;
}
.video-library-list .card:hover .card-img a i {
  background: #121314;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
}
.video-library-list .card .card-img a {
  display: block;
  height: 140px;
  position: relative;
  cursor: pointer;
  background: url("/assets/images/no-image-big.jpg") #ddd;
}
.video-library-list .card .card-img a img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video-library-list .card .card-img a i {
  position: absolute;
  bottom: -17px;
  width: 35px;
  height: 35px;
  background: #dc3545;
  border-radius: 50%;
  right: 20px;
  padding: 10px 11px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.2);
}
.video-library-list .card-body {
  padding: 0;
}
.video-library-list .card-body a {
  display: block;
  padding: 15px;
  text-decoration: none;
  height: 110px;
  cursor: pointer;
}
.video-library-list .card-body a .card-title {
  font-size: 19px;
  font-weight: 600;
  color: #121314;
}
.video-library-list .card-body a .card-text {
  color: #525252;
  font-size: 15px;
}

.pagination .page-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.pagination .page-item.disabled .page-link {
  background: #e0e0e0;
}

@media (max-width: 1920px) and (min-width: 1800px) {
  .video-library-item {
    flex: 0 0 20%;
  }
}
@media (min-width: 1800px) {
  .video-library-item {
    flex: 0 0 20%;
  }
}
@media (max-width: 1799px) and (min-width: 1600px) {
  .video-library-item {
    flex: 0 0 20%;
  }
}
.panel.panel-course .panel-body a {
  min-height: 60px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
