// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagenotfound {
  min-height: calc(100% - 155px);
}

.password-expired {
  background: #fdf9fa;
  padding: 55px;
  border: 2px dashed #f3d9e0;
  border-radius: 5px;
  margin: 0 0 85px;
}
.password-expired h3 {
  font-size: 28px;
  font-weight: 300;
  font-family: var(--poppins);
  color: #cc4e5a;
}
.password-expired p {
  color: #000;
}
.password-expired .login-again {
  color: #000;
}
.password-expired .icon {
  font-size: 5rem;
  font-weight: normal;
  color: #cc4e5a;
  line-height: 95px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
