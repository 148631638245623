// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wiki-filter {
  padding: 5px 5px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
}
.wiki-filter h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0;
  line-height: 36px;
}
.wiki-filter h4 .badge {
  color: #212529;
  background-color: #ffc107;
  margin-left: 5px;
  font-size: 13px;
  padding: 4px 12px;
}
.wiki-filter .form-group {
  display: inline-block;
  width: 180px;
  margin: 0;
  position: relative;
}
.wiki-filter .form-group .error {
  position: absolute;
  bottom: auto;
  width: 100%;
  left: 0;
  text-align: left;
  background: #fffafa;
  padding: 5px 15px;
  color: #c12d2d;
  border: 1px dashed #fba3a3;
  border-radius: 5px;
  z-index: 5;
}
.wiki-filter .form-group .error::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 6px;
  border-color: transparent transparent #c76a6a transparent;
  top: -12px;
}
.wiki-filter .form-group + .form-group {
  margin-left: 15px;
}
.wiki-filter .form-group + .form-button-group {
  margin-left: 15px;
}
.wiki-filter .form-button-group {
  display: inline-block;
  position: relative;
}
.wiki-filter .form-button-group .btn {
  padding: 4px 17px;
  background-color: #000;
  color: #fff;
}
.wiki-filter .form-button-group .btn + .btn {
  margin-left: 15px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.card-cat {
  border: 1px solid #eee;
  box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.1);
  height: 260px;
  border-radius: 8px;
  margin: 0 0 20px;
}
.card-cat .wiki-img {
  height: 130px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.card-cat .wiki-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-cat .wiki-desc {
  padding: 12px;
}
.card-cat .wiki-desc h5 {
  margin: 0 0 8px;
  font-size: 17px;
  color: #111;
}
.card-cat .wiki-desc p {
  margin: 0 0 0;
  color: #555;
  line-height: 20px;
}

.card {
  border: 1px solid #ddd;
  box-shadow: none;
  min-height: 97px;
  border-radius: 8px;
  margin: 0 0 15px;
  padding: 10px 130px 10px 100px;
  position: relative;
  cursor: pointer;
}
.card .wiki-img {
  height: 130px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.card .wiki-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .wiki-icon {
  position: absolute;
  z-index: 11;
  left: 10px;
  top: 10px;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 6px;
  text-align: center;
  padding: 10px 0;
  background-color: #eee;
  border-radius: 6px;
  padding: 20px;
}
.card .wiki-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.card .article-img {
  position: absolute;
  z-index: 11;
  left: 10px;
  top: 10px;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 6px;
  text-align: center;
  background-color: #eee;
  border-radius: 6px;
}
.card .article-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .date {
  position: absolute;
  z-index: 11;
  right: 15px;
  top: 10px;
  font-size: 15px;
  color: #555;
}
.card .date img {
  width: 18px;
  margin-right: 6px;
  opacity: 0.5;
}
.card .wiki-desc h5 {
  margin: 0 0 8px;
  font-size: 20px;
  color: #111;
}
.card .wiki-desc p {
  margin: 0 0 0;
  color: #555;
  line-height: 20px;
}

@media screen and (max-width: 480px) {
  .hide-xs {
    display: none;
  }
  .show-xs {
    display: block;
  }
  .xs-flex {
    display: flex;
  }
  .xs-ml-5 {
    margin-left: 5px !important;
  }
  .form-group {
    width: 55% !important;
  }
  .form-button-group {
    margin-left: 6px !important;
  }
  .text-md-end {
    margin-top: 10px;
  }
  .wiki-panel {
    margin-left: 0 !important;
  }
  .wiki-filter {
    padding: 10px;
  }
}
@media screen and (min-width: 481px) {
  .hide-xs {
    display: block;
  }
  .show-xs {
    display: none;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
