import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from './../../service/common.service';
import { DataService } from './../../service/data.service';
import { Router } from '@angular/router';
import { LiveStreamApiService } from './../../stream/service/live-stream-api.service';

@Component({
  selector: 'app-active-room-details',
  templateUrl: './active-room-details.component.html',
  styleUrls: ['./active-room-details.component.scss']
})
export class ActiveRoomDetailsComponent implements OnInit {
  isLoading: boolean = false
  roomData: any;
  showData: boolean;
  constructor(private commonService: CommonService,
    private dataService: DataService, private router: Router,
    private renderer: Renderer2, private liveStream: LiveStreamApiService) { }

  ngOnInit() {
    this.getActiveRoomData()
  }

  getActiveRoomData() {
    this.isLoading = true
    const post = {
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      method: 'getUserWiseSharedLinkData',
      is_active: 1
    }

    this.liveStream.getUserWiseSharedLinkData(post).subscribe((data) => {
      if (!data['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return
      }
      if (data['status']) {
        if (data['data']) {
          this.roomData = data['data']
          this.showData = true
        } else {
          this.showData = false
        }

      }

      this.isLoading = false
    }, (error) => {
      console.log(error)
    })
  }

  reloadCurrentRoute(link) {
    let currentUrl = '/join-room/' + link



    this.router.navigate([currentUrl])
      .then(() => {
        window.location.reload();
      });


    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([currentUrl]);
    // });
  }

}