import { HttpClient } from "@angular/common/http";
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from "@angular/core";
import { BehaviorSubject, observable, Observable } from "rxjs";
import { io, Socket } from "socket.io-client";
import { ApiService } from "src/app/service/api.service";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private socket: Socket;
  private socketId: any;
  num: number = 0

  constructor(private http: HttpClient, private apiService: ApiService) {
    //   this.socket = io("http://localhost:3000", {
    //     autoConnect: true,
    // });

    // this.socket = io("https://general-chat007.herokuapp.com/", {
    //   autoConnect: true,
    //   transports: ["websocket"],
    // });


    this.socket = io('https://socket.retteracademy.com/', {
      autoConnect: true,
      transports: ["websocket"]
    });



    this.socket.on("connect", () => {
      this.socketId = this.socket.id;
      this.changeCurrentSocketId(this.socketId)
      console.log("socket connected");
      
    });
  }


  private currentConnectionSource = new BehaviorSubject<any>('0');
  currentSocketId = this.currentConnectionSource.asObservable();
  changeCurrentSocketId(cId: any) {
    this.currentConnectionSource.next(cId);
  }

  // EMITTER example
  sendMessagea(message: any) {
    this.socket.emit("sendMessage", message, (error) => {
      if (error) {
        return 0;
      } else {
        return 1;
      }
    });
  }

  sendMessage(message) {
    return new Observable((observer) => {
      this.socket.emit("sendMessage", message, (error) => {
        if (error) {
          observer.next(0);
        } else {
          observer.next(1);
        }
      });
    });
  }

  sendMessage2(message) {
    return new Observable((observer) => {
      this.socket.emit("sendMessage", message, (error: any) => {
        if (error) {
          observer.next(0);
        } else {
          observer.next(1);
        }
      });
    });
  }

  sendingMessage2(message: any) {
    // console.log(message , 'message')
    // console.log("sendMessage2 called");

    this.socket.emit("sendingMessage2", message, this.socket.id);
  }

  // HANDLER example
  onNewMessage() {
    return new Observable((observer) => {
      this.socket.on("newMessage", (msg) => {
        observer.next(msg);
      });
    });
  }

  onNewBrodcastMsg() {
    return new Observable((observer) => {
      this.socket.on("broadcastMessages", (msg) => {
        observer.next(msg);
      });
    });
  }

  joinRoom(roomName) {
    // console.log('join room emit called')
    setTimeout(() => {
      this.socket.emit("join", roomName);
    }, 1000);
  }

  disconnectSocket() {
    this.socket.disconnect();
  }

  connect() {
    this.socket.connect();
  }

  sendUsername(username, roomName) {
    this.socket.emit("clientName", username, this.socketId, roomName, "HID");
  }

  onClientName() {
    this.num = this.num + 1;
    //console.log(this.num , 'number in onclient')
    return new Observable((observer) => {
      this.socket.on("clientName", (name, id, webID) => {
        console.log(name, id, webID, "yahoo");
        let data = {
          name,
          id,
          webID,
        };
        observer.next(data);
      });
    });
  }

  onSendRoomCount() {
    return new Observable((observer) => {
      this.socket.on("sendRoomCount", (roomCount) => {
        observer.next(roomCount);
      });
    });
  }

  onAppend() {
    return new Observable((observer) => {
      this.socket.on('appendMsg', (msg) => {
        // console.log(msg , 'memme')
        observer.next(msg)
      })
    })
  }

  onJoined() {
    return new Observable((observer) => {
      this.socket.on('joined', () => {
        observer.next(true)
        observer.complete()
      })
    })
  }

  onSocketConnection() {
    console.log(this.socketId, ' in on Socket connection in service')
    //  return new Observable((observer) => {
    //   observer.next(this.socketId)
    //  })
  }

  getChatHistory(data) {
    return this.http.post(
      this.apiService.getApiURL() + 'chatSystem', data, { headers: this.apiService.setHeaderVal() }
    );
  }

  deleteMsg(data) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getChatSytem(), data, { headers: this.apiService.setHeaderVal() }
    );
  };

  deleteMsgPermission(data) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getChatSytem(), data, { headers: this.apiService.setHeaderVal() }
    );
  }

}
