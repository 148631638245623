import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';



import { StreamMainComponent } from './layout/stream-main/stream-main.component';
import { StreamSidebarComponent } from './layout/stream-sidebar/stream-sidebar.component';
import { HostRoomComponent , UserRoomComponent , RoomExpireComponent ,
  } from './../index';

  import { MatInputModule } from "@angular/material/input";
    import { MatPaginatorModule} from "@angular/material/paginator";
      import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
        import { MatSortModule } from "@angular/material/sort";
          import { MatTableModule } from "@angular/material/table";
import { ShareLinkComponent } from './component/share-link/share-link.component';
import { CreateHostRoomComponent } from './component/create-host-room/create-host-room.component';




@NgModule({
  declarations: [StreamMainComponent, StreamSidebarComponent , HostRoomComponent , UserRoomComponent , RoomExpireComponent, ShareLinkComponent, CreateHostRoomComponent ],
  imports: [
    CommonModule,SharedModule,MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
    MatSortModule, MatTableModule
  ]
})
export class StreamModule { }
