import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../../service/common.service';
import { UserService } from './../../service/user.service';
import { ApiService } from './../../service/api.service';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  userData: any;
  geTUserpost: any;
  userProfileimg: any;
  sitePath: any;
  isLoading: boolean = true;
  showPanelStatus: boolean = true;
  userCertificates: any;
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private commonService: CommonService, private userService: UserService, private apiService: ApiService , private dataService: DataService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  ngOnInit() {
    this.getUserData();
    this.sitePath = this.apiService.getSiteFolderPath();
    this.getUserCertificates();
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }

  /**
   * @description  navigate to edit profile page
   * @date 2019-09-16
   * @memberof ProfileComponent
   */
  routeToEditProfile() {
    this.router.navigate(['edit-profile'])
  }

  togglePanel(togglePanelElement: HTMLElement, event) {
    const getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }
  }


  /**
   * @description get user data from database
   * @date 2019-09-16
   * @memberof ProfileComponent
   */
  getUserData() {
    this.geTUserpost = {
      method: 'getUserData',
      id: this.commonService.getUserId()
    }

    this.userService.getUserData(this.geTUserpost).subscribe(res => {
      if (res['jwt_status']) {
        if (res['status']) {
          this.getUserProfileImg(res['data'].user_img);
          this.userData = res['data']
          this.isLoading = false
        }
      } else {
        this.commonService.openSessionExpireDialog()
      }

    }, error => error);
  }

  /**
   * @description get user profile image detail from database
   * @date 2019-09-16
   * @param {*} userImg
   * @memberof ProfileComponent
   */
  getUserProfileImg(userImg) {
    if (userImg) {
      this.userProfileimg = this.apiService.getUserProfilePath() + userImg;
    } else {
      this.userProfileimg = null
    }
  }

  getUserCertificates() {
    const data = {
      method: 'getUserCertificate',
      container_id: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    };
    this.userService.getUserCertificate(data).subscribe(res => {
      this.userCertificates = res['data'];
    });
  }



}
