import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef,
   ComponentFactoryResolver, ComponentFactory,
    OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './../../../../service/data.service';
import { CommonService } from './../../../../service/common.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from './../../../../service/api.service';
import { WikiService } from './../../service/wiki.service';
import { WikiPollComponent } from '../wiki-poll/wiki-poll.component';
import { WikiVideoPlayerComponent } from '../wiki-video-player/wiki-video-player.component';
@Component({
  selector: 'app-wiki-article-detail',
  templateUrl: './wiki-article-detail.component.html',
  styleUrls: ['./wiki-article-detail.component.scss']
})
export class WikiArticleDetailComponent implements OnInit {

  isCompleted: boolean;
  userId: any;
  dialogRef: any;
  pollDetails: any = null;
  pollDetailsOptions: any;
  poll_submited: any;
  pollexist: boolean = false
  results: any;
  optionStatus: boolean;
  errorMessage: any;
  pollResultQues: any = [];
  isLoading: boolean = true;
  addPollData: any;
  routerLinkSubscription: Subscription;
  showIframe: boolean = false
  sitePath: any;
  routeParamValues: any;
  charLimit: number = 300;
  // Image Citation Data
  isLodingBtn: boolean = false;
  showPanelStatus: Boolean = false;
  // showTitle: boolean = true;
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  /************************************** */
  componentRef: ComponentRef<any>;
  videoComponentRef: ComponentRef<any>;
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;

  @ViewChild('wikiPoll', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild('wikiVideo', { read: ViewContainerRef, static: true }) videoContainer: ViewContainerRef;

  // tslint:disable-next-line: variable-name
  getInstituteUrl: any;
  j: number;
  count: number = 0

  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextLsnBtn: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextModuleBtn: boolean = false;
  showPollTitle: any;


  panelBgCol: any;
  panelTxtCol: any;

  currentLangContainer: Subscription;
  lang: number;
  langDataAvailable: boolean = false
  pollService: any;
  articleId: string;
  article: any;
  preArticle: any;
  nextArticle: any;
  articleText: any;
  articleVideos: any;
  pollsList: any;
  articleImg: any;
  articleName: any;
  noDataError: string;
  showNoDataErr: boolean = false;
  articleEndDate: any;

  constructor(private router: Router, private route: ActivatedRoute,
    private dataService: DataService, private commonService: CommonService,
    private sanitizer: DomSanitizer, private renderer: Renderer2, private apiService: ApiService, private wikiService: WikiService,
     private resolver: ComponentFactoryResolver
     ) {
    this.articleId = atob(this.route.snapshot.paramMap.get('articleId'))
    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.getMainLangHeader();
      this.getArticleDetail();
    }, error => error);
    this.getMainLangHeader();

    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);

  }

  getMainLangHeader() {
    if (localStorage.getItem('locale') == 'ger') {
      this.lang = 1
    } else if (localStorage.getItem('locale') == 'en') {
      this.lang = 2
    }
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }



  ngOnInit() {
    this.clearContainer()
    this.getPanelVisuals();
    this.getInstituteUrl = this.apiService.getApiSitePath()
    this.sitePath = this.apiService.getSiteFolderPath();
  }

  startPoll(pollId) {
    if (this.container) {
      this.container.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(WikiPollComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.pollId = pollId;
    }
  }

  startVideo(video) {
    if (this.videoContainer) {
      this.videoContainer.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(WikiVideoPlayerComponent);
      this.videoComponentRef = this.videoContainer.createComponent(factory);
      this.videoComponentRef.instance.video = video;
    }
  }



  clearContainer(){
    if(this.container){
      this.container.clear();
    }
    if(this.videoContainer){
      this.videoContainer.clear()
    }
  }

  getArticleDetail() {
    this.clearContainer()
    this.isLoading = true;
    const data = {
      method: "getArticleDetail",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      articleId: this.articleId,
      langId: this.lang
    }
    this.wikiService.getArticleDetail(data).subscribe((res) => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return;
      }
      this.resetPanelCollapse();
      this.article = res['data'].article;
      this.preArticle = res['data'].previousArticle;
      this.nextArticle = res['data'].nextArticle;

      if(this.article.length == 0){
        this.showNoDataErr = true
          if(this.lang == 2){
            this.noDataError = 'Sorry ! No data is available in selected language.';
          }else{
            this.noDataError = 'Es tut uns leid ! In der ausgewählten Sprache sind keine Daten verfügbar.';
          }

      }else{
        this.showNoDataErr = false
      }

      this.articleName = this.article.name
      this.dataService.changeArticleId(this.articleName)
      this.articleImg = this.article.wiki_img
      this.articleText = res['data'].articleText;
      this.articleVideos = res['data'].articleVideos
      this.pollsList = res['data'].polls
      this.articleEndDate = this.article.end_date
      this.isLoading = false;
      this.langDataAvailable = true
    })
  }

  ngOnDestroy() {
    this.isLoading = true;
    this.currentLangContainer.unsubscribe();
    this.currentBtnThemeContainer.unsubscribe();
    this.container.clear();
  }

  ngAfterViewInit() {

  }

  toggleContainer(event) {
    event.target.parentElement.parentElement.parentElement.classList.toggle('open');
    // tslint:disable-next-line: prefer-const
    let parentElm = event.target.parentElement.parentElement.parentElement;
    if (parentElm.className === 'lessonCollapseContent open') {
      event.target.innerText = 'Less';
      this.charLimit = 5000000;
    } else {
      event.target.innerText = 'Read More';
      this.charLimit = 300;
    }
  }


  togglePanel(togglePanelElement: HTMLElement, event) {
    this.showPanelStatus = true;
    let getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active');
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active');
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }
  }

  resetPanelCollapse() {

    if (this.showPanelStatus) {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header.active');
        const getContentPan = document.querySelectorAll('.pc-content.open');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.removeClass(getHeaderPan[i], 'active');
          if (getContentPan[i] != undefined) {
            this.renderer.removeClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', '0');
          }

        }
      }, 200)
    } else {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header');
        const getContentPan = document.querySelectorAll('.pc-content');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.addClass(getHeaderPan[i], 'active');

          if (getContentPan[i] != undefined) {
            this.renderer.addClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', 'auto');
          }

        }
      }, 200)
    }

  }

  previousArticleData(articleId) {
    this.articleId = articleId
    this.getArticleDetail()
  }

  nextArticleData(articleId) {
    this.articleId = articleId
    this.getArticleDetail()
  }
}
