import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-course-layout',
  templateUrl: './course-layout.component.html'
})
export class CourseLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    localStorage.setItem('panelCollapse' , '1');
  }

}
