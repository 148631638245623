// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
    font-family: 'calibriregular';
    src: url('calibri-webfont.eot');
    src: url('calibri-webfont.eot?#iefix') format('embedded-opentype'),
         url('calibri-webfont.woff') format('woff'),
         url('calibri-webfont.ttf') format('truetype'),
         url('calibri-webfont.svg#calibriregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'calibribold';
    src: url('Calibri-Bold.eot');
    src: url('Calibri-Bold.eot?#iefix') format('embedded-opentype'),
         url('Calibri-Bold.woff') format('woff'),
         url('Calibri-Bold.ttf') format('truetype'),
         url('Calibri-Bold.svg#calibribold') format('svg');
    font-weight: normal;
    font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
