import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VideoService } from '../../../service/video.service';
import { CommonService } from './../../../service/common.service';
import { ApiService } from './../../../service/api.service';
import { DataService } from './../../../service/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-list-video',
  templateUrl: './category-list-video.component.html',
  styleUrls: ['./category-list-video.component.scss']
})
export class CategoryListVideoComponent implements OnInit, OnDestroy {

  /** @var videoDataContainer store all video data  */
  videoDataContainer: any;

  /** @var getVideoUploadPath store video image path  */
  getVideoUploadPath: any;

  /** @var pagingLimit store pagination limit counter default 20  */
  pagingLimit: number = 20;

  /** @var totalLengthOfPage Total length of recored for pagination default in null  */
  totalLengthOfPage: any[] = [];

  /** @var increasCount Number counter inscreas in function for pagination  */
  increasCount: number = 0;

  /** @var firstPage First page button for pagination  */
  firstPage: any[] = [];

  /** @var lastPage Last page button for pagination  */
  lastPage: any[] = [];

  /** @var paginationLimit Pagination Limit defualt empty it will be update in function  */
  paginationLimit: any[] = [];

  /** @var globalCounterPagin Global counter for pagination  */
  globalCounterPagin: number = 0;

  /** @var notfound For error when data is not found  */
  notfound: boolean = false;

  /** @var isLoading For loading when data featching  */
  isLoading: boolean = false;

  /** @var totalRecorde store total recorde of page */
  totalRecorde: any;

  /** @var videUrlParam vide url path merge */
  videUrlParam: any;

  /** @var categoryTitle store category title and used in top of page heading  */
  categoryTitle: any;

  /** @var categoryId store category id and used in top of page heading  */
  categoryId: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  filetypeList: any;
  showdata: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private videoService: VideoService, private commonService: CommonService, private apiService: ApiService, private renderer: Renderer2, private activatedRoute: ActivatedRoute, private dataService: DataService) {
    this.videUrlParam = atob(this.activatedRoute.snapshot.paramMap.get('categoryId'));
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  sort = [
    {
      file_type: 'Videos',
      value: 'videos'
    }
    , {
      file_type: 'PDFs',
      value: 'pdfs'
    }
    , {
      file_type: 'Documents',
      value: 'documents'
    }
    , {
      file_type: 'Tables',
      value: 'tables'
    }
    , {
      file_type: 'Group by files',
      value: 'group_by_files'
    }
  ]

  ngOnInit() {
    this.getVideoUploadPath = this.apiService.getCourseImgPath();
    this.setCounterLink(0);
    this.getVideoListing(0, '', '', '');
    this.getfileType()
    this.mediaVideo(0, '', '', '')
  }

  /**
 * @description Get all video lising by category id and param for search
 * @date 2020-05-22
 * @param pageCounter, search_title, search_cat
 * @method  getVideoListing
 * @memberof CategoryListVideoComponent
 */
  getVideoListing(pageCounter, search_title, search_cat, sort) {
    this.totalLengthOfPage = [];
    this.increasCount = 0;
    this.isLoading = true;
    const videoData = {
      method: 'relatedCategoryVideoList',
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      category_id: this.videUrlParam,
      pageNumber: pageCounter,
      sort_by: sort
    };
    this.videoService.getVideoListData(videoData).subscribe((res) => {
      this.isLoading = false;
      if (res['status'] = true) {
        if (res['data'] != null || res['data'] != undefined) {
          this.videoDataContainer = res['data'];
          this.categoryTitle = res['category'][0]['name'];
          this.categoryId = res['category'][0]['id'];
          const getTotalCount = parseInt(res['recordsCount']);
          this.totalRecorde = res['data'].length;
          
          const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);

          // for (let i = 1; i <= roundOffCount; i++) {
          //   const pageItenData = {
          //     pageItem: this.increasCount
          //   };
          //   this.totalLengthOfPage.push(pageItenData);
          //   // this.increasCount = this.increasCount + 20;
          // }
          // this.getPaginationLimit(this.totalLengthOfPage);

          // setTimeout(() => {
          //   const getPagingId = localStorage.getItem('video_pagination_counter');
          //   const pageItemsClass = document.querySelectorAll('.page-item');
          //   pageItemsClass.forEach(element => {
          //     this.renderer.removeClass(element, 'active');
          //   });
          //   const pagingElement = document.querySelectorAll('.paging_link_' + getPagingId);
          //   pagingElement.forEach(element => {
          //     this.renderer.addClass(element, 'active');
          //   });
          // }, 100);

          setTimeout(() => {
            const getPagingId = localStorage.getItem('video_pagination_counter');
            let pagingElement: any = '';
            pagingElement = document.getElementById('paging_link_' + getPagingId);
            if (pagingElement != null) {
              this.renderer.addClass(pagingElement, 'active');
            }
          }, 100);
          this.notfound = false
        } else {
          this.notfound = true;
          this.categoryTitle = res['category'][0]['name'];
        }
      } else {
        this.notfound = true;
        this.categoryTitle = res['category'][0]['name'];
      }

    })
  }


  /**
 * @description Get current pagination counter
 * @date 2020-05-22
 * @param getCurrentPageCount
 * @method  getDataById
 * @memberof CategoryListVideoComponent
 */
  getDataById(getCurrentPageCount: any) {
    this.getVideoListing(getCurrentPageCount, "", "", "")
    this.setCounterLink(getCurrentPageCount)
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }


  /**
 * @description Set counter in localstorage for pagination
 * @date 2020-05-22
 * @param counterId
 * @method  setCounterLink
 * @memberof CategoryListVideoComponent
 */
  setCounterLink(counterId: any) {
    localStorage.setItem('video_pagination_counter', counterId)
  }


  /**
* @description Set pagination html by data length
* @date 2020-05-22
* @param data
* @method  getPaginationLimit
* @memberof CategoryListVideoComponent
*/
  getPaginationLimit(data) {
    this.paginationLimit = [];
    const getPagingId = localStorage.getItem('video_pagination_counter');
    const indexByVal = data.findIndex(x => x.pageItem == getPagingId);
    if (indexByVal > 1) {
      this.globalCounterPagin = indexByVal - 2;
    }
    if (indexByVal > 0 && indexByVal < 1) {
      this.globalCounterPagin = indexByVal - 1;
    }
    if (indexByVal == 0) {
      this.globalCounterPagin = indexByVal;
    }
    let prevVal: any[] = [];
    let nextVal: any[] = [];
    let currentPrevVal: any[] = [];
    let indexForLoop = indexByVal - 3;
    for (let i = (indexByVal - 1); i > indexForLoop; i--) {
      if (i > -1) {
        if (data[i] != undefined && data[i] != null) {
          prevVal.push(data[i])
        }
      }
    }
    let dualIndexVal = indexByVal + 1;
    let prevValLength = this.checkPrevValLength(prevVal);

    for (let j = dualIndexVal; j < (dualIndexVal + prevValLength); j++) {
      if (data[j] != undefined && data[j] != null) {
        nextVal.push(data[j])
      }
    }

    currentPrevVal = prevVal.concat(data[indexByVal])
    currentPrevVal.sort((a, b) => { if (a.pageItem < b.pageItem) return -1; return a.pageItem > b.pageItem ? 1 : 0 })

    this.paginationLimit = currentPrevVal.concat(nextVal);
    this.paginationLimit = this.paginationLimit.filter((item) => {
      return item != undefined
    })
  }


  /**
  * @description Check previous value length for pagination
  * @date 2020-05-22
  * @param prevData
  * @method  checkPrevValLength
  * @memberof CategoryListVideoComponent
  */
  checkPrevValLength(prevData) {
    switch (prevData.length) {
      case 0:
        return 4;
        break;
      case 1:
        return 3;
        break;
      case 2:
        return 2;
        break;
      default:
        return 2;
    }
  }


  /**
 * @description Navigate to the category listing page
 * @date 2020-05-22
 * @method  gotoVideoCategoryPage
 * @memberof CategoryListVideoComponent
 */
  gotoVideoCategoryPage() {
    this.dataService.mediaCat.next('');
    this.router.navigate(['video-category']);
  }


  /**
 * @description Navigate to the details page
 * @date 2020-05-22
 * @param video_id
 * @method  videoDetails
 * @memberof CategoryListVideoComponent
 */
  videoDetails(video_id, categoryId) {
    const paraData = video_id + ',' + categoryId;
    this.router.navigate(['video-detail/' + btoa(paraData)]);
  }

  getImgIcon(file, fileType) {
    return this.videoService.getImgIcon(file, fileType)
  }

  getfileType() {
    const data = {
      "method": "getFileTypeList",
      "id": this.commonService.getUserId()
    }
    this.videoService.getfileType(data).subscribe((res: any) => {
      this.filetypeList = res.result
    })
  }

  clearSort() {
    this.getVideoListing("", "", "", "")

  }

  onSort(event) {
    let sortVal = event.target.value
    this.getVideoListing("", "", "", sortVal)
  }

  mediaVideo(pageCounter, search_title, search_cat, sort) {
    this.dataService.mediaCategoryId.subscribe(res => {

      if(res == 'gotoback' || !res){
        this.gotoVideoCategoryPage();
        return;
      }

      const currentRoute = this.activatedRoute.snapshot.routeConfig.path;
      const currentId = this.activatedRoute.snapshot.paramMap.get('categoryId');
      
      if (res != false && res != 'all') {
        let catId = res.id
        this.totalLengthOfPage = [];
        this.increasCount = 0;
        this.isLoading = true;
        const videoData = {
          method: 'relatedCategoryVideoList',
          id: this.commonService.getUserId(),
          container_id: this.commonService.getDomainContainerId(),
          category_id: catId,
          pageNumber: pageCounter,
          sort_by: sort
        };
        this.videoService.getVideoListData(videoData).subscribe((res) => {  
          if(res['category'].length > 0){
            this.isLoading = false;
            if (res['status'] = true) {
              if (res['data'] != null || res['data'] != undefined) {
                this.videoDataContainer = res['data'];
                this.categoryTitle = res['category'][0]['name'];
                this.categoryId = res['category'][0]['id'];
                const getTotalCount = parseInt(res['recordsCount']);
                this.totalRecorde = res['data'] ? res['data'].length : 0;                                
                const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);
  
                for (let i = 1; i <= roundOffCount; i++) {
                  const pageItenData = {
                    pageItem: this.increasCount
                  };
                  this.totalLengthOfPage.push(pageItenData);
                  this.increasCount = this.increasCount + 20;
                }
                this.getPaginationLimit(this.totalLengthOfPage);
  
                // setTimeout(() => {
                //   const getPagingId = localStorage.getItem('video_pagination_counter');
                //   const pageItemsClass = document.querySelectorAll('.page-item');
                //   pageItemsClass.forEach(element => {
                //     this.renderer.removeClass(element, 'active');
                //   });
                //   const pagingElement = document.querySelectorAll('.paging_link_' + getPagingId);
                //   pagingElement.forEach(element => {
                //     this.renderer.addClass(element, 'active');
                //   });
                // }, 100);
  
                setTimeout(() => {
                  const getPagingId = localStorage.getItem('video_pagination_counter');
                  let pagingElement: any = '';
                  pagingElement = document.getElementById('paging_link_' + getPagingId);
                  if (pagingElement != null) {
                    this.renderer.addClass(pagingElement, 'active');
                  }
                }, 100);
                this.notfound = false
              } else {
                this.notfound = true;
                this.categoryTitle = res['category'][0]['name'];
                this.totalRecorde = 0 ; 
              }
            } else {
              this.notfound = true;
              this.categoryTitle = res['category'][0]['name'];
              this.totalRecorde = 0 ; 
            }            
          }
        })
      }
    })
  }
}


