// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-progress-section .collapse-section {
  background: #3a434c;
  padding: 10px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
}
.quiz-progress-section .collapse-section span {
  pointer-events: none;
}

.spinner .mat-mdc-progress-spinner {
  height: 20px !important;
  width: 20px !important;
}

.instruction-content {
  overflow: auto;
}

.drag-drop-user-ans {
  background: #fff9e9;
  padding: 15px 25px;
  border-radius: 3px;
}
.drag-drop-user-ans h3 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--poppins);
  margin-bottom: 17px;
}
.drag-drop-user-ans .question-option {
  padding: 0;
}
.drag-drop-user-ans .question-option label {
  background: #fde7ab;
  color: #634a06;
  border-radius: 3px;
  width: 100%;
  padding: 7px 15px;
}

.drag-drop-correct-ans {
  background: rgba(102, 245, 100, 0.1);
  padding: 15px 25px;
  border-radius: 3px;
}
.drag-drop-correct-ans h3 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--poppins);
  margin-bottom: 17px;
}
.drag-drop-correct-ans .question-option {
  padding: 0;
}
.drag-drop-correct-ans .question-option label {
  background: #c1ecc1;
  color: #155015;
  border-radius: 3px;
  width: 100%;
  padding: 7px 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
