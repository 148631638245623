import { Component, OnInit } from '@angular/core';
import { FooterService } from './../../service/footer.service';
import { CommonService } from './../../service/common.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  bodyText: any;
  recordStatus: boolean;
  isLoading: boolean = true;

  constructor(private footerService: FooterService, private commonService: CommonService) { }

  ngOnInit() {
    this.getImprintData();
  }

  getImprintData() {
    const data = {
      container_id: this.commonService.getDomainContainerId(),
      method: 'getImprintData'
    };

    this.footerService.getImprint(data).subscribe(res => {
      if(res['status']){
        this.recordStatus = true;
        this.bodyText = res['data'].body
      } else{
        this.recordStatus = false;
      }
      this.isLoading = false;
    });

  }

}
