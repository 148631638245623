import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketPlaceService } from './../../../service/market-place.service';

import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from './../../../service/api.service';
import { CommonService } from './../../../service/common.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RedeemCourseComponent } from './../../../dialogBox/redeem-course/redeem-course.component';
import { Subscription } from 'rxjs';
import { DataService } from './../../../service/data.service';


@Component({
	selector: 'app-marketplace-course-list',
	templateUrl: './marketplace-course-list.component.html',
	styleUrls: ['./marketplace-course-list.component.scss']
})
export class MarketplaceCourseListComponent implements OnInit, OnDestroy {
	dataArr: any;
	CourseAssignedData: any;
	userData: any;
	userId: any;
	assignedCourses: any;
	isLoading: boolean = true;
	sitePath: any;
	roleId: any;
	dashboardRoute: string;
	showErrorMsg: boolean;
	showCourseProgressBar: boolean;
	dialogRef: any;

	currentBtnThemeContainer: Subscription;
	buttonTheme: any;

	constructor(private marketPlaceService: MarketPlaceService, private domSanitizer: DomSanitizer,
		// tslint:disable-next-line: max-line-length
		           private apiService: ApiService, private commonService: CommonService, private router: Router, private dialog: MatDialog, private dataService: DataService) {
		this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
			this.buttonTheme = message;
		}, error => error);
	}

	ngOnInit() {
		this.userData = JSON.parse(localStorage.getItem('loggedUser'));
		this.userId = this.userData.id;
		this.getUserAssignedCourseList();
		this.sitePath = this.apiService.getSiteFolderPath();
		this.roleId = this.commonService.getUserRole();
		this.showCourseProgressBar = (this.roleId == this.commonService.userRolesArr['Student']) ? true : false;

		// if ((this.roleId == this.commonService.userRolesArr['Grader'])) {
		// 	this.dashboardRoute = this.commonService.getDashBoardRoutes(this.roleId)
		// 	this.router.navigate([this.dashboardRoute])
		// }
	}

	ngOnDestroy() {
		this.currentBtnThemeContainer.unsubscribe();
	}

	/**
	 * @description sanitize html url path
	 * @date 2019-09-16
	 * @param {*} html
	 * @returns
	 * @memberof DashboardComponent
	 */
	getHtml(html) {
		return this.domSanitizer.bypassSecurityTrustHtml(html);
	}


	/**
	 * @description get course list assigned to user
	 * @date 2019-09-16
	 * @param {*} userId
	 * @memberof DashboardComponent
	 */
	getUserAssignedCourseList() {
		this.CourseAssignedData = {
			id: this.commonService.getUserId(),
			method: 'marketPlaceCourseList',
			lang_id: this.commonService.setSelectedValueByLang()
		};
		this.marketPlaceService.getMarketPlace(this.CourseAssignedData).subscribe(res => {
			if (!res['jwt_status']) {
				this.commonService.openSessionExpireDialog();
			} else {
				this.assignedCourses = (res['data'] != undefined) ? res['data'] : [];

				this.showErrorMsg = (this.assignedCourses.length == 0) ? true : false;
				this.isLoading = false;
			}
		})
	}

	openRedeemCoursePopUp(courseName: any, courseDesc: any, courseId: any, sectionId: any, shopLink: any) {
		this.dialogRef = this.dialog.open(RedeemCourseComponent, {
			//	height: '40%',
			width: 'auto',
			data: {
				container_id: this.commonService.getDomainContainerId(),
				user_id: this.commonService.getUserId(),
				course_name: courseName,
				course_id: courseId,
				section_id: sectionId,
				course_description: courseDesc,
				shop_link: shopLink
			}
		});

		this.dialogRef.afterClosed().subscribe(res => {
			if (res == 'true') {
				this.getUserAssignedCourseList();
			}
		})
	}

}
