import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, Form } from '@angular/forms';
import { CommonService } from './../../service/common.service';
import { FeedbackService } from 'src/app/service/feedback.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';



@Component({
  selector: 'app-create-feedback',
  templateUrl: './create-feedback.component.html',
  styleUrls: ['./create-feedback.component.scss']
})
export class CreateFeedbackComponent implements OnInit , OnDestroy {

  public Editor = ClassicEditor;
  ckconfig = {
    //toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
    toolbar: [],
    //placeholder: 'Leave a feedback!'
  };


  feedbackTopic: any;
  createFeedbackForm: FormGroup;
  formTopic = new FormControl('1', Validators.required)
  studentFeedback = new FormControl('', Validators.required)
  formCourse = new FormControl('')
  assignedCourses: any;
  showCourseSelect: Boolean = false
  dialogRef: any;
  currentLangContainer: Subscription;
  constructor(private feedbackService: FeedbackService, private commonService: CommonService, private formBuilder: FormBuilder, private dialog: MatDialog, private router: Router, private dataService: DataService) {
    this.currentLangContainer = this.dataService.currentLang.subscribe(lang => {
      this.feedbackTopic = (lang == 'ger') ? this.commonService.getGerFeedBackTopic : this.commonService.getEngFeedBackTopic
    }, error => error);
  }

  ngOnInit() {
    this.createFeedbackForm = this.formBuilder.group({
      topic: this.formTopic,
      feedback: this.studentFeedback,
      course: this.formCourse

    })
    //this.getFeedbackTopic()
    this.getUserAssignedCourses()
  }

  getFeedbackTopic() {
    const data = {
      id:this.commonService.getUserId(),
      method:'getFeedbackTopicList'
    }
    this.feedbackService.getFeedbackTopic(data).subscribe((res) => {
      this.feedbackTopic = res['data']
    })
  }

  submitFeedback(event: Event) {
    // if(this.createFeedbackForm.value.feedback == ''  ||  this.createFeedbackForm.value.feedback == null){
    //   let feedErrMsg;
    //   if (localStorage.getItem('locale') == 'ger') {
    //     feedErrMsg = 'Please enter feedback'
    //   } else {
    //     feedErrMsg = 'Please enter feedback'
    //   }
    //   this.openGeneralDialogBox(feedErrMsg , 1)
    // }

    if (this.createFeedbackForm.status == 'VALID') {
      let message;
      if (localStorage.getItem('locale') == 'ger') {
        message = 'Vielen Dank für dein Feedback.'
      } else {
        message = 'Thank you for your feedback.'
      }


      if (this.createFeedbackForm.value.topic == 2) {
        if (this.createFeedbackForm.value.course == '' || this.createFeedbackForm.value.course == null) {
          let courseErrMsg;
          if (localStorage.getItem('locale') == 'ger') {
            courseErrMsg = 'Bitte einen Kurs auswählen.'
          } else {
            courseErrMsg = 'Please select course.'
          }
          this.openGeneralDialogBox(courseErrMsg, 1)
        } else {
          this.saveFeedback(this.createFeedbackForm.value);
          // this.createFeedbackForm.reset()

          this.openGeneralDialogBox(message, 2)
          //this.router.navigate(['student-feedback'])
        }
      } else {
        this.saveFeedback(this.createFeedbackForm.value);
        //this.createFeedbackForm.reset()
        // this.router.navigate(['student-feedback'])

        this.openGeneralDialogBox(message, 2)
      }


    }
  }

  getUserAssignedCourses() {
    const data = {
      method: "getUserWiseAssignedCoursesForFeedback",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId()
    }
    this.feedbackService.getUserAssignedCourses(data).subscribe((res) => {
      this.assignedCourses = res['data']

    })
  }

  onSelect(event) {
    if (event.value == 2) {
      this.showCourseSelect = true
    } else {
      this.showCourseSelect = false
    }
  }

  saveFeedback(data: any) {
    const postData = {
      method: 'saveFeedback',
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      topicId: data.topic,
      courseId: data.course,
      feedback: data.feedback,
      userName: this.commonService.getUserFullName()
    }
    this.feedbackService.saveFeedback(postData).subscribe((res) => {
      //alert('tofk')
    })
  }

  openGeneralDialogBox(message, action) {

    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: message
      }
    })
    this.dialogRef.afterClosed().subscribe(res => {
      if (action == 2) {
        this.router.navigate(['student-feedback'])
      }
    })
  }

  ngOnDestroy() {
    this.currentLangContainer.unsubscribe();
  }

}
