// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grader-menu-list {
  margin: 25px 0 0;
  padding: 0;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.grader-menu-list li {
  flex: 0 0 24%;
  list-style: none;
}
.grader-menu-list li button {
  width: 100%;
  border: 0;
  background: #fff;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
}
.grader-menu-list li button.active {
  background: var(--primarycolor);
  color: var(--colorWhite);
  border: 1px solid var(--primarycolor);
  border-bottom: 0;
}
.grader-menu-list li + li {
  margin-left: 10px;
}

.grader-menu-list li button.active {
  background: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  border-bottom: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
