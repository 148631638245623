import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GradeBookService } from '../../service/gradebook.service';
import { CommonService } from '../../service/common.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gradbook-table',
  templateUrl: './gradbook-table.component.html',
  styleUrls: ['./gradbook-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GradbookTableComponent implements OnInit, OnDestroy {
  courseSlug: any;
  sectionId: any;
  @Input() type;
  @Output() output = new EventEmitter();

  gradbookDataContainer: any;
  gradBookHeaderData: any;
  isLoading: boolean = true;
  finalGradTotal: any;
  currentGradTotal: any;
  strCheck: string = '&#10004;'



  // Pagination Count
  pagingLimit: number = 20;
  totalLengthOfPage: any[] = [];
  increasCount: number = 0;
  studentSearch: FormControl;
  studentSearchForm: FormGroup;
  // courseDetail: any;

  searchBoxVal: any;
  tooltipElm: any;

  constructor(
    private router: Router, private gradbookService: GradeBookService, private commonService: CommonService,
    private renderer: Renderer2, private formBuilder: FormBuilder, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.studentSearch = this.formBuilder.control('', [Validators.required])
    this.studentSearchForm = this.formBuilder.group({
      student_name: this.studentSearch,
      method: 'getInstructorGradebookData',
      id: this.commonService.getUserId(),
      pageNumber: 0
    });
    this.getInstructorGradebook(0, null)
    this.setCounterLink(0);

  }

  /**
   * @description Get instructor gradebook data
   * @date 2019-12-18
   * @param {*} pageCount
   * @param {*} name
   * @memberof GradbookTableComponent
   */
  getInstructorGradebook(pageCount: any, name: any) {
    this.totalLengthOfPage = [];
    this.increasCount = 0;
    this.isLoading = true;
    const gradBookParam = {
      id: this.commonService.getUserId(),
      courseId: this.type.courseId,
      sectionId: this.type.sectionId,
      method: "getInstructorGradebookData",
      pageNumber: pageCount,
      student_name: name
    }

    this.gradbookService.getInstructorGradBookData(gradBookParam).subscribe(
      (res) => {
        this.isLoading = false;
        if (res['data']) {
          this.gradbookDataContainer = res['data']

          if (this.gradbookDataContainer.Headers[5].total == 0) {
            delete this.gradbookDataContainer.Headers[5];
          }
          if (this.gradbookDataContainer.Headers[6].total == 0) {
            delete this.gradbookDataContainer.Headers[6];
          }  
          if (this.gradbookDataContainer.Headers[7].total == 0) {
            delete this.gradbookDataContainer.Headers[7];
          }

          const getTotalCount = parseInt(res['data']['total_students'])
          const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);

          for (let i = 1; i <= roundOffCount; i++) {
            const pageItenData = {
              pageItem: this.increasCount
            }
            this.totalLengthOfPage.push(pageItenData)
            this.increasCount = this.increasCount + 20;
          }

          setTimeout(() => {
            const getPagingId = localStorage.getItem('paging_count');
            const pagingElement = document.getElementById('paging_link_' + getPagingId)
            this.renderer.addClass(pagingElement, 'active')
          }, 100)

        }

      }
    )

  }

  ngOnDestroy() {
    localStorage.removeItem('paging_count')
  }


  /**
   * @description for splitting sub header 
   * @date 2019-12-18
   * @param {*} subheader
   * @returns
   * @memberof GradbookTableComponent
   */
  grdSubHeaderSplit(subheader: any) {
    const newTitle = subheader.split(' => ');

    if (newTitle.length > 1) {
      const htmlParam = '<span matTooltip="' + newTitle[1] + '">' + newTitle[0] + '</span>';
      return this.sanitizer.bypassSecurityTrustHtml(htmlParam);
    } else {
      const htmlParam = newTitle[0];
      return this.sanitizer.bypassSecurityTrustHtml(htmlParam);
    }

  }

  checkForToolTip() {
    this.tooltipElm = document.querySelector('.cdk-overlay-container') as HTMLElement;
    //this.renderer.removeChild(this.tooltipElm, )
  }


  /**
   * @description sub header tooltip
   * @date 2019-12-18
   * @param {*} subheader
   * @returns
   * @memberof GradbookTableComponent
   */
  grdSubHeaderTooltip(subheader: any) {

    const newTitle = subheader.split(' => ');
    if (newTitle.length > 1) {
      const htmlParam = newTitle[1];
      return newTitle[1];
    } else {
      const htmlParam = newTitle[0];
      return newTitle[0];
    }
  }

  getDataById(getCurrentPageCount: any) {
    this.getInstructorGradebook(getCurrentPageCount, null)
    this.setCounterLink(getCurrentPageCount)
  }


  /**
   * @description get search by name
   * @date 2019-12-18
   * @param {*} name
   * @memberof GradbookTableComponent
   */
  getSearchByName(name: any) {
    this.getInstructorGradebook(0, name)
  }


  setCounterLink(counterId: any) {
    localStorage.setItem('paging_count', counterId)
  }


  getFinalGradAvg(finalGradData: any) {
    this.finalGradTotal = 0;
    for (let i = 1; i <= 5; i++) {
      if (finalGradData[i] != null) {
        this.finalGradTotal += parseInt(finalGradData[i])
      }
    }
    return this.finalGradTotal;
  }



  /**
   * @description get current grade average
   * @date 2019-12-18
   * @param {*} currentGradData
   * @returns
   * @memberof GradbookTableComponent
   */
  getCurrentGradAvg(currentGradData: any) {
    this.currentGradTotal = 0;
    for (let i = 1; i <= 5; i++) {
      if (currentGradData[i] != null) {
        this.currentGradTotal += parseInt(currentGradData[i])
      }
    }
    return this.currentGradTotal;
  }


  /**
   * @description search student functionality
   * @date 2019-12-18
   * @param {*} event
   * @memberof GradbookTableComponent
   */
  searchStudent(event) {
    if (this.studentSearchForm.status == 'VALID') {
      event.preventDefault()
      this.gradbookService.getInstructorGradBookData(this.studentSearchForm.value).subscribe(
        (res) => {
          this.isLoading = false;
          if (res['data']) {
            this.gradbookDataContainer = res['data']
            const getTotalCount = parseInt(res['data']['studentTotal'])
            const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);
            for (let i = 1; i <= roundOffCount; i++) {
              const pageItenData = {
                pageItem: this.increasCount
              }
              this.totalLengthOfPage.push(pageItenData)
              this.increasCount = this.increasCount + 20;
            }

            setTimeout(() => {
              const getPagingId = localStorage.getItem('paging_count');
              const pagingElement = document.getElementById('paging_link_' + getPagingId)
              this.renderer.addClass(pagingElement, 'active')
            }, 100)

          }

        }
      )
    }

  }
  showTooltip(subheader: any, index: any, event) {
    const newTitle = subheader.split(' => ');
    if (newTitle.length > 1) {
      const htmlParam = newTitle[1];
      const elmRef = document.createElement('div');
      elmRef.id = 'toltip_cmp_' + index;
      elmRef.style.top = (event.target.offsetTop + 40) + 'px';
      elmRef.style.left = (event.target.offsetLeft + 30) + 'px';
      elmRef.classList.add('tooltip-dialog');
      elmRef.innerHTML = htmlParam;
      const elmparent = document.getElementsByClassName('table-responsive');
      elmparent[0].appendChild(elmRef)
    } else {
      const htmlParam = newTitle[0];
      const elmRef = document.createElement('div');
      elmRef.id = 'toltip_cmp_' + index;
      elmRef.style.top = (event.target.offsetTop + 40) + 'px';
      elmRef.style.left = (event.target.offsetLeft + 30) + 'px';
      elmRef.innerHTML = htmlParam;
      elmRef.classList.add('tooltip-dialog');
      const elmparent = document.getElementsByClassName('table-responsive');
      elmparent[0].appendChild(elmRef)
    }
  }
  hideTooltip(event, index) {
    setTimeout(() => {
      const toltipElm = document.querySelectorAll('.tooltip-dialog');
      toltipElm.forEach(element => {
        element.remove()
      });
    }, 100)
  }

}
