import { AfterViewInit, Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
// import { MatTableDataSource } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { LiveStreamApiService } from './../../service/live-stream-api.service';
import { CommonService } from './../../../service/common.service';
import { environment } from 'src/environments/environment';


export interface PeriodicElement {
  id: any
  first_name: string;
  last_name: string;
  email: string;
}

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})


export class ShareLinkComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  filterInput: any;
  matPaginatorIcon: any;
  roomName: any;
  link: any;

  isLoading:boolean = true
  hostRoomId: any;

  constructor(private liveStream: LiveStreamApiService, private commonService: CommonService ,  public dialogRef: MatDialogRef<ShareLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     this.roomName =  data.roomName
     this.link = data.link
     this.hostRoomId = data.hostRoomId

  }

  public members: PeriodicElement[];
  dataSource: MatTableDataSource<PeriodicElement>;
  selectedValues: any = [];

  ngOnInit() {
    this.selectedValues = []
    const postData = {
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      method: 'getAllUsers',
      roomName: this.roomName,
      link: this.link
    }
    this.liveStream.getAllUsers(postData).subscribe((data) => {
      this.members = data['data']
    })

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.members);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false
    }, 3500)





  }
  displayedColumns: string[] = ['select', 'id', 'first_name', 'last_name', 'email'];

  ngAfterViewInit() {
    this.filterInput = document.querySelector('#filter')
    this.matPaginatorIcon = document.querySelector('.mat-paginator-navigation-next')
    this.matPaginatorIcon.addEventListener('click', () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

    })
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clear() {
    this.filterInput.value = ''
    this.applyFilter(this.filterInput.value)
  }

  tbodyChkSelected(event) {
    const checked = event.checked
    const value = event.source.value
    if (checked) {
      this.selectedValues.push(value)
    } else {
      const index: number = this.selectedValues.indexOf(value);
      if (index !== -1) {
        this.selectedValues.splice(index, 1);
      }
    }
  }

  isSelected(val: any) {
    return this.selectedValues.indexOf(val) >= 0;
  }

  shareLink() {
    this.isLoading = true
    const postData = {
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      users: this.selectedValues,
      roomName: this.roomName,
      link: this.link,
      domain: environment.BASE_SITE_DOMAIN,
      hostRoomId:this.hostRoomId,
      method: 'shareRoomLink'
    }
    this.liveStream.shareRoomLink(postData).subscribe((data) => {
      this.ngOnInit()
      this.filterInput.value = ''
      this.applyFilter(this.filterInput.value)
      this.isLoading = false
    } , (e) => {
      console.log(e)
      this.isLoading = false
    })

    //this.closeDialog(1)
  }

  closeDialog(value: any) {
    this.dialogRef.close({ event: value });
  }

}
