import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  getImprint(data){
    return this.http.post(
      this.apiService.getApiURL() + 'container', data, { headers: this.apiService.setHeaderVal() }
    );
  }

  geDataProtection(data){
    return this.http.post(
      this.apiService.getApiURL() + 'container', data, { headers: this.apiService.setHeaderVal() }
    );
  }
}
