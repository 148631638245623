// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-btn-text {
  font-size: 15px;
  padding: 20px 0 5px;
  font-family: var(--poppins);
  font-weight: 500;
}

.loadingImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.loadingImg .mat-mdc-progress-spinner {
  width: 20px !important;
  height: 20px !important;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.loadingImg .mat-mdc-progress-spinner svg {
  width: 100% !important;
  height: 100% !important;
}

.btn-browse {
  font-size: 15px;
  color: #fff;
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.btn-browse input[type=file] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.user-profile-panel .panel-body .line-separator {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
}
.user-profile-panel .panel-body .user-profile-img {
  width: 200px;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.8);
  position: relative;
}
.user-profile-panel .panel-body .user-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-profile-panel .panel-body .user-profile-img:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}
.user-profile-panel .panel-body .user-profile-img .overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 55px;
  transition: all 0.5s ease-in-out;
}
.user-profile-panel .panel-body .user-profile-img .overlay button {
  border: 0;
  background: #e16666;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 3px 10px;
}
.user-profile-panel .panel-body .user-profile-info h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--poppins);
}
.user-profile-panel .panel-body .user-profile-info .user-options {
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--blackcolor);
  padding: 10px 0;
}
.user-profile-panel .panel-body .user-profile-info .user-options .caption {
  font-weight: 500;
  color: var(--lightGrey);
}
.user-profile-panel .panel-body .user-profile-info .user-options-gray {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--poppins);
  padding: 5px 0;
}
.user-profile-panel .panel-body .user-profile-info .user-options-gray span {
  font-weight: 500;
  color: var(--lightGrey);
}
.user-profile-panel .panel-body .user-profile-info .sub-heading {
  font-size: 16px;
  font-family: var(--poppins);
  font-weight: 300;
  color: var(--lightGrey);
}
.user-profile-panel .panel-body .user-profile-info .sub-heading i {
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
}
.user-profile-panel .panel-body .user-profile-info .badge {
  font-size: 12px;
  font-family: var(--poppins);
  font-weight: 500;
  padding: 5px 10px;
}
.user-profile-panel .btn-profile {
  font-size: 12px;
}
.user-profile-panel .btn-profile i {
  font-weight: 600;
}

.instructions {
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--blackcolor);
  padding: 10px 0;
}
.instructions .caption {
  font-weight: 500;
  color: var(--blackcolor);
}
.instructions .info {
  font-weight: 500;
  color: #e68a00;
}

.form-error {
  font-size: 12px;
  color: rgb(152, 1, 1);
  font-family: var(--poppins);
  background: rgba(234, 65, 65, 0.1);
  padding: 5px;
  border-radius: 3px;
}

.new-password-input-error {
  margin-top: 15px;
  margin-left: 180px;
  color: rgb(152, 1, 1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
