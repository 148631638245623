import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { CourseService } from '../../service/course.service';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'glossary-popup',
  templateUrl: './glossary-popup.component.html',
  styleUrls: ['./glossary-popup.component.scss']
})
export class GlossaryPopupComponent implements OnInit {

  @Input() type;
  @Output() output = new EventEmitter();
  userId: any;
  glossaryData: any;
  glossaryImgPath: any;
  modelTop: any;
  modelLeft: any;

  constructor(private commonService: CommonService, private courseService: CourseService, private apiService: ApiService) {
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
    this.modelTop = this.type.top - 50;
    this.modelLeft = this.type.left;
    this.userId = this.commonService.getUserId()
    const glossaryParam = {
      id: this.userId,
      user_id: this.userId,
      glossary_id: this.type.id,
      method: "getGlossaryDetails"
    }
    this.courseService.getGlossaryDetailsById(glossaryParam).subscribe((res) => {
      this.glossaryData = res['data'][0];
      if (this.glossaryData.image_file != "") {
        this.glossaryImgPath = this.apiService.getCourseImgPath() + this.glossaryData.image_file
      } else {
        this.glossaryImgPath = null
      }
    })
  }
  closed() {
    const newGlossaryData = {
      closed: true
    }
    this.output.next(newGlossaryData)
  }

}
