import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from './../../../../service/data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-wiki-sidebar',
  templateUrl: './wiki-sidebar.component.html',
  styleUrls: ['./wiki-sidebar.component.scss']
})
export class WikiSidebarComponent implements OnInit {
currentArtcleIdContainer:Subscription;
articleName:any;
  constructor(private dataService:DataService , private renderer:Renderer2 , private router:Router) { 
    this.currentArtcleIdContainer = this.dataService.currentArticleId.subscribe((name) => {
      this.articleName = name
    })   
  
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.currentArtcleIdContainer.unsubscribe()
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById('pageContainer');
    if (pageelement.classList.contains('collapse-panel')) {
      this.renderer.removeClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '1');
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '0');
    }
  }

  goToHome(){
    this.router.navigate(['wiki-system'])
  }

}
