import { Component, OnInit, Input, EventEmitter, Output, Renderer2, HostListener, OnDestroy, DoCheck } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonService } from '../../service/common.service';
import { QuizService } from '../../service/quiz.service';
import { ModuleService } from '../../service/module.service';
import { LessonService } from '../../service/lesson.service';
import { CourseService } from '../../service/course.service';
import { UserIdleService } from 'angular-user-idle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GeneralMessageComponent } from 'src/app/dialogBox/general-message/general-message.component';
import { CertificateQuizFormComponent } from 'src/app/dialogBox/certificate-quiz-form/certificate-quiz-form.component';

declare let $: any;


export enum KEY_CODE {
  ENTER = 13,
  BACKSPACE = 8
}
@Component({
  selector: 'app-quiz-multiple',
  templateUrl: './quiz-multiple.component.html',
  styleUrls: ['./quiz-multiple.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('open', style({
        // tslint:disable-next-line: object-literal-key-quotes
        'height': '*'
      })),
      state('close', style({
        // tslint:disable-next-line: object-literal-key-quotes
        'height': '0px'
      })),
      transition('open <=> close', animate(1000))
    ])
  ]
})
export class QuizMultipleComponent implements OnInit, DoCheck {

  // Get data/param and send param to outside component
  @Input() type;
  @Output() output = new EventEmitter();

  // Quiz section display status
  // tslint:disable-next-line: no-inferrable-types
  quizShowStatus: number = 0;

  // Post param data to fetch quiz data
  postData: any;

  // Quiz all data and all question count and quiz wise question count
  quizDataContainer: any;
  allSectionQuestionsCount: number;
  quizWiseQuestionCount: number;
  // tslint:disable-next-line: no-inferrable-types
  questionProgressCount: number = 0;
  // tslint:disable-next-line: no-inferrable-types
  allQuestionProgressCount: number = 0;
  dialogRef: any;
  codeApproved: {} = {};
  // Section progress count
  // tslint:disable-next-line: no-inferrable-types
  sectionWiseQuizCounter: number = 0;
  // tslint:disable-next-line: no-inferrable-types
  allSectionCount: number = 0;

  // Quiz and question butttons
  // tslint:disable-next-line: no-inferrable-types
  nextButton: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  startBtnLoading: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  startQuizDisable: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  prevButtonAction: boolean = false;
  viewBtn: any;

  // Error and data not found
  // tslint:disable-next-line: no-inferrable-types
  notFound: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  quizFormValid: boolean = false;

  // Quiz form variable declaration
  submitted: boolean = false;
  quizFormGroup: FormGroup;
  quiz_question_id: any = "";
  quizOption: any = "";
  quizFormDataFinal: any[] = [];
  attempt: any;
  quizType: any;
  institutionDetails: any;
  totalCorrectAns: number = 0;
  anscounter: any = 1;

  //  Select options variable as per type 1,2,3
  selectOptionData: any[] = [];

  //  For Quiz Timer
  timestamp: any;
  timestampInterval: any;
  secondCount: number = 0;
  clearTimeOutSection: Subscription;

  //  View Result section
  viewResultContainer: any[] = [];
  viewCorrectAns: any[] = [];
  viewTotalQuestionCount: any;
  openCloseAnim: any;
  counter: number = 0;

  questionPrompt: any;
  dataClosePanel: any;

  //  Drag and drop advance variables
  _DragDropArrary: any[] = [];
  _dndFinalDataArray: any[] = [];
  _dndAllDataArray: any[] = [];
  _tempdndArr: any[] = [];
  checkDNDValidate: number = 0;
  quizDataContainerForDND: any;
  gradebookSectionType: any;
  certificate_id: any;
  quizTitle: any;
  skillMasteryPoint: any;

  constructor(private quizService: QuizService, private fb: FormBuilder, private commonService: CommonService, private renderer: Renderer2, private dialog: MatDialog,
    // private moduleService: ModuleService, private lessonService: LessonService, private courseService: CourseService, public dialog: MatDialog,
    private userIdle: UserIdleService, private sanitizer: DomSanitizer) {
    this.openCloseAnim = 'open';
  }

  /**
 * @description Disable Backspace and Enter key for quiz section page
 * @date 2020-01-28
 * @memberof QuizMultipleComponent
 */
  @HostListener('document:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      event.returnValue = false;
      event.preventDefault();
    }
    if (event.keyCode === KEY_CODE.BACKSPACE) {
      event.returnValue = false;
      event.preventDefault();
    }
  }


  ngOnInit() {
    this.setQuizDataLocal();
    this.getTimeStamp();
    this.checkQuizStatus();
    this.quiz_question_id = this.fb.control('', [Validators.required]);
    this.quizOption = this.fb.control('', [Validators.required]);
    this.quizFormGroup = this.fb.group({
      quiz_question_id: this.quiz_question_id,
      quizOption: this.quizOption
    });
    this.checkDataClosePanel();

  }

  /**
  * @description Get time from api or parent component
  * @date 2020-01-28
  * @method getTimeStamp
  * @memberof QuizMultipleComponent
  */
  getTimeStamp() {
    this.timestamp = this.type.quiz_time;
    if (this.timestamp == null || this.timestamp == undefined || this.timestamp == '') {
      this.timestamp = '00:00:00'
    }
  }

  /**
  * @description Check quiz status for view, attampt and retake
  * @date 2020-01-28
  * @method checkQuizStatus
  * @memberof QuizMultipleComponent
  */
  checkQuizStatus() {
    console.log(this.type.is_completed);
    
    if (this.type.is_completed == 1) {
      this.quizShowStatus = 7;
    }
    if (this.type.view_result_btn == 1) {
      this.viewBtn = 1
    }
    if (this.type.allow_attempts == 0) {
      this.attempt = 0
    }
    if (this.type.allow_attempts == 1 && this.type.view_result_btn == 1) {
      this.attempt = 1;
      this.viewBtn = 1;
      this.quizShowStatus = 7;
    }
  }
  /**
  * @description Quiz timer
  * @date 2020-01-28
  * @method getQuizTimer
  * @memberof QuizMultipleComponent
  */
  getQuizTimer() {
    this.secondCount = 0;
    this.timestampInterval = setInterval(() => {
      const value = Math.abs(this.secondCount);
      const days = Math.floor(value / 1440);
      const hours = Math.floor((value - (days * 1440)) / 3600);
      const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
      const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
      this.timestamp = `${this.secondCount < 0 ? '-' : ''}${days > 0 ? days + '.' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
      this.secondCount++;
    }, 1000)

  }

  /**
  * @description Quiz time out and user idle time out
  * @date 2020-01-28
  * @method getTimeOutForQuiz
  * @memberof QuizMultipleComponent
  */
  // getTimeOutForQuiz() {
  //   this.startWatching();
  //   this.userIdle.onTimerStart().subscribe(count => {
  //     //this.openQuizExpireDialog();
  //   });
  //   this.clearTimeOutSection = this.userIdle.onTimeout().subscribe(() => {
  //     this.openQuizExpireDialog();
  //     this.clearTimeOutSection.unsubscribe()
  //   });
  // }
  // stop() {
  //   this.userIdle.stopTimer();
  // }

  // stopWatching() {
  //   this.userIdle.stopWatching();
  // }

  // startWatching() {
  //   this.userIdle.startWatching();
  // }
  // restart() {
  //   this.userIdle.resetTimer();
  //   this.clearTimeOutSection = this.userIdle.onTimeout().subscribe(() => {
  //     this.openQuizExpireDialog();
  //     this.clearTimeOutSection.unsubscribe()
  //   });
  // }

  /**
  * @description Set quiz data to localStorage
  * @date 2020-01-28
  * @method setQuizDataLocal
  * @memberof QuizMultipleComponent
  */
  setQuizDataLocal() {
    localStorage.setItem('quizData', JSON.stringify(this.type));
  }

  /**
  * @description Check quiz for password dialog
  * @date 2020-01-28
  * @method checkPassword
  * @memberof QuizMultipleComponent
  */


  startQuiz(quizId: any) {
    console.log(this.type.quizCode);
    this.getAllQuizById(quizId)
    // this.openCertificateQuizDialog(this.type.quizCode, quizId)
  }

  /**
  * @description Password dialog for final and mid term quiz
  * @date 2020-01-28
  * @method openQuizVarifyDialog
  * @memberof QuizMultipleComponent
  */
  // openQuizVarifyDialog() {
  //   this.startBtnLoading = true;
  //   const dialogRef = this.dialog.open(QuizPasswordVarifyDialogComponent, {
  //     width: '600px',
  //     height: '400px',
  //     disableClose: true,
  //     panelClass: "varify-password-dialog",
  //     data: this.type
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result.status) {
  //       this.getAllQuizById(this.type.id);
  //     } else {
  //       this.startBtnLoading = false;
  //     }
  //   });
  // }

  /**
  * @description Get all quiz and question data
  * @date 2020-01-28
  * @method getAllQuizById
  * @memberof QuizMultipleComponent
  */
  getAllQuizById(quizId: any) {
   // this.getTimeOutForQuiz();
    this.quizShowStatus = 5;
    this.postData = {
      course_slug: this.type.course_slug,
      module_slug: this.type.module_slug,
      lesson_slug: this.type.lesson_slug,
      section_slug: this.type.section_slug,
      quiz_id: quizId,
      user_id: this.commonService.getUserId(),
      method: 'getQuizQuestionAll',
    }
    this.quizService.getQuestion(this.postData).subscribe((res) => {
      console.log(res);
      
      if (res['data'] != undefined || res['status'] == true) {
        this.startBtnLoading = false;
        if (res['data']['play_allow'] > 0 || res['data']['play_allow'] == undefined) {
          this.getQuizTimer();
          if (res['data'].questions[0] != undefined) {
            if (res['data'].questions[0].questions.length > 0) {
              this.notFound = false;
              this.quizDataContainerForDND = res['data']['questions'];
              this.quizDataContainer = res['data']['questions'];
              this.allSectionQuestionsCount = res['data']['allSectionQuestionsCount'];
              this.quizShowStatus = 1;
              this.quizWiseQuestionCount = res['data']['questions'][0]['total_question'];
              this.allSectionCount = this.quizDataContainer;
              this.quizType = res['data'].quiz_type.type;
              this.gradebookSectionType = res['data'].quiz_type.gradebook_section_type;
              this.quizTitle = res['data'].quiz_type.quiz_title;
              this.skillMasteryPoint = res['data'].quiz_type.skill_mastery_point;
              this.certificate_id = res['data'].quiz_type.certificate_id;
              this.quizShowStatus = 1;
              if (this.allQuestionProgressCount >= (this.allSectionQuestionsCount - 1)) {
                this.nextButton = true;
              } else {
                this.nextButton = false;
              }
              this.dragDropQuestion(this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount])

            } else {
              this.quizShowStatus = 6;
            }
          } else {
            this.quizShowStatus = 6
          }
        } else {
          this.quizShowStatus = 7;
          this.attempt = 1;
          this.timestamp = res['data']['quiz_spent_time']
        }
      } else {
        this.notFound = true;
      }
    })
    
  }

  /*****************************************************************************
   *****************************************************************************
   *****************************************************************************/
  /**  @DragDrop */
  dragDropQuestion(question: any) {
    const self = this;
    let questionPromptItem = question.question_prompt;
    console.log(question.question_prompt);
    
    // if (question.type === "5") {
    //   const newReg = new RegExp("[[+[0-9]+]]", "gi");
    //   const filterArray = question.question_prompt.match(newReg);
    //   const faFilterData = this.quizFormDataFinal.filter(x => x.quiz_question_id === question.id)


    //   if (faFilterData.length > 0) {
    //     filterArray.forEach((element, key) => {
    //       if (faFilterData[0].quizOption[key] != 0 && faFilterData[0].quizOption[key] != undefined) {
    //         self._tempdndArr.push({
    //           id: `${faFilterData[0].quizOption[key].id}`,
    //           response: faFilterData[0].quizOption[key].response
    //         });
    //         const index = questionPromptItem.indexOf(element)
    //         const indexLength = questionPromptItem.slice(0, index + element.length);
    //         const htmlContent = indexLength.replace(
    //           filterArray[key],
    //           `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="${faFilterData[0].quizOption[key].id}" /> <span class="droppable-tag" id="drop_opt_${faFilterData[0].quizOption[key].id}">${faFilterData[0].quizOption[key].response}<span class="close" data-close="close_opt_${faFilterData[0].quizOption[key].id}"><i class="flaticon-letter-x"></i></span></span> </span></span>`,
    //           index
    //         );
    //         questionPromptItem = htmlContent + questionPromptItem.slice(index + element.length, -1)

    //       } else {
    //         self._tempdndArr.push(0);
    //         const index = questionPromptItem.indexOf(element)
    //         const indexLength = questionPromptItem.slice(0, index + element.length);
    //         const htmlContent = indexLength.replace(
    //           filterArray[key],
    //           `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="0" /></span></span>`,
    //           index
    //         );
    //         questionPromptItem = htmlContent + questionPromptItem.slice(index + element.length, -1)
    //       }



    //     });
    //   } else {
    //     self._tempdndArr = [];
    //     if(filterArray != null){
    //       filterArray.forEach((element, key) => {

    //         self._tempdndArr.push(0);
    //         const index = questionPromptItem.indexOf(element)
    //         const indexLength = questionPromptItem.slice(0, index + element.length);
    //         const htmlContent = indexLength.replace(
    //           filterArray[key],
    //           `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="0" /></span></span>`,
    //           index
    //         );
    //         questionPromptItem = htmlContent + questionPromptItem.slice(index + element.length, -1)
    //       });
    //     }
    //   }


    //   this.questionPrompt = questionPromptItem;
    //   setTimeout(() => {

    //     $("#draggable li").draggable({
    //       connectToSortable: ".sortable",
    //       helper: "clone",
    //       revert: "invalid",
    //       appendTo: "body",
    //     });

    //     $(".sortable").droppable({
    //       accept: "#draggable li.ui-state-default",
    //       drop: function (event, ui) {
    //         const _dropHTMLData = `<span class="droppable-tag" id="drop_${ui.draggable[0].id}">${ui.draggable[0].innerText}<span class="close" data-close="close_${ui.draggable[0].id}"><i class="flaticon-letter-x"></i></span></span>`
    //         const listItemById = document.getElementById(ui.draggable[0].id);
    //         listItemById.classList.add('ui-disable')
    //         $(event.target).append(_dropHTMLData);
    //         $(event.target.firstChild).val(ui.draggable[0].value);
    //         $(event.target).droppable("option", "disabled", true);
    //         self.setSingleMultipleOptions(ui.draggable[0].value, 1);
    //         self._DragDropArrary.push({ id: ui.draggable[0].value });
    //         const getdndArrExist = self._dndAllDataArray.filter(x => x.questionId == self.quizDataContainer[self.sectionWiseQuizCounter].questions[self.questionProgressCount].id);
    //         self._tempdndArr[event.target.id] = {
    //           id: `${ui.draggable[0].value}`,
    //           response: ui.draggable[0].innerText
    //         };



    //         // if (getdndArrExist.length > 0) {
    //         //   getdndArrExist[0].optionIds.push({
    //         //     id: ui.draggable[0].value,
    //         //     response: ui.draggable[0].innerText
    //         //   })
    //         // } else {
    //         //   self._dndAllDataArray.push({
    //         //     questionId: self.quizDataContainer[self.sectionWiseQuizCounter].questions[self.questionProgressCount].id,
    //         //     optionIds: [{
    //         //       id: ui.draggable[0].value,
    //         //       response: ui.draggable[0].innerText
    //         //     }]
    //         //   })
    //         // }



    //       }
    //     });
    //   }, 100)

    // }

    if (question.type === "5") {
      const newReg = /\[\[\d+\]\]/gi;
      const filterArray = question.question_prompt.match(newReg);
      console.log('question.question_prompt:', question.question_prompt);
      console.log('filterArray:', filterArray);
      const faFilterData = this.quizFormDataFinal.filter(x => x.quiz_question_id === question.id);

      if (faFilterData.length > 0 && filterArray) {
        filterArray.forEach((element, key) => {
          if (faFilterData[0].quizOption[key] != 0 && faFilterData[0].quizOption[key] != undefined) {
            this._tempdndArr.push({
              id: `${faFilterData[0].quizOption[key].id}`,
              response: faFilterData[0].quizOption[key].response
            });
            const index = question.question_prompt.indexOf(element);
            const indexLength = question.question_prompt.slice(0, index + element.length);
            const htmlContent = indexLength.replace(
              filterArray[key],
              `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="${faFilterData[0].quizOption[key].id}" /> <span class="droppable-tag" id="drop_opt_${faFilterData[0].quizOption[key].id}">${faFilterData[0].quizOption[key].response}<span class="close" data-close="close_opt_${faFilterData[0].quizOption[key].id}"><i class="flaticon-letter-x"></i></span></span> </span></span>`,
              index
            );
            question.question_prompt = htmlContent + question.question_prompt.slice(index + element.length);
          } else {
            this._tempdndArr.push(0);
            const index = question.question_prompt.indexOf(element);
            const indexLength = question.question_prompt.slice(0, index + element.length);
            const htmlContent = indexLength.replace(
              filterArray[key],
              `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="0" /></span></span>`,
              index
            );
            question.question_prompt = htmlContent + question.question_prompt.slice(index + element.length);
          }
        });
      } else {
        this._tempdndArr = [];
        if (filterArray != null) {
          filterArray.forEach((element, key) => {
            this._tempdndArr.push(0);
            const index = question.question_prompt.indexOf(element);
            const indexLength = question.question_prompt.slice(0, index + element.length);
            const htmlContent = indexLength.replace(
              filterArray[key],
              `<span class="draggable-area" id="draggable_area_${key}" data-droppable-index="${key}"><span class="area ui-state-highlight sortable" id="${key}"><input type="hidden" id="input_${key}" value="0" /></span></span>`,
              index
            );
            question.question_prompt = htmlContent + question.question_prompt.slice(index + element.length);
          });
        }
      }

      this.questionPrompt = question.question_prompt;
      setTimeout(() => {
        $("#draggable li").draggable({
          connectToSortable: ".sortable",
          helper: "clone",
          revert: "invalid",
          appendTo: "body",
        });

        $(".sortable").droppable({
          accept: "#draggable li.ui-state-default",
          drop: (event, ui) => {
            const _dropHTMLData = `<span class="droppable-tag" id="drop_${ui.draggable[0].id}">${ui.draggable[0].innerText}<span class="close" data-close="close_${ui.draggable[0].id}"><i class="flaticon-letter-x"></i></span></span>`;
            const listItemById = document.getElementById(ui.draggable[0].id);
            listItemById.classList.add('ui-disable');
            $(event.target).append(_dropHTMLData);
            $(event.target.firstChild).val(ui.draggable[0].value);
            $(event.target).droppable("option", "disabled", true);
            this.setSingleMultipleOptions(ui.draggable[0].value, 1);
            this._DragDropArrary.push({ id: ui.draggable[0].value });
            const getdndArrExist = this._dndAllDataArray.filter(x => x.questionId == this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id);
            this._tempdndArr[event.target.id] = {
              id: `${ui.draggable[0].value}`,
              response: ui.draggable[0].innerText
            };
          }
        });
      }, 100);
    }
  }

  checkDroppableArea() {
    setTimeout(() => {
      const getElm = document.querySelectorAll(".sortable input");

      getElm.forEach(element => {

        if ($(element).val() > 0) {
          $(element.parentElement).droppable("option", "disabled", true)
        }
      });
    }, 200)
  }


  /**  @DragDrop */
  checkDataClosePanel() {
    const self = this;
    $('[data-close]').on("click", function (event) {
      let thisId = $(this).attr('data-close');
      let thisSplit = thisId.split('_');
      thisId = thisId.replace(thisId, thisSplit[1] + '_' + thisSplit[2]);
      $(".sortable.ui-droppable-disabled").droppable("option", "disabled", false);
      if ($(this)[0].parentElement.previousSibling) {
        $(this)[0].parentElement.previousSibling.value = 0
      }
      //$(this)[0].parentElement.previousSibling.value = 0;
      $("#" + thisId).removeClass('ui-disable')
      $(this)[0].parentNode.remove();
      self.setSingleMultipleOptions(thisSplit[2], 0);
      const index = self._DragDropArrary.findIndex(x => x.id == thisSplit[2]);
      self._DragDropArrary.splice(index, 1);

      // const getdndArrExist = self._dndAllDataArray.filter(x => x.questionId == self.quizDataContainer[self.sectionWiseQuizCounter].questions[self.questionProgressCount].id);
      // const filterOptId = getdndArrExist[0].optionIds.findIndex(x => x.id == thisSplit[2])
      // if (filterOptId > -1) {
      //   getdndArrExist[0].optionIds.splice(filterOptId, 1)
      // }

      const getIndex = self._tempdndArr.findIndex(x => x.id == thisSplit[2]);
      self._tempdndArr[getIndex] = 0;


    })

  }

  checkDragDropFinalArray() {
    const getdndArrExist = this._dndAllDataArray.filter(x => x.questionId == this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id);

    if (getdndArrExist.length > 0) {
      getdndArrExist[0].optionIds = this._tempdndArr
    } else {
      this._dndAllDataArray.push({
        questionId: this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id,
        optionIds: this._tempdndArr
      })
    }

  }
  /**  @DragDrop */
  getViewQuestionData(question) {
    let questionPromptItem = question;
    const newReg = new RegExp("[[+[0-9]+]]", "gi");
    const filterArray = question.match(newReg);
    filterArray.forEach((element, key) => {
      const index = questionPromptItem.indexOf(element)
      const indexLength = questionPromptItem.slice(0, index + element.length);
      const htmlContent = indexLength.replace(
        filterArray[key],
        `<span class="draggable-area"><span class="area dark ui-state-highlight" id="${key}"></span></span>`,
        index
      );
      questionPromptItem = htmlContent + questionPromptItem.slice(index + element.length, -1)
    });

    return questionPromptItem;
  }

  replaceFIBQuestion(question) {
    let questionPromptItem = question;
    const newReg = new RegExp("[[+[0-9]+]]", "gi");
    const filterArray = question.match(newReg);
    filterArray.forEach((element, key) => {
      const index = questionPromptItem.indexOf(element)
      const indexLength = questionPromptItem.slice(0, index + element.length);
      const htmlContent = indexLength.replace(
        filterArray[key],
        `<span >__________</span>`,
        index
      );
      questionPromptItem = htmlContent + questionPromptItem.slice(index + element.length, -1)
    });

    return questionPromptItem;
  }


  /*****************************************************************************
   *****************************************************************************
   *****************************************************************************/

  /**
  * @description Selected index array on change
  * @date 2020-01-28
  * @method selectedIndex
  * @memberof QuizMultipleComponent
  */
  selectedIndex(sectionWiseQuizCounter: any, questionProgressCount: any, optionID: any, checkStatus: boolean, questionType: any) {
    switch (questionType) {
      case "1":
        this.selectOptionData = [];
        this.selectOptionData = [{ "id": optionID, "status": checkStatus }];
        this.setOnlySingleOpt();
        this.setSingleMultipleOptions(optionID, 1);
        break;
      case "2":
      case "3":
      case "4":
        //case "5":
        if (checkStatus) {
          this.selectOptionData.push({ "id": optionID, "status": checkStatus });
          this.setSingleMultipleOptions(optionID, 1);
          this.quizFormValid = false;
        } else {
          let index = this.selectOptionData.findIndex(x => x.id == optionID)
          this.selectOptionData.splice(index, 1);
          this.setSingleMultipleOptions(optionID, 0);
        }
        break;
    }
  }

  /**
  * @description Reset all single selection
  * @date 2020-01-28
  * @method setOnlySingleOpt
  * @memberof QuizMultipleComponent
  */
  setOnlySingleOpt() {
    const allOption = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options;
    allOption.forEach(element => {
      element.selected_option = 0;
      element.checked = false;
    });
  }

  /**
  * @description Set selected_option for single and multiple
  * @date 2020-01-28
  * @method setSingleMultipleOptions
  * @memberof QuizMultipleComponent
  */
  setSingleMultipleOptions(optionID, status) {

    const selectedOption = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options.filter(x => x.id == optionID);
    const selectedOptionForDND = this.quizDataContainerForDND[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options_random.filter(x => x.id == optionID);


    selectedOption.forEach(element => {
      element.selected_option = status;
      if (status == 1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    selectedOptionForDND.forEach(element => {
      element.selected_option = status;
      if (status == 1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });

    if (this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].type === "5") {

    }
  }

  /**
  * @description Process for previous button
  * @date 2020-01-28
  * @method prevButton
  * @memberof QuizMultipleComponent
  */
  prevButton(sectionCount, quizQuestionId, sectionId) {
    /**  @DragDrop */
    this.checkDNDValidate = 0;
    if (this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].type == "5") {

      const quizParamData = {
        id: this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id,
        _tempdndArr: this._tempdndArr
      }
      this.CheckPrevDNDData(quizParamData);

    }
    this._tempdndArr = [];
    //return;
    this.qcDecrease();
    this.checkAllQusCount();
    this.checkForFinishBtn();

    //return;
    /**  @DragDrop */

    this.dragDropQuestion(this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount]);
    //this.checkDragDropFinalArray()
    //this.checkCorrectAnsForDND();
    this.checkDroppableArea()
  }

  /**
  * @description Process for next button
  * @date 2020-01-28
  * @method nextQuestionButton
  * @memberof QuizMultipleComponent
  */
  nextQuestionButton(sectionCount, quizQuestionId, sectionId) {
    /**  @DragDrop */
    this.checkDragDropFinalArray();
    this._tempdndArr = [];


    this.setFinalDataArray();
    if (this.allQuestionProgressCount <= (this.allSectionQuestionsCount - 1)) {
      this.qcIncrease(this.quizDataContainer[sectionCount].questions.length);
    }
    this.checkAllQusCount();
    this.checkForFinishBtn();
    this.quizOption = this.fb.control('', Validators.required);
    this.quiz_question_id = this.fb.control('', Validators.required);

    if (this.allQuestionProgressCount === this.allSectionQuestionsCount) {
      this.finishQuiz();
    } else {
    }
    /**  @DragDrop */
    this.dragDropQuestion(this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount])
    this.checkDroppableArea()
  }



  CheckPrevDNDData(data) {

    const checkExData = this.quizFormDataFinal.filter(x => x.quiz_question_id === data.id)

    if (checkExData.length > 0) {

      this.checkDragDropFinalArray();
      this.checkCorrectAnsForDND();
    } else {
      this.checkDragDropFinalArray();

      //this._tempdndArr = [];
      this.checkCorrectAnsForDND();
    }
  }


  /**
  * @description Check all question for finish button
  * @date 2020-01-28
  * @method checkForFinishBtn
  * @memberof QuizMultipleComponent
  */
  checkForFinishBtn() {

    if (this.allQuestionProgressCount < (this.allSectionQuestionsCount - 1)) {
      this.nextButton = false;
    } else {
      this.nextButton = true;
    }
  }


  /**
  * @description Check question count grater then 0 for previous button
  * @date 2020-01-28
  * @method checkAllQusCount
  * @memberof QuizMultipleComponent
  */
  checkAllQusCount() {
    if (this.allQuestionProgressCount > 0) {
      this.prevButtonAction = true;
    } else {
      this.prevButtonAction = false;
    }
  }


  /**
  * @description Increase quiz count
  * @date 2020-01-28
  * @method qcIncrease
  * @memberof QuizMultipleComponent
  */
  qcIncrease(totalQuestionCount) {
    this.allQuestionProgressCount++;
    if (this.allQuestionProgressCount === this.allSectionQuestionsCount) {
    } else {
      if (this.questionProgressCount <= totalQuestionCount) {
        this.questionProgressCount++;
      } else {
        this.questionProgressCount = 0
      }
      if (this.questionProgressCount == totalQuestionCount) {
        this.sectionWiseQuizCounter++;
        this.questionProgressCount = 0
      }
    }
  }


  /**
  * @description Decrease quiz count
  * @date 2020-01-28
  * @method qcDecrease
  * @memberof QuizMultipleComponent
  */
  qcDecrease() {
    if (this.questionProgressCount == 0) {
      this.sectionWiseQuizCounter--;
      this.questionProgressCount = this.quizDataContainer[this.sectionWiseQuizCounter].questions.length
    }
    this.questionProgressCount--;
    this.allQuestionProgressCount--;
  }

  /**
  * @description Insrease section count
  * @date 2020-01-28
  * @method scIncrease
  * @memberof QuizMultipleComponent
  */
  scIncrease(totalSectionCount) {
    if (this.sectionWiseQuizCounter <= totalSectionCount) {
      this.sectionWiseQuizCounter++
    } else {
      this.sectionWiseQuizCounter = 0
    }
  }

  /**
  * @description Get selected options
  * @date 2020-01-28
  * @method getSelectedOptions
  * @memberof QuizMultipleComponent
  */
  getSelectedOptions() {
    const selectedOption = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options.filter(x => x.selected_option === 1);
    return selectedOption.length;
  }

  /**
  * @description Process of final data array
  * @date 2020-01-28
  * @method setFinalDataArray
  * @memberof QuizMultipleComponent
  */
  setFinalDataArray() {
    if (this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].type != "5") {
      const optParam = [];
      const sectionId = this.quizDataContainer[this.sectionWiseQuizCounter].id;
      const quizId = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id;
      const optionsFilter = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options.filter(x => x.selected_option === 1);
      optionsFilter.forEach(element => {
        const optData = {
          "id": element.id,
          "status": element.checked
        }
        optParam.push(optData)
      });
      const filterDataFinal = this.quizFormDataFinal.filter(x => x.quiz_question_id == quizId);
      const checkAnswer = this.checkCorrectAnswer();
      if (filterDataFinal.length > 0) {
        const indexData = this.quizFormDataFinal.findIndex(x => x.quiz_question_id == filterDataFinal[0].quiz_question_id);
        if (indexData > -1) {
          this.quizFormDataFinal.splice(indexData, 1)
          this.pushToFinalArray(optParam, quizId, sectionId, checkAnswer)
        }
      } else {
        this.pushToFinalArray(optParam, quizId, sectionId, checkAnswer)
      }
    } else {

      this.checkCorrectAnsForDND();



    }


  }

  checkCorrectAnsForDND() {
    let counterCheck = [];
    const sectionId = this.quizDataContainer[this.sectionWiseQuizCounter].id;
    const quizId = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].id;
    const optionsData = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options;

    const dndAllTempArray = this._dndAllDataArray.filter(x => x.questionId === quizId);

    dndAllTempArray[0].optionIds.forEach((element, key) => {

      if (element.id === optionsData[key].id) {
        counterCheck.push({ status: 1 })
      } else {
        counterCheck.push({ status: 0 })
      }
    });

    const counterFilter = counterCheck.filter(x => x.status === 0)
    const checkQuizIdExist = this.quizFormDataFinal.filter(x => x.quiz_question_id === quizId);

    if (checkQuizIdExist.length > 0) {
      checkQuizIdExist[0].quizOption = [];
      checkQuizIdExist[0].quizOption = dndAllTempArray[0].optionIds;
      if (counterFilter.length > 0) {
        checkQuizIdExist[0].correct = 0
      } else {
        checkQuizIdExist[0].correct = 1
      }

    } else {
      if (counterFilter.length > 0) {
        this.pushToFinalArray(dndAllTempArray[0].optionIds, quizId, sectionId, 0)
      } else {
        this.pushToFinalArray(dndAllTempArray[0].optionIds, quizId, sectionId, 1)
      }
    }


    this._dndAllDataArray = []
  }

  /**
   * @description Check correct answer
   * @date 2020-01-28
   * @method checkCorrectAnswer
   * @memberof QuizMultipleComponent
   */
  checkCorrectAnswer() {
    let returnStatsu = 0;
    let statusCheck = [];
    const optionData = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options;
    const optionAnddata = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options.filter(x => x.answer_status === '1');
    const optionSelected = this.quizDataContainer[this.sectionWiseQuizCounter].questions[this.questionProgressCount].options.filter(x => x.selected_option === 1);
    if (optionAnddata.length === optionSelected.length) {
      for (let j = 0; j < optionData.length; j++) {
        if (optionData[j].answer_status === "1" && optionData[j].selected_option === 1) {
          statusCheck.push(1)
        }
      }
      if (optionAnddata.length === statusCheck.length) {
        returnStatsu = 1
      } else {
        returnStatsu = 0
      }
    } else {
      statusCheck.push(0);
      returnStatsu = 0
    }
    return returnStatsu;
  }

  /**
   * @description Push data to final array all param
   * @date 2020-01-28
   * @method pushToFinalArray
   * @memberof QuizMultipleComponent
   */
  pushToFinalArray(optParam, quizId, sectionId, status) {
    const qfdParam = {
      'quizOption': optParam,
      'quiz_question_id': quizId,
      'section_id': sectionId,
      'correct': status
    }
    this.quizFormDataFinal.push(qfdParam)
  }

  /**
     * @description reset all param of quiz
     * @date 2020-01-28
     * @method resetAllParams
     * @memberof QuizMultipleComponent
     */
  resetAllParams() {
    this.quizFormDataFinal = [];
    this.sectionWiseQuizCounter = 0;
    this.questionProgressCount = 0
    this.allQuestionProgressCount = 0;
    this.nextButton = false;
    this.prevButtonAction = false;
  }

  /**
   * @description finishing process for quiz
   * @date 2020-01-28
   * @method finishQuiz
   * @memberof QuizMultipleComponent
   */
  finishQuiz() {

    this.totalCorrectAns = 0;
    clearInterval(this.timestampInterval);
    const instData = JSON.parse(localStorage.getItem('theme_style'));
    this.institutionDetails = {
      id: instData['id'],
      name: instData['name'],
      theme_style: instData['theme_style']
    }

    this.quizFormDataFinal.forEach(element => {
      if (element.correct === 1) {
        this.totalCorrectAns++
      }
    });

    const finishQuizData = {
      contain_id: this.type.id,
      institution_data: this.institutionDetails,
      contain_type: this.commonService.containTypeArr['quiz'],
      course_slug: this.type.course_slug,
      id: this.commonService.getUserId(),
      lesson_slug: this.type.lesson_slug,
      module_slug: this.type.module_slug,
      method: 'courseActivityCompletionMethod',
      quizFormData: this.quizFormDataFinal,
      quiz_type: this.type.quiz_reference_type,//this.commonService.quizTypeArr['Module'],
      gradebook_section_type: this.gradebookSectionType,
      reference_id: this.type.id,
      reference_type: this.commonService.quizReferenceType,
      section_slug: this.type.section_slug,
      quiz_spent_time: this.timestamp,
      container_id: this.commonService.getDomainContainerId(),
      certificate_id: this.certificate_id,
      quiz_title: this.quizTitle,
      skill_mastery_point: this.skillMasteryPoint
    };
    this.resetAllParams();
    this.quizShowStatus = 5;
    this.quizService.finishMultipleQuiz(finishQuizData).subscribe(
      (res) => {
        
        this.viewBtn = res['data']['view_result_btn'];
        this.quizShowStatus = 2;
        if (res['data']['quiz_attempt_allow'] == "0") {
          this.attempt = 0
        } else {
          this.attempt = 1
        }

        if (res['data']['quiz_attempt_allow'] == "1"){
         this.quizShowStatus = 7
        }
        this.totalCorrectAns = this.totalCorrectAns != undefined ? this.totalCorrectAns : 0
        const quizComplete = {
          action_type: 1,
          quizId: this.type.id,
          is_completed : '1',
          quiz_spent_time: this.timestamp,
          question_count : this.allSectionQuestionsCount,
          total : this.totalCorrectAns,
          quiz_attemp : res['data']['quiz_attempt_allow']
        }
        
        console.log(quizComplete);      
        this.output.next(quizComplete)
        this.checkDNDValidate = 0
        console.log(quizComplete);
      },
      error => error
    )
  }


  /**
  * @description Restart quiz
  * @date 2020-01-28
  * @method restartQuiz
  * @memberof QuizMultipleComponent
  */
  restartQuiz() {
    this.getAllQuizById(this.type.id);
    // this.openCertificateQuizDialog(this.type.quizCode, this.type.id)
  }


  /**
  * @description View result
  * @date 2020-01-28
  * @method viewQuestion
  * @memberof QuizMultipleComponent
  */
 viewQuestion(gradebook_section_type, quiz_reference_type) {
    this.quizShowStatus = 5;
    const data = {
      method: 'getQuizMultipleLastResult',
      quiz_id: this.type.id,
      user_id: this.commonService.getUserId(),
      course_slug: this.type.course_slug,
      section_slug: this.type.section_slug,
      gradebook_section_type: gradebook_section_type,
      quiz_type: quiz_reference_type
    }
    this.quizService.getMultipleQuizView(data).subscribe(
      (res) => {
        this.quizShowStatus = 3;
        this.viewResultContainer = res['data'];
        this.viewTotalQuestionCount = res['data']['allSectionQuestionsCount'];
        this.viewResultContainer['questions'].forEach((element, key) => {
          const filteredData = element.questions.filter(x => x.correct === "1");
          this.viewCorrectAns[key] = {
            total_answer: filteredData.length,
            total_question: element.total_question
          }
        });
      }
    )
  }


  /**
  * @description Submit quiz
  * @date 2020-01-28
  * @method onSubmit
  * @memberof QuizMultipleComponent
  */
  onSubmit(sectionCount, quizQuestionId, sectionId) {

    // For Drag & Drop
    this.checkDNDValidate = 0;

    this._tempdndArr.forEach(element => {
      if (element === 0) {
        this.checkDNDValidate++;
      } else {

      }
    });

    //return;


    if (this.getSelectedOptions() > 0 && this.checkDNDValidate === 0) {
      // this.quizFormValid = false;
      this.nextQuestionButton(sectionCount, quizQuestionId, sectionId)
    } else {
      this.quizFormGroup = this.fb.group({
        quiz_question_id: this.quiz_question_id,
        quizOption: this.quizOption
      });
      this.submitted = true;
      if (this.quizFormGroup.invalid && !this.quizFormGroup.value.quizOption) {
        return;
      }
      this.quizFormValid = true;
    }

    this._DragDropArrary = [];  // For Drag & Drop

  }


  /**
 * @description Collapse for view section
 * @date 2020-01-28
 * @method openReportsFilter
 * @memberof QuizMultipleComponent
 */
  openReportsFilter(event): void {
    this.openCloseAnim = (this.openCloseAnim == 'open') ? 'close' : 'open';
  }


  /**
  * @description Quiz time expire dialog
  * @date 2020-01-28
  * @method openQuizExpireDialog
  * @memberof QuizMultipleComponent
  */
  // openQuizExpireDialog() {
  //   const dialogRef = this.dialog.open(QuizExpireDialogComponent, {
  //     width: '400px',
  //     height: '280px',
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       clearInterval(this.timestampInterval);
  //       this.restartQuiz();
  //       this.stop();
  //       this.stopWatching();
  //       this.quizShowStatus = 0;
  //       this.clearTimeOutSection.unsubscribe();
  //     } else {
  //       this.quizShowStatus = 0;
  //       this.restart()
  //     }

  //   });
  // }


  /**
  * @description Destroy all quiz time
  * @date 2020-01-28
  * @method quizTimeDestroy
  * @memberof QuizMultipleComponent
  */
  // quizTimeDestroy() {
  //   this.userIdle.stopTimer();
  //   this.userIdle.stopWatching();
  //   if (this.clearTimeOutSection != undefined) {
  //     this.clearTimeOutSection.unsubscribe();
  //   }
  // }


  // ngOnDestroy() {
  //   this.quizTimeDestroy();
  // }

  ngDoCheck() {
    // For Drag & Drop
    this.checkDataClosePanel()
  }

  openCertificateQuizDialog(quizCode: any, qId: any) {
    this.dialogRef = this.dialog.open(CertificateQuizFormComponent, {
      data: {
        code: quizCode,
        qId: qId
      }
    });
    this.dialogRef.afterClosed().subscribe(res => {
      console.log(qId);
      console.log(res);

      let msg: any;
      if (res.event == 1) {
        if (localStorage.getItem('locale') == 'ger') {
          msg = 'Code erfolgreich aktiviert Bitte klicke jetzt auf "Quiz starten".';
        } else {
          msg = 'Code successfully activated. Please click on "Start quiz"';
        }
        this.codeApproved[qId] = true;
        // this.getAllQuizById(qId)
      } else {
        if (localStorage.getItem('locale') == 'ger') {
          msg = 'Code nicht gültig für dieses Quiz.';
        } else {
          msg = 'Code not valid for this quiz.';
        }
      }
      this.openGeneralDialogBox(msg, res.event, qId);
    });
  }

  openGeneralDialogBox(msg: any, event: any, qid: any) {
    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: msg
      }
    });
    this.dialogRef.afterClosed().subscribe(res => {
      if (res && event == 1) {
        this.getAllQuizById(qid)
      }
    });
  }
}
