import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-course-certificate',
  templateUrl: './course-certificate.component.html',
  styleUrl: './course-certificate.component.scss'
})
export class CourseCertificateComponent implements OnInit {
  userCertificates: any;
  showPanelStatus: boolean = true;
  show : boolean = false ; 
  msg : string;


  constructor(private commonService: CommonService,private userService: UserService){
  }

  ngOnInit(){
    this.getUserCertificates()
  }

  togglePanel(togglePanelElement: HTMLElement, event) {
    const getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }
  }

  getUserCertificates() {
    const data = {
      method: 'getUserCertificate',
      container_id: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    };
    this.userService.getUserCertificate(data).subscribe(res => {
      console.log(res);
      if(res['status']){
        this.userCertificates = res['data'];  
      }
      else{
        this.show = true ; 
        this.msg = res['message']
      }
    });
  }

}
