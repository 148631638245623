// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poll-input-error {
  color: #f00;
  margin-bottom: 10px;
  margin-left: 53px;
  margin-top: -18px;
}

.module-content-flex h3 {
  font-size: 18px;
}

.module-content-flex ul {
  margin-left: auto;
  display: table-cell;
}

.heading {
  font-size: 14px;
  font-weight: 500;
}

.lessonCollapseContent {
  transition: max-height 0.5s ease-in-out;
}
.lessonCollapseContent.open {
  max-height: none;
}

.readMoreBtn {
  text-align: right;
  width: 100%;
}
.readMoreBtn .btn {
  text-align: right;
  font-size: 13px;
  padding-bottom: 0;
}

.quiz-list-container button {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
  width: 100%;
  border: 0;
  text-align: left;
  background: transparent;
}
.quiz-list-container button .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container button.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container button.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}
.quiz-list-container button.is-complete .circle-item {
  border: 3px solid #fff;
}
.quiz-list-container button.is-complete .circle-item:before {
  position: absolute;
  top: -2px;
  content: "\\f121";
  font-size: 7px;
  color: #fff;
  font-family: var(--flaticon);
  background: #1f9c3a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -3px;
  padding: 3px 5px;
}
.quiz-list-container button.is-eassy-complete {
  pointer-events: none;
}
.quiz-list-container button.is-eassy-complete .circle-item {
  border: 3px solid #fff;
}
.quiz-list-container button.is-eassy-complete .circle-item:before {
  position: absolute;
  top: -2px;
  content: "\\f121";
  font-size: 7px;
  color: #fff;
  font-family: var(--flaticon);
  background: #1f9c3a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -3px;
  padding: 3px 5px;
}
.quiz-list-container a {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
}
.quiz-list-container a .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container a.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container a.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}

.gradebook-section .heading {
  background: #445161;
  color: #fff;
  font-family: var(--poppins);
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.gradebook-section .gradebook-table .table th,
.gradebook-section .gradebook-table .table td {
  font-family: var(--poppins);
}
.gradebook-section .gradebook-table .table td.bgnone {
  background: none;
}
.gradebook-section .gradebook-table .table tfoot td {
  font-weight: 500;
}
.gradebook-section .gradebook-table .bg {
  font-weight: 500;
  background: #f2f2f2;
}
.gradebook-section .gradebook-table .bg-default {
  color: #000;
  background: #e9ecef;
}

.citation-img {
  width: 220px;
  text-align: center;
  margin: auto;
}
.citation-img img {
  max-width: 100%;
}
.citation-img a {
  font-size: 45px !important;
  color: #dc3545;
  text-decoration: none;
}

.btn.btn-white {
  background: #f7f7f7;
  border: 1px solid #f5f5f5;
  padding: 7px 15px !important;
}
.btn.btn-white .mat-mdc-progress-spinner {
  width: 40px !important;
  height: 20px !important;
}
.btn.btn-white .mat-mdc-progress-spinner circle {
  stroke: #fff !important;
}
.btn.btn-white .mat-mdc-progress-spinner circle {
  stroke: #fff !important;
}

.pagination-container {
  padding: 15px 0 0;
}
.pagination-container .pagination {
  justify-content: flex-end;
}
.pagination-container .pagination .page-item {
  cursor: pointer;
}
.pagination-container .pagination .page-item.active {
  pointer-events: none;
}

.btn-page .icon-right {
  margin-left: 5px;
}

.pc-inner-content.read-lesson-content.tokyo p img {
  max-width: 48%;
  min-width: 48%;
  max-height: 290px;
  min-height: 290px;
  object-fit: contain;
  margin: 10px 1%;
  width: inherit;
}

.cwp-img {
  /*min-height: 350px;max-height:450px;*/
  position: relative;
  overflow: hidden;
  height: 450px;
  /* padding-bottom: 60px;*/
  background: #333;
}
.cwp-img img {
  /*width:100%; height:100%; object-fit: contain; border-radius:4px;*/
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  position: relative;
  top: calc(50% - 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
