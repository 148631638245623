// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popheader {
  position: relative;
  padding-right: 50px;
}
.popheader .btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  padding: 2px 10px;
  width: 36px;
  text-align: center;
  font-size: 20px;
}
.popheader .mat-mdc-dialog-title {
  margin: 0 0 15px;
}

.mat-mdc-dialog-content p {
  margin: 0 0 10px;
}

.btnrow {
  text-align: center;
  margin: 15px 0 0;
}
.btnrow .btn {
  margin: 0 10px 10px;
  padding: 7px 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
