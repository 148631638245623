import { Component, OnInit } from '@angular/core';
import { CourseService } from './../../service/course.service';

import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from './../../service/api.service';
import { CommonService } from './../../service/common.service';
import { Router } from '@angular/router';
import { MarketPlaceService } from './../../service/market-place.service';
import { MatDialog } from '@angular/material/dialog';
import { RedeemCourseComponent } from './../../dialogBox/redeem-course/redeem-course.component';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

	dataArr: any;
	CourseAssignedData: any;
	userData: any;
	userId: any;
	assignedCourses: any;
	isLoading: boolean = true;
	sitePath: any;
	roleId: any;
	dashboardRoute: string;
	showErrorMsg: boolean;
	showCourseProgressBar: boolean;
	marketCourses: any;
	dialogRef: any;

	constructor(private courseService: CourseService, private domSanitizer: DomSanitizer,
		private apiService: ApiService, private commonService: CommonService, private router: Router, private marketPlaceService: MarketPlaceService , private dialog: MatDialog) {
	}

	ngOnInit() {
		this.userData = JSON.parse(localStorage.getItem('loggedUser'));
		this.userId = this.userData.id;
		this.getUserAssignedCourseList();
		this.sitePath = this.apiService.getSiteFolderPath();
		this.roleId = this.commonService.getUserRole();
		this.showCourseProgressBar = (this.roleId == this.commonService.userRolesArr['Student']) ? true : false;


	}

	/**
	 * @description sanitize html url path
	 * @date 2019-09-16
	 * @param {*} html
	 * @returns
	 * @memberof DashboardComponent
	 */
	getHtml(html) {
		return this.domSanitizer.bypassSecurityTrustHtml(html);
	}

	/**
	 * @description get course list assigned to user
	 * @date 2019-09-16
	 * @param {*} userId
	 * @memberof DashboardComponent
	 */
	getUserAssignedCourseList() {
		this.CourseAssignedData = {
			id: this.commonService.getUserId(),
			user_role: this.commonService.getUserRole(),
			container_id: this.commonService.getDomainContainerId(),
			method: 'courseAssignListbyUserID'
		}

		this.courseService.getUserAssignedCourses(this.CourseAssignedData).subscribe(res => {
			if (!res['jwt_status']) {
				this.commonService.openSessionExpireDialog();
			} else {
				this.assignedCourses = (res['data'] != undefined) ? res['data'] : [];
				this.showErrorMsg = (this.assignedCourses.length == 0) ? true : false;
				this.isLoading = false;
				if (this.assignedCourses.length == 0) {
					const postData = {
						id: this.commonService.getUserId(),
						method: 'marketPlaceCourseList',
						limit: '2'
					};
					this.marketPlaceService.getMarketPlace(postData).subscribe(resp => {
						this.marketCourses = resp['data'];
					});
				}
			}
		});
	}

	openRedeemCoursePopUp(courseName: any, courseDesc: any , courseId: any , sectionId: any , shopLink: any) {
		this.dialogRef = this.dialog.open(RedeemCourseComponent, {
			//	height: '40%',
			width: 'auto',
			data: {
				container_id: this.commonService.getDomainContainerId(),
				user_id: this.commonService.getUserId(),
				course_name: courseName,
				course_id: courseId,
				section_id: sectionId,
				course_description: courseDesc,
				shop_link: shopLink
			}
		});

		this.dialogRef.afterClosed().subscribe(res => {
			if (res == 'true') {
				this.getUserAssignedCourseList();
			}
		})
	}

	goToMarketPlace() {
		this.router.navigate(['maket-place-course-list']);
	}

}
