import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { CommonService } from './../../service/common.service';

@Component({
  selector: 'app-host-sidebar',
  templateUrl: './host-sidebar.component.html',
  styleUrls: ['./host-sidebar.component.scss']
})
export class HostSidebarComponent implements OnInit {
  userRole: any;

  constructor(private commonService : CommonService , private renderer: Renderer2) { }

  ngOnInit() {
    this.userRole = this.commonService.getUserRole()
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById('pageContainer')
    if (pageelement.classList.contains('collapse-panel')) {
      this.renderer.removeClass(pageelement, 'collapse-panel')
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel')
    }
  }

}
