import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../service/common.service';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss']
})
export class ChatHistoryComponent implements OnInit {
  chat: { data: { date: string; message: string; admin: boolean; username: string; }[]; };
  getUserName:any;
  socket: any;

  constructor(private commonService : CommonService , private socketContainer: Socket) { }

  ngOnInit() {
    this.socket = this.socketContainer.connect()
    this.getUserName = this.commonService.getUserName()

    this.socket.emit('chat')
    this.socket.on('chat' , (data) => {
      this.chat = data
    })
    console.log(this.chat)
  }

  push(){
    this.socket.emit('push')
  }

  

}
