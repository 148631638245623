// tslint:disable-next-line: max-line-length
import { Component, OnInit,
   ComponentFactoryResolver, 
   Renderer2, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../service/data.service';
import { LessonService } from '../../../service/lesson.service';
import { CommonService } from '../../../service/common.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../../service/api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lesson-details-preview',
  templateUrl: './lesson-details-preview.component.html',
  styleUrls: ['./lesson-details-preview.component.scss'],
})

export class LessonDetailsPreviewComponent implements OnInit {

  lessonListPost: any;
  lessonId: any;
  lessonDetail: any;
  dialogRef: any;
  lessonName: string = '';
  errorMessage: any;
  isLoading: boolean = true;
  lessonImg: any;
  routerLinkSubscription: Subscription;
  templateType: any;
  sitePath: any;
  courseSlug;
  readMoreStatus: any;
  launchURL: any;
  routeParamValues: any;
  charLimit: number = 300;
  isLodingBtn: boolean = false;
  newLessonTempArr: { lesson_description: any, lesson_title: any }
  pagingLimit: number = 20;
  pageCount: any[] = [];
  increasCount: number = 0;
  nextBtnLoading: boolean = false;
  dialogParam: any;
  showPanelStatus: false;
  // showTitle: boolean = true;
  checkData: any;
  buttonTheme: any;
  noRecord: boolean;

  mediaFileLocationPath: string;
  getInstituteUrl: any;
  lessonTemplates: any;
  firstLessonTemplate: void;
  modifiedLessonTemplate: void;
  j: number;
  count: number = 0

  showInquireSectionPanel: boolean;
  showpollPanel: boolean;
  showLessonResouesesPanel: boolean;
  showReadPanel: boolean;
  shoewexpandPanel: boolean;
  courseMenuArr: any;
  courseMenu: any;
  courseName: any;
  menuModuleArr: any = [];
  lastMenuArrIndex: number;
  courseOutCome: boolean;
  moduleOutCome: boolean;
  moduleOutComeDetail: any;
  courseOutcomeDetail: any;
  uploadVideoPath: string;
  courseMenuLastIndex: any;
  lastMduleStatus: boolean;
  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextLsnBtn: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextModuleBtn: boolean = false;
  lessonConfirmStatus: boolean;

  showInquireTitle: any;

  showReadTitle: any;
  showPollTitle: any;
  showExpandTitle: any;

  panelBgCol: any;
  panelTxtCol: any;


  seconds: number;
  timestamp: string;
  timer: any;
  readSectionLength: any;
  readSecMultiImg: boolean;
  inquireSectionLength: any;
  inquireSecMultiImg: boolean;
  expandSectionLength: any;
  expandSecMultiImg: boolean;
  lessonSummarySectionLength: any;
  lessonSummarySecMultiImg: boolean;
  lessonToolboxSecMultiImg: boolean;
  lessonToolboxSectionLength: any;
  lessonCitationSectionLength: any;
  lessonCitationSecMultiImg: boolean;
  lessonInquireWords: any;

  @ViewChild('readContentSection', { static: false }) readContentSection: ElementRef;
  @ViewChild('imageCitationDataPanel', { static: false }) imageCitationDataPanel: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute,
    private dataService: DataService, private lessonService: LessonService,
    private commonService: CommonService, private sanitizer: DomSanitizer, private apiService: ApiService,
     private resolver: ComponentFactoryResolver,
     private renderer: Renderer2,private titleService: Title) {
    this.lessonId = atob(this.route.snapshot.paramMap.get('lessonId'));
    console.log(isNaN(this.lessonId));
    this.charLimit = 300;
    const lessonElm = document.querySelector('.lessonCollapseContent');
    const lessonBtn = document.querySelector('.readMoreBtn .btn');
    
    if (lessonElm != null) {
      if (lessonElm.classList.contains('open')) {
        lessonElm.classList.remove('open');
        lessonBtn.innerHTML = 'Read More';
      }
    }
  }


  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }

  ngOnInit() {
    this.getLessonDetail(this.lessonId);
    this.getPanelVisuals();
    this.getInstituteUrl = this.apiService.getApiSitePath()
    this.sitePath = this.apiService.getSiteFolderPath();
  }

  getLessonDetail(lessonId) {
    this.lessonListPost = {
      // tslint:disable-next-line: object-literal-shorthand
      lessonId: lessonId,
      method: 'getLessonByLessonSlug'
    };

    this.isLoading = true;
    this.lessonService.getLessonPreviewList(this.lessonListPost).subscribe(res => {
      this.isLoading = false;
      if (res['status']) {
        this.noRecord = false;
        this.lessonDetail = res['data'];
        this.setFavicon(this.lessonDetail.name);
        if (this.lessonDetail != undefined) {
          // Read Section Img
          this.readSectionLength = (this.lessonDetail.read_section.match(/img/g) || []).length;
          this.readSecMultiImg = (this.readSectionLength > 1) ? true : false;

          // Inquire Section Img
          this.inquireSectionLength = (this.lessonDetail.inquire_section.match(/img/g) || []).length;
          this.inquireSecMultiImg = (this.inquireSectionLength > 1) ? true : false;

          // Expand Section Img
          this.expandSectionLength = (this.lessonDetail.expand_description.match(/img/g) || []).length;
          this.expandSecMultiImg = (this.expandSectionLength > 1) ? true : false;

          // Lesson Summary Section Img
          // this.lessonSummarySectionLength = (this.lessonDetail.summary.match(/img/g) || []).length;
          // this.lessonSummarySecMultiImg = (this.lessonSummarySectionLength > 1) ? true : false;

          // Lesson Summary Section Img
          this.lessonToolboxSectionLength = (this.lessonDetail.lesson_toolbox.match(/img/g) || []).length;
          this.lessonToolboxSecMultiImg = (this.lessonToolboxSectionLength > 1) ? true : false;

          // Lesson Summary Section Img
          this.lessonCitationSectionLength = (this.lessonDetail.license_citation.match(/img/g) || []).length;
          this.lessonCitationSecMultiImg = (this.lessonCitationSectionLength > 1) ? true : false;

          this.lessonConfirmStatus = (this.lessonDetail.lesson_confirm == 1) ? true : false;
          //this.showPanelStatus = (this.lessonDetail['is_open_text'] == 1) ? false : true;
          this.resetPanelCollapse();

          this.showInquireTitle = (this.lessonDetail.inquire_section_hide == 0) ? true : false;
          this.showReadTitle = (this.lessonDetail.read_section_hide == 0) ? true : false;

          this.showExpandTitle = (this.lessonDetail.expand_section_hide == 0) ? true : false;
          this.showPollTitle = (this.lessonDetail.reflect_section_hide == 0) ? true : false;

          this.showInquireSectionPanel = (this.lessonDetail.inquire_section_title != '') ? true : false;
          this.showReadPanel = (this.lessonDetail.read_section_title != '') ? true : false;
          this.showpollPanel = (this.lessonDetail.poll_id != null) ? true : false;
          this.shoewexpandPanel = (this.lessonDetail.expand_title != '') ? true : false;

          if ((this.lessonDetail.lesson_toolbox == '') && (this.lessonDetail.license_citation == '')) {
            this.showLessonResouesesPanel = false;
          } else {
            this.showLessonResouesesPanel = true;
          }

          //read more
          this.lessonInquireWords = this.lessonDetail.inquire_section.split('').length;

          if (this.lessonInquireWords > 300) {
            this.readMoreStatus = true;
          } else {
            this.readMoreStatus = false;
          }
          this.lessonName = this.lessonDetail['name'];
          this.isLoading = false;
          this.lessonImg = this.lessonDetail.lesson_img != 'undefined' ? this.lessonDetail.lesson_img : null;
          this.templateType = this.lessonDetail.template_type;
          this.lessonTemplates = res['data'].lesson_templates;
          this.increasCount = 0;
          this.pageCount = [];
        }

      } else {
        this.noRecord = true;
      }

    }, error => error);
  }


  toggleContainer(event) {
    event.target.parentElement.parentElement.parentElement.classList.toggle('open');
    // tslint:disable-next-line: prefer-const
    let parentElm = event.target.parentElement.parentElement.parentElement;
    if (parentElm.className === 'lessonCollapseContent open') {
      event.target.innerText = 'Less';
      this.charLimit = 5000000;
    } else {
      event.target.innerText = 'Read More';
      this.charLimit = 300;
    }
  }

  openLinkFile(event, fileName) {
    const url: any = this.apiService.getApiSitePath() + 'uploads/media_images/' + fileName;
    window.location.href = url
    event.preventDefault()
  }


  togglePanel(togglePanelElement: HTMLElement, event) {
    const getElmH = togglePanelElement.children[0].clientHeight;

    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
        if (togglePanelElement.id == 'imageCitationDataPanel') {
          this.renderer.setStyle(this.imageCitationDataPanel.nativeElement, 'height', 'auto')
        }
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }



  }

  resetPanelCollapse() {
    if (this.showPanelStatus) {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header.active');
        const getContentPan = document.querySelectorAll('.pc-content.open');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.removeClass(getHeaderPan[i], 'active');
          if (getContentPan[i] != undefined) {
            this.renderer.removeClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', '0');
          }
        }
      }, 200)
    } else {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header');
        const getContentPan = document.querySelectorAll('.pc-content');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.addClass(getHeaderPan[i], 'active');

          if (getContentPan[i] != undefined) {
            this.renderer.addClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', 'auto');
          }

        }
      }, 200)
    }

  }

  refresh() {
    this.ngOnInit();
  }

  setFavicon(tabName) {
    this.titleService.setTitle(tabName);
  }

}

