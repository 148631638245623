// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  padding-top: 25px;
  min-height: 480px;
}

.leftsidebar {
  z-index: 1;
  position: relative;
  padding: 0 0 15px;
  margin-bottom: 45px;
}
.leftsidebar a {
  padding-left: 50px !important;
}
.leftsidebar .btn-sidebar-menu {
  background: #ddd;
  border-radius: 4px;
  color: #2c2c2c;
  font-size: 17px;
  text-align: center;
  padding: 9px;
  position: relative;
  border: 0;
  overflow: hidden;
  font-weight: 400;
  font-family: var(--poppins);
  cursor: pointer;
}
.leftsidebar .btn-sidebar-menu span {
  display: inline-block;
  height: 40px;
  background: #000;
  color: #fff;
  padding: 13px 16px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.leftsidebar .search-field {
  position: relative;
}
.leftsidebar .search-field .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  padding: 10px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #b8b8b8;
}
.leftsidebar .search-field .form-search-filter {
  padding-left: 45px;
  border-color: #edecec;
  height: 46px;
}
.leftsidebar .search-field .form-search-filter::placeholder {
  color: #b8b8b8;
  font-family: var(--poppins);
  font-style: italic;
  font-size: 15px;
  font-weight: 400;
}
.leftsidebar .btn {
  font-family: var(--calibri);
  width: 100%;
}
.leftsidebar .btn-default {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #373737;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
}
.leftsidebar .btn-default:hover, .leftsidebar .btn-default.active {
  background: #000;
  border: none;
  color: #fff;
  position: relative;
  border: 1px solid #000;
}
.leftsidebar .btn-default.active::after {
  position: absolute;
  content: "";
  width: 30px;
  top: -1px;
  bottom: -1px;
  right: -25px;
  border-radius: 0 28px 28px 0;
  background: #000;
}
.leftsidebar .btn.btn-sidebar {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #444;
  margin-bottom: 15px;
  font-family: var(--calibri);
  font-weight: 400;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
}
.leftsidebar .btn.btn-sidebar:hover, .leftsidebar .btn.btn-sidebar.active {
  background: #000;
  border: none;
  color: #fff;
  position: relative;
  border: 1px solid #000;
  width: 100%;
}
.leftsidebar .btn.btn-sidebar.active::after {
  position: absolute;
  content: "";
  width: 30px;
  top: -1px;
  bottom: -1px;
  right: -25px;
  border-radius: 0 28px 28px 0;
  background: #000;
}
.leftsidebar .btn.btn-sidebar-help {
  background: #ffeace;
  color: #463a00;
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
  border: 1px solid #f7debc;
}
.leftsidebar .btn.btn-sidebar-help:hover, .leftsidebar .btn.btn-sidebar-help.active {
  background: #f5d2a3;
  color: #463a00;
  border-color: #f5d2a3;
}
.leftsidebar .course-progress-panel {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 10px 12px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}
.leftsidebar .course-progress-panel .progress-header {
  margin-bottom: 10px;
}
.leftsidebar .course-progress-panel .progress-header .title,
.leftsidebar .course-progress-panel .progress-header .count {
  font-size: 14px;
  color: #111;
  font-family: var(--calibri);
  display: inline-block;
}
.leftsidebar .course-progress-panel .progress-header .count.right {
  float: right;
}
.leftsidebar .course-progress-panel .progress {
  border-radius: 25px;
  height: 8px;
  background: #ccc;
  overflow: hidden;
}
.leftsidebar .course-progress-panel .progress .progress-bar {
  border-radius: 25px;
  height: 8px;
  background: #777;
}
.leftsidebar .sidebar-navigation-container ul {
  margin: 0;
  padding: 0;
}
.leftsidebar .sidebar-navigation-container ul li + li {
  border-top: 1px solid #f5f5f5;
}
.leftsidebar .sidebar-navigation-container ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.leftsidebar .sidebar-navigation-container ul li h4 {
  margin: 0;
  position: relative;
  cursor: pointer;
  padding: 0 43px 0 5px;
}
.leftsidebar .sidebar-navigation-container ul li h4 .title {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 16px;
}
.leftsidebar .sidebar-navigation-container ul li h4 p {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li h4 .collacollapsible {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 39px;
  font-size: 12px;
  text-align: center;
  margin: auto;
  bottom: 0;
  color: #7f7f7f;
  font-weight: 600;
}
.leftsidebar .sidebar-navigation-container ul li h4 .collacollapsible i {
  position: absolute;
  height: 14px;
  width: 10px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu {
  border: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul {
  margin: 0;
  padding: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li {
  margin: 0;
  padding: 0 0;
  list-style: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 {
  margin: 0;
  position: relative;
  padding: 10px 43px 10px 35px;
  border-radius: 6px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  width: calc(100% - 1px);
  display: block;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle {
  border: 2px solid #444;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 20px;
  left: 11px;
  bottom: 0;
  border-radius: 50%;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle.active {
  background: #000;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 2px;
  top: 1px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .title {
  color: #444;
  font-family: var(--calibri);
  font-size: 16px;
  line-height: 16px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 p {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active {
  background: #fff;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active .title {
  color: #000;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active p {
  color: #000;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active ~ .sidebar-child-menu {
  height: auto;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu {
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul {
  margin: 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: calc(100% - 1px);
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li {
  border: 0;
  margin: 0 0 10px;
  padding: 0 0 0 0px;
  list-style: none;
  border-bottom: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li:last-child {
  margin: 0 0 0px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 {
  margin: 0;
  position: relative;
  padding: 10px 10px 10px 38px;
  border-radius: 5px;
  cursor: pointer;
  background: #eee;
  border: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle {
  border: 0;
  background: #888;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 11px;
  bottom: 0;
  border-radius: 50%;
  pointer-events: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle.active {
  border: 0;
  background: #1f9c3a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 4px;
  top: 3px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .title {
  display: block !important;
  color: #343434;
  font-size: 15px;
  font-weight: 300;
  pointer-events: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 p {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active {
  background: #000;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active .title {
  color: #fff;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active p {
  color: #fff;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active .status-circle {
  background: #fff;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li + li h4:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 1px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items {
  margin: 5px 0 0;
  padding: 0;
  border-radius: 3px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li {
  border: 0;
  margin: 2px 0 0;
  padding: 0 0 0 27px;
  list-style: none;
  border-bottom: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 {
  margin: 0;
  position: relative;
  padding: 7px 43px 7px 31px;
  border-radius: 5px;
  background: #fff4e5;
  cursor: pointer;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 .status-circle {
  border: 0;
  background: #cab395;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 9px;
  bottom: 0;
  border-radius: 50%;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 .status-circle.active {
  border: 0;
  background: #1f9c3a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 3px;
  top: 2px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 .title {
  color: #343434;
  font-family: var(--poppins);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4 p {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4.active {
  background: transparent;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4.active .title {
  color: #0e449a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li h4.active p {
  color: #060606;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.quiz-list-items li + li h4:before {
  content: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items {
  margin: 0 0 0;
  padding: 0;
  border-radius: 3px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li {
  border: 0;
  margin: 2px 0 0;
  padding: 0 0 0 27px;
  list-style: none;
  border-bottom: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 {
  margin: 0;
  position: relative;
  padding: 7px 43px 7px 31px;
  border-radius: 5px;
  background: #fdf1f5;
  cursor: pointer;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 .status-circle {
  border: 0;
  background: #c7acb5;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 9px;
  bottom: 0;
  border-radius: 50%;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 .status-circle.active {
  border: 0;
  background: #1f9c3a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 4px;
  top: 3px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 .title {
  color: #343434;
  font-family: var(--poppins);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4 p {
  color: #5a5a5a;
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4.active {
  background: transparent;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4.active .title {
  color: #0e449a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li h4.active p {
  color: #060606;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul.assessment-list-items li + li h4:before {
  content: none;
}
.sidebar-course-level ul.quiz-list-items {
  margin-top: 5px;
}
.sidebar-course-level ul.quiz-list-items li {
  padding: 0 !important;
  border: 0 !important;
  margin: 0;
}
.sidebar-course-level ul.quiz-list-items li h4 {
  background: #fff4e5;
}
.sidebar-course-level ul.quiz-list-items li h4 .status-circle {
  top: 12px !important;
}
.sidebar-course-level ul.quiz-list-items li h4 .title {
  font-size: 14px;
}
.sidebar-course-level ul.quiz-list-items li + li {
  margin-top: 5px !important;
}
.sidebar-course-level ul.assessment-list-items {
  margin-top: 5px !important;
}
.sidebar-course-level ul.assessment-list-items li {
  padding: 0 !important;
  border: 0 !important;
  margin: 0;
}
.sidebar-course-level ul.assessment-list-items li h4 {
  background: #fdf1f5;
}
.sidebar-course-level ul.assessment-list-items li h4 .status-circle {
  top: 12px !important;
}
.sidebar-course-level ul.assessment-list-items li h4 .title {
  font-size: 14px;
}
.sidebar-course-level ul.assessment-list-items li + li {
  margin-top: 5px !important;
}

.leftSidebarActive {
  position: fixed;
  z-index: 99;
  left: 0;
  margin-top: 25px;
  display: none;
}
.leftSidebarActive button {
  background-color: #000;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  border: 0 solid #fff;
  border-radius: 0 5px 5px 0;
  padding: 13px 18px;
  box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.05);
}
.leftSidebarActive button:hover {
  background-color: #458cde;
  color: #fff;
}

#pageContainer.collapse-panel #sidebar-section .leftSidebarActive {
  display: block !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
