// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/css/variables.scss");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}

#loginpageContainer {
  min-height: calc(100% - 134px);
}
#loginpageContainer .welcom-text {
  color: #444;
  /*font-family: var(--poppins);*/
  font-weight: 400;
  font-size: 15px;
  padding: 45px 0;
  text-align: center;
  line-height: 28px;
}
#loginpageContainer .login-form {
  border: 1px solid #dddddd;
  width: 560px;
  margin: 100px 0 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  /*overflow: hidden;*/
  background: #fff;
  position: relative;
}
#loginpageContainer .login-form .login-header {
  padding: 0 25px;
  text-align: center;
}
#loginpageContainer .login-form .login-header h4 {
  color: #000;
  font-size: 1.45rem;
  margin: 5px 0 10px;
}
#loginpageContainer .login-form .login-icon {
  font-size: 4.3rem;
  color: #777;
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  margin-top: -55px;
  padding-top: 10px;
  box-shadow: 0px -12px 10px rgba(0, 0, 0, 0.08);
}

#loginpageContainer .login-form .login-body {
  padding: 25px;
}

#loginpageContainer .login-form .login-body .form-group {
  width: 80%;
  margin: 0 auto 1.5rem;
}

#loginpageContainer .login-form .login-body .form-control {
  border-color: #c6cbd2;
  border-radius: 55px;
  color: #434343;
  padding: 10px 25px 13px;
  line-height: normal;
  height: auto;
}

#loginpageContainer .login-form .login-body .form-control:focus {
  border-color: #2460be;
  box-shadow: 0 0 2px 3px rgba(36, 96, 190, 0.1);
}

#loginpageContainer .login-form .login-body a.btn-link {
  color: var(--blackcolor);
}

.login-comp:hover {
  background-color: #000 !important;
}

@media screen and (max-width: 480px) {
  .login-form {
    width: 100%;
  }
  .login-icon {
    margin-top: 20px !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
