// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactpage {
  min-height: calc(100% - 150px);
  height: calc(100% - 150px);
}

.contactbanner {
  min-height: calc(100% - 240px);
  position: relative;
  background-size: cover;
  background-position: center;
}
.contactbanner .title {
  position: absolute;
  z-index: 111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contactbanner .title h1 {
  text-align: center;
  font-size: 60px;
  color: #fff;
}

.contacttext {
  margin: 50px 100px 0;
  text-align: center;
  font-size: 16px;
}

.contacts {
  margin: 40px 100px 20px;
}
.contacts .icon {
  width: 110px;
  height: 110px;
  display: inline-block;
  margin: 0 0 20px;
  border: 3px solid #ccc;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.contacts .icon img {
  width: 60px;
  filter: invert(0.6);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contacts p {
  font-size: 16px;
  color: #333;
}

@media screen and (max-width: 480px) {
  .contactpage {
    min-height: auto;
    height: auto;
  }
  .contactbanner {
    min-height: 240px;
  }
  .contactbanner {
    min-height: 240px;
  }
  .contactbanner .title h1 {
    font-size: 44px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
