import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { PresafetyService } from 'src/app/service/presafety.service';

@Component({
  selector: 'app-pre-safety-listing',
  templateUrl: './pre-safety-listing.component.html',
  styleUrl: './pre-safety-listing.component.scss'
})
export class PreSafetyListingComponent implements OnInit {
  constructor(private commonService: CommonService, private preSafeService: PresafetyService, private router: Router) { }
  lang: any;
  preSafeList: any[] = []
  is_sign: any;
  public searchEmailSubject: BehaviorSubject<any> = new BehaviorSubject('')
  userEmail: any;
  permission: number = 0;
  userID: any;
  mail: any;
  returnEmail: any;
  validMessage : boolean = false ; 
  isLoading : boolean = false ; 
  hideInput : boolean = false ; 
  searchPermission : boolean = false; 
  notfound : boolean = false ; 

  EmailSearchInput = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      this.customEmailValidator()
    ])
  })

  ngOnInit(): void {
    if (localStorage.getItem('locale') == 'ger') {
      this.lang = 1
    } else {
      this.lang = 2
    }

    const storedEmail = localStorage.getItem("usermail");
  if (storedEmail) {
    this.hideInput = true ; 
    this.EmailSearchInput.get('email')?.setValue(storedEmail);
    setTimeout(() => {
      localStorage.removeItem('usermail')
    }, 2000);
  }
    this.preSafeService.emailReturn.subscribe(emailreturn => {
      this.returnEmail = emailreturn;
    })

    this.searchEmailSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(res => {
      if (this.returnEmail != "") {
        this.userEmail = this.returnEmail;
        this.permission = 1;
        this.getPreSafetyListing()
      } else {
        this.userEmail = res;
        if (this.userEmail == "") {
          this.permission = 0;
          this.getPreSafetyListing()
        } else {
          this.permission = 1;
          this.getPreSafetyListing()
        }
      }
    })
  }


  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailPattern.test(control.value);
      return valid ? null : { customEmail: true };
    };
  }


  getPreSafetyListing() {
    this.isLoading = true ; 
    const postData = {
      method: "getUserAssingedBriefing",
      id: this.commonService.getUserId(),
      lang_id: this.lang,
      pageNumber: 0,
      container_id: this.commonService.getDomainContainerId(),
      all_briefing_permision: this.permission,
      email: this.userEmail
    }

    this.preSafeService.getPresafetyList(postData).subscribe((res: any) => {
      this.isLoading = false ; 
      this.notfound = false ; 
      if (res['jwt_status']) {
       if(res['status']){
        console.log(res);
        this.preSafeList = res.data;
        console.log(this.preSafeList);
        
        this.userID = res.userId;
        this.preSafeService.userId.next(this.userID)
        localStorage.setItem("uid", this.userID)
        if(res.checkBriefingsAllPermisions == 1){
          this.searchPermission = true ; 
        }else{
          this.searchPermission = false ;
        }
       }else{
        this.notfound = true ; 
       }
      } else {
        this.commonService.redirectToLogin()
      }
    })
  }

  showInput(){
    console.log(this.hideInput);
    
    this.hideInput = true ; 
  }

  searchUserEmail(event: Event) {
    this.validMessage = true ; 
    let enteredEmail = event.target as HTMLInputElement;
    
    if (this.EmailSearchInput.valid) {
      console.log("email valid...");
      
      this.searchEmailSubject.next(enteredEmail.value)
      this.permission = 1;
      this.preSafeService.userEmail.next(enteredEmail.value);
      localStorage.setItem("usermail", enteredEmail.value)
    }
    else if(enteredEmail.value == "") {
      this.validMessage = false ; 
      console.log("email invalid");
      this.searchEmailSubject.next('');
      this.permission = 0;
      this.preSafeService.userEmail.next('');
      localStorage.setItem("usermail", '')
      setTimeout(() => {
        this.userEmail = ""
        this.getPreSafetyListing()  
      }, 500);
    }
  }

  routeToPresafetyDetail(id : any) {
    let presafe = this.preSafeList.find(elem => id == elem.id);
    console.log("find presafety by id => ",presafe);
    
    const ps_id = btoa(id);
    this.router.navigate([`pre-safety-detail/${ps_id}`])
    if (presafe.user_complete_briefing) {
      this.preSafeService.briefingComplete.next('complete')
      localStorage.setItem("complete", "complete");
    } else {
      this.preSafeService.briefingComplete.next('pending')
      localStorage.setItem("complete", "pending");
    }
  }
}
