import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-reason',
  templateUrl: './logout-reason.component.html',
})
export class LogoutReasonComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
  }
}
