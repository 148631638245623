// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datapropage {
  min-height: calc(100% - 138px);
}

h3 {
  margin: 30px 20px 0;
  font-size: 22px;
}

p {
  margin: 10px 20px 0;
  font-size: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
