import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  jwt: any;
  constructor(private apiService: ApiService, private http: HttpClient,) { }

  getDashboardListData(data) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getDashboard() , data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }


  getcourseListData(data) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getDashboard() , data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

  relatedCategoryCourseList(data) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getDashboard() , data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

  

}
