import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-lesson-available',
  templateUrl: './lesson-available.component.html',
})
export class LessonAvailableComponent implements OnInit {
  constructor() { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
  }
}
