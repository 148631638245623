import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {

  constructor(private socket: Socket) { }

  sendingMessage(msg: string) {
    this.socket.emit('sendingMessage', msg);
  }
  getMessage() {
    return this.socket.fromEvent('broadcastMessages').pipe(map((data) => data));
  }
}
