import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-profile-update-dg-box',
  templateUrl: './profile-update-dg-box.component.html'
})
export class ProfileUpdateDgBoxComponent implements OnInit {

  constructor() { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    console.log('ccc')
    event.preventDefault();
  }

  ngOnInit() {
  }

}
