// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardRight {
  padding-top: 25px;
}
.dashboardRight .message-list .message-list-header {
  border-bottom: 2px solid #f3f3f3;
  padding: 0 0 8px;
  margin-bottom: 17px !important;
}
.dashboardRight .message-list .message-list-header .heading {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--poppins);
}
.dashboardRight .message-detail {
  border: 2px solid #f2f2f2;
  padding: 25px;
  border-radius: 5px;
  min-height: 300px;
  background: #fdfdfd;
}
.dashboardRight .message-detail h3 {
  margin: 0;
  padding: 0 110px 0 0;
  font-size: 16px;
  color: #4d4e4e;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  font-family: var(--poppins);
}
.dashboardRight .message-detail h3 a {
  color: #4d4e4e;
}
.dashboardRight .message-detail h3 span {
  font-size: 13px;
  color: #7d7d7d;
  font-weight: normal;
  position: absolute;
  right: 0;
  top: 0;
}
.dashboardRight .message-detail p {
  margin: 8px 0 0;
  padding: 0;
  font-size: 14px;
  font-family: var(--poppins);
  line-height: 22px;
  text-align: justify;
}

@media (max-width: 767px) {
  .message-detail {
    min-height: 250px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
