// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#video-chat-lobby {
  margin: 5vh auto;
  width: 300px;
  padding: 15px;
  background-color: #525a63;
  border-radius: 6px;
}

#video-chat-lobby input {
  margin-bottom: 15px;
}

#video-chat-lobby h2 {
  font-size: 15px;
  color: #dcdcdc;
  margin-bottom: 17px;
}

#video-chat-lobby h1 {
  color: #dcdcdc;
  margin-bottom: 17px;
}

#video-chat-lobby p {
  color: #dcdcdc;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
