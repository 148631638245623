import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wiki-layout',
  templateUrl: './wiki-layout.component.html',
  styleUrls: ['./wiki-layout.component.scss']
})
export class WikiLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
