import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProfileUpdateDgBoxComponent } from './../../dialogBox/profile-update-dg-box/profile-update-dg-box.component';
import { UserService } from './../../service/user.service';
import { CommonService } from './../../service/common.service';
import { ApiService } from '../../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './../../service/data.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private userService: UserService,
    private commonService: CommonService, private apiService: ApiService, private dialog: MatDialog
    , private dataService: DataService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  userData: any;
  editProfileForm: FormGroup;

  // state = new FormControl('');
  // city = new FormControl('');
  // zipcode = new FormControl('', [Validators.maxLength(5), Validators.minLength(5), Validators.pattern('^[0-9]*$')]);
  // street_address_2 = new FormControl('');
  // street_address_1 = new FormControl('');
  // secondary_email = new FormControl('', [Validators.email]);
  //phone = new FormControl('',[Validators.pattern('^[0-9]*$')]);
  // phone = new FormControl('', [Validators.pattern('^(?=.*[0-9])[- +()0-9]+$'), Validators.maxLength(25), Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  last_name = new FormControl('', Validators.required);
  // middle_name = new FormControl('');

  // first_name = new FormControl('', [Validators.required,Validators.pattern('^(?=.*\S).+$')]);
  first_name = new FormControl('', [Validators.required]);
  user_img = new FormControl('');


  geTUserpost: any;
  userProfileimg: any;
  uploadImg: File;
  isProfileImgLoading = false;
  //uploadImgData:any;
  color = 'primary';
  sitePath: any;

  dialogRef: any;

  public mask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

  options: any;
  optionsCity: any;

  filteredOptions: Observable<string[]>;
  filteredcities: Observable<string[]>;

  allStateData: any;
  autoCity: any = 'off';
  cityInputReadStatus: boolean = false
  errorMsg: any;
  isLoading: boolean = true
  showErrorMsg: boolean = false;



  /**
   * @description update user profile image
   * @date 2019-09-16
   * @param {*} event
   * @memberof EditProfileComponent
   */
  userProfileImg(event) {
    this.showErrorMsg = false;
    this.isProfileImgLoading = true;
    const uploadImgData: FormData = new FormData();
    this.uploadImg = event.target.files[0];
    uploadImgData.append('id', JSON.stringify(this.commonService.getUserId()));
    uploadImgData.append('method', JSON.stringify('UserProfilePictureUpdate'));
    uploadImgData.append('user_img_file', this.uploadImg, this.uploadImg.name);

    this.userService.profileImage(uploadImgData)
    // .then(response => {
    //   if (response['status']) {
    //     this.getUserProfileImg(response['data']['user_img']);
    //   } else {
    //     this.errorMsg = response['message'];
    //     this.showErrorMsg = true;
    //   }
    //   this.isProfileImgLoading = false;
    // }).catch(error => {
    //   console.log("ERROR : ",error);
      
    // })
    .subscribe(
      (response) => {
        if (response['status']) {
          this.getUserProfileImg(response['data']['user_img']);
        } else {
          this.errorMsg = response['message'];
          this.showErrorMsg = true;
        }
        this.isProfileImgLoading = false;
      },
      error => error
    );
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }


  /**
   * @description get state wise cities for auto complete
   * @date 2019-09-16
   * @param {*} stateCode
   * @memberof EditProfileComponent
   */
  getStatewiseCities(stateCode) {
    this.optionsCity = []
    if (stateCode != undefined) {
      const data = {
        method: "getCities",
        id: this.commonService.getUserId(),
        state_code: stateCode
      }
      this.userService.getStatewiseCities(data).subscribe(res => {
        if (res['status']) {
          this.optionsCity = res['cityList']
          this.autoCity = 'autoCity';
        } else {
          this.optionsCity = []
        }
      })
    } else {
      this.optionsCity = []
    }
  }

  /**
   * @description get all states data
   * @date 2019-09-16
   * @memberof EditProfileComponent
   */
  getAllStates() {
    const data = {
      method: "getStates",
      id: this.commonService.getUserId()
    }
    this.userService.getAllStates(data).subscribe(res => {
      this.allStateData = res['data']
      this.options = res['stateList']
    })
  }


  /**
   * @description filter all state list data to get fit into auto-complete
   * @date 2019-09-16
   * @private
   * @param {string} value
   * @returns {string[]}
   * @memberof EditProfileComponent
   */
  private _filter(value: string): string[] {
    if (value == '') {
      return
    } else {
      const filterValue = value.toLowerCase();
      const stateArr: any = this.allStateData.filter(option => option.name.toLowerCase().includes(filterValue)
      );
      return stateArr
    }
  }


  /**
   * @description get state wise cities list on selecting state in drop down
   * @date 2019-09-16
   * @param {*} event
   * @memberof EditProfileComponent
   */
  onSelect(event) {
    // this.city.setValue('');
    this.cityInputReadStatus = false
    const stateCode = event.option.id;
    this.getStatewiseCities(stateCode)
  }


  keyUpState(stateCode) {
    const stateDetail = {
      state_name: stateCode,
      method: "getStateByStateCode",
      id: this.commonService.getUserId()
    }
    this.userService.getStateCodeByStateName(stateDetail).subscribe(res => {
      this.getStatewiseCities(res['state_code'])
    })
  }


  /**
   * @description filter cities list in order to get into auto complete
   * @date 2019-09-16
   * @private
   * @param {string} value
   * @returns {string[]}
   * @memberof EditProfileComponent
   */
  private _filterCity(value: string): string[] {
    if (value == '' || value == null) {
      return
    } else {
      const filterValueC = value.toLowerCase();
      if (this.optionsCity != undefined) {
        let cityArr: any = this.optionsCity.filter(option => option.toLowerCase().includes(filterValueC));
        if (cityArr.length == 0) {
          cityArr = []
        }
        return cityArr
      }

    }
  }

  ngOnInit() {
    this.getUserData()
    this.getAllStates()
    // this.filteredOptions = this.state.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );

    // this.filteredcities = this.city.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterCity(value))
    //   );

    this.sitePath = this.apiService.getSiteFolderPath();
    this.editProfileForm = this.formBuilder.group({
      first_name: this.first_name,
      // middle_name: this.middle_name,
      last_name: this.last_name,
      // phone: this.phone,
      // secondary_email: this.secondary_email,
      // street_address_1: this.street_address_1,
      // street_address_2: this.street_address_2,
      // city: this.city,
      //  state: this.state,
      // zip: this.zipcode,
      method: 'ProfileUpdate',
      id: this.commonService.getUserId()
    });

  }

  /**
   * @description get realtime logged user data from database
   * @date 2019-09-16
   * @memberof EditProfileComponent
   */
  getUserData() {
    this.geTUserpost = {
      method: 'getUserData',
      id: this.commonService.getUserId()
    }

    this.userService.getUserData(this.geTUserpost).subscribe(res => {
      if (res['jwt_status']) {
        if (res['status']) {
          this.userData = res['data'];

          this.userData.secondary_email = (this.userData.secondary_email != null) ? this.userData.secondary_email : '';
          this.userData.street_address_1 = (this.userData.street_address_1 != null) ? this.userData.street_address_1 : '';
          this.userData.street_address_2 = (this.userData.street_address_2 != null) ? this.userData.street_address_2 : '';

          this.userData.zip = (this.userData.zip != null) ? this.userData.zip : '';
          this.userData.first_name = (this.userData.first_name != null) ? this.userData.first_name : '';
          this.userData.middle_name = (this.userData.middle_name != null) ? this.userData.middle_name : '';
          this.userData.last_name = (this.userData.last_name != null) ? this.userData.last_name : '';
          this.userData.company = (this.userData.company != null) ? this.userData.company : '';
          this.userData.city = (this.userData.city != null) ? this.userData.city : '';
          this.userData.state = (this.userData.state != null) ? this.userData.state : '';
          this.userData.email = (this.userData.email != null) ? this.userData.email : '';



          // this.state.setValue(this.userData.state);
          // this.city.setValue(this.userData.city);
          // this.getUserProfileImg(this.userData.user_img);
          this.isLoading = false;
        }
        this.getStatewiseCities(this.userData.state_code);
      } else {
        this.commonService.openSessionExpireDialog();
      }

    }, error => error);
  }


  /**
   * @description get logged user profile image detail from database
   * @date 2019-09-16
   * @param {*} userImg
   * @memberof EditProfileComponent
   */
  getUserProfileImg(userImg) {
    if (userImg) {
      this.userProfileimg = this.apiService.getUserProfilePath() + userImg;
    } else {
      this.userProfileimg = null;
    }
    // localStorage.setItem('user_img', userImg);
    this.dataService.changeProfileImgName(userImg);
  }


  /**
   * @description update user profile data
   * @date 2019-09-16
   * @param {*} event
   * @memberof EditProfileComponent
   */
  editUser(event) {
    if (this.editProfileForm.status == 'VALID') {
      event.preventDefault();
      this.userService.profileUpdate(this.editProfileForm.value).subscribe(res => {
        if (res['status']) {
          const fullN = this.editProfileForm.value.first_name + ' ' + this.editProfileForm.value.last_name;
          localStorage.setItem('userFullName', fullN);

          const loggedUserString = localStorage.getItem('loggedUser');
          let loggedUser = JSON.parse(loggedUserString);
          loggedUser.first_name = this.editProfileForm.value.first_name;
          loggedUser.last_name = this.editProfileForm.value.last_name;
          localStorage.setItem('loggedUser', JSON.stringify(loggedUser));

          this.dataService.changeHeaderUserName(fullN);
          this.openProfileUpdateDialog();
        }
      }, error => error);

    } else {
      let msg;
      if (localStorage.getItem('locale') == 'ger') {
        msg = 'Bitte füllen Sie gültige Pflichtfelder aus.';
      } else {
        msg = 'Please fill valid required fields.';
      }

      this.openGeneralDialogBox(msg);
    }
  }


  /**
   * @description open profile update dialog box
   * @date 2019-09-16
   * @memberof EditProfileComponent
   */
  openProfileUpdateDialog() {
    this.dialogRef = this.dialog.open(ProfileUpdateDgBoxComponent);
    this.dialogRef.afterClosed().subscribe(res => {
      this.router.navigate(['/profile'])
    });
  }


  openGeneralDialogBox(msg: any) {
    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: msg
      }
    });
    this.dialogRef.afterClosed().subscribe(res => {
    })
  }


  /**
   * @description  navigate to edit profile page
   * @date 2019-09-16
   * @memberof EditProfileComponent
   */
  routeToEditProfile() {
    this.router.navigate(['edit-profile'])
  }


  /**
   * @description  navigate to change user password page
   * @date 2019-09-16
   * @memberof EditProfileComponent
   */
  routeToChangePassword() {
    this.router.navigate(['change-user-password'])
  }

}
