// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/css/variables.scss");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 540px) {
  .xs-70 {
    width: 65% !important;
    padding: 0 !important;
  }
  .xs-30 {
    width: 35% !important;
  }
  .xs-30 nav {
    flex-wrap: nowrap;
  }
  .xs-30 .lang-name {
    display: none;
  }
  .col-wrap-xs {
    width: 100% !important;
  }
  .site-header .navigation-section .nav .dropdown {
    width: 100% !important;
    margin-top: 18px;
  }
  .site-header .navigation-section .nav .profile {
    width: 100%;
  }
  .site-header .navigation-section .nav .nav-link.nav-avtar {
    height: auto !important;
  }
  .site-header .navigation-section .nav .nav-link.nav-with-icon {
    border-right: none !important;
  }
  .headerlogo {
    height: 70px;
    width: 250px;
  }
  .headerlogo img {
    object-fit: contain;
    width: 100% !important;
    height: 100%;
  }
}
.logo-img {
  cursor: pointer;
  margin-top: 3px;
}

.site-header {
  background-color: #000;
  padding: 15px 0;
}
.site-header .logo-section a {
  text-decoration: none;
  color: var(--colorWhite);
}
.site-header .logo-section h1 {
  /*font-family: var(--poppins);*/
  font-size: 25px;
  margin: 3px 0 0;
  padding: 0;
  font-weight: 500;
  word-break: break-word;
}
.site-header .logo-section h2 {
  /*font-family: var(--poppins);*/
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 400;
}
.site-header .logo-section .headerlogo {
  height: 70px;
}
.site-header .logo-section .headerlogo img {
  width: 280px;
  object-fit: contain;
  height: 100%;
}
.site-header .navigation-section {
  color: var(--colorWhite);
}
.site-header .navigation-section .nav .profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.site-header .navigation-section .nav .nav-link {
  padding: 0.7rem 1rem;
  line-height: 29px;
  cursor: pointer;
  font-weight: 400;
  /*font-family: Poppins;*/
  font-size: 16px;
  color: inherit;
}
.site-header .navigation-section .nav .nav-link.nav-avtar {
  display: inline-block;
  width: 55px;
  height: 55px;
  background-color: var(--colorWhite);
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.site-header .navigation-section .nav .nav-link.nav-avtar img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.site-header .navigation-section .nav .nav-link.nav-avtar.active {
  color: var(--colorWhite);
  position: relative;
}
.site-header .navigation-section .nav .nav-link.nav-avtar.active:before {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--colorWhite) transparent;
}
.site-header .navigation-section .nav .nav-link.nav-with-icon {
  font-size: 19px;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
}
.site-header .navigation-section .nav .nav-link.nav-with-icon span {
  position: relative;
}
.site-header .navigation-section .nav .nav-link.nav-with-icon .count {
  background: #d42424;
  color: #fff;
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  line-height: 20px;
  font-weight: 600;
}
.site-header .navigation-section .nav .nav-link.profilename {
  padding: 0.7rem 1rem 0.7rem 1.5rem;
  display: inline-block;
}
.site-header .navigation-section .nav .nav-link .username {
  display: none;
}
.site-header .navigation-section .nav .dropdown {
  width: 14rem;
}
.site-header .openappbtn {
  border: none;
  background: transparent;
}
.site-header .openappbtn img {
  width: 28px;
}

.breadcrumb-section {
  background-color: var(--colorWhite);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}
.breadcrumb-section h5 {
  margin: 0;
  padding: 0 0 2px;
  color: var(--heading);
  /*font-family: var(--poppins);*/
  font-size: 18px;
  font-weight: 400;
}
.breadcrumb-section p {
  margin: 0;
  padding: 0;
  /*font-family: var(--poppins);*/
  font-size: 12px;
  color: var(lightGrey);
}

.btn.btn-menu-toggle {
  color: var(--colorWhite);
  display: none;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 0;
}

.useravtar-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 26px;
  padding: 13px;
  font-weight: 500;
}

button.mat-mdc-menu-item {
  font-size: 12px !important;
  min-height: 30px;
  padding-top: 5px !important;
}
button.mat-mdc-menu-item:hover {
  background-color: black;
}
button.mat-mdc-menu-item img {
  width: 35px;
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px !important;
}

.nav-link-img {
  width: 30px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}

.sidebar-item .profile {
  cursor: pointer;
  display: block;
}
.sidebar-item .nav-link {
  padding: 0.7rem 1rem;
  line-height: 29px;
  cursor: pointer;
  font-weight: 400;
  /*font-family: Poppins;*/
  font-size: 16px;
  color: inherit;
}
.sidebar-item .nav-link.nav-avtar {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: var(--colorWhite);
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.sidebar-item .nav-link.nav-avtar.active {
  color: var(--colorWhite);
  position: relative;
}
.sidebar-item .nav-link.nav-avtar.active:before {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--colorWhite) transparent;
}
.sidebar-item .nav-link.profilename {
  padding: 0.7rem 1rem 0.7rem 1.5rem;
  display: block;
  text-align: center;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
