import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VideoService } from './../../service/video.service';
import { CommonService } from './../../service/common.service';
import { ApiService } from './../../service/api.service';
import { DashboardService } from './../../service/dashboard.service';

@Component({
  selector: 'app-view-all-cat-courses',
  templateUrl: './view-all-cat-courses.component.html',
  styleUrls: ['./view-all-cat-courses.component.scss']
})
export class ViewAllCatCoursesComponent implements OnInit {


  /** @var courseDataContainer store all video data  */
  courseDataContainer: any;

  /** @var getVideoUploadPath store video image path  */
  getVideoUploadPath: any;

  /** @var pagingLimit store pagination limit counter default 20  */
   pagingLimit: number = 20;
 

  /** @var totalLengthOfPage Total length of recored for pagination default in null  */
  totalLengthOfPage: any[] = [];

  /** @var increasCount Number counter inscreas in function for pagination  */
  increasCount: number = 0;

  /** @var firstPage First page button for pagination  */
  firstPage: any[] = [];

  /** @var lastPage Last page button for pagination  */
  lastPage: any[] = [];

  /** @var paginationLimit Pagination Limit defualt empty it will be update in function  */
  paginationLimit: any[] = [];

  /** @var globalCounterPagin Global counter for pagination  */
  globalCounterPagin: number = 0;

  /** @var notfound For error when data is not found  */
  notfound: boolean = false;

  /** @var isLoading For loading when data featching  */
  isLoading: boolean = false;

  /** @var totalRecorde store total recorde of page */
  totalRecorde: any;

  /** @var courseUrlParam vide url path merge */
  courseUrlParam: any;

  /** @var categoryTitle store category title and used in top of page heading  */
  categoryTitle: any;

  /** @var categoryId store category id and used in top of page heading  */
  categoryId: any;


  constructor(private router: Router, private videoService: VideoService, private commonService: CommonService, private apiService: ApiService, private renderer: Renderer2, private activatedRoute: ActivatedRoute, private dashboardService: DashboardService) {
    this.courseUrlParam = atob(this.activatedRoute.snapshot.paramMap.get('categoryId'));

  }

  ngOnInit() {
    this.getVideoUploadPath = this.apiService.getCourseImgPath();
    this.setCounterLink(0);
    this.getCourseListing(0, '', '');
  }

  /**
 * @description Get all video lising by category id and param for search
 * @date 2020-05-22
 * @param pageCounter, searchTitle, searchCat
 * @method  getCourseListing
 * @memberof CategoryListVideoComponent
 */
  getCourseListing(pageCounter, searchTitle, searchCat) {
    this.totalLengthOfPage = [];
    this.increasCount = 0;
    this.isLoading = true;
    const videoData = {
      method: 'relatedCategoryCourseList',
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      category_id: this.courseUrlParam,
      pageNumber: pageCounter
    };
    this.dashboardService.relatedCategoryCourseList(videoData).subscribe((res) => {
      this.isLoading = false;
      if (res['status'] = true) {
        if (res['data'] != null || res['data'] != undefined) {
          this.courseDataContainer = res['data'];
          this.categoryTitle = res['category'][0]['name'];
          this.categoryId = res['category'][0]['id'];
          const getTotalCount = parseInt(res['recordsCount']);

          this.totalRecorde = res['recordsCount'];
          const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);

          for (let i = 1; i <= roundOffCount; i++) {
            const pageItenData = {
              pageItem: this.increasCount
            };
            this.totalLengthOfPage.push(pageItenData);
             this.increasCount = this.increasCount + 20;
          }
          this.firstPage = this.totalLengthOfPage[0];
          if (this.totalLengthOfPage.length > 1) {
            this.lastPage = this.totalLengthOfPage[this.totalLengthOfPage.length - 1];
          }
          this.getPaginationLimit(this.totalLengthOfPage);
          setTimeout(() => {
            const getPagingId = localStorage.getItem('video_pagination_counter');
            const pageItemsClass = document.querySelectorAll('.page-item');
            pageItemsClass.forEach(element => {
              this.renderer.removeClass(element, 'active');
            });
            const pagingElement = document.querySelectorAll('.paging_link_' + getPagingId);
            pagingElement.forEach(element => {
              this.renderer.addClass(element, 'active');
            });
          }, 100);

        } else {
          this.notfound = true;
        }
      } else {
        this.notfound = true;
      }

    });
  }


  /**
 * @description Get current pagination counter
 * @date 2020-05-22
 * @param getCurrentPageCount
 * @method  getDataById
 * @memberof CategoryListVideoComponent
 */
  getDataById(getCurrentPageCount: any) {
    this.getCourseListing(getCurrentPageCount, '', '');
    this.setCounterLink(getCurrentPageCount);
  }


  /**
 * @description Set counter in localstorage for pagination
 * @date 2020-05-22
 * @param counterId
 * @method  setCounterLink
 * @memberof CategoryListVideoComponent
 */
  setCounterLink(counterId: any) {
    localStorage.setItem('video_pagination_counter', counterId)
  }


  /**
* @description Set pagination html by data length
* @date 2020-05-22
* @param data
* @method  getPaginationLimit
* @memberof CategoryListVideoComponent
*/
  getPaginationLimit(data) {
    this.paginationLimit = [];
    const getPagingId = localStorage.getItem('video_pagination_counter');
    const indexByVal = data.findIndex(x => x.pageItem == getPagingId);

    if (indexByVal > 1) {
      this.globalCounterPagin = indexByVal - 2;
    }
    if (indexByVal > 0 && indexByVal < 1) {
      this.globalCounterPagin = indexByVal - 1;
    }
    if (indexByVal == 0) {
      this.globalCounterPagin = indexByVal;
    }
   
    const prevVal: any[] = [];
    const nextVal: any[] = [];
    let currentPrevVal: any[] = [];
    const indexForLoop = indexByVal - 3;
    for (let i = (indexByVal - 1); i > indexForLoop; i--) {
      if (i > -1) {
        if (data[i] != undefined && data[i] != null) {
          prevVal.push(data[i]);
        }
      }
    }
    const dualIndexVal = indexByVal + 1;
    const prevValLength = this.checkPrevValLength(prevVal);

    for (let j = dualIndexVal; j < (dualIndexVal + prevValLength); j++) {
      if (data[j] != undefined && data[j] != null) {
        nextVal.push(data[j]);
      }
    }

    currentPrevVal = prevVal.concat(data[indexByVal])
    currentPrevVal.sort((a, b) => { if (a.pageItem < b.pageItem) { return -1; } return a.pageItem > b.pageItem ? 1 : 0; });

    this.paginationLimit = currentPrevVal.concat(nextVal);
    this.paginationLimit = this.paginationLimit.filter((item) => {
      return item != undefined;
    });
  }


  /**
  * @description Check previous value length for pagination
  * @date 2020-05-22
  * @param prevData
  * @method  checkPrevValLength
  * @memberof CategoryListVideoComponent
  */
  checkPrevValLength(prevData) {
    switch (prevData.length) {
      case 0:
        return 4;
        break;
      case 1:
        return 3;
        break;
      case 2:
        return 2;
        break;
      default:
        return 2;
    }
  }


  /**
 * @description Navigate to the category listing page
 * @date 2020-05-22
 * @method  gotoCourseCategoryPage
 * @memberof CategoryListVideoComponent
 */
  gotoCourseCategoryPage() {
    this.router.navigate(['category-dashboard']);
  }


  /**
 * @description Navigate to the details page
 * @date 2020-05-22
 * @param video_id
 * @method  videoDetails
 * @memberof CategoryListVideoComponent
 */
  videoDetails(video_id, categoryId) {
    const paraData = video_id + ',' + categoryId;
    this.router.navigate(['video-detail/' + btoa(paraData)]);
  }


}
