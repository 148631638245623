import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-course-section-expired',
  templateUrl: './course-section-expired.component.html'
})
export class CourseSectionExpiredComponent implements OnInit {
  dialogParam: any;
  constructor(@Inject(MAT_DIALOG_DATA) public escalateData: any, private dialogRef: MatDialogRef<CourseSectionExpiredComponent>) { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {

  }
  closeDialog() {
    if (this.escalateData.redirect) {
      this.dialogParam = {
        redirect: true
      }
    } else {
      this.dialogParam = {
        redirect: false
      }
    }
    this.dialogRef.close(this.dialogParam)
  }
}
