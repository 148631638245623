// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minspace {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 1;
}
.minspace .spinner {
  width: 50px;
  height: 50px;
  display: block;
  margin: 40vh auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
