// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardRight {
  padding-top: 25px;
}

.message-list .message-list-header {
  border-bottom: 2px solid #f3f3f3;
  padding: 0 0 8px;
  margin-bottom: 17px !important;
}
.message-list .message-list-header .heading {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--poppins);
}
.message-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.message-list ul li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  position: relative;
  display: flex;
  border: 1px solid #efefef;
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
}
.message-list ul li .check-icon {
  padding: 5px;
}
.message-list ul li .check-icon .btn i {
  pointer-events: none;
}
.message-list ul li .noti-icon {
  color: #7d7d7d;
  padding: 5px 14px;
  margin: 0 18px 0 0;
  font-size: 18px;
  background: #f3f3f3;
  border-radius: 4px;
  font-weight: 500;
}
.message-list ul li .noti-detail {
  width: 100%;
}
.message-list ul li .noti-detail h3 {
  margin: 0;
  padding: 5px 110px 5px 0;
  font-size: 16px;
  color: #2f5784;
  position: relative;
  line-height: 22px;
}
.message-list ul li .noti-detail h3 a {
  color: #3e4348;
  text-decoration: none;
}
.message-list ul li .noti-detail h3 span {
  font-size: 13px;
  color: #7d7d7d;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 5px;
}
.message-list ul li .noti-detail p {
  margin: 8px 0 0;
  padding: 0;
  font-size: 13px;
  font-family: var(--poppins);
  line-height: 22px;
  text-align: justify;
}
.message-list ul li.unread {
  border: 1px solid #c9dbf1;
  box-shadow: 0 4px 4px rgba(169, 189, 212, 0.3);
}
.message-list ul li.unread .noti-icon {
  background: #458cde;
  color: #fff;
}
.message-list ul li.unread .noti-detail h3 {
  color: #458cde;
}
.message-list ul li.unread .noti-detail h3 a {
  color: #458cde;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
