import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuizService } from './../../service/quiz.service';
import { CommonService } from './../../service/common.service';
import { SiteDomainService } from './../../service/sitedomain.service';



@Component({
  selector: 'app-gradebook-student-quiz-result',
  templateUrl: './gradebook-student-quiz-result.component.html',
  styleUrls: ['./gradebook-student-quiz-result.component.scss']
})
export class GradebookStudentQuizResultComponent implements OnInit {

  studentId: any;
  quizId: any;
  courseSlug: any;
  sectionSlug: any;
  cykSubmittedData: any ;
  viewBtn: any;
  totalQuestionCount: any;
  submittedAnswerValues: any = [];
  allSectionQuestionsCount: any;
  k: any;
  resultArray:any;
  siteDomain:any;
  message:any;
  showMsg:boolean = false
  quiz_title: any;

  constructor(private router: Router, private route: ActivatedRoute , private quizService : QuizService , 
    private commonService : CommonService , private domain : SiteDomainService) {
    this.studentId = this.route.snapshot.paramMap.get('studentId')
    this.quizId = this.route.snapshot.paramMap.get('quizId')
    this.courseSlug = this.route.snapshot.paramMap.get('courseId')
    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId')
  }

  ngOnInit() {
    this.getCykSubmittedDetails(this.quizId)
  }

  /**
   * @description get cyk submitted details
   * @date 2019-12-18
   * @param {*} quizId
   * @memberof GradebookStudentQuizResultComponent
   */
  getCykSubmittedDetails(quizId) {
    this.cykSubmittedData = {
      "method": "getLastQuizResult",
      "quiz_id": quizId,
      "user_id": this.commonService.getUserId()
    }

    this.quizService.getCykSubmittedDetail(this.cykSubmittedData).subscribe(res => {

      if (res['status']) {
        this.viewBtn = res['view_result_button']
        this.quiz_title = res['quiz_title']
        this.resultArray = res['data']['questions']
        this.quizService.quizShowStatus = 2
        this.totalQuestionCount = res['data']['allSectionQuestionsCount']
        this.k = 0;
        for (let i = 0; i < this.resultArray.length; i++) {

          for (let j = 0; j < this.resultArray[i]['questions'].length; j++) {
            this.submittedAnswerValues[this.k] = this.resultArray[i]['questions'][j].correct
            this.k++;
          }
        }
        const search = true;
        this.quizService.correctAnswercount = this.submittedAnswerValues.reduce(function (n, val) {
          return n + (val == search);
        }, 0);

        this.allSectionQuestionsCount = res['data']['allSectionQuestionsCount']
      }else{
        this.showMsg = true
        this.message = "This quiz has not yet been completed."
      } 
    },
      error => error);
  }

}
