import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-user-warning',
  templateUrl: './remove-user-warning.component.html',
  styleUrls: ['./remove-user-warning.component.scss']
})
export class RemoveUserWarningComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveUserWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
      event.preventDefault();
    }

  ngOnInit() {
  }


}
