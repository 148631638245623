import { Component, OnInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactory, ComponentFactoryResolver, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { ActiveRoomDetailsComponent } from './../active-rooms-details/active-room-details.component';
import { ExpiredRoomDetailsComponent } from './../expired-rooms-details/expired-room-details.component';

@Component({
  selector: 'app-user-room-section',
  templateUrl: './user-room-section.component.html',
  styleUrls: ['./user-room-section.component.scss']
})
export class UserRoomSectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("getRoomsData", { read: ViewContainerRef, static: true }) container;
  @ViewChild('activeRoomTab', { static: false }) activeRoomTab;
  @ViewChild('expiredRoomTab', { static: false }) expiredRoomTab;
  componentRef: ComponentRef<any>;

  componentArr = {
    1: ActiveRoomDetailsComponent,
    2: ExpiredRoomDetailsComponent
  }

  constructor(private resolver: ComponentFactoryResolver, private renderer: Renderer2) { }
  ngOnInit() {
    // this.container.clear();
    $('#activeRooms').trigger('click')
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.activeRoomTab.nativeElement, "active");
   // this.renderer.setProperty(this.activeRoomTab.nativeElement,'disabled','true')
  }

  ngOnDestroy() {
    this.container.clear();
  }
  getUserWiseRoomData(value) {
    //  if(value == 1){
    //   this.renderer.removeClass(this.expiredRoomTab.nativeElement , 'active') 
    //   this.renderer.addClass(this.activeRoomTab.nativeElement,"active");
    //  }else{
    //   this.renderer.removeClass(this.activeRoomTab.nativeElement , 'active') 
    //   this.renderer.addClass(this.expiredRoomTab.nativeElement,"active");
    //  }
    //this.renderer.addClass(this.activeRoomTab.nativeElement, "active");

    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.componentArr[value]);
    this.componentRef = this.container.createComponent(factory);
  }
}
