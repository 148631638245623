import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from './../../../../service/api.service';

@Component({
  selector: 'app-wiki-video-player',
  templateUrl: './wiki-video-player.component.html',
  styleUrls: ['./wiki-video-player.component.scss']
})
export class WikiVideoPlayerComponent implements OnInit {
  
  @Input() video:any;
  isLoading:Boolean = true
  videoPath:String;

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.videoPath = (this.video.type == 1) ? this.apiService.getVideoUploadPath() + this.video.path : this.video.path
    setTimeout(() => {
      this.isLoading = false
    } , 500)
    
  }

}
