import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-general-message',
  templateUrl: './general-message.component.html',
  styleUrls: ['./general-message.component.scss']
})
export class GeneralMessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GeneralMessageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
      event.preventDefault();
    }

  ngOnInit() {
  }

}
