import { Component, OnInit, AfterViewInit, HostListener, Renderer2 } from '@angular/core';
import { CourseService } from './../../../service/course.service';
import { Router } from '@angular/router';
import { CommonService } from './../../../service/common.service';
import { GroupLeaderService } from './../../../service/group-leader.service';

@Component({
  selector: 'app-stream-sidebar',
  templateUrl: './stream-sidebar.component.html',
  styleUrls: ['./stream-sidebar.component.scss']
})
export class StreamSidebarComponent implements OnInit {

  groupLeaderLinkStatus: boolean = false;
  instructorgroupLeaderLinkStatus: boolean = false;
  transcriptLinkStatus: boolean = false;
  showGroupsNav: boolean;

  constructor(private courseService: CourseService, private router: Router, private renderer: Renderer2,
    private commonService: CommonService , private groupLeaderService : GroupLeaderService) { }
  CourseAssignedData: any;
  assignedCourses: any;
  userData: any;
  userId: any;
  gradeBookLinkStatus: boolean = false;
  dashboradRoute: any;
  groupsRoute: any;

  studentGradebookPath: any;

  ngOnInit() {
    this.setSidebarRoutes()
    this.setActiveLinks()
    //this.checkIfUserAssignedGroupSet()
  }

  checkIfUserAssignedGroupSet(){
    const data = {
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      method:'checkIfUserAssignedGroupSet'
    }
    this.groupLeaderService.checkIfUserAssignedGroupSet(data).subscribe((res) => {
       this.showGroupsNav =  (res['status']) ?  true : false
    }),(error)=> {
      console.log(error)
    }
  }

  goToNotification(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/notification-list']);
    });
  }

  setActiveLinks() {
    if ((this.commonService.getUserRole() == this.commonService.userRolesArr['Student'])) {
      this.transcriptLinkStatus = true
    }
    if ((this.commonService.getUserRole() == this.commonService.userRolesArr['Instructor'])) {
      this.instructorgroupLeaderLinkStatus = true
      this.studentGradebookPath = '/instructor-courses'
    }
    if ((this.commonService.getUserRole() == this.commonService.userRolesArr['GroupLeader'])) {
      this.gradeBookLinkStatus = true
    }
    if (this.commonService.getUserRole() == this.commonService.userRolesArr['GroupLeader']) {
      this.groupLeaderLinkStatus = true
      this.studentGradebookPath = '/groupleader-gradebook'
    }
  }

  setSidebarRoutes(){
    this.dashboradRoute = this.commonService.getDashBoardRoutes(this.commonService.getUserRole())
    if (this.commonService.getUserRole() == this.commonService.userRolesArr['Student']) {
      const studentParam = btoa('0')
      this.groupsRoute = '/group-leader/general-group-set/groups/' + studentParam
    } else {
      this.groupsRoute = '/group-leader/general-group-set'
    }
  }

  ngAfterViewInit() {
    var acc = document.getElementsByClassName("taggleClassEvent");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.height) {
          panel.style.height = null;
        } else {
          panel.style.height = panel.scrollHeight + "px";
        }
      });
    }
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById('pageContainer')
    if (pageelement.classList.contains('collapse-panel')) {
      this.renderer.removeClass(pageelement, 'collapse-panel')
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel')
    }
  }

}
