import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, HostListener } from '@angular/core';
import { Socket } from 'ngx-socket-io';
// import * as Socket from 'socket.io-client';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as SimplePeer from 'simple-peer/simplepeer.min';
import * as dateFormat from 'dateformat';
import { map } from 'rxjs/operators';
import { CommonService } from './../../service/common.service';
import { StreamingService } from './../../stream/service/streaming.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from './../../stream/service/socket.service';
import { LiveStreamApiService } from './../../stream/service/live-stream-api.service'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-room',
  templateUrl: './user-room.component.html',
  styleUrls: ['./user-room.component.scss']
})
export class UserRoomComponent implements OnInit {

  submitSuccess: boolean;
  isLoading: boolean = true;
  hsocId: any;

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.submitSuccess) {
      return true;
    }
    return false
  }


  //   @HostListener('window:beforeunload', ['$event'])
  //   onWindowClose(event: any): void {
  //    // Do something
  //     event.preventDefault();
  //     event.returnValue = false;
  //  }

  roomNameURLParam: string;
  userRoomForm: FormGroup
  username: FormControl
  chatWindowForm: FormGroup;
  chatMessage: FormControl
  lobbyStatus = 0
  socket: any;
  validatefields: Boolean = false
  hostUser: any;
  levelStatus: Boolean = true;
  peers: any = {};

  constraints: any = {
    audio: {
      sampleRate: 48000,
      channelCount: {
        ideal: 2,
        min: 1
      },
      volume: 1,
      echoCancellation: true,
      noiseSuppression: false,
      googAutoGainControl: false
    },
    // video: {
    //     width: { min: 640, ideal: 720, max: 1920 },
    //     height: { min: 480, ideal: 510, max: 1080 },
    //     frameRate: {
    //         ideal: 50,
    //         min: 10
    //     }
    // },
    video: true
  };

  iceServers: any = {
    // trickle: false,
    // reconnectTimer: 100,
    // iceTransportPolicy : 'relay',
    iceServers: [
      {
        urls: 'turn:numb.viagenie.ca:5389',
        credential: 'W{sndp@6600}R',
        username: 'sandeep.bansod.007@gmail.com'
      },
      { urls: "stun:stun.services.mozilla.com" },
      { urls: "stun:stun1.l.google.com:19302" },
    ],
    sdpSemantics: 'unified-plan',
  };
  tempUserName: any;
  dynamicPeerUser: HTMLElement;
  chatOption: HTMLElement;
  videoChatWindow: HTMLElement;
  clientChatOption: HTMLElement;
  outputContainer: HTMLElement;
  roomName: any;
  getUserName: any;
  constructor(private _route: ActivatedRoute, private commonService: CommonService, private socketContainer: Socket, private _fb: FormBuilder, private changeDetector: ChangeDetectorRef, private renderer: Renderer2, private streamingService: StreamingService, private socketService: SocketService, private liveStream: LiveStreamApiService, private router: Router) {
    this.roomNameURLParam = this._route.snapshot.paramMap.get('roomId')
    console.log(this.roomNameURLParam, 'roomName param')
    this.hsocId = this.roomNameURLParam.split('hsoc21')[1]
    console.log(this.hsocId , 'hsocId Data')
    this.verifyIfRoomIsExpired(this.roomNameURLParam)

  }

  verifyIfRoomIsExpired(encRoom) {
    const data = {
      id: this.commonService.getUserId(),
      encryptedRoom: encRoom,
      method: 'verifyIfRoomIsExpired'
    }
    this.liveStream.verifyIfRoomIsExpired(data).subscribe((res) => {
      console.log(res['status'])
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return
      }
      if (!res['status']) {
        this.router.navigate(['/room-expired'])
      }
    })
  }


  ngOnInit() {
    this.getUserName = this.commonService.getUserFullName()
    this.socket = this.socketContainer.connect()
    setTimeout(() => {
      console.log(this.socket.id , 'well well well user socket id')
      if(this.socket.id !== undefined){
        this.socket.emit('verifyActiveHost' , this.hsocId , this.socket.id)
      }
    }, 3000)
    this.username = this._fb.control('', Validators.required)
    this.chatMessage = this._fb.control('', Validators.required)
    this.userRoomForm = this._fb.group({
      username: this.username
    })
    this.chatWindowForm = this._fb.group({
      chatMessage: this.chatMessage
    })

    this.username.setValue(this.getUserName)
    setTimeout(() => {
      this.isLoading = false
    },3500)
   
  }

  ngAfterViewInit() {
    //this.isLoading = true;
    localStorage.removeItem('HIDNAME');
    this.dynamicPeerUser = document.getElementById("dynamic-peer-user");
    this.chatOption = document.getElementById("chatOption");
    this.videoChatWindow = document.getElementById("video-chat-window");
    this.clientChatOption = document.getElementById("clientChatOption");
    this.outputContainer = document.getElementById("output-container");
    this.initConnections()

  }

  userNamekeyUp(event) {
    this.tempUserName = event.target.value
    console.log(this.tempUserName)
  }

  joinRoom() {
    console.log(this.userRoomForm.status)
    if (this.userRoomForm.status == 'VALID') {
      console.log('roomOn : ', this.socketService.roomOn);
      this.socketService.setRoomStatus(true);
      console.log('roomOn : ', this.socketService.roomOn);
      this.validatefields = false
      this.socket.emit('joining', this.roomNameURLParam);
      this.socket.emit('userjoin', this.roomNameURLParam);
    } else {
      this.validatefields = true
    }
  }


  sendJoinedMsg(){
    let currentDateTime = new Date();
    let hostMsg = ' ' + this.getUserName + ' has joined'
    this.socket.emit("sendingMessage", {
      message: hostMsg,
      username: this.getUserName,
      dateTime: dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"),
      roomName: this.roomName,
      adminMsg:true
    });
  }

  initConnections() {
    this.socket.on('joined', (socketId, level, roomName) => {
      this.roomName = roomName
      console.log(this.roomName, 'roomName')
      this.levelStatus = level
      this.initFunction(socketId)
      console.log("joined", socketId)
      console.log("levelStatus", this.levelStatus);
      this.lobbyStatus = 1
      this.sendJoinedMsg()
    });

    this.socket.on("roomData", (roomData) => {
      console.log(roomData)
    })

    this.socket.on('hostConnection', (socket_id) => {
      console.log('hostconnection called in client js', this.levelStatus)
      // if (!this.levelStatus) {
      this.hostUser = socket_id;
      console.log("hostConnection", socket_id)
      // }
    });

    this.socket.on('clientName', (name, id, webID) => {
      console.log("client name", name)
      console.log("client id", id)
      console.log('levelstatus on clientName event', this.levelStatus)
      if (this.levelStatus) {
        // clientRemoteName = name;
        setTimeout(() => {
          let getRemoteElm = document.querySelectorAll('[client-id]');
          console.log(getRemoteElm)
          getRemoteElm.forEach(element => {
            let getElmId = element.getAttribute('client-id');
            if (getElmId === id) {
              console.log(element.children[0].innerHTML = name)
            }
          });
        }, 800)
      } else {
        console.log("Host Name", name, webID)
        if (webID == 'HID') {
          localStorage.setItem("HIDNAME", name);
        }
        // setTimeout( ()=>{
        //     let getRemoteElm = document.querySelector('#'+id);
        //     console.log(getRemoteElm)
        // }, 500)
      }
    })

    this.socket.on("closeConnection", (socket_id) => {
      console.log("closeConnection", socket_id)
      console.log("peers", this.peers)
      console.log("socket", this.socket.id)
      console.log(this.socket.id, socket_id)

      if (this.socket.id === socket_id) {
        //window.location.reload()
        this.socketService.setRoomStatus(false);
        this.socket.disconnect(true)
        this.router.navigate(['user-room-section']);
    //     this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
    //     this.router.navigate(['user-room-section']);
    // });
      }
    });

    this.socket.on('removePeer', socket_id => {
      console.log('removing peer ' + socket_id)
      this.removePeer(socket_id)
      
  })

    this.socket.on('removeAllPeer', socket_id => {
      console.log('removing peer ' + socket_id)
      this.removeAllPeerConnections(socket_id)
    })

    this.socket.on('disconnect', () => {
      console.log('GOT DISCONNECTED')
      for (let socket_id in this.peers) {
          this.removePeer(socket_id)
      }
  })

  this.socket.on('sendHostActiveStatus' , (hostStatus) => {
    if(!hostStatus){
      //this.router.navigate(['/room-expired']) 
      this.router.navigate(['user-room-section'])
    }
  })

  this.socket.on('hostExpired' , () => {
    this.socketService.setRoomStatus(false);
    this.router.navigate(['user-room-section'])
  })

  }

  initFunction(socId) {
    this.socket.emit('peerPlay', socId)
    this.socket = this.socketContainer.connect();
    console.log('init fun called !')
    this.socket.on('initReceive', socket_id => {
      console.log('INIT RECEIVE ' + socket_id)
      this.addPeer(socket_id, false)
      this.socket.emit('initSend', socket_id)
    })

    this.socket.on('initSend', socket_id => {
      console.log('INIT SEND ' + socket_id, ' and levelStatus' + this.levelStatus)
      // if (!this.levelStatus) {
      console.log('yoyo')
      this.socket.emit("clientName", this.getUserName, this.socket.id, 'UID');
      //}
      this.addPeer(socket_id, true)
    })

    this.socket.on('signal', data => {
      console.log('client signal')
      this.peers[data.socket_id].signal(data.signal)
    })

    this.socket.on("broadcastMessages", (msgData) => {
      console.log(msgData, 'in broadcast')
      if(msgData.adminMsg){
        this.outputContainer.innerHTML += '<div class="adminMsg"><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
      }else{
        if (msgData.username !== this.getUserName) {
          this.outputContainer.innerHTML += '<div class="message-left"><div class="messgae-user">' + msgData.username + '</div><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
        } else {
          this.outputContainer.innerHTML += '<div class="message-right"><div class="messgae-user">' + msgData.username + '</div><div class="message-content"><span>' + msgData.dateTime + '</span>' + msgData.message + '</div></div>'
        }
      }
     
      if (!this.videoChatWindow.classList.contains('open')) {
        this.clientChatOption.classList.add('highlight');
      }else{
        this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
      }
    })

  }

  clientCallEndButtonFn() {
    this.socketService.setRoomStatus(false);
    this.socket.emit('disconnectFromPeer');
    let getClientId = localStorage.getItem('clientId');
    console.log(getClientId)
    console.log(this.peers[getClientId])
    this.removePeer(getClientId)
    // console.log(localStream.getTracks())
    // localStream.getTracks().forEach(function(track) {
    //     track.stop();
    // });
    // console.log(localStream.getTracks())
    this.lobbyStatus = 0
    localStorage.removeItem('clientId');
    this.isLoading = true
     //window.location.reload();
    this.socket.disconnect(true)
    this.router.navigate(['user-room-section']);
  }

  removePeer(socket_id) {
    let videoEl: any = document.getElementById(socket_id)
    if (videoEl) {
      console.log(videoEl)
      let tracks = videoEl.srcObject.getTracks();
      tracks.forEach(function (track) {
        track.stop()
      })
      videoEl.srcObject = null
      $(videoEl).parent('div.item').remove();
    }
    if (this.peers[socket_id]) this.peers[socket_id].destroy()
    delete this.peers[socket_id]


    //this.renderer.setStyle(this.clientChatOption,'display','none');
    //alert('Video stopped')

    //*****/ comment
    //window.location.reload()
  }

  removeAllPeerConnections = (socket_id) => {
    let videoEl = document.getElementById(socket_id)
    // localStream.getTracks().forEach(function(track) {
    //     track.stop();
    // });
    // console.log(localStream.getTracks())
    window.location.reload();
    this.lobbyStatus = 0
    $(this.dynamicPeerUser).html('')
    localStorage.removeItem('clientId');
    localStorage.removeItem('HIDNAME');
    if (this.peers[socket_id]) this.peers[socket_id].destroy()
    delete this.peers[socket_id]
  }

  chatOptionFn(event) {
    event.target.classList.toggle('off')
    event.target.classList.remove('highlight');
    this.videoChatWindow.classList.toggle('open');
    if (this.videoChatWindow.classList.contains('open')) {
      this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
    }

  }

 handleKeyUp(e) {
    if (e.keyCode === 13) {
        console.log(e.target.value);
        let data = e.target.value;
        if (data.replaceAll(/\s/g, '') != '') {
          this.sendMessage();
        } else {
          this.chatWindowForm.patchValue({ chatMessage: '' });
        }
      }
    }

  closeChatWindowFn(event) {
    this.clientChatOption.classList.add('off');
    this.videoChatWindow.classList.remove('open');
  }

  sendMessage() {
    let currentDateTime = new Date();
    console.log(dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"));
    console.log(this.chatWindowForm.value.chatMessage)
    if (this.chatWindowForm.value.chatMessage != '') {
      this.socket.emit("sendingMessage", {
        message: this.chatWindowForm.value.chatMessage,
        username: this.getUserName,
        dateTime: dateFormat(currentDateTime, "mmmm d, yyyy, hh:MM TT"),
        roomName: this.roomName
      });
      this.chatMessage.setValue('')
      this.streamingService.getMessage().subscribe((msgData) => { console.log(msgData) })
    }
  }

  addPeer(socket_id, am_initiator) {

    // let clientStream = {
    //     id: socket_id
    // }
    this.peers[socket_id] = new SimplePeer({
      initiator: am_initiator,
      // stream: clientStream,
      config: this.iceServers
    })
    console.log(this.peers)
    console.log(this.peers[socket_id])
    console.log(socket_id)
    this.peers[socket_id].on('signal', data => {
      this.socket.emit('signal', {
        signal: data,
        socket_id: socket_id
      })
    })
    this.peers[socket_id].on('stream', stream => {
      console.log(stream)
      console.log(stream.getTracks())
      let newVid: any = document.createElement('video')
      this.renderer.setStyle(newVid , 'width','100%')
      this.renderer.setStyle(newVid , 'height','100%')
      let divElm = document.createElement('div');
      let divremoteElm = document.createElement('div');
      let spanremoteElm = document.createElement('span');
      let noUserDiv = document.createElement('div');
      let userIconAvatar = document.createElement('span');
      let userNameAvatar = document.createElement('span');
      divElm.className = 'item';
      divElm.setAttribute('client-id', socket_id);
      newVid.srcObject = stream
      newVid.id = socket_id
      //newVid.playsinline = false
      newVid.autoplay = true
      newVid.className = "vid"
      console.log(newVid, 'newVid newVid newVid newVid newVid newVid')
      divremoteElm.className = "item"
      this.renderer.setStyle(divremoteElm,'color','#ffffff')
      this.renderer.setStyle(divremoteElm,'text-align','center')
      spanremoteElm.className = "name"
      spanremoteElm.innerHTML = socket_id;
      noUserDiv.className = "no-user-div";
      userIconAvatar.className = "user-icon-avatar";
      userNameAvatar.className = "user-name-avatar";
      noUserDiv.append(userIconAvatar);
      noUserDiv.append(userNameAvatar);
      localStorage.setItem("clientId", socket_id)
      this.dynamicPeerUser.style.display = "block";
      if (this.hostUser === socket_id) {
        console.log('in')
        divremoteElm.append(spanremoteElm)
        divremoteElm.append(newVid)
        divremoteElm.append(noUserDiv)
        let mergElm = divremoteElm;
        console.log(mergElm)
        this.dynamicPeerUser.append(mergElm)
        setTimeout(() => {
          console.log('inside setTime')
          if (localStorage.getItem('HIDNAME')) {
            console.log('inside localgethidename')
            let getHostElm: any = document.querySelector("#" + socket_id).previousSibling;
            console.log(getHostElm, 'getHostElm')
            console.log(getHostElm.innerHTML = localStorage.getItem('HIDNAME'))
            userNameAvatar.innerHTML = localStorage.getItem('HIDNAME')
          }
          console.log(localStorage.getItem('HIDNAME'));
        }, 500)
        // peerDynamicElement.append(dynamicPeerUser)
      }

    })
  }

  ngOnDestroy(){
    this.socketService.setRoomStatus(false);
    //this.socket.disconnect()
    //this.socket.close() 
    this.socket.disconnect(true)
    //this.socket.emit('forceDisconnect' , 'data');
  }
}
