// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel.panel-course .panel-header {
  padding: 12px 12px 12px 12px;
  color: #ffffff;
  text-align: center;
  background-color: #ffffff;
  min-height: 80px;
}

.panel.panel-course .panel-body h2 {
  margin: 0px 0px 0px 4px;
  color: #ffffff;
}

.panel.panel-course .panel-body {
  padding: 12px 12px 12px 12px;
  color: #ffffff;
  text-align: center;
  height: fit-content;
  background-color: #990000;
}

.panel.panel-course .panel-body a {
  min-height: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label {
  min-width: 50% !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
