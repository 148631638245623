// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradbook-table {
  font-family: var(--poppins);
  font-size: 12px;
  margin-bottom: 55px;
}
.gradbook-table .table-responsive {
  position: relative;
}
.gradbook-table .outer-table {
  margin: -1px;
  position: relative;
  z-index: 2;
  background: #fff;
}
.gradbook-table .outer-table thead tr:first-child th {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 2;
}
.gradbook-table .outer-table thead tr:first-child th:first-child {
  left: 0;
  z-index: 3;
  border-right: 1px solid #dee2e6;
}
.gradbook-table .outer-table thead tr th {
  position: sticky;
  background-color: #fff;
  top: 0px;
  z-index: 2;
  border: 0;
  box-shadow: 0 0 0 1px #dee2e6;
}
.gradbook-table .outer-table thead tr th table {
  margin: 0;
}
.gradbook-table .outer-table thead tr th table th {
  border: 0 !important;
  box-shadow: 0 0 0 1px #dee2e6;
}
.gradbook-table .outer-table thead tr th:first-child {
  left: 0;
  z-index: 3;
  border-right: 2px solid #dee2e6;
}
.gradbook-table .outer-table tbody tr td.fixedLeftColumn,
.gradbook-table .outer-table tbody tr td:first-child {
  position: sticky;
  background-color: #fff;
  left: 0;
  z-index: 1;
  border-color: transparent;
  border: 0;
  box-shadow: 0 0 0 1px #dee2e6;
}
.gradbook-table th {
  font-weight: 600;
  font-size: 12px;
}
.gradbook-table th table,
.gradbook-table td table {
  margin-bottom: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: -1px;
  border-collapse: collapse;
}
.gradbook-table .fixed-table-row-one {
  visibility: hidden;
}
.gradbook-table .fixed-table-row-two {
  visibility: hidden;
}

.gradbookSpinner {
  text-align: center;
  margin: auto;
}
.gradbookSpinner .spinner {
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto 0;
}
.gradbookSpinner .spinner .mat-mdc-progress-spinner {
  width: 100% !important;
  height: 100% !important;
}
.gradbookSpinner .spinner .mat-mdc-progress-spinner svg {
  width: 100% !important;
  height: 100% !important;
}

.pagination-container {
  padding: 15px 0 0;
}
.pagination-container .pagination {
  justify-content: flex-end;
}
.pagination-container .pagination .page-item {
  cursor: pointer;
}
.pagination-container .pagination .page-item.active {
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
