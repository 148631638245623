import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../service/common.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        private commanService: CommonService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUserAuth = localStorage.getItem('loggedUser')
        let jwtToken = localStorage.getItem('jwt')
        let authtoken =  localStorage.getItem('rw_jwt');
        if (currentUserAuth && jwtToken) {
            request = request.clone({
                setHeaders: {
                    'Accept': 'application/json;q=0.9,*/*;q=0.8',
                    'X-API-KEY': 'LMSAPI@123#',
                    'Token': jwtToken,
                    'authtoken': authtoken
                }
            })
        } else {
            request = request.clone({
                setHeaders: {
                    'Accept': 'application/json',
                    'X-API-KEY': 'LMSAPI@123#',
                }
            })
        }

      
        return next.handle(request).pipe(catchError(err => {
            if (err.length != undefined) { 
                if (err.status == 401) {
                    this.commanService.redirectToLogin()
                }
                const error = err.error.message || err.statusText;
                return throwError(error)
            } else {
                return []
            }
        }))

    }

}