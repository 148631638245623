import { Component, OnInit, Renderer2, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VideoService } from './../../service/video.service';
import { DashboardService } from './../../service/dashboard.service';
import { CommonService } from './../../service/common.service';
import { ApiService } from './../../service/api.service';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';

@Component({
  selector: 'app-category-dashboard',
  templateUrl: './category-dashboard.component.html',
  styleUrls: ['./category-dashboard.component.scss']
})
export class CategoryDashboardComponent implements OnInit, OnDestroy {
  @Input() selectedCategory: number;

  /** @var courseDataContainer store all course data  */
  courseDataContainer: any;

  /** @var getVideoUploadPath store video image path  */
  getVideoUploadPath: any;

  /** @var notfound For error when data is not found  */
  notfound = false;

  /** @var isLoading For loading when data featching  */
  isLoading = false;

  /** @var totalRecorde Counting total record of data  */
  totalRecorde: any;

  /** @var categoryList store all feating category list data  */
  categoryList: any;

  /** @var categoryForm form group for searching form field  */
  categoryForm: FormGroup;

  /** @var searchByTitle form field for title  */
  searchByTitle: FormControl;

  /** @var selectCategory form field for category  */
  selectCategory: FormControl;

  sortCategory : FormControl

  /** @var selectCategory category form field error status handler  */
  selectCategoryError = false;
  uncategoryCourseDataContainer: any;

  buttonTheme: { button_back_color: any; button_text_color: any; };
  currentBtnThemeContainer: Subscription;
  selectedOption: string;
  showPlaceholder: boolean = true;
  load : boolean = false ; 
  catId : number ; 



  selectedSortVal: string;
  arrayOfSortObject = [
    {
      value: "alphabetically",
      name: "Alphabetically"
    },
    {
      value: "reversed_alphabetically",
      name: "Reversed alphabetically"
    },
    {
      value: "by_latest",
      name: "By latest"
    },
    {
      value: "by_oldest",
      name: "By oldest"
    },
  ]

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private videoService: VideoService, private commonService: CommonService, private apiService: ApiService, private renderer: Renderer2, private formBuilder: FormBuilder, private dashboardService: DashboardService, private dataService: DataService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  ngOnInit() {

    // alert("category dashboard component : "+localStorage.getItem('jwt'))
    // console.log("category dashboard component : "+localStorage.getItem('jwt'));
    
    this.getVideoUploadPath = this.apiService.getCourseImgPath();
    this.setCounterLink(0);
    // this.getCourseCategories();
    this.courseCategoryId();

    //this.searchByTitle = new FormControl('');
    this.selectCategory = new FormControl(null, Validators.required);
    this.categoryForm = this.formBuilder.group({
      //searchByTitle: this.searchByTitle,
      selectCategory: this.selectCategory,
      sortCategory :  this.sortCategory 
    })
    this.selectedOption = null; // Initialize selectedOption
  }
  

  getCourseCategories() {
    const paramCat = {
      method: 'courseCategoryListDropDown',
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId()
    }
    this.dashboardService.getDashboardListData(paramCat).subscribe((res) => {
      console.log(res);
      
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return;
      }
      if (res['status']) {
        if (res['data']) {
          this.categoryList = res['data'];

        }
      }
    })
  }


  getCourseListing(pageCounter, searchTitle, searchCat, sort) {
    this.isLoading = true;
    this.load = false ; 
    const videoData = {
      method: 'courseCategoryList',
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      search_by_title: searchTitle,
      search_by_category: searchCat,
      pageNumber: pageCounter,
      sort_by: sort
    }
    this.dashboardService.getcourseListData(videoData).subscribe((res) => {
      this.isLoading = false;
      this.load = true
      if(res['jwt_status']){
        if (res['status']) {
          if (res['data'] != null || res['data'] != undefined) {
            this.notfound = false;
            this.courseDataContainer = res['data'].category_courses;
            this.uncategoryCourseDataContainer = res['data'].uncategory_courses;
          } else {
            this.notfound = true;
          }
        } else {
          this.notfound = true;
        }
       }else{
        this.commonService.openSessionExpireDialog()
       }
    });
  }


  gotoCategoryWiseView(catId) {
    this.router.navigate(['view-all-category-courses/' + btoa(catId)]);
  }

  gotoUncategoryWiseView() {
    this.router.navigate(['view-all-category-courses/' + btoa('0')]);
  }

  searchByCategory() {
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.getCourseListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, this.categoryForm.value.sortCategory);
    }else if(this.categoryForm.status == 'INVALID'){
      this.selectCategoryError = true
    }
    else{
      this.selectCategoryError = false
      this.getCourseListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, '');
    }
  }


  checkValidate() {
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.selectCategoryError = false;
    } else {
      this.selectCategoryError = false;      
    }
  }


  onCategoryChange(option) {
    if (option !== null) {
      this.showPlaceholder = false;
    }
    if (option === 'all') {
      this.getCourseListing(0, '', '', ''); 
    } else {
      this.getCourseListing(0, "", option , "")
    }
    this.checkValidate();
  }


  resetForm() {
    this.selectCategoryError = false;
    this.selectedOption = null;
    this.showPlaceholder = true;
    if (this.categoryForm.value.selectCategory != null || this.categoryForm.value.sortCategory != null) {
      this.getCourseListing(0, '', '', '');
    }
  }

  setCounterLink(counterId: any) {
    localStorage.setItem('video_pagination_counter', counterId);
  }

  videoDetails(videoId, categoryId) {
    const paraData = videoId + ',' + categoryId;
    this.router.navigate(['video-detail/' + btoa(paraData)]);
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }


  onChangeSorting(event) {
    console.log("selected category id",this.selectedCategory);   
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.getCourseListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, this.categoryForm.value.sortCategory);
    }else{
      this.selectCategoryError = false
      this.getCourseListing(0, '', '', this.categoryForm.value.sortCategory);
    }
  }

  courseCategoryId() {
    this.dataService.courseCategoryId.subscribe((res:any) => {      
      this.catId = res.category_id      
      if(res === 'all' || this.catId === undefined){
        this.getCourseListing(0, '', '', '');
      }
      else{
        this.getCourseListing(0, '', this.catId, "");
      }
    });
  }
}
