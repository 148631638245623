// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/css/variables.scss");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loginpageContainer .welcom-text {
  color: var(--darkcolor);
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 15px;
  padding: 45px 0;
  text-align: center;
  line-height: 28px;
}
#loginpageContainer .login-form {
  border: 1px solid #dddddd;
  width: 560px;
  margin: 0 0 80px;
  box-shadow: 0 0 8px rgba(36, 96, 190, 0.2);
  border-radius: 15px;
  overflow: hidden;
}
#loginpageContainer .login-form .login-header {
  padding: 25px;
  text-align: center;
  background: url('login-header-bg.png') no-repeat;
  background-size: contain;
}
#loginpageContainer .login-form .login-header .login-icon {
  font-size: 55px;
  color: var(--primarytwocolor);
}
#loginpageContainer .login-form .login-header h4 {
  color: var(--primarytwocolor);
  font-weight: 500;
  font-family: var(--poppins);
}
#loginpageContainer .login-form .login-header p {
  font-size: var(--f14);
}
#loginpageContainer .login-form .login-body {
  padding: 25px;
}
#loginpageContainer .login-form .login-body .form-group {
  width: 75%;
  margin: 0 auto 1.5rem;
}
#loginpageContainer .login-form .login-body .form-control {
  border-color: #c6cbd2;
  border-radius: 55px;
  color: #434343;
  padding: 10px 25px 13px;
  line-height: normal;
  height: auto;
}
#loginpageContainer .login-form .login-body .form-control:focus {
  border-color: var(--primarytwocolor);
  box-shadow: 0 0 2px 3px rgba(36, 96, 190, 0.1);
}
#loginpageContainer .login-form .login-body a.btn-link {
  color: var(--blackcolor);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
