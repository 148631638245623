import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../../service/common.service';

@Component({
  selector: 'app-stream-main',
  templateUrl: './stream-main.component.html',
  styleUrls: ['./stream-main.component.scss']
})
export class StreamMainComponent implements OnInit {
  userRole: any;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.userRole = this.commonService.getUserRole()
  }

}
