import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
//import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { CommonService } from './../../service/common.service';
import { GraderDashboardService } from './../../service/grader-dashboard.service';
import { DataService } from './../../service/data.service';
import { UserService } from './../../service/user.service';
import { Router , ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-certificate-quiz-form',
  templateUrl: './certificate-quiz-form.component.html',
  styleUrls: ['./certificate-quiz-form.component.scss']
})
export class CertificateQuizFormComponent implements OnInit {

  checkQuizCodeForm: FormGroup;
  esc_Option = new FormControl('', Validators.required);
  message = new FormControl('', Validators.required);
  quiz_code = new FormControl('');
  section_id = new FormControl('');
  course_id = new FormControl('');
  institute_id = new FormControl('');
  assessment_id = new FormControl('');
  assignment_submission_id = new FormControl('');
  formData: any;
  showOtherOptionField: boolean = false;
  blankString: String = '';
  courseSlug: any;
  sectionSlug: any;
  quizId: any;

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private dataService: DataService, private commonService: CommonService, public dialogRef: MatDialogRef<CertificateQuizFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService , private router: Router , private route: ActivatedRoute ) {
    this.quizId = data.qId;
    this.courseSlug = this.route.snapshot.paramMap.get('courseId');
    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId');
    this.formData = data;
  }

  ngOnInit() {
    this.checkQuizCodeForm = this.formBuilder.group({
      quiz_code: this.quiz_code
    });
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 0 });
  }

  checkQuizCodeFormSubmission(event: any) {
    event.preventDefault();
    
    console.log('here');
    console.log(event);
    
    console.log('checkQuizCodeForm',this.checkQuizCodeForm.value.quiz_code);
    console.log('formData',this.formData.code);

    
    if (this.checkQuizCodeForm.value.quiz_code == this.formData.code) {
     
      
      // console.log(typeof this.formData.code);
      // const data = {
      //   id: this.commonService.getUserId(),
      //   container_id: this.commonService.getDomainContainerId(),
      //   course_slug: this.courseSlug,
      //   section_slug: this.sectionSlug,
      //   method: 'insertUserCertCodeAttemptData',
      //   quiz_id: this.quizId,
      //   code: this.formData.code,
      //   is_attempt: 1
      // };
      // this.userService.insertUserCertCodeAttemptData(data).subscribe(res => {

      // });

      this.closeDialog(1);
    }else{
      this.closeDialog(0);
    }
  }

  closeDialog(value: any) {
    //alert(value)
    this.dialogRef.close({ event: value });
    // console.log(value);  
  }

}


