import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { LiveChatService } from "src/app/live-chat/service/live-chat.service";
import { CommonService } from "src/app/service/common.service";
import { DataService } from "./../../../app/service/data.service";

@Component({
  selector: "app-user-chat-terms",
  templateUrl: "./user-chat-terms.component.html",
  styleUrls: ["./user-chat-terms.component.scss"],
})
export class UserChatTermsComponent implements OnInit {
  loading: boolean = false;
  chatTerms: any;
  termsErr: boolean;
  userId: any;
  constructor(
    private liveChatService: LiveChatService,
    private dataService: DataService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<UserChatTermsComponent>
  ) {}

  ngOnInit() {
    this.getChatTerms();
    this.userId = this.commonService.getUserId()
  }

  saveUserChatTerms(event: Event, val: any) {
    let termsVal: Boolean;
    this.loading = true;
    const data = {
      userId: this.userId,
      id:this.userId,
      method: "saveUserChatTerms",
      containerId: this.commonService.getDomainContainerId(),
      isAccepted: val,
      roomId: 1,
    };
    this.liveChatService.saveUserChatTerms(data).subscribe((res) => {
      console.log(res);
      this.loading = false;
    });

    termsVal = val === 1 ? true : false;
    this.dataService.changeUserGeneralChatTermsSource(termsVal);
    this.dialogRef.close();
  }

  getChatTerms() {
    this.loading = true;
    const data = {
      method: "getChatTerms",
      containerId: this.commonService.getDomainContainerId(),
      id:this.commonService.getUserId()
    };
    this.liveChatService.getChatTerms(data).subscribe((res) => {
      this.termsErr = (res['data'] === '' || res['data'] === undefined) ? true : false;
      this.chatTerms =  res['data'];
      this.loading = false;
    });
  }
}
