import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresafetyService {
  public briefingComplete : BehaviorSubject<any> = new BehaviorSubject('') ; 
  public userEmail : BehaviorSubject<any> = new BehaviorSubject('');
  public userId : BehaviorSubject<any> = new BehaviorSubject('');
  public emailReturn : BehaviorSubject<any> = new BehaviorSubject('');

  jwt: any;
  constructor(private http: HttpClient, private apiService: ApiService) { }

  getPresafetyList(data): Observable<object> {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getPresafety(), data, {
      headers: this.apiService.setHeaderValJWTForImage(this.jwt)
    })
  }

  viewBriefingDetail(data): Observable<object> {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getPresafety(), data, {
      headers: this.apiService.setHeaderValJWTForImage(this.jwt)
    })
  }

  saveBriefing(data):Observable<object>{
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getPresafety(), data, {
      headers: this.apiService.setHeaderValJWTForImage(this.jwt)
    })
  }
}
