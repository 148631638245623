import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  
  public courseCategoryId = new BehaviorSubject<any>(false)
  public wikiCategoryId = new BehaviorSubject<any>(false)
  public mediaCategoryId = new BehaviorSubject<any>(false)


  public mediaCat : BehaviorSubject<any> = new BehaviorSubject('');
  public wikiCat :BehaviorSubject<any> = new BehaviorSubject('');

  lessonArr = [];
  lessonProgress: number;

  jwt: any;


  constructor(private http: HttpClient, private apiService: ApiService) { }


  private buttonThemeSource = new BehaviorSubject<any>({ button_back_color: '#000', button_text_color: '#fff'});
  currentButtonTheme = this.buttonThemeSource.asObservable();
  changeButtonThemeSource(headUname: any) {
    this.buttonThemeSource.next(headUname);
  }


   // tslint:disable-next-line: member-ordering
   private langSource = new BehaviorSubject<any>('ger');
   // tslint:disable-next-line: member-ordering
   currentLang = this.langSource.asObservable();
   changeAppLag(message) {
     this.langSource.next(message);
   }
   
  private headerUserNameSource = new BehaviorSubject<any>('');
  currentHeaderUserName = this.headerUserNameSource.asObservable();
  changeHeaderUserName(headname: any) {
    this.headerUserNameSource.next(headname);
  }

  private contanerIdSource = new BehaviorSubject<any>('0');
  currentContainerId = this.contanerIdSource.asObservable();
  changeContainerId(cId: any) {
    this.contanerIdSource.next(cId);
  }

  private articleIdSource = new BehaviorSubject<any>('');
  currentArticleId = this.articleIdSource.asObservable();
  changeArticleId(aId: any) {
    this.articleIdSource.next(aId);
  }


  private profileImgNameSource = new BehaviorSubject<any>('');
  currentProfileImg = this.profileImgNameSource.asObservable();
  changeProfileImgName(message) {
    this.profileImgNameSource.next(message);
  }



  private notificationIdSource = new BehaviorSubject<any>(["0"])
  currentNotificationId = this.notificationIdSource.asObservable()
  changeNotificationId(message) {
    this.notificationIdSource.next(message);
  }

  private pollSource = new BehaviorSubject<string>("default-pollId")
  currentPollId = this.pollSource.asObservable()
  changePollId(message: string) {
    this.pollSource.next(message);
  }

  private messageSource = new BehaviorSubject<string>("default-module")
  currentMessage = this.messageSource.asObservable()
  changeMessage(message: string, lesson?: string, currentModule?: string) {
    const param = {
      message: message,
      lesson: lesson,
      currentModule: currentModule
    }
    this.messageSource.next(message);
  }

  // tslint:disable-next-line: member-ordering
  private lessonSource = new BehaviorSubject<string>('default-lesson');
  // tslint:disable-next-line: member-ordering
  currentLessonSlug = this.lessonSource.asObservable();
  changeLessonSlug(lessonSlug: any) {
    this.lessonSource.next(lessonSlug);
  }

  private updateNotificationSource = new BehaviorSubject<any>("0")
  currentNotificationCounter = this.updateNotificationSource.asObservable()
  changeNotificationCounter(message: any) {
    this.updateNotificationSource.next(message)
  }

  private containerregisterLinkSource = new BehaviorSubject<any>('0');
  currentShowRegisterLinkVal = this.containerregisterLinkSource.asObservable();
  changeShowRegisterLink(cId: any) {
    this.containerregisterLinkSource.next(cId);
  }



  private generalChatSource = new BehaviorSubject<any>(false)
  currentGeneralChatId = this.generalChatSource.asObservable()
  changeGeneralChatStatus(message) {
    this.generalChatSource.next(message);
  }

  private userGeneralChatTerms = new BehaviorSubject<any>(false)
  currentUserGeneralChatTerms = this.userGeneralChatTerms.asObservable()
  changeUserGeneralChatTermsSource(msg){
    this.userGeneralChatTerms.next(msg)
  }


  private courseChatActiveTerms = new BehaviorSubject<any>(false)
  currentCourseChatActiveTems = this.courseChatActiveTerms.asObservable()
  changeCourseChatTermsSource(msg){
    this.courseChatActiveTerms.next(msg)
  }

  courseCategory(category_id: number){
    return this.courseCategoryId.next(category_id)
  }

  wikiCategory(Category_Id:any){
    return this.wikiCategoryId.next(Category_Id)
  }

  mediaCategory(category_id:any){    
    return this.mediaCategoryId.next(category_id)
  }

  verifySiteDomain(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getContainer(), data, { headers: this.apiService.setHeaderVal() })
  }

















}
