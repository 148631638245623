// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/***************************/
/*  For Local Environment  */
/***************************/

export const environment = {
  production: false,
  SOCKET_ENDPOINT: 'https://localhost:4000',
  application_version : '1.6.4',

  // ........................weberrescueacademy......................
  //BASE_API_URL: 'https://admin.weberrescueacademy.com/api/',
  //BASE_SITE_URL: 'https://admin.weberrescueacademy.com/',


// .....................Local set up................................
    // BASE_API_URL: 'http://www.angularphp.com:8090/api/',
    // BASE_SITE_URL: 'http://www.angularphp.com:8090/',


  // BASE_API_URL: 'http://localhost/tokyo/api/',
  // BASE_SITE_URL: 'http://localhost/tokyo/',

//..................................................................


  // BASE_API_URL: 'http://devdemo.pro/tel-library/lms/api/',
  // BASE_SITE_URL: 'http://devdemo.pro/tel-library/lms/',





   //BASE_API_URL: 'https://admin-development.retteracademy.com/api/',
  // BASE_SITE_URL: 'https://admin-development.retteracademy.com/',

  //BASE_API_URL: 'http://devdemo.pro/tel-library/lms/api/',
  //BASE_SITE_URL: 'http://lms.devdemo.pro/',



 //  BASE_API_URL: 'https://admin.retteracademy.com/api/',
 //  BASE_SITE_URL: 'https://admin.retteracademy.com/',



  DASHBOARD_ROUTE: 'dashboard',
  LOGIN_ROUTE: 'login',

  // BASE_SITE_DOMAIN: 'www.weberrescueacademy.com',

  // BASE_SITE_DOMAIN: 'lts.retteracademy.com',

 // BASE_SITE_DOMAIN: 'heitersheim.retteracademy.com',
  //  BASE_SITE_DOMAIN: 'www-development.retteracademy.com'
  //  BASE_SITE_DOMAIN: 'lts.devdemo.pro'
 //  BASE_SITE_DOMAIN: 'weber.devdemo.pro'

 //BASE_SITE_DOMAIN: 'gueg.retteracademy.com'


  // BASE_SITE_DOMAIN: 'weber.devdemo.pro/frontend',
  // BASE_SITE_DOMAIN: 'weber.devdemo.pro',
  // BASE_SITE_DOMAIN: 'lts.devdemo.pro',
  //  BASE_SITE_DOMAIN: 'devide.devdemo.pro',
 // DEMO_SITE_DOMAIN: 'demoweber.devdemo.pro',


  BASE_SITE_DOMAIN: 'www-testing.retteracademy.com',
  // BASE_SITE_DOMAIN: 'www-testing-weber.retteracademy.com',
  BASE_API_URL: 'https://admin-testing.retteracademy.com/api/',
  BASE_SITE_URL: 'https://admin-testing.retteracademy.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
