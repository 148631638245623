// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  padding-top: 25px;
  min-height: 480px;
  position: relative;
  margin-right: 20px;
}

.leftsidebar {
  z-index: 1;
  position: relative;
  padding: 0 0 15px;
  margin-bottom: 45px;
}
.leftsidebar .btn-sidebar-menu {
  background: #ddd;
  border-radius: 6px;
  color: #2c2c2c;
  font-size: 18px;
  text-align: center;
  padding: 11px 9px 9px;
  height: 50px;
  position: relative;
  border: 0;
  overflow: hidden;
  font-weight: 600;
  font-family: var(--poppins);
  width: 100% !important;
  cursor: pointer;
}
.leftsidebar .btn-sidebar-menu span {
  display: inline-block;
  height: 40px;
  background: #000;
  color: #fff;
  padding: 13px 16px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.leftsidebar .search-field {
  position: relative;
}
.leftsidebar .search-field .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  padding: 10px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #b8b8b8;
}
.leftsidebar .search-field .form-search-filter {
  padding-left: 45px;
  border-color: #edecec;
  height: 46px;
}
.leftsidebar .search-field .form-search-filter::placeholder {
  color: #b8b8b8;
  font-family: var(--poppins);
  font-style: italic;
  font-size: 15px;
  font-weight: 400;
}
.leftsidebar .btn {
  font-family: var(--calibri);
}
.leftsidebar .btn-default {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #373737;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
  width: 100%;
}
.leftsidebar .btn-default:hover, .leftsidebar .btn-default.active {
  background: #000;
  border: none;
  color: #fff;
  position: relative;
  border: 1px solid #000;
}
.leftsidebar .btn-default.active::after {
  position: absolute;
  content: "";
  width: 30px;
  top: -1px;
  bottom: -1px;
  right: -25px;
  border-radius: 0 28px 28px 0;
  background: #000;
}
.leftsidebar .btn.btn-sidebar {
  background: #f8fbff;
  color: #214a78;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
  border: 1px solid #e4f1ff;
}
.leftsidebar .btn.btn-sidebar:hover, .leftsidebar .btn.btn-sidebar.active {
  background: #458cde;
  color: #fff;
  border-color: #458cde;
}
.leftsidebar .btn.btn-sidebar:hover:before, .leftsidebar .btn.btn-sidebar.active:before {
  content: "";
  position: absolute;
  right: -14px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent #458cde;
  width: 0;
  height: 0;
}
.leftsidebar .category-block {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #373737;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
  width: 100%;
  max-height: 445px;
  overflow-y: auto;
}
.leftsidebar .category-block a {
  background: #e6e6e6;
  color: #404040;
  text-decoration: none;
  padding: 5px 15px;
  display: block;
  margin: 0px 0 6px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leftsidebar .category-block a:hover {
  background: #333;
  color: #fff;
}
.leftsidebar .category-block a.active {
  background: #333;
  color: #fff;
}
.leftsidebar .category-block::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.leftsidebar .category-block::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
.leftsidebar .category-block::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #808080;
}
.leftsidebar .btn.btn-sidebar-help {
  background: #ffeace;
  color: #463a00;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 18px;
  text-align: left;
  border: 1px solid #f7debc;
}
.leftsidebar .btn.btn-sidebar-help:hover, .leftsidebar .btn.btn-sidebar-help.active {
  background: #f5d2a3;
  color: #463a00;
  border-color: #f5d2a3;
}
.leftsidebar .course-progress-panel {
  background: #fff8f2;
  border: 1px solid #ede7e1;
  padding: 10px 15px 15px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}
.leftsidebar .course-progress-panel .progress-header {
  margin-bottom: 10px;
}
.leftsidebar .course-progress-panel .progress-header .title,
.leftsidebar .course-progress-panel .progress-header .count {
  font-size: 14px;
  color: #b79272;
  font-weight: 400;
  display: inline-block;
}
.leftsidebar .course-progress-panel .progress-header .count.right {
  float: right;
}
.leftsidebar .course-progress-panel .progress {
  border-radius: 25px;
  height: 5px;
  background: #ead7c7;
  overflow: hidden;
}
.leftsidebar .course-progress-panel .progress .progress-bar {
  border-radius: 25px;
  height: 5px;
  background: #af9177;
}
.leftsidebar .sidebar-navigation-container ul {
  margin: 0;
  padding: 0;
}
.leftsidebar .sidebar-navigation-container ul li + li {
  border-top: 1px solid #f5f5f5;
}
.leftsidebar .sidebar-navigation-container ul li {
  margin: 0;
  padding: 8px 0;
  list-style: none;
}
.leftsidebar .sidebar-navigation-container ul li h4 {
  margin: 0;
  position: relative;
  cursor: pointer;
  padding: 0 43px 0 5px;
}
.leftsidebar .sidebar-navigation-container ul li h4 .title {
  color: #5a5a5a;
  font-size: 16px;
}
.leftsidebar .sidebar-navigation-container ul li h4 p {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li h4 .collacollapsible {
  display: inline-block;
  position: absolute;
  right: 13px;
  top: 0;
  width: 20px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  margin: auto;
  bottom: 0;
  color: #7f7f7f;
  font-weight: 600;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu {
  border: 0;
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul {
  margin: 0;
  padding: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li {
  margin: 0;
  padding: 8px 0;
  list-style: none;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 {
  margin: 0;
  position: relative;
  padding: 0 43px 8px 38px;
  border-radius: 5px;
  cursor: pointer;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle {
  border: 2px solid #4f4f50;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 11px;
  bottom: 0;
  border-radius: 50%;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle.active {
  border: 2px solid #1f9c3a;
  background: #1f9c3a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 2px;
  top: 1px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 .title {
  color: #343434;
  font-size: 16px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4 p {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active {
  background: #f6f8fb;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active .title {
  color: #0e449a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li h4.active p {
  color: #060606;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu {
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul {
  margin: 0;
  padding: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li {
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 {
  margin: 0;
  position: relative;
  padding: 0 43px 8px 38px;
  border-radius: 5px;
  cursor: pointer;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle {
  border: 0;
  background: #dfe1e0;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 11px;
  bottom: 0;
  border-radius: 50%;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle.active {
  border: 0;
  background: #1f9c3a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .status-circle.active:before {
  content: "\\f121";
  position: relative;
  font-family: var(--flaticon);
  color: #fff;
  font-size: 8px;
  position: absolute;
  left: 4px;
  top: 3px;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 .title {
  color: #343434;
  font-size: 14px;
  font-weight: 600;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4 p {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active {
  background: transparent;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active .title {
  color: #0e449a;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li h4.active p {
  color: #060606;
}
.leftsidebar .sidebar-navigation-container ul li .sidebar-sub-menu ul li .sidebar-child-menu ul li + li h4:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 1px;
  background: #eaeaea;
}

.leftSidebarActive {
  position: fixed;
  z-index: 99;
  left: 0;
  margin-top: 25px;
  display: none;
}
.leftSidebarActive button {
  background-color: #000;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  border: 0 solid #fff;
  border-radius: 0 6px 6px 0;
  padding: 13px 16px;
  box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.05);
}
.leftSidebarActive button:hover {
  background-color: #000;
  color: #fff;
}

#pageContainer.collapse-panel #sidebar-section .leftSidebarActive {
  display: block !important;
}

.submenu {
  border: 2px solid grey;
}
.submenu .item {
  cursor: pointer;
}

@media (max-width: 767px) {
  .sidebar {
    min-height: auto !important;
    margin-right: 0;
  }
  app-instructor-grade-book {
    padding: 0 15px;
    display: block;
  }
}
.terms-small {
  padding-left: 20px;
  padding-right: 20px;
}

.terms-small-two span {
  padding: 0px 10px 2px 10px;
  float: right;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
