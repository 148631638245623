import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class SiteDomainService {
  public domain: string;
  jwt: any;
  notificationIds: any = [];
  notificationCount: any;
  geTUserpost: any;
  private _refreshNeeded$ = new Subject<void>();
  constructor(private http: HttpClient, private apiService: ApiService,  private commonService: CommonService, private userService: UserService) {
    this.domain = environment.BASE_SITE_DOMAIN;
  //  this.domain = 'weber-lms.azurewebsites.net';
  }
  getDomainName() {
    return this.domain;
  }

  getUnreadNotificationCount(data): Observable<any> {
    this.geTUserpost = {
      method: 'getUserData',
      id: this.commonService.getUserId()
    }

    this.userService.getUserData(this.geTUserpost).subscribe(res => {
      
      if (res['jwt_status']) {
        if (res['status']) {
          localStorage.setItem('user_img', res['data'].profile_image_url);
                    
          if (res['data'].active == 0) {
            this.commonService.redirectToLogin();
          }
        }
      } else {
        // this.commonService.openSessionExpireDialog()
      }

    }, error => error);

    this.jwt = this.apiService.getJWT()
    return this.http.post<any>(this.apiService.getApiURL() + this.apiService.getNotification(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  updateNotificationReadStatus(data): Observable<any> {
    this.jwt = this.apiService.getJWT()
    return this.http.post<any>(this.apiService.getApiURL() + this.apiService.getNotification(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getInstituteDomain(detail):Observable<any>{
    this.jwt = this.apiService.getJWT()
    return this.http.post(this.apiService.getApiURL()+ this.apiService.getSwitchDomain(), JSON.stringify(detail),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

}
