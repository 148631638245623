// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wiki-filter {
  padding: 6px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  min-height: 50px;
}
.wiki-filter h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0;
  line-height: 36px;
}
.wiki-filter h4 .badge {
  color: #212529;
  background-color: #ffc107;
  margin-left: 5px;
  font-size: 13px;
  padding: 4px 12px;
}
.wiki-filter .form-group {
  display: inline-flex;
  justify-content: flex-center;
  min-width: 90%;
  margin: 0;
  position: relative;
}
.wiki-filter .form-group .select-small {
  width: auto !important;
}
.wiki-filter .form-group .btn-flex {
  min-width: 90px;
  flex: none;
}
.wiki-filter .form-group .error {
  position: absolute;
  bottom: auto;
  width: 50%;
  left: 0;
  top: 50px;
  text-align: left;
  background: #fffafa;
  padding: 5px 15px;
  color: #c12d2d;
  border: 1px dashed #fba3a3;
  border-radius: 5px;
  z-index: 5;
}
.wiki-filter .form-group .error::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 6px;
  border-color: transparent transparent #c76a6a transparent;
  top: -12px;
}
.wiki-filter .form-group + .form-group {
  margin-left: 15px;
}
.wiki-filter .form-group + .form-button-group {
  margin-left: 15px;
}
.wiki-filter .form-button-group {
  display: inline-block;
  position: relative;
}
.wiki-filter .form-button-group .btn {
  padding: 4px 17px;
  background-color: #000;
  color: #fff;
}
.wiki-filter .form-button-group .btn + .btn {
  margin-left: 15px;
}

h3 {
  margin: 15px 0;
  font-size: 19px;
  font-weight: 600;
}

.card {
  border: 1px solid #eee;
  box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.1);
  height: 260px;
  border-radius: 8px;
  margin: 0 0 20px;
}
.card .wiki-img {
  height: 130px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.card .wiki-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .wiki-desc {
  padding: 12px;
}
.card .wiki-desc h5 {
  margin: 0 0 8px;
  font-size: 17px;
  color: #111;
}
.card .wiki-desc p {
  margin: 0 0 0;
  color: #555;
  line-height: 20px;
}

.cate {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
