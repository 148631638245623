import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-peview-layout',
  templateUrl: './preview-layout.component.html'
})
export class PreviewLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   // localStorage.setItem('panelCollapse' , '1');
  }

}
