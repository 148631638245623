// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changepasswrap .titlewrap h1 {
  margin: 0;
  font-size: 2.1rem;
  color: #000;
  font-weight: 600;
}

.changepasswrap .titlewrap p {
  color: #999999;
  margin: 5px 0 16px;
  font-size: 16px;
}

.changepasswrap .form-group {
  padding: 6px 0;
}

.changepasswrap .btnblack {
  background: #333;
  color: #fff;
}

.infopass-msg {
  color: #e68a00;
  padding-left: 16px;
  font-size: 16px;
}

.infotitle {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.5rem;
}

.flexwrap {
  flex-wrap: wrap;
}

.dialog-container {
  padding: 1rem;
}

.changepasswrap .form-control {
  height: 42px;
}

@media only screen and (min-width: 1024px) {
  .dialog-container {
    width: 850px;
    padding: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .infotitle {
    margin-top: 1rem;
  }
}
.btn-update-black {
  width: 100%;
  margin-top: 15px;
}

.minspace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.minspace .spinner {
  margin: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
