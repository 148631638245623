// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-brief {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border: 1px solid #EDEDED;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin: 0 0 20px;
  cursor: pointer;
}
.list-brief .brief-title {
  width: 88%;
  font-size: 16px;
  font-weight: 600;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-brief .brief-status {
  width: 12%;
  text-align: right;
}
.list-brief .brief-status .badge-sm {
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 30px;
}
.list-brief .brief-status .success {
  background: #B5EFCC;
  color: #1E7D44;
}
.list-brief .brief-status .pending {
  background: #FFC9C9;
  color: #C63232;
}
.list-brief .brief-status i {
  color: #555;
  font-size: 18px;
}

.briefing-title {
  padding: 11px 6px 11px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  font-size: 18px;
  font-weight: 600;
}

.video-library-filter {
  padding: 6px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
}
.video-library-filter h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
