import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { moduleDetail } from './../model/module';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  private _refreshNeeded$ = new Subject<void>();

  apicalled: boolean = false;
  jwt: any;

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getModuleList(data): Observable<moduleDetail[]> {
    return this.http.post<moduleDetail[]>(this.apiService.getApiURL() + this.apiService.getContainer(),
      data, { headers: this.apiService.setHeaderVal() }
    ).pipe(
      tap(() => {
      //  this._refreshNeeded$.next();
      })
    )
  }

  getModuleDEtailPreview(data): Observable<moduleDetail[]> {
    return this.http.post<moduleDetail[]>(this.apiService.getApiURL() + this.apiService.getCoursePreview(),
      data, { headers: this.apiService.setHeaderVal() }
    ).pipe(
      tap(() => {
      //  this._refreshNeeded$.next();
      })
    )
  }

  getAssesmentById(assesmentData) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getAssesment() , JSON.stringify(assesmentData) , 
   { headers: this.apiService.setHeaderValJWT(this.jwt) }  )
  }
}
