import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ApiService } from './../service/api.service';
import { userMessage } from './../model/userMessage';


@Injectable({
  providedIn: 'root'
})
export class UserService {


  jwt: any;
  authtoken: any;

  private _refreshNeeded$ = new Subject<void>();
  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  constructor(private http: HttpClient, private apiService: ApiService) { }

  ngOnInit() {

  }

  // profileImage(filedata) {
  //   return new Promise((resolve, reject) => {
  //     this.jwt = localStorage.getItem('jwt');
  //     this.authtoken = localStorage.getItem('rw_jwt')
  //     console.log(this.jwt);
  //     console.log(this.authtoken);
  //     this.http.post(
  //       this.apiService.getApiURL() + 'Profile', filedata, { headers: this.apiService.setHeaderValJWTForImage(this.jwt, this.authtoken) }
  //     ).subscribe(res => {
  //       resolve(res)
  //     }, err => {
  //       reject(err)
  //     })
  //   });
  // }


  // async profileImage(filedata) {
  //   this.jwt = await localStorage.getItem('jwt');
  //   this.authtoken = await localStorage.getItem('rw_jwt');

  //   let headers = new HttpHeaders();
  //   headers.set('Content-Type', 'multipart/form-data');
  //   headers.append('Accept', 'application/json');
  //   headers.append('X-API-KEY', 'LMSAPI@123#');
  //   headers.append('Token', this.jwt);
  //   headers.append('authtoken', this.authtoken);

  //   console.log(headers);
    
  //   return new Promise((resolve, reject) => {
     
  //     console.log('JWT from local storage:', this.jwt);
  //     console.log('AuthToken from local storage:', this.authtoken);
  
  //     console.log('Headers:', headers);
  
  //     this.http.post(
  //       this.apiService.getApiURL() + 'Profile', 
  //       filedata, 
  //       { headers: headers }
  //     ).subscribe(
  //       (res) => {
  //         console.log('Response:', res);
  //         resolve(res);
  //       },
  //       (err) => {
  //         console.error('Error:', err);
  //         reject(err);
  //       }
  //     );
  //   });
  // }


  profileImage(filedata){
    this.jwt = localStorage.getItem('jwt');
    return  this.http.post(
            this.apiService.getApiURL() + 'Profile', filedata, { headers: this.apiService.setHeaderValJWTForImage(this.jwt) }
          )
  }

  


  profileUpdate(student) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + 'Profile', student, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      )
  }

  getAllStates(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getStateCodeByStateName(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  changeUserPassword(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    )
  }

  getStatewiseCities(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getUserData(student) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + 'Profile', student, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getParentsAssignedStudentList(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getStudent(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  deleteParentRelation(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getStudent(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getUserNotifications(student) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getNotification(), student,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  checkParentEntry(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getParentsData(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getProfile(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getUserMessages(data): Observable<userMessage> {
    this.jwt = this.apiService.getJWT()
    return this.http.post<userMessage>(
      this.apiService.getApiURL() + 'Messages', data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getUserMessageDetail(data): Observable<userMessage> {
    this.jwt = this.apiService.getJWT()
    return this.http.post<userMessage>(
      this.apiService.getApiURL() + 'Messages', data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  updateMessageReadStatus(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + 'Messages', data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getInstructorStudents(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getInstructor(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  sendInstructorNewNotification(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getInstructor(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getInstructorSentNotificationsList(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getInstructor(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getInstructorReceivedNotificationsList(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getInstructor(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getNotificationDetail(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getNotification(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  deleteUserNotification(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getNotification(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getUserCertificate(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getQuiz(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  insertUserCertCodeAttemptData(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getUsers(), data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  registerUser(data) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getUserRegistration(), data, { headers: this.apiService.setHeaderVal() }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  setPrivacyPolicySession(data) {
    return this.http.post<any>(this.apiService.getApiURL() + this.apiService.getContainer(), JSON.stringify(data),
      { headers: this.apiService.setHeaderVal() }
    )
  }

  getContainerRegistrationTextData(data) {
    return this.http.post<any>(this.apiService.getApiURL() + this.apiService.getContainer(), JSON.stringify(data), { headers: this.apiService.setHeaderVal() })
  }


  getUserFromCentralDB(data){
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLoginRegister(),data, {headers : this.apiService.setHeaderVal() })
  }


}
