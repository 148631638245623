import { Component, OnInit, SimpleChange } from '@angular/core';
import * as SimplePeer from 'simple-peer/simplepeer.min';
import { Router  , ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-screen-share',
  templateUrl: './screen-share.component.html',
  styleUrls: ['./screen-share.component.scss']
})
export class ScreenShareComponent implements OnInit {
  private peer: any;
  peerIdShare: string;
  peerId: string;
  public lazyStream: any;
  currentPeer: any;
  private peerList: Array<any> = [];
  urlParam: any;

  constructor(private _router: Router , private _route:ActivatedRoute) { 
    this.urlParam = this._route.snapshot.params['val']
    console.log(this.urlParam , 'param')
  }

  ngOnInit() {
    this.media()
   // setTimeout(() => {
    this.peerData()
    //},2000)
  }

  init(){
    document.querySelector('#connect').addEventListener('click', (e) => {
      e.preventDefault()
      let answer = (document.querySelector('#answer') as HTMLInputElement).value
      this.peer.signal(JSON.parse(answer))
  })

  this.peer.on('connect' , () => {
    console.log('connected !')
  })

  this.peer.on('stream', (stream) => {
    console.log(stream , 'stream returned')
  })

  }

  media(){
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {
      console.log(stream,'sttt')
      this.goToStream(stream)
      // call.answer(stream);
      // call.on('stream', (remoteStream) => {
      //   console.log(remoteStream , 'remoteScreen')
      //   if (!this.peerList.includes(call.peer)) {
      //     this.streamRemoteVideo(remoteStream);
      //     this.currentPeer = call.peerConnection;
      //     this.peerList.push(call.peer);
      //   }
      // });
    }).catch(err => {
      console.log(err + 'Unable to get media');
    });
  }

  goToStream(stream){
    this.lazyStream = stream;
    console.log(this.lazyStream , 'lazyStream')
    this.peer = new SimplePeer({
      initiator:(this.urlParam === 'init'),
      trickle:false,
      streams:stream
    })
     console.log(this.peer)
  }

  peerData(){
      //setTimeout(() => {
        this.peer = new SimplePeer({
          initiator:(this.urlParam === 'init'),
          trickle:false,
          streams:this.lazyStream
        })
         console.log(this.peer)
      //},500)
      
      this.peer.on('signal' , (data) => {
       document.querySelector('#offer').textContent = JSON.stringify(data)
      })
      // this.init()
  }

  connectWithPeer(){}

  screenShare(){}

}
