import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { ApiService } from "./../../../app/service/api.service";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LiveChatService {
  private _refreshNeeded$ = new Subject<void>();
  jwt;
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getChatRoomActiveStatus(data) {
    this.jwt = this.apiService.getJWT();
    console.log(this.jwt , 'jwt in service chat')
    return this.http
      .post(
        this.apiService.getApiURL() + this.apiService.getChatSytem(),
        JSON.stringify(data),
        { headers: this.apiService.setHeaderValJWT(this.jwt) }
      ).pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      )
  }

  getUserChatTermsAcceptStatus(data) {
    this.jwt = this.apiService.getJWT();
    return this.http
      .post(
        this.apiService.getApiURL() + this.apiService.getChatSytem(),
        JSON.stringify(data),
        { headers: this.apiService.setHeaderValJWT(this.jwt) }
      ).pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      )
  }

  saveUserChatTerms(data) {
    this.jwt = this.apiService.getJWT();
    return this.http
      .post(
        this.apiService.getApiURL() + this.apiService.getChatSytem(),
        JSON.stringify(data),
        { headers: this.apiService.setHeaderValJWT(this.jwt) }
      ).pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      )
  }

  getChatTerms(data){
    this.jwt = this.apiService.getJWT();
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getChatSytem(),JSON.stringify(data), { headers:this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getCourseSectionRoomName(data){
    this.jwt = this.apiService.getJWT();
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getChatSytem(),JSON.stringify(data), { headers: this.apiService.setHeaderValJWT(this.jwt)}
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )

  }

}
