// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-heading-block {
  padding: 4px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
  min-height: 50px;
}
.panel-heading-block h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 0 7px;
}

.module-document-item {
  margin: 18px 0;
  background: rgba(242, 242, 242, 0.6);
  padding: 10px 20px 10px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.module-document-item a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.module-document-item a img {
  width: 26px;
  margin-right: 8px;
}
.module-document-item a .btn {
  padding: 4px 10px;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  line-height: inherit !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
