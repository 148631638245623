// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.mat-elevation-z8 {
  margin-top: 4%;
  margin-bottom: 5%;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-paginator, .mat-mdc-paginator-page-size .mat-select-trigger {
  color: #000000;
}

.mat-mdc-header-cell {
  color: #000000;
  font-weight: bold;
  font-size: medium;
}

.heading {
  color: #000000;
  font-weight: bold;
  font-size: larger;
}

.alert-success {
  padding: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
