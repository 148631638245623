import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../service/api.service';  
import { Subject , Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LiveStreamApiService {
  jwt:any;
  constructor(private apiService : ApiService , private http: HttpClient) {}
  private _refreshNeeded$ = new Subject<void>();

  createHostRoom(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  verifyIfRoomIsExpired(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  expireHostRoom(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getAllUsers(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  shareRoomLink(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getUserWiseSharedLinkData(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  verifyHostLogin(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLiveStream(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }
}
