import { Component, OnInit, OnDestroy, OnChanges, Input, HostListener } from '@angular/core';
import { QuizService } from './../../service/quiz.service';
import { ActivatedRoute, Data } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { course } from 'src/app/model/course';
import { lesson } from 'src/app/model/lesson';
import { DataService } from './../../service/data.service';
import { CommonService } from './../../service/common.service';
import { Subscription } from 'rxjs';
export enum KEY_CODE {
  ENTER = 13,
  BACKSPACE = 8
}
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy {

  @Input() cykSubmitted: any;
  finishQuizStatus: boolean = false;
  errorMessage: string;
  quizFormDataIndex: any = 0;
  correctAnswerArr: any = [];
  postData: any;
  quesData: any = [];
  totalQuestionCount: any;

  quizQuestionForm: FormGroup;
  quizOption: FormControl;
  quiz_question_id: FormControl;
  quizFormData: Array<{ qId: any, optionId: any }> = [];
  selectedIndex: any;
  radioSel: any;
  quizId: any;
  secondLastQuestionCount: any;

  completeCourseSlug: any;
  completeModuleSlug: any;
  completeLessonSlug: any;

  finalTime: any;
  finishQuizData: any;
  resultArray: any = [];

  sectionSlug: any;
  quizType: any;

  currentQuizIdContainer: Subscription;
  currentModuleSlugContainer: Subscription;
  currentLessonSlugContainer: Subscription;
  currentShowQuizSectionContainer: Subscription;
  currentRadioContainer: Subscription;
  currentCykSubmittedContainer: Subscription;

  cykSubmittedData: any;
  submittedAnswerValues: any = [];

  //........latest-quiz-----------

  allSectionQuestionsCount: any;
  secondLastSectionQuestionCount: any;
  sectionTitle: any;

  questionCount: number = 1
  k: number
  startQuizDisable: boolean = false;
  radioSelectionError: boolean = false
  courseSlug: any;
  moduleSlug: any;
  lessonSlug: any;
  viewBtn: any;

  quizContainerData: any;
  quizContainerSection: any;
  optionVal: any;
  showRestartBtn: any;

  constructor(private quizService: QuizService, private fb: FormBuilder, private route: ActivatedRoute,
    private dataService: DataService, private commonService: CommonService) {


    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId')
    this.currentRadioContainer = this.quizService.currentRadio.subscribe(radioSelVal => {
      this.radioSel = radioSelVal
    })

    this.currentQuizIdContainer = this.quizService.currentQuizId.subscribe(data => {
   
      this.resetAllContainer()
      this.quizId = data
     
      // if(this.quizId == 0 ){
      //   if(localStorage.getItem('lessonCYK') != null){
      //     this.quizId = localStorage.getItem('lessonCYK') 
      //   }
      // }
     
      if (this.quizId > 0) {
    
        this.currentCykSubmittedContainer = this.quizService.currentQuizSubmittedStatus.subscribe(quizSubStatus => {
          this.cykSubmitted = quizSubStatus['cyk_submitted']

          // setTimeout(() => {
          //   if (this.cykSubmitted) {
          //     this.getCykSubmittedDetails(this.quizId)
          //     this.quizService.quizShowStatus = 2;
          //     this.finalTime = quizSubStatus['cyk_time']
          //   }
          // } , 300)
          
          if (this.cykSubmitted) {
            this.getCykSubmittedDetails(this.quizId)
            this.quizService.quizShowStatus = 2;
            this.finalTime = quizSubStatus['cyk_time']
          }

        })
      }
    })

   // this.currentQuizIdContainer.unsubscribe()


    this.completeCourseSlug = this.route.snapshot.paramMap.get('courseId')
    this.completeModuleSlug = this.route.snapshot.paramMap.get('moduleId')
    this.completeLessonSlug = this.route.snapshot.paramMap.get('lessonId')

    this.courseSlug = this.route.snapshot.paramMap.get('courseId')
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId')
    this.lessonSlug = this.route.snapshot.paramMap.get('lessonId')

    this.currentModuleSlugContainer = this.dataService.currentMessage.subscribe(message => {
      this.completeModuleSlug = message
    })

    this.currentLessonSlugContainer = this.dataService.currentLessonSlug.subscribe(lessonSlug => {
      this.completeLessonSlug = lessonSlug
    })

    this.currentShowQuizSectionContainer = this.quizService.currentShowQuizSection.subscribe(showQuizVal => {
    
      this.quizService.quizShowStatus = showQuizVal
    })
    this.quizService.qns = [];
    this.quizService.qnProgress = 0;
    this.quizService.sectionProgress = 0;
  }

  resetAllContainer() {
    if (this.currentCykSubmittedContainer != undefined) {
      this.currentCykSubmittedContainer.unsubscribe()
    }

  }

  ngOnInit() {

    this.quizService.seconds = 0
    this.quizService.qnProgress = 0

    this.quizOption = this.fb.control('', Validators.required)
    this.quiz_question_id = this.fb.control('', Validators.required)

    this.quizQuestionForm = this.fb.group({
      quizOption: this.quizOption,
      quiz_question_id: this.quiz_question_id
    });
  }
  /**
 * @description Disable Backspace and Enter key for quiz section page 
 * @date 2019-10-16
 * @memberof QuizComponent
 */
  @HostListener('document:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      event.returnValue = false;
      event.preventDefault();
    }
    if (event.keyCode === KEY_CODE.BACKSPACE) {
      event.returnValue = false;
      event.preventDefault();
    }
  }
  /**
   * @description get cyk submitted details
   * @date 2019-09-16
   * @param {*} quizId
   * @memberof QuizComponent
   */
  getCykSubmittedDetails(quizId) {
    this.quizService.resultArray = []
    this.submittedAnswerValues = []

    this.cykSubmittedData = {
      "method": "getLastQuizResult",
      "quiz_id": quizId,
      "user_id": this.commonService.getUserId(),
      "course_slug":this.route.snapshot.paramMap.get('courseId'),
      "section_slug":this.route.snapshot.paramMap.get('sectionId'),
      "module_slug":this.route.snapshot.paramMap.get('moduleId'),
      "lesson_slug":this.route.snapshot.paramMap.get('lessonId'),
    }

    this.quizService.getCykSubmittedDetail(this.cykSubmittedData).subscribe(res => {
      if (res['status']) {
        this.viewBtn = res['view_result_button']
        this.quizService.resultArray = res['data']['questions']
        this.quizService.quizShowStatus = 2
        this.totalQuestionCount = res['data']['allSectionQuestionsCount']
        this.showRestartBtn = res['data']['showRestartBtn']
        this.k = 0;
        for (let i = 0; i < this.quizService.resultArray.length; i++) {
          for (let j = 0; j < this.quizService.resultArray[i]['questions'].length; j++) {
            this.submittedAnswerValues[this.k] = this.quizService.resultArray[i]['questions'][j].correct
            this.k++;
          }
        }

        const search = true;
        this.quizService.correctAnswercount = this.submittedAnswerValues.reduce(function (n, val) {
          return n + (val == search);
        }, 0);

        this.allSectionQuestionsCount = res['data']['allSectionQuestionsCount']
      } else {
        this.quizService.quizShowStatus = 0
      }
    },
      error => error);
  }

  startTimer() {
    this.quizService.timer = setInterval(() => {
      this.quizService.seconds++;
    }, 1000)
  }



  /**
   * @description get start CYK quiz 
   * @date 2019-09-16
   * @memberof QuizComponent
   */
  startQuiz() {
    this.getQuizDetail(this.quizId)
    this.quizService.quizShowStatus = 1

    if (this.quizService.timer != undefined) {
      clearInterval(this.quizService.timer);
    }
    this.startTimer();
    this.quizService.qns = [];
    this.quizService.qnProgress = 0;
    this.quizService.sectionProgress = 0;
    this.finishQuizStatus = false;
    this.questionCount = 1
    this.correctAnswerArr = []
    this.quizService.seconds = 0
    this.quizService.quizFormData = []
    this.quizFormDataIndex = 0

  }


  /**
   * @description get restart CYK quiz
   * @date 2019-09-16
   * @memberof QuizComponent
   */
  restartQuiz() {
    this.questionCount = 1
    this.correctAnswerArr = []
    this.quizService.seconds = 0
    this.quizService.qnProgress = 0
    this.quizService.sectionProgress = 0
    this.quizService.quizFormData = []
    clearInterval(this.quizService.timer);
    this.quizService.quizShowStatus = 0;
    this.quizFormDataIndex = 0
    this.startQuiz()
  }



  /**
   * @description get CYK quiz complete detail
   * @date 2019-09-16
   * @param {*} quizId
   * @memberof QuizComponent
   */
  getQuizDetail(quizId) {
    this.postData = {
      method: "getQuizQuestion",
      quiz_id: quizId,
      user_id: this.commonService.getUserId(),
      section_slug: this.sectionSlug,
      course_slug: this.courseSlug,
      module_slug: this.moduleSlug,
      lesson_slug: this.lessonSlug
    }


    this.quizService.getQuestion(this.postData).subscribe(res => {
      this.quizContainerData = res['data'].questions[0];
      this.quizContainerSection = res['data'];
      if (res['status']) {
        if (res['data']) {
          this.allSectionQuestionsCount = res['data']['allSectionQuestionsCount']
          this.totalQuestionCount = res['data']['questions'][this.quizService.sectionProgress]['total_question']
        } else {
          this.totalQuestionCount = 0
          this.allSectionQuestionsCount = 0
        }

        if (res['data']['questions'] != undefined) {
          this.quizService.qns = res['data']['questions']

          this.sectionTitle = res['data']['questions'][this.quizService.sectionProgress].title

          this.quesData = this.quizService.qns
          this.quizService.resultArray = this.quesData

          this.secondLastQuestionCount = this.totalQuestionCount - 1
          this.secondLastSectionQuestionCount = this.allSectionQuestionsCount - 1
          this.startQuizDisable = false

          if (this.allSectionQuestionsCount == 1) {
            this.finishQuizStatus = true
          }

        }
      } else {
        this.quizService.qns = []
        this.quizService.sectionProgress = 0
        this.quizService.qnProgress = 0
        this.startQuizDisable = false
      }

    },
      (error) => error
    );
  }


  /**
   * @description CYK quiz answer submission process
   * @date 2019-09-16
   * @param {*} questionId
   * @memberof QuizComponent
   */
  answer(questionId: any) {
    this.errorMessage = "";
    let newarray;
    let newarray2;

    this.quizQuestionForm = this.fb.group({
      quizOption: this.quizOption,
      quiz_question_id: this.quiz_question_id
    });

    if (this.quizQuestionForm.value.quizOption == 0) {
      this.errorMessage = "Please select option."
      this.radioSelectionError = true
      return
    } else {
      this.radioSelectionError = false
    }





    this.selectedIndex = this.quizService.quizFormData.findIndex(item => (item.qId === this.quizQuestionForm.value.quiz_question_id) && (item.sectionId == this.quizService.qns[this.quizService.sectionProgress].id));



    if (this.selectedIndex >= 0) {
      this.quizService.quizFormData[this.selectedIndex].qId = this.quizQuestionForm.value.quiz_question_id
      this.quizService.quizFormData[this.selectedIndex].optionId = this.quizQuestionForm.value.quizOption
    } else {
      this.quizService.quizFormData.push({ sectionId: this.quizService.qns[this.quizService.sectionProgress].id, qId: this.quizQuestionForm.value.quiz_question_id, optionId: this.quizQuestionForm.value.quizOption });
    }


    newarray = this.quizService.resultArray[this.quizService.sectionProgress]['questions'].filter(item => item.id === questionId);
    for (let i = 0; i < newarray[0].options.length; i++) {
      if (newarray[0].options[i].id === this.quizQuestionForm.value.quizOption) {
        newarray[0].options[i]['selected_option'] = '1'
      } else {
        newarray[0].options[i]['selected_option'] = '0'
      }
    }

    newarray2 = this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress].options.filter(item => item.selected_option == 1)[0];

    if (newarray2 != undefined) {
      this.radioSelectionError = false
      if (newarray2.selected_option === newarray2.answer_status) {

        this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress]['correct'] = true
        this.quizService.quizFormData[this.quizFormDataIndex]['correct'] = true
      } else {
        this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress]['correct'] = false
        this.quizService.quizFormData[this.quizFormDataIndex]['correct'] = false
      }
      this.questionCount++;
    } else {
      this.errorMessage = "Please select option."
      this.radioSelectionError = true
      return
    }

    this.quizFormDataIndex++;
    // this.correctAnswerArr.push(this.quizService.resultArray[this.quizService.sectionProgress]['questions'][this.quizService.qnProgress]['correct'])

    if (this.quizService.qnProgress == this.secondLastQuestionCount) {

      if (!this.finishQuizStatus) {
        this.quizService.sectionProgress++;
        this.quizService.qnProgress = 0;
        this.totalQuestionCount = this.quizService.qns[this.quizService.sectionProgress]['total_question']
        this.secondLastQuestionCount = this.totalQuestionCount - 1
      }
    } else {
      this.quizService.qnProgress++;
    }



    if (!this.finishQuizStatus) {
      if (this.quizService.sectionProgress == this.quizService.qns.length - 1) {
        if (this.quizService.qnProgress == this.secondLastQuestionCount) {
          this.finishQuizStatus = true
        }
      }
    }

    // this.quizService.qnProgress++;
    const questionkkId = this.quizService.qns[this.quizService.sectionProgress]['questions'][this.quizService.qnProgress].id
    const questionPCount = this.questionCount - 1
    if (this.quizService.quizFormData.hasOwnProperty(questionPCount)) {
      const prevSelectedIndex = this.quizService.quizFormData.findIndex(item => (item.qId === questionkkId) && (item.sectionId == this.quizService.qns[this.quizService.sectionProgress].id));
      this.quizService.changeRadio(this.quizService.quizFormData[prevSelectedIndex].optionId)
      // this.quizService.changeRadio(this.quizService.quizFormData[this.quizService.qnProgress].optionId)
    } else {
      this.quizService.changeRadio('0')
    }
    if (this.quizService.qnProgress == this.allSectionQuestionsCount) {
      clearInterval(this.quizService.timer);
      this.questionCount = 1
      this.quizService.quizShowStatus = 2
    }
    this.optionVal = 0



  }

  /**
   * @description Previous button activity
   * @date 2019-10-09
   * @param {*} questionId
   * @memberof QuizComponent
   */
  previous(questionId) {
    this.finishQuizStatus = false
    this.errorMessage = "";
    this.previousAnswer(questionId)
    if (this.quizService.qnProgress == 0) {
      this.quizService.sectionProgress--
      this.quizService.qnProgress = this.quizService.qns[this.quizService.sectionProgress]['total_question'] - 1
    } else {
      this.quizService.qnProgress--;
    }

    this.quizFormDataIndex--;
    this.questionCount--;
    if (this.quizService.sectionProgress == this.quizService.qns.length - 1) {
      if (this.quizService.qnProgress == this.secondLastQuestionCount) {
        this.finishQuizStatus = true
      }
    }

    this.totalQuestionCount = this.quizService.qns[this.quizService.sectionProgress]['total_question']
    this.secondLastQuestionCount = this.totalQuestionCount - 1
    const questionkkId = this.quizService.qns[this.quizService.sectionProgress]['questions'][this.quizService.qnProgress].id
    const questionPCount = this.questionCount - 1
    if (this.quizService.quizFormData.hasOwnProperty(questionPCount)) {
      const prevSelectedIndex = this.quizService.quizFormData.findIndex(item => (item.qId === questionkkId) && (item.sectionId == this.quizService.qns[this.quizService.sectionProgress].id));
      this.quizService.changeRadio(this.quizService.quizFormData[prevSelectedIndex].optionId)
    } else {
      this.quizService.changeRadio('0')
    }
    this.optionVal = 0
  }


  /**
   * @description Previos button actions settings
   * @date 2019-10-09
   * @param {*} questionId
   * @memberof QuizComponent
   */
  previousAnswer(questionId: any) {
    this.errorMessage = "";
    let newarray;
    let newarray2;
    this.selectedIndex = this.quizService.quizFormData.findIndex(item => item.qId === questionId);
    if (this.selectedIndex >= 0) {
      this.quizService.quizFormData[this.selectedIndex].qId = questionId
      if (this.optionVal > 0) {
        this.quizService.quizFormData[this.selectedIndex].optionId = this.optionVal
      }

    } else {
      if (this.optionVal > 0) {
        this.quizService.quizFormData.push({ sectionId: this.quizService.qns[this.quizService.sectionProgress].id, qId: questionId, optionId: this.quizQuestionForm.value.quizOption });
      }

    }

    newarray = this.quizService.resultArray[this.quizService.sectionProgress]['questions'].filter(item => item.id === questionId);
    for (let i = 0; i < newarray[0].options.length; i++) {

      if (newarray[0].options[i].id === this.optionVal) {
        newarray[0].options[i]['selected_option'] = '1'
      } else {
        newarray[0].options[i]['selected_option'] = '0'
      }
    }

    newarray2 = this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress].options.filter(item => item.selected_option == 1)[0];

    if (newarray2 != undefined) {
      this.radioSelectionError = false
      if (newarray2.selected_option === newarray2.answer_status) {

        this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress]['correct'] = true
        this.quizService.quizFormData[this.quizFormDataIndex]['correct'] = true
      } else {
        this.quizService.resultArray[this.quizService.sectionProgress].questions[this.quizService.qnProgress]['correct'] = false
        this.quizService.quizFormData[this.quizFormDataIndex]['correct'] = false
      }
    } else {
      // this.errorMessage = "Please select option."
      //   this.radioSelectionError = true
      //   return
    }
    //this.correctAnswerArr.push(this.quizService.resultArray[this.quizService.sectionProgress]['questions'][this.quizService.qnProgress]['correct'])


    if (this.quizService.quizFormData.hasOwnProperty(this.quizService.qnProgress)) {
      this.quizService.changeRadio(this.quizService.quizFormData[this.quizService.qnProgress].optionId)
    } else {
      this.quizService.changeRadio('0')
    }

  }


  /**
   * @description finishing CYK quiz process
   * @date 2019-09-16
   * @param {*} courseSlug
   * @param {*} moduleSlug
   * @param {*} lessonSlug
   * @param {*} questionId
   * @memberof QuizComponent
   */
  finishQuiz(courseSlug, questionId) {
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId')
    this.lessonSlug = this.route.snapshot.paramMap.get('lessonId')
    this.finalTime = this.quizService.displayTimeElaspsed()
    this.answer(questionId)

    if (!this.radioSelectionError) {
      this.finishQuizData = {
        id: this.commonService.getUserId(),
        method: 'getSaveCYKResult',
        course_slug: courseSlug,
        module_slug: this.moduleSlug,
        lesson_slug: this.lessonSlug,
        contain_id: this.quizId,
        contain_type: this.commonService.containTypeArr['quiz'],
        reference_type: this.commonService.quizReferenceType,
        reference_id: this.quizId,
        quizFormData: this.quizService.quizFormData,
        quize_type: this.quizService.quizType,
        section_slug: this.sectionSlug,
        quiz_spent_time: this.finalTime,
        gradebook_section_type: this.quizService.quizType
      }


      const search = true;
      // this.quizService.correctAnswercount = this.correctAnswerArr.reduce(function (n, val) {
      //   return n + (val == search);
      // }, 0);
      this.quizService.finishQuiz(this.finishQuizData).subscribe(res => {
        this.viewBtn = res['view_result_button']
        this.getCykSubmittedDetails(this.quizId)
      })
      this.quizService.quizShowStatus = 2
    }

  }

  radioChangehandler(event) {
    this.optionVal = event.target.id;
  }


  /**
   * @description update display status for viewing CYK quiz qustion
   * @date 2019-09-16
   * @memberof QuizComponent
   */
  viewQuestion() {
    this.quizService.quizShowStatus = 3;
  }


  ngOnDestroy() {
    this.correctAnswerArr = []
    this.quizId = null
    this.questionCount = 1
    this.quizService.quizShowStatus = 0;
    this.currentQuizIdContainer.unsubscribe()
    this.currentModuleSlugContainer.unsubscribe()
    this.currentLessonSlugContainer.unsubscribe()
    this.currentShowQuizSectionContainer.unsubscribe()
    this.currentRadioContainer.unsubscribe()
    //this.currentCykSubmittedContainer.unsubscribe()
  }


}
