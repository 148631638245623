import { Injectable } from '@angular/core';
import { ApiService } from './../service/api.service';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class QuizService {
  quizShowStatus: number = 0;

  private _refreshNeeded$ = new Subject<void>();

  jwt: any;

  qns = [];
  seconds: number;
  timer: any;
  qnProgress: number;
  sectionProgress: number = 0;
  radioSelected: any;

  quizType: any;

  resultArray: any = [];

  quizFormData: Array<{ sectionId: any, qId: any, optionId: any }> = [];
  correctAnswercount: any;

  questionCount = 1


  private cykSumittedStatusSource = new BehaviorSubject<any>(false)
  currentQuizSubmittedStatus = this.cykSumittedStatusSource.asObservable()
  changeCykSubmittedStatus(message: any) {
    this.cykSumittedStatusSource.next(message)
  }


  private radioSource = new BehaviorSubject<any>("0")
  currentRadio = this.radioSource.asObservable()
  changeRadio(message: any) {
    this.radioSource.next(message)
  }

  private quizIdSource = new BehaviorSubject<any>("0")
  currentQuizId = this.quizIdSource.asObservable()
  changeQuizId(message: any) {
    this.quizIdSource.next(message)
  }

  private showQuizSectionSource = new BehaviorSubject<any>("0")
  currentShowQuizSection = this.showQuizSectionSource.asObservable()
  changeShowQuizSection(message: number) {
    this.quizIdSource.next(message)
    this.quizShowStatus = message
  }

  constructor(private apiService: ApiService, private http: HttpClient) {

  }

  
  displayTimeElaspsed() {
 // return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds / 60) + ':' + Math.floor(this.seconds % 60)
    const value = Math.abs(this.seconds);
    const days = Math.floor(value / 1440);
    const hours = Math.floor((value - (days * 1440)) / 3600);
    const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
    const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
    return `${this.seconds < 0 ? '-':''}${days > 0 ? days + '.':''}${hours < 10 ? '0' + hours:hours}:${min < 10 ? '0' + min:min}:${sec < 10 ? '0' + sec:sec}`
  }


  getQuizIdByLesson(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data, { headers: this.apiService.setHeaderVal() })
  }

  getQuestion(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data, { headers: this.apiService.setHeaderVal() }
    )
  }

  getCykSubmittedDetail(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  getMultipleQuizSubmited(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  resetAll() {
    this.seconds = 0
    this.qnProgress = 0
    this.sectionProgress = 0
    this.quizFormData = []
    this.resultArray = []
    clearInterval(this.timer);
    this.changeShowQuizSection(0)
  }


  finishQuiz(data) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }


  getUserCertificate(data) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  finishMultipleQuiz(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  getMultipleQuizView(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderVal() }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getVarifyQuizPassword(data) {
    return this.http.post(this.apiService.getApiURL() + this.apiService.getQuiz(), data,
      { headers: this.apiService.setHeaderVal() }
    )
  }




}
