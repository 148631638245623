// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brief-detail-wrap {
  width: 100%;
  padding: 20px;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  margin-bottom: 30px;
}
.brief-detail-wrap h3 {
  margin-bottom: 20px;
}
.brief-detail-wrap p {
  color: #848484;
}

.briefing-title {
  padding: 11px 6px 11px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  font-size: 18px;
  font-weight: 600;
}

canvas {
  display: block;
  box-shadow: 0 0 5px 1px #999;
  margin: 2em auto;
}

@media screen and (max-width: 480px) {
  canvas {
    width: 100% !important;
    height: 250px !important;
  }
  .mandatory {
    margin-left: 0 !important;
    text-align: left !important;
  }
  .mb-col {
    flex-direction: column;
  }
}
.mandatory {
  display: block;
  margin-left: 15rem;
  text-align: center;
  font-size: 16px;
  color: red;
}

.video-library-filter {
  padding: 6px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
}
.video-library-filter h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
