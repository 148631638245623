import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  jwt: any;
  getVideoUploadPath = this.apiService.getCourseImgPath();
  constructor(private apiService: ApiService, private http: HttpClient,) { }

  getImgIcon(file , fileType){
    let path:String;
    const iconDestination = '/assets/images/file_format_icons/';

    switch(fileType){
      case 'mp4':
        path = this.getVideoUploadPath + 'media_images/' + file;
        break;

      case 'pdf':
        path = iconDestination + 'pdf.png';
        break;

      case 'doc':
        path = iconDestination + 'word_doc.png';
        break;

      case 'docx':
        path = iconDestination + 'word_doc.png';
        break;

      case 'xlsx':
        path = iconDestination + 'excel_green.png';
        break;

      case 'ppt':
        path = iconDestination + 'ppt_icon.png';
        break;

      case 'pptx':
        path = iconDestination + 'ppt_icon.png';
        break;
    }
    return path;
  }

  getVideoListData(videoData) {
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getVideo() , videoData, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

  getfileType(data:any){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getVideo() ,data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }





}
