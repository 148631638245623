import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../service/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from './../../service/common.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';



@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  browserLang: string;
  // currentLangContainer: Subscription;
  langChanged: any;
  formFillError: boolean = false;
  messageStatus: boolean = false;
  msgResponse: string;
  msghide : boolean = false ; 
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private formBuilder: FormBuilder, private commonService: CommonService, private dataService: DataService) {
    // this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
    //   this.langChanged = message;
    // }, error => error);
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }
  forgetPasswordForm: FormGroup;
  email: FormControl;
  public message: string;
  submitted = false;
  method: string;
  recaptcha: any;
  rcres: any;
  dataStatus: boolean = false
  successMsg: boolean = false

  siteDetail: any;

  formHidden: boolean = false;
  isLoading = false;
  language: string;
  domain : string ; 

  ngOnInit() {
    this.language = localStorage.getItem('locale')
    this.siteDetail = JSON.parse(localStorage.getItem('theme_style'));
    this.domain = `https://${environment.BASE_SITE_DOMAIN}`;
    this.email = this.formBuilder.control('', [Validators.required, Validators.email]);
    this.forgetPasswordForm = this.formBuilder.group({
      username: this.email,
      domain: this.domain,
      container_id: this.commonService.getDomainContainerId(),
      method: 'forgotPassword',
      language: this.language
    });

  }

  getLangVariable() {
    if (localStorage.getItem('locale')) {
      this.browserLang = localStorage.getItem('locale');
    } else {
      this.browserLang = 'ger'
    }
    return this.browserLang
  }

  // onloadCallback = function () {
  //   grecaptcha.render('google_recaptcha', {
  //   });
  // };


  /**
   * @description Verify correct user if present in database and send password reset link to user email
   * @date 2019-09-16
   * @memberof ForgotPasswordComponent
   */
  VerifyUser() {
    this.formFillError = false
    this.forgetPasswordForm.value.lang = this.getLangVariable();
    if (this.forgetPasswordForm.status == "VALID") {
      this.isLoading = true;
      this.submitted = true;
      this.authService.verifyUser(this.forgetPasswordForm.value).subscribe(verifyResponse => {
        console.log(verifyResponse);
        if (verifyResponse['status'] == true) {
          this.forgetPasswordForm.reset()
          this.formHidden = true;
          this.messageStatus = true ;
          this.msgResponse = verifyResponse['message']
          this.msghide = true ; 
        }
        else{
          this.messageStatus = false ;
          this.msgResponse = verifyResponse['message'];
          this.msghide = true ; 
        }
        this.isLoading = false;
      });
    } else {
      this.formFillError = true
      this.successMsg = false
    }
  }

  /**
   * @description : Navigate to login page
   * @date 2019-09-16
   * @memberof ForgotPasswordComponent
   */
  routeToLogin() {
    this.router.navigate(['../login'], { relativeTo: this.route })
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }

}
