import { Component, OnInit, Input } from '@angular/core';

import { PollService } from './../../service/poll.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DataService } from './../../service/data.service';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html'
})
export class PollComponent implements OnInit {

  @Input() poll_id: any;
  constructor(private pollService: PollService, private fb: FormBuilder, private dataService: DataService) {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    this.userId = this.userData.id;
    this.dataService.currentPollId.subscribe(pollId => {
      this.poll_id = pollId;
      this.getPollDetails(this.poll_id);
      this.checkPollSubmited(this.poll_id, this.userId);
    })
    //imp
    this.dataService.changePollId(this.poll_id)

  }

  pollDetails: any = [];
  results: any = [];
  pollQuestionForm: FormGroup;
  pollFormData: any = {};
  userId: any;
  userData: any;

  poll_submited: boolean;

  ngOnInit() {
    this.poll_submited = false;
    this.pollQuestionForm = this.fb.group({
      poll_id: ['', Validators.required],
      option: ['', Validators.required]
    });
    this.getResultCount(this.poll_id);
  }

  /**
   * @description get poll details
   * @date 2019-09-16
   * @param {*} poll
   * @memberof PollComponent
   */
  getPollDetails(poll) {
    this.pollService.getPollByid(poll).subscribe(res => {
      this.pollDetails = res['data'];
    });
  }

  addPoll() {
    // this.pollService.savePollResult(this.pollQuestionForm.value.poll_id, this.userId, this.pollQuestionForm.value.option).subscribe(res => {
    //   this.poll_submited = true;
    //   this.getResultCount(this.pollQuestionForm.value.poll_id);
    // });
  }


  /**
   * @description get poll result count
   * @date 2019-09-16
   * @param {*} poll_id
   * @memberof PollComponent
   */
  getResultCount(poll_id) {
    this.pollService.getPollCount(poll_id).subscribe(res => {
      this.results = res['data'];
    });
  }


  /**
   * @description check if poll is already submitted
   * @date 2019-09-16
   * @param {*} poll_id
   * @param {*} userId
   * @memberof PollComponent
   */
  checkPollSubmited(poll_id, userId) {
    this.pollService.checkPollSubmited(poll_id, userId).subscribe(res => {
      if (res['status'] == true) {
        this.poll_submited = true;
      } else {
        this.poll_submited = false;
      }
    });
  }

}
