import { Injectable , inject, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {
 // basepath: any = environment.BASE_SITE_DOMAIN;
  // tslint:disable-next-line: variable-name
  constructor(private http: HttpClient , @Inject(DOCUMENT) private _document: HTMLDocument  ) {}

  setAppFavicon( basepath: string) {
    this._document.getElementById('appFavicon').setAttribute('href', basepath);
  }
}
