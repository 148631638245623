import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';  
import { Subject , Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { lessonDetail } from './../model/lesson';

@Injectable({
  providedIn: 'root'
})
export class LessonService {
  jwt:any;
  startLessonStatus:boolean = false

  courseMenu:any = [];
  lastCourseModule:any

  constructor(private apiService : ApiService , private http: HttpClient) { 
    
  }
  private _refreshNeeded$ = new Subject<void>();

  getLessonList(data) : Observable<lessonDetail[]>{
    this.jwt = localStorage.getItem('jwt');
    return this.http.post<lessonDetail[]>(this.apiService.getApiURL() + this.apiService.getCourse(),
     data, { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getCompleteLesson(data){
    this.jwt = localStorage.getItem('jwt');
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(),data, {headers: this.apiService.setHeaderValJWT(this.jwt)} 
    ).pipe(
      tap(()=>{
        this._refreshNeeded$.next();
      })
    )
  }


  checkIfLessonAlreadyCompleted(data){
    this.jwt = localStorage.getItem('jwt');
    // tslint:disable-next-line: max-line-length
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), data, {headers: this.apiService.setHeaderValJWT(this.jwt)}
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getLessonsByModuleContainer(data){
    this.jwt = this.apiService.getJWT()
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(),data, 
    { 
      headers: this.apiService.setHeaderValJWT(this.jwt) 
    }
    )
  }

  getAssesmentById(assesmentData) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getAssesment() , JSON.stringify(assesmentData) , 
   { headers: this.apiService.setHeaderValJWT(this.jwt) }  )
  }

  checkNecessaryQuizActivity(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), data,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

   getLessonPreviewList(data){
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCoursePreview(),
     data
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

}
