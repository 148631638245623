import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ChatService } from "../../service/chat.service";
import { CommonService } from "./../../../service/common.service";
// import * as dateFormat from "dateformat";
import { format } from 'date-fns';
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators"
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UserChatTermsComponent } from "src/app/dialogBox/user-chat-terms/user-chat-terms.component";
import { LiveChatService } from "../../service/live-chat.service";
import { DataService } from "src/app/service/data.service";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-general-chat",
  templateUrl: "./general-chat.component.html",
  styleUrls: ["./general-chat.component.scss"],
})
export class GeneralChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("sendMsgInput", { static: false }) sendMsgInput: ElementRef;
  @ViewChild('scrollContainer') scrollbar: ElementRef;
  messageForm: FormGroup;
  msgInput: FormControl;
  sendBtnDisabled: boolean = false;

  chatWindowForm: FormGroup;
  chatMessage: FormControl;

  chatOption: HTMLElement;
  chatData: any = []
  outputContainer: HTMLElement;
  videoChatWindow: HTMLElement;
  getUserName: any;
  //roomName: string = this.commonService.getLMSGeneralRoomName();
  roomName: string;
  roomCount: any;
  getUserId: any;
  currentSocketConnectionContainer: Subscription
  componentDestroyed$: Subject<void> = new Subject()
  historyChatCompoent$: Subject<void> = new Subject()


  datePipe = new DatePipe('en-US'); // Use your own locale
  startPoint: any = 0;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  selector: string = ".chat-window-history";
  scrollLoading: boolean = false
  scrollDisabled: boolean;
  showLoadMore: boolean = true
  dialogRef: any;
  userjoined: boolean = false;
  userJoinLoading: boolean;
  generalUserChatTermsContainer: Subscription;
  adminUrl: any;
  chatRoomId: any;
  permission: boolean = false;


  constructor(
    private chatService: ChatService,
    private formBuilder: FormBuilder,
    private render: Renderer2,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private dialog: MatDialog,
    private liveChatService: LiveChatService,
    private dataService: DataService,
    private apiService: ApiService
  ) {
    this.currentSocketConnectionContainer = this.chatService.currentSocketId.subscribe((socket) => {
      if (socket !== '0') {
        //this.joinRoom()
        // setTimeout(() => {
        //   this.chatService.sendUsername(this.getUserName, this.roomName);
        // }, 1600);
      }
      this.onSocketJoined();
    }, (error => error))

    this.generalUserChatTermsContainer =
      this.dataService.currentUserGeneralChatTerms.subscribe((res) => {
        if (res === undefined || res === '') {
          this.userjoined = false;
        } else {
          this.userjoined = res
        }
        //this.userJoinLoading = false;
      });
  }

  ngOnInit() {
    this.setAdminUrl();
    this.getUserName = this.commonService.getUserName();
    this.getUserId = this.commonService.getUserId()
    this.msgInput = this.formBuilder.control("", [Validators.required]);
    this.chatMessage = this._fb.control("", Validators.required);
    this.messageForm = this.formBuilder.group({
      message: this.msgInput,
    });
    this.chatWindowForm = this._fb.group({
      chatMessage: this.chatMessage,
    });

    this.socketConnection();
    this.init();

    setTimeout(() => {
      this.outputContainer = document.getElementById("output-container");
      this.getInitialAPIData()
    }, 100);
    this.deleteMessagePermission()
  }

  setAdminUrl() {
    const domain: any = this.commonService.getSiteDomain()
    let split: any = domain.split('.')
    if ((split[0] === 'www-development') && (split[1] === 'retteracademy')) {
      this.adminUrl = 'https://admin-development.retteracademy.com/api/'
    } else if ((split[0] === 'www-testing') && (split[1] === 'retteracademy')) {
      this.adminUrl = 'https://admin-testing.retteracademy.com/api/'
    }else if ((split[0] === 'www-testing-weber') && (split[1] === 'retteracademy')) {
      this.adminUrl = 'https://admin-testing.retteracademy.com/api/'
    } else if(split[0] === 'migration-test' && split[1] === 'retteracademy'){
      this.adminUrl = 'https://retteracademy-backend-migration-test.azurewebsites.net/api/'
    }else if(split[0] === 'migration-test' && split[1] === 'weberrescueacademy'){
      this.adminUrl = 'https://retteracademy-backend-migration-test.azurewebsites.net/api/'
    }else if ((split[0] !== 'www-testing') && (split[0] !== 'www-development') && (split[1] === 'retteracademy')) {
      this.adminUrl = 'https://admin.retteracademy.com/api/'
    } else if (split[0] === 'weberrescueacademy' || split[1] === 'weberrescueacademy') {
      this.adminUrl = 'https://admin.weberrescueacademy.com/api/'
    }
    // console.log(this.adminUrl , 'adminur')
  }

  getInitialAPIData() {
    //this.getChatRoomActiveStatus()
    this.getCourseSectionRoomName()
    this.getUserChatTermsAcceptStatus()
    this.outputContainer.scrollTop = this.outputContainer.scrollHeight;

  }


  getCourseSectionRoomName() {
    const data = {
      method: "getCourseSectionRoomName",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId()
    }
    this.liveChatService.getCourseSectionRoomName(data).subscribe((res) => {
      if (res['data'] !== undefined) {
        this.dataService.changeGeneralChatStatus(res['data'])
        this.chatRoomId = res['data'][0].room_id
        this.roomName = res['data'][0].room_name
        this.joinRoom()
      } else {
        this.router.navigate(['dashboard'])
      }
    })
  }

  getUserChatTermsAcceptStatus() {
    this.userJoinLoading = true;
    const data = {
      method: 'getUserChatTermsAcceptStatus',
      roomId: 1,
      userId: this.getUserId,
      containerId: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    }
    this.liveChatService.getUserChatTermsAcceptStatus(data).subscribe((res) => {
      // console.log(res['data']  , 'tempo')
      if (res['data'] === undefined || res['data'] === '') {
        this.userjoined = false;
        this.dataService.changeUserGeneralChatTermsSource(false)
      } else {
        this.userjoined = res['data']
        this.dataService.changeUserGeneralChatTermsSource(res['data'])
      }
      this.userJoinLoading = false;
    })

  }


  getChatRoomActiveStatus() {
    const data = {
      method: 'getChatRoomActiveStatus',
      roomId: 1,
      containerId: this.commonService.getDomainContainerId(),
      id: this.getUserId
    }
    this.liveChatService.getChatRoomActiveStatus(data).subscribe((res) => {
      this.dataService.changeGeneralChatStatus(res['data'])
      if (!res['data']) {
        this.router.navigate(['dashboard'])
      }
    })
  }

  getChatHistoryAndPrepend(startPoint) {
    let data = {
      method: 'getChatHistory',
      id: this.getUserId,
      roomId: this.chatRoomId,
      startPoint,
      containerId: this.commonService.getDomainContainerId()
    }
    this.chatService.getChatHistory(data).pipe(takeUntil(this.componentDestroyed$)).subscribe((msgD: any) => {
      if (msgD['data'] === '' || msgD['data'] === undefined) {
        this.scrollLoading = false
        this.scrollDisabled = false
        return
      }
      let scrolledChat: any = msgD['data'];

      scrolledChat.forEach((m) => {
        this.chatData.unshift(m)
        return
      })
      //this.outputContainer.scrollBy(0,1)
      this.outputContainer.scrollTop = 1
      //this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
      this.scrollLoading = false
      this.scrollDisabled = false
    });

  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getChatHistory(startPoint) {
    let data = {
      method: 'getChatHistory',
      id: this.getUserId,
      roomId: this.chatRoomId,
      startPoint,
      containerId: this.commonService.getDomainContainerId()
    }
    this.chatService.getChatHistory(data).pipe(takeUntil(this.historyChatCompoent$)).subscribe((msgD) => {
      if (msgD['data'] === '' || msgD['data'] === undefined) {
        return
      }
      this.chatData = msgD['data'];
      this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
    });

  }


  getChatHistoryAfterDelete(startPoint) {
    let data = {
      method: 'getChatHistory',
      id: this.getUserId,
      roomId: this.chatRoomId,
      startPoint,
      containerId: this.commonService.getDomainContainerId()
    }
    this.chatService.getChatHistory(data).pipe(takeUntil(this.historyChatCompoent$)).subscribe((msgD) => {
      if (msgD['data'] === '' || msgD['data'] === undefined) {
        return
      }
      this.chatData = msgD['data'];
    });

  }

  socketConnection() {
    this.chatService.connect();
  }

  ngAfterViewInit() {
    this.chatOption = document.getElementById("chatOption");
    //this.outputContainer = document.getElementById("output-container");
    this.videoChatWindow = document.getElementById("video-chat-window");
    this.videoChatWindow.classList.toggle("open");
  }

  joinRoom() {
    // console.log(this.roomName , 'roomName in join ')
    this.chatService.joinRoom(this.roomName);
  }

  init() {
    //this.openChatTermsDialogBox()
    this.newBroadcastMessages();
    this.chatService.onClientName().pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
    });
    this.chatService.onSendRoomCount().pipe(takeUntil(this.componentDestroyed$)).subscribe((roomC) => {
      this.roomCount = roomC;
    });
    this.onAppend()
  }

  onSocketJoined() {
    this.chatService.onJoined().subscribe((res) => {
      this.getChatHistory(this.startPoint)
    })
  }

  newBroadcastMessages() {
    this.chatService.onNewBrodcastMsg().pipe(takeUntil(this.componentDestroyed$)).subscribe((msgD) => {
      this.chatData = msgD;
      this.outputContainer.innerHTML = "";
      if (this.chatData === '' || this.chatData === undefined) {
        return
      }
      this.chatData.forEach((msgData) => {
        if (msgData.adminMsg) {
          this.outputContainer.innerHTML +=
            '<div class="adminMsg"><div class="message-content"><span>' +
            msgData.dateTime +
            "</span>" +
            msgData.message +
            "</div></div>";
        } else {
          if (msgData.username !== this.getUserName) {
            this.outputContainer.innerHTML +=
              '<div class="message-left"><div class="messgae-user">' +
              msgData.username +
              '</div><div class="message-content"><span>' +
              msgData.dateTime +
              "</span>" +
              msgData.message +
              "</div></div>";
          } else {
            this.outputContainer.innerHTML +=
              '<div class="message-right"><div class="messgae-user">' +
              msgData.username +
              '</div><div class="message-content"><span>' +
              msgData.dateTime +
              "</span>" +
              msgData.message +
              "</div></div>";
          }
        }
      });

      if (!this.videoChatWindow.classList.contains("open")) {
        this.chatOption.classList.add("highlight");
      } else {
        this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
      }
    });
  }


  onAppend() {
    this.chatService.onAppend().pipe(takeUntil(this.componentDestroyed$)).subscribe((msgData: any) => {
      // console.log(msgData , ',msgData in onAppedj')
      let adminMsg: any = (!msgData.adminMsg) ? "0" : "1"
      let tokyo = {
        userId: msgData.id,
        message: msgData.message,
        chat_room: this.roomName,
        room_id: this.chatRoomId,
        adminMsg,
        dateTime: msgData.dateTime,
        username: msgData.username
      }
      this.chatData.push(tokyo)
      setTimeout(() => {
        this.outputContainer.scrollTop = this.outputContainer.scrollHeight
      }, 100)
    })
  }

  closeChatWindowFn(event) {
    this.chatOption.classList.add("off");
    this.videoChatWindow.classList.remove("open");
  }

  // handleKeyUp(e) {
  //   if (e.keyCode === 13) {
  //     let data = e.target.value;
  //     if (data.replaceAll(/\s/g, "") != "") {
  //       this.sendMessage();
  //     } else {
  //       this.chatWindowForm.patchValue({ chatMessage: "" });
  //     }
  //   }
  // }


  handleKeyUp(event: KeyboardEvent) {
    
    const textarea = event.target as HTMLTextAreaElement;
  
    if (event.shiftKey && event.keyCode === 13) {
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const value = textarea.value;
      const endsWithNewline = value.endsWith('\n');
  
      if (!endsWithNewline) {
        textarea.value = value.substring(0, startPos) + '\n' + value.substring(endPos);
      }
  
      textarea.selectionStart = textarea.selectionEnd = startPos + 1;
  
      event.preventDefault();
    } else if (event.keyCode === 13) {
      const data = textarea.value;
      if (data.trim() !== "") {
        this.sendMessage();
      } else {
        this.chatWindowForm.patchValue({ chatMessage: "" });
      }
    }
  }
  
  
  

  chatOptionFn(event) {
    event.target.classList.toggle("off");
    event.target.classList.remove("highlight");
    this.videoChatWindow.classList.toggle("open");
    if (this.videoChatWindow.classList.contains("open")) {
      this.outputContainer.scrollTop = this.outputContainer.scrollHeight;
    }
  }

  sendJoinedMsg() {
    let currentDateTime = new Date();
    let hostMsg = " " + this.getUserName + " has joined";
    this.chatService.sendingMessage2({
      message: hostMsg,
      username: this.getUserName,
      userLogin: this.getUserName,
      dateTime: format(currentDateTime, "MMMM d, yyyy, hh:mm a"),
      roomName: this.roomName,
      roomId: this.chatRoomId,
      adminMsg: true,
      method: "insertChatData",
      id: this.getUserId,
      type: 1,
      jwt: this.apiService.getJWT(),
      adminUrl: this.adminUrl,
      containerId: this.commonService.getDomainContainerId()
    });

  }

  sendMessage() {
    // console.log("sendmessage is called");

    let currentDateTime = new Date();
    this.chatWindowForm.value.chatMessage.trim();
    if (this.chatWindowForm.value.chatMessage.replaceAll(/\s/g, "") == "") {
      this.chatWindowForm.patchValue({ chatMessage: "" });
    }
    if (this.chatWindowForm.value.chatMessage != "") {
      // console.log(this.chatWindowForm.value);
      this.chatService.sendingMessage2({
        message: this.chatMessage.value,
        username: this.getUserName,
        dateTime: format(currentDateTime, "MMMM d, yyyy, hh:mm a"),
        roomName: this.roomName,
        roomId: this.chatRoomId,
        adminMsg: false,
        method: "insertChatData",
        id: this.getUserId,
        type: 1,
        jwt: this.apiService.getJWT(),
        adminUrl: this.adminUrl,
        containerId: this.commonService.getDomainContainerId()
      });
      setTimeout(() => {
        this.getChatHistory(this.startPoint);
      }, 500);
    }
    this.chatMessage.setValue("");    
  }

  ngOnDestroy() {
    this.currentSocketConnectionContainer.unsubscribe()
    this.generalUserChatTermsContainer.unsubscribe()
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
    this.historyChatCompoent$.next()
    this.historyChatCompoent$.complete()
    this.chatService.disconnectSocket();
  }


  onScrollDown(ev: any) {
    //this.sum += 20;
    //this.appendItems();
    //this.direction = "scroll down";
  }

  onScrollUp() {
    this.showLoadMore = (this.outputContainer.scrollTop === 0) ? true : false;
    this.onScroll()
  }

  onScroll(): void {
    const scrollElement: HTMLElement = this.scrollbar.nativeElement;
    // console.log(this.scrollbar.nativeElement.value);
    if(this.startPoint+50 ==  this.chatData.length){
      if (scrollElement.scrollTop === 0) { 
        this.loadMoreData();
      }
    }
  }

  loadMoreData() {
    this.startPoint = this.startPoint + 50
    this.scrollLoading = true
    setTimeout(() => {
      this.getChatHistoryAndPrepend(this.startPoint)
      setTimeout(() => {
        this.outputContainer.scrollTop = 5000
      }, 1300)
    }, 2000)
  }

  openChatTermsDialogBox() {
    let message = 'This meeting has been expired.'
    this.dialogRef = this.dialog.open(UserChatTermsComponent, {
      data: {
        message: message,
        // status: this.actStatus
      }
    })
    this.dialogRef.afterClosed().subscribe(res => {
      if (res == 'true') {
        //this.getActiveRoomData()
      }
    })
  }


  visibleElementwhenScrollToTop() {
    this.showLoadMore = false
    //use reference http://jsfiddle.net/dystroy/gXSLE/
    //Hint : this.outputContainer.scroll
  }


  deleteMessage(id) {
    const postData = {
      "method": "deleteChatByUserId",
      "id": this.commonService.getUserId(),
      "type": "1",
      "container_id": this.commonService.getDomainContainerId(),
      "chat_id": id
    }

    this.chatService.deleteMsg(postData).subscribe(res => {
      this.getChatHistoryAfterDelete(this.startPoint)
    })
  }

  deleteMessagePermission() {
    const postData = {
      "method": "getDeleteChatStatus",
      "id": this.commonService.getUserId()
    }

    this.chatService.deleteMsgPermission(postData).subscribe(res =>{
      if(res['chat_delete_status'] == 0){
        this.permission = false
      }
      else{
        this.permission = true
      }
      
    })
  }

}
