import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getBrowserLanguage(): string {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else if (navigator.language) {
      return navigator.language;
    } else {
      return 'en'; // Default to English if no language is found
    }
  }
}
