import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../../service/common.service';
import { MarketPlaceService } from './../../../service/market-place.service';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {
  purchaseData: any;
  showErrorMsg: boolean = true;
  isLoading: boolean = true;

  constructor(private commonService: CommonService, private marketPlaceService: MarketPlaceService) { }

  ngOnInit() {
    this.getPurchaseHistory();
  }

  getPurchaseHistory() {
    const data = {
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      method: 'purchaseHistory'
    };
    this.marketPlaceService.getPurchaseHistory(data).subscribe(res => {
      this.isLoading = false;
      if (res['jwt_status']) {
        this.purchaseData = res['data'];
        if (this.purchaseData != undefined) {
          this.showErrorMsg = false;
        } else {
          this.showErrorMsg = true;
        }
      } else {
        this.commonService.openSessionExpireDialog();
      }
    });
  }

}
