import { Component, OnDestroy, OnInit , Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService } from './../../service/feedback.service';
import { CommonService} from './../../service/common.service';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-student-feedback',
  templateUrl: './student-feedback.component.html',
  styleUrls: ['./student-feedback.component.scss']
})
export class StudentFeedbackComponent implements OnInit , OnDestroy {
  studentFeedback: any;
  error:Boolean = false
  isLoading: boolean = true

  totalLengthOfPage: any[] = [];
  pagingLimit: number = 20;
  increasCount: number = 0;
  paginationClick: boolean = false;
  initialData: { id: any; method: string; pageNumber: number; container_id : number };
  currentLangContainer: Subscription;
  langVar:any;
 

  constructor(private router:Router , private feedbackService: FeedbackService , private commonService:CommonService , private renderer:Renderer2 , private dataService:DataService) { 
    this.currentLangContainer = this.dataService.currentLang.subscribe(lang => {
      this.langVar = lang
    }, error => error);
  }

  ngOnInit() {
    localStorage.setItem('studentFeedbackPaginationCounter', '0');
    this.initialData = {
      id: this.commonService.getUserId(),
      method: 'getStudentWiseFeedback',
      pageNumber: 0,
      container_id: this.commonService.getDomainContainerId() 
    }
    this.getStudentWiseFeedback(this.initialData , 0);
  }

  createFeedback(){
    this.router.navigate(['create-feedback'])
  }

  getStudentWiseFeedback(data, pageCount = 0){
    this.totalLengthOfPage = [];
    this.increasCount = 0;
    if (pageCount > 0) {
      data.pageNumber = pageCount;
    }

    this.feedbackService.getStdentWiseFeedback(data).subscribe((res) => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog()
      } 
      this.studentFeedback = res['data']
      if(this.studentFeedback.length == 0){
        this.error = true
      }
      const getTotalCount = parseInt(res['recordsCount']);
      const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);
      for (let i = 1; i <= roundOffCount; i++) {
        const pageItenData = {
          pageItem: this.increasCount
        }
        this.totalLengthOfPage.push(pageItenData);
        this.increasCount = this.increasCount + 20;
      }
      setTimeout(() => {
        const getPagingId = localStorage.getItem('studentFeedbackPaginationCounter');
        let pagingElement: any = '';
        pagingElement = document.getElementById('paging_link_' + getPagingId);
        if (pagingElement != null) {
          this.renderer.addClass(pagingElement, 'active');
        }
      }, 100);
      this.isLoading = false
    })
    this.paginationClick = false;
  }

  
  getDataById(getCurrentPageCount: any) {
    this.isLoading = true
    this.paginationClick = true;
    const formPostData: any = {
      id: this.commonService.getUserId(),
      method: 'getStudentWiseFeedback',
      pageNumber: 0,
      container_id: this.commonService.getDomainContainerId() 
    }
    this.getStudentWiseFeedback(formPostData, getCurrentPageCount)
    this.setCounterLink(getCurrentPageCount)
  }

  setCounterLink(counterId: any) {
    localStorage.setItem('studentFeedbackPaginationCounter', counterId);
  }

  ngOnDestroy() {
    localStorage.removeItem('studentFeedbackPaginationCounter');
    this.currentLangContainer.unsubscribe();
  }

}
