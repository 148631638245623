// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardRight {
  padding-top: 25px;
}

.notification-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.notification-list ul li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  position: relative;
  display: flex;
  border: 1px solid #efefef;
  padding: 7px;
  border-radius: 4px;
}
.notification-list ul li .noti-icon {
  color: #7d7d7d;
  padding: 10px 20px;
  margin: 0 15px 0 0;
  font-size: 28px;
  background: #f3f3f3;
  border-radius: 4px;
}
.notification-list ul li .noti-detail {
  width: 100%;
}
.notification-list ul li .noti-detail h3 {
  margin: 0;
  padding: 0 200px 0 0;
  font-size: 16px;
  color: #2f5784;
  position: relative;
  line-height: 22px;
}
.notification-list ul li .noti-detail h3 a {
  color: #3e4348;
  cursor: default;
}
.notification-list ul li .noti-detail h3 span {
  font-size: 13px;
  color: #7d7d7d;
  font-weight: normal;
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 15px;
}
.notification-list ul li .noti-detail p {
  margin: 8px 0 0;
  padding: 0;
  font-size: 13px;
  font-family: var(--poppins);
  line-height: 22px;
  text-align: justify;
}
.notification-list ul li.unread {
  border: 1px solid #c9dbf1;
  box-shadow: 0 4px 4px rgba(169, 189, 212, 0.3);
}
.notification-list ul li.unread .noti-icon {
  background: #458cde;
  color: #fff;
}
.notification-list ul li.unread .noti-detail h3 {
  color: #458cde;
}
.notification-list ul li.unread .noti-detail h3 a {
  color: #458cde;
}

.danger {
  color: #dc3545;
  position: absolute;
  margin-left: 10px;
  cursor: pointer;
  right: 0;
}

.notification-heading {
  padding: 4px 6px 6px 18px;
  border-bottom: 0;
  background: #ddd;
  border-radius: 5px;
  margin-top: 25px !important;
  min-height: 50px;
}
.notification-heading h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 0 7px;
}

@media screen and (max-width: 480px) {
  .notification-list ul li .noti-icon {
    color: #fff;
    padding: 5px;
    font-size: 18px;
    background: #333333;
    width: 36px;
    height: 36px;
    text-align: center;
  }
  .notification-list ul li .noti-detail {
    width: 100%;
  }
  .notification-list ul li .noti-detail h3 {
    margin: 0;
    padding: 0;
  }
  .notification-list ul li .noti-detail h3 a {
    display: block;
  }
  .notification-list ul li .noti-detail h3 span {
    position: relative;
    display: block;
    width: 100%;
  }
  .notification-list ul li .danger {
    top: -18px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
