import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { sitedetail } from './../model/sitedetail';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getSiteDetail(siteAdress): Observable<sitedetail[]> {
    const postData = {
      method: 'get_container_uri',
      domain: siteAdress
    }
    return this.http.post<sitedetail[]>(
      this.apiService.getApiURL() + 'container', postData, { headers: this.apiService.setHeaderVal() }
    )
  }

}
