// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  font-weight: bold;
}

.title {
  color: #000;
  border-bottom: 1px solid #ccc;
  background: #eee;
  padding: 12px;
}

.mat-mdc-form-field {
  width: 100% !important;
}

.feedbackTextInput {
  min-height: 90px;
}

.mat-mdc-unelevated-button.mat-primary {
  background-color: #000000;
}

mat-form-field {
  margin-bottom: -2.5rem;
}
mat-form-field ::ng-deep {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
mat-form-field ::ng-deep .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 0.75em 0 0.75em;
}
mat-form-field ::ng-deep .mat-mdc-form-field-infix {
  padding: 0px 0 !important;
}
mat-form-field ::ng-deep .mdc-line-ripple {
  display: none;
}
mat-form-field ::ng-deep .mat-mdc-select-panel {
  background-color: white;
  padding: 0px !important;
  width: 75%;
  align-items: baseline;
  border-radius: 20px;
  margin: 0;
}
mat-form-field ::ng-deep .mat-mdc-select-trigger {
  background: white;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 15px !important;
}
mat-form-field ::ng-deep .mdc-list-item {
  color: #000 !important;
}
mat-form-field ::ng-deep .mat-mdc-select-value-text {
  font-family: "calibriregular";
  font-size: 15px;
  color: black;
}
mat-form-field ::ng-deep .mat-mdc-form-field-infix {
  background: white;
  margin-top: 0;
}
mat-form-field ::ng-deep .mdc-text-field {
  padding: 0;
}

.mat-mdc-button {
  background-color: #000000;
  color: #fff;
}

.error {
  color: red;
}

.editorBox label {
  width: 100%;
}
.editorBox ckeditor {
  min-height: 100px;
}

.form-error {
  color: red;
}

.p-align {
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
