import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';
import { Subject} from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './../../../service/api.service';

@Injectable({
  providedIn: 'root'
})
export class WikiService {
  logUser = localStorage.getItem('loggedUser');
  jwt;
  wikiApiPath:any = 'Wiki'

  constructor(private http: HttpClient, private apiService: ApiService) { }

  private _refreshNeeded$ = new Subject<void>();

  getArticleCategories(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.wikiApiPath, JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getWikiCategory(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.wikiApiPath, JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getSearchWikiArticle(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.wikiApiPath, JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getArticlesByCategory(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.wikiApiPath, JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getArticleDetail(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.wikiApiPath, JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

}
