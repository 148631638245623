import { Component, OnInit, OnDestroy, input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthService } from './../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteDomainService } from './../../service/sitedomain.service';
import { CommonService } from './../../service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './../../service/data.service';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { UserService } from './../../service/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit, OnDestroy {

  currentBtnThemeContainer: Subscription;
  // currentShowRegistrationLinkContainer: Subscription;
  buttonTheme: any;
  headerUsername: string;
  browserLang: string;
  messageCode: any;
  showRegisterLink: boolean;
  userLogged: any;
  deviceInfo: any = null;
  registrationText: any;
  btn: boolean = false;
  siteDomain : any ; 
  userlanguage : string = ''
  //transObject:any
  constructor(private authService: AuthService, private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private domain: SiteDomainService,
    private commonService: CommonService, public translate: TranslateService, private dataService: DataService, private userService: UserService, private deviceService: DeviceDetectorService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);

  }
  registerForm: FormGroup;
  email: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  terms: FormControl;
  userlang : FormControl;
  method: string;
  public message: string;
  submitted = false;
  isLoading: boolean = false;
  mainisLoading: boolean = true;
  user: any;
  containerId: any;
  formFillError: boolean = false;
  formHidden: boolean = false;
  privacyPolicyCookies: boolean = false;
  language: string;
  firstname: string;
  lastname: string;
  msg: boolean = false;
  messageResponse: string;
  register_msg: string;
  r_msg: boolean = false;
  validEmail : string ;
  validEmailMsg : boolean = false ; 

  public isChecked$ = new BehaviorSubject(false);
  toggleChecked() {
    this.isChecked$.next(!this.isChecked$.value);
  }

  getLangVariable() {
    if (localStorage.getItem('locale')) {
      this.browserLang = localStorage.getItem('locale');
    } else {
      this.browserLang = 'ger'
    }
    return this.browserLang
  }

  ngOnInit() {
    this.language = localStorage.getItem('locale')
    this.getContainerRegistrationTextData();
    //  setTimeout(() => {
    this.userLogged = JSON.parse(localStorage.getItem('loggedUser'))
    if (this.userLogged) {
      this.router.navigate(['dashboard'])
    }
    // this.currentShowRegistrationLinkContainer = this.dataService.currentShowRegisterLinkVal.subscribe(v => {
    //   if (v == 0) {
    //     this.router.navigate(['not-found']);
    //   }
    // }, error => error);
    // this.mainisLoading = false;
    // }, 500)

    this.containerId = this.commonService.getDomainContainerId();
    this.siteDomain = localStorage.getItem('domain');
    this.user = JSON.parse(localStorage.getItem('loggedUser'))

    this.email = this.formBuilder.control('', [Validators.required, Validators.email])
    this.firstName = this.formBuilder.control('', Validators.required)
    this.lastName = this.formBuilder.control('', Validators.required)
    this.userlang = this.formBuilder.control('', Validators.required)
    this.terms = this.formBuilder.control('', Validators.required)

    this.registerForm = this.formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      method: 'registration',
      container_id: this.commonService.getDomainContainerId(),
      terms: this.terms,
      language: this.language,
      domain : `https://${this.siteDomain}`,
      user_language : this.userlang
    });
    console.log("post data", this.registerForm);
    
    const emailInput = document.querySelector('#emailInput') as HTMLInputElement
    emailInput.addEventListener('input', () => {
      const lang = localStorage.getItem('locale')
      const value = emailInput.value;
      // const atIndex = value.indexOf('@');
      console.log("email");

      // const data = {
      //   "email": value,
      //   "method": "checkUserNameCentralDB",
      //   "language": lang
      // }

      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) { // Basic regex for email validation
        console.log("email");

        const data = {
          "email": value,
          "method": "checkUserNameCentralDB",
          "language": lang
        };
        this.userService.getUserFromCentralDB(data).pipe(debounceTime(200),distinctUntilChanged())
        .subscribe((res: any) => {
          console.log(res);
          if (res['status'] == '200') {
            const firstName = res['result'].User.firstName;
            const lastName = res['result'].User.lastName;
            this.registerForm.patchValue({
              firstName: firstName,
              lastName: lastName
            });
            this.msg = true;
            this.btn = true;
            this.validEmailMsg = false ; 
            setTimeout(() => {
              this.msg = false;
            }, 3000);
            // this.messageResponse = res['message']
          }
          else {
            if(res['status'] == 400){
              this.messageCode = 400
              if(localStorage.getItem('locale') == 'en'){
                this.validEmail = 'Invalid email format'
              }else {
                this.validEmail = 'Fehlerhaftes E-Mail-Format'
              }
              this.validEmailMsg = true;
            }
            else if(res['status'] == 404){
              this.messageCode = 404
              // if(localStorage.getItem('locale') == 'en'){
              //   this.validEmail = 'Email is not available.'
              // }else {
              //   this.validEmail = 'Email ist nicht verfügbar. '
              // }
              this.validEmailMsg = false;
            }
            this.msg = false ; 
            this.btn = false ;
            setTimeout(() => {
              this.validEmailMsg = false;
            }, 8000);
          }
        })
      }
      // if (atIndex !== -1) {

      // }
    });
  }

  getContainerRegistrationTextData() {
    const postData = {
      container_id: this.commonService.getDomainContainerId(),
      method: 'getContainerRegistrationInfo'
    }
    this.userService.getContainerRegistrationTextData(postData).subscribe((res) => {
      this.dataService.changeShowRegisterLink(res['data'].is_registration);
      this.registrationText = res['data'].registration_info;
      if (res['data'].is_registration == 0) {
        this.routeToNotFound();
      }
      this.mainisLoading = false;
    })
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      // const pattern: RegExp = /^[a-zA-ZäöüÄÖÜß]+[a-zA-Z0-9_.%+-]*@[a-zA-ZäöüÄÖÜß.-]+\.[a-zA-Z]{2,}$/;
      const pattern: RegExp = /^[a-zA-Z0-9äöüÄÖÜß_.%+\-]+@[a-zA-Z0-9äöüÄÖÜß.\-]+\.[a-zA-Z]{2,}$/;

      if (!pattern.test(email)) {
        return { 'invalidEmail': true };
      }
      return null;
    };
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
    // this.currentShowRegistrationLinkContainer.unsubscribe();
  }

  // onloadCallback = function () {
  //   grecaptcha.render('google_recaptcha', {});
  // };

  registerUser(event) {
    this.messageCode = null
    this.formFillError = false
    this.registerForm.value.lang = this.getLangVariable();
    const postData = {
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName,
      email: this.registerForm.value.email,
      method: this.registerForm.value.method,
      container_id: this.registerForm.value.container_id,
      terms: this.registerForm.value.terms,
      language : localStorage.getItem('locale'),
      domain :  `https://${this.siteDomain}`,
      user_language : this.registerForm.value.user_language
    }


    console.log('send data', postData);
    
    const jsonstr = btoa(JSON.stringify(postData));
    console.log(this.registerForm);

    if (this.registerForm.status == 'VALID') {
      event.preventDefault();
      this.submitted = true;
      this.isLoading = true;
      this.message = '';
      this.userService.registerUser(postData).subscribe((res) => {
        this.messageCode = res['messageCode'];
        setTimeout(() => {
          this.messageCode = null
        }, 8000);
        this.isLoading = false;
        if (res['status']) {
          this.setPrivacyPolicyParam(this.registerForm.value.terms, res['user_id'])
          this.registerForm.reset();
          // this.formHidden = true;
        }else if(res['messageCode'] == 1){
          this.validEmailMsg = true ; 
          this.validEmail = res['message'] ;
          setTimeout(() => {
            this.validEmailMsg = false ;     
          }, 8000);
          
        }
         else {
            this.r_msg = true
            this.register_msg = res['msg']
            setTimeout(() => {
              this.r_msg = false
            }, 8000);
          this.isLoading = false;
        }
      })
    } else {
      this.markFormGroupTouched(this.registerForm);
    }
  }

  routeToForgotPassword() {
    this.router.navigate(['/forgot-password'], { relativeTo: this.route })
  }

  routeToNotFound() {
    this.router.navigate(['not-found']);
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  setPrivacyPolicyParam(statusData, userId) {
    const val = (statusData) ? 1 : 0;

    this.deviceInfo = this.deviceService.getDeviceInfo()
    const setDeviceDetails = "browser:" + this.deviceInfo.browser + ",browser_version:" + this.deviceInfo.browser_version + ",device:" + this.deviceInfo.device + ",os:" + this.deviceInfo.os + ",os_version:" + this.deviceInfo.os_version + ",userAgent:" + this.deviceInfo.userAgent;
    const userPolicyData = {
      user_ip: '',
      user_device_address: '',
      user_browser_details: setDeviceDetails,
      id: userId,
      user_id: userId,
      method: "privacy_policy_tracking",
      status: val
    }


    this.userService.setPrivacyPolicySession(userPolicyData).subscribe(
      (response) => {

        if (response['status']) {
          if (response['data'][0]['status'] == 1) {
            this.privacyPolicyCookies = false;
            localStorage.setItem("user_policy", "true")
          }

        }
      }
    )
  }

  redirectToDataPrivacy() {
    this.router.navigate['data-protection'];
  }
}
