import { Component, OnInit } from '@angular/core';
import { UserService } from './../../service/user.service';
import { CommonService } from './../../service/common.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {

  constructor(private userService: UserService, private commonService: CommonService, private router: Router,
    private route: ActivatedRoute) { }
  messagePostData: any;
  list: any;
  messageCount: any;

  ngOnInit() {
    this.getUserMessages()
  }


  /**
   * @description get user messages by user 
   * @date 2019-09-16
   * @memberof MessageListComponent
   */
  getUserMessages() {
    this.messagePostData = {
      id: this.commonService.getUserId(),
      method: 'getMessagesByUserId'
    }
    this.userService.getUserMessages(this.messagePostData).subscribe(res => {
      if (res['status']) {
        this.list = res['list']
        this.messageCount = res['count']
      }

    })
  }


  /**
   * @description  navigate to user message detail page
   * @date 2019-09-16
   * @param {*} event
   * @param {*} id
   * @memberof MessageListComponent
   */
  routeToMessageDetail(event, id) {
    event.preventDefault()
    this.router.navigate(['message-detail/' + id], { relativeTo: this.route })
  }


}
