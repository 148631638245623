import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SharedModule } from "../../shared/shared.module";
import { CardModule,ButtonModule,PlaceholderModule,GridModule,UtilitiesModule } from '@coreui/angular';

@Component({
    selector: 'app-switch-institute',
    standalone: true,
    templateUrl: './switch-institute.component.html',
    styleUrl: './switch-institute.component.scss',
    imports: [MatProgressSpinnerModule, SharedModule, CardModule,ButtonModule,PlaceholderModule,
      GridModule,
      UtilitiesModule]
})
export class SwitchInstituteComponent {
  userId: any;
  containerId: any;
  key: any;
  display : false ; 
  // loginForm: FormGroup;
  // email: FormControl;
  method: string;
  // password: FormControl;
  public message: string;
  submitted = false;
  isLoading: boolean = false;
  user: any;
  wrongUsernamePasswordErr: boolean = false;
  notAllowedLoginErr: boolean = false;
  isPresent: any;
  roleIdRes: any;
  cookie : any ; 
  allowedUserRoles = [
    { id: 11, name: 'Student' },
    // { id: 17, name: 'Host' }
  ];
  constructor(private route: ActivatedRoute, private authService: AuthService, private dataService: DataService,
    private commonService: CommonService, private router: Router) { }

  ngOnInit() {

 
    this.route.queryParams.subscribe(params => {
      this.userId = decodeURIComponent(params['uid'].replace(/\s/g, ''))
      this.containerId = decodeURIComponent(params['cid'].replace(/\s/g, ''));
      this.key = decodeURIComponent(params['sk'].replace(/\s/g, ''));
    });

    let currentURL = window.location.href;
    var newURL = currentURL.split('?')[0];
    history.replaceState(null, null, newURL);
    
    // this.loginUser();
    this.routeToLogin();
  }


  getUserRoleWiseRoutes(role_id) {
    switch (role_id) {
      case this.commonService.userRolesArr['Student']:
        this.router.navigate(['/dashboard'], { relativeTo: this.route })
        break;
      case this.commonService.userRolesArr['Host']:
        this.router.navigate(['/create-room'])
        break;
    }
  }

  getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }




  // getCookie(name) {
  //   var nameEQ = name + "=";
  //   var cookies = document.cookie.split(';');
  //   for (var i = 0; i < cookies.length; i++) {
  //     var cookie = cookies[i];
  //     while (cookie.charAt(0) == ' ') {
  //       cookie = cookie.substring(1, cookie.length);
  //     }
  //     if (cookie.indexOf(nameEQ) == 0) {
  //       return cookie.substring(nameEQ.length, cookie.length);
  //     }
  //   }
  //   return null;
  // }



  loginUser() {
    const detail = {
      method: 'getUserRecordByContainerAndUserId',
      container_id: this.containerId,
      user_id: this.userId,
      secret_key: this.key,
    }
    
    this.authService.autoLogin(detail).subscribe(res => {  
      localStorage.setItem('containerId', this.containerId) 
      let token = localStorage.getItem('jwt') ; 
      if (res['status']) {
        this.roleIdRes = res['data'].role_id
        const roleId = this.roleIdRes
        const presentRoleArr = this.allowedUserRoles.filter(
          item => {
            item.id == roleId ? this.isPresent = true : this.isPresent = false;
            return this.isPresent;
          }
        )
        // Set User Policy Status param in local storage
        if (res['data']['privacy_policy_tracking'] == 1) {
          localStorage.setItem("user_policy", "true")
        } else if (res['data']['privacy_policy_tracking'] == 0) {
          localStorage.setItem("user_policy", "false")
        }

        if(token == res['token']){
          // alert('token same')
        }else{
          // alert('token mismatched');
          localStorage.setItem('jwt', res['token'])
          localStorage.setItem('rw_jwt', this.getCookie('rw_jwt'))          
        }
         
        if (presentRoleArr.length) {
          localStorage.setItem('user_img', res['data'].profile_image_url);
          localStorage.setItem('headerFullName', res['data'].first_name +' ' + res['data'].last_name);
          // alert("after switch  "+res['token'])
          // localStorage.setItem('jwt', res['token'])
          // alert("after set  "+ res['token'])

          localStorage.setItem('loggedUser', JSON.stringify(res['data']));

          if ((res['data'].user_lang == 1) || (res['data'].user_lang == 0)) {
            localStorage.setItem('locale', 'ger');
            this.dataService.changeAppLag('ger');
          } else {
            localStorage.setItem('locale', 'en');
            this.dataService.changeAppLag('en');
          }

          this.authService.setLoggenIn(true)
          this.getUserRoleWiseRoutes(roleId)
        } else {
          //this.message = 'You are not allowed to login.';
          this.notAllowedLoginErr = true;
        }

      } else {
        //this.message = 'Wrong Username and password';
        this.wrongUsernamePasswordErr = true;
      }
    })
  }

  routeToLogin(){
    this.commonService.redirectToLogin();
  }
}
