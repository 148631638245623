import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../../service/common.service';
import { environment } from './../../../environments/environment';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  userData: any;
  userRole: any;
  dashboradRoute: any;
  url: string;

  constructor(private router: Router , private commonService : CommonService) { }
/**
 * @description Route to Login Page
 * @date 2019-09-16
 * @param {*} event
 * @memberof NotFoundComponent
 */
goToHomeLogin(event){
    event.preventDefault()
    this.url = environment.DASHBOARD_ROUTE
    this.router.navigate([this.url]) ;
  }


  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'))
    if(this.userData != undefined){
      this.userRole = this.userData.role_id 
      this.dashboradRoute = this.commonService.getDashBoardRoutes(this.userData.role_id)
    }else{
      this.dashboradRoute = environment.LOGIN_ROUTE
    }   
  }
}
