import {
  Component, OnInit, Renderer2
} from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleService } from './../../../service/module.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { DataService } from './../../../service/data.service';
import { CommonService } from './../../../service/common.service';
import { ApiService } from './../../../service/api.service';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-module-details-preview',
  templateUrl: './module-details-preview.component.html',
  styleUrls: ['./module-details-preview.component.scss']
})
export class ModuleDetailsPreviewComponent implements OnInit {
  moduleId;
  moduleDetail;
  isLoading = true;
  moduleImg: any;
  sitePath: any;
  getInstituteUrl: any;
  readMoreStatus: any;
  charLimit = 400;
  panelBgCol: any;
  panelTxtCol: any;
  showPanelStatus: any;
  moduleGettingStartedLength: any;
  moduleIntroductionLength: any;
  getModuleDocuments: any;
  moduleGettingStartedWords: any;
  quizPanelStatus: boolean;
  moduleListPost: { moduleId: any; method: string; };
  noRecord: boolean;

  constructor(private location: Location, private moduleService: ModuleService, private router: Router, private route: ActivatedRoute,
    private dataService: DataService, private commonService: CommonService, private apiService: ApiService,
    private sanitizer: DomSanitizer, private renderer: Renderer2,private titleService: Title) {
    window.scrollTo(0, 0);
    this.moduleId = atob(this.route.snapshot.paramMap.get('moduleId'));
  }
  
  ngOnInit() {
    this.getModuleList(this.moduleId);
    this.sitePath = this.apiService.getSiteFolderPath();
    this.getInstituteUrl = this.apiService.getApiSitePath()
  }

  toggleContainer(event) {
    event.target.parentElement.parentElement.classList.toggle('open');
    const parentElm = event.target.parentElement.parentElement;
    if (parentElm.className === 'col-sm-12 img-content pl-0 open') {
      event.target.innerText = "Less";
      this.charLimit = 5000000;
    } else {
      event.target.innerText = "Read More";
      this.charLimit = 400;
    }

  }

  togglePanel(togglePanelElement: HTMLElement, event) {
    const getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }


  }

  resetPanelCollapse() {
    setTimeout(() => {
      const getHeaderPan = document.querySelectorAll('.pc-header');
      const getContentPan = document.querySelectorAll('.pc-content');
      for (let i = 0; i < getHeaderPan.length; i++) {
        this.renderer.addClass(getHeaderPan[i], 'active');
        this.renderer.addClass(getContentPan[i], 'open');
        this.renderer.setStyle(getContentPan[i], 'height', 'auto');
      }
    }, 200);
  }

  getModuleList(mId) {
    this.moduleListPost = {
      moduleId: mId,
      method: 'getModuleBySlug'
    };
    this.isLoading = true;
    this.moduleService.getModuleDEtailPreview(this.moduleListPost).subscribe(res => {
      this.isLoading = false;
      if (res['status']) {
        this.noRecord = false;
        this.moduleDetail = res['data'];
        this.setFavicon(this.moduleDetail.name);
        // getting started Section Img
        this.moduleGettingStartedLength = (this.moduleDetail.getting_started.match(/img/g) || []).length;
        this.moduleIntroductionLength = (this.moduleDetail.introduction.match(/img/g) || []).length;
        this.getModuleDocuments = res['data']['documents']
        if (this.moduleDetail != undefined) {
          // this.showPanelStatus = (this.moduleDetail['is_open_text'] == 1) ? false : true
          this.resetPanelCollapse();
          //read more
          this.moduleGettingStartedWords = this.moduleDetail.getting_started.split('').length;
          if (this.moduleGettingStartedWords > 400) {
            this.readMoreStatus = true;
          } else {
            this.readMoreStatus = false;
          }
          this.moduleImg = this.moduleDetail.module_img != 'undefined' ? this.moduleDetail.module_img : null;
          this.isLoading = false;
        }
      } else {
        this.noRecord = true;
      }
    });
  }

  refresh() {
    this.ngOnInit();
  }

  setFavicon(tabName) {
    this.titleService.setTitle(tabName);
  }


}
