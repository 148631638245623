import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { GradeBookService } from '../../service/gradebook.service';
import { CommonService } from '../../service/common.service';
import { SiteDomainService } from './../../service/sitedomain.service';


@Component({
  selector: 'app-grade-book',
  templateUrl: './grade-book.component.html',
  styleUrls: ['./grade-book.component.scss']
})
export class GradeBookComponent implements OnInit, OnDestroy {

  noGradebookFound:boolean;

  gradeBookCYK: any[] = [];
  cykGradeArray: any[] = [];
  cykGradeCount: any;
  cykGradeWeight: any;
  cykCurrentTotalGrade: any;
  cykFinalTotalGrade: any;

  gradeBookModule: any[] = [];
  mqsCurrentTotalGrade: any;
  mqsFinalTotalGrade: any;

  gradeBookEvidence: any[] = [];
  esCurrentTotalGrade: any;
  esFinalTotalGrade: any;

  gradeBookMidTerm: any[] = [];
  mtCurrentTotalGrade: any;
  mtFinalTotalGrade: any;

  gradeBookFinalTerm: any[] = [];
  ftCurrentTotalGrade: any;
  ftFinalTotalGrade: any;

  gradsummary: any;
  stdSectionAvg: any[] = [];

  gradSumCurrentTotal: any;
  gradSumFinalTotal: any;

  //-------------
  storeCurrectAvg: any = 0;
  inLoopCurrentTotalCount: any = 0;

  storeFinalAvg: any = 0;
  inLoopFinalTotalCount: any;

  totalcurrentGrade: any;
  totalfinalGrade: any;
  isLoading: boolean = true;
  allSectionTypeCurrentGrades: any;
  allSectionTypeFinalGrades: any;
  courseSlug: string;
  sectionSlug: string;
  siteDomain: string;
  gradeBookData: any;


  constructor(private gradeBookService: GradeBookService, private route: ActivatedRoute,
    private commonService: CommonService, private router: Router, private domain: SiteDomainService) {

    // Get All param course, section, sitedomain, slugs
    this.siteDomain = this.domain.getDomainName()
    this.courseSlug = this.route.snapshot.paramMap.get('courseId')
    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId')

    // Create object for api request
    const gradeBookParam = {
      courseSlug: this.courseSlug,
      sectionSlug: this.sectionSlug,
      id: this.commonService.getUserId(),
      method: "getGradebookData"
    }
    // Call api request with param 'gradeBookParam'
    this.gradeBookService.getGradbookData(gradeBookParam).subscribe((res) => {
     // console.log(res)
      this.isLoading = false;
      this.gradeBookData = res['data'];

     // console.log(this.gradeBookData.summary_new , 'gradebook Data')
     
       this.noGradebookFound = (this.gradeBookData.summary_new == null) ? false : true
  
    //   console.log(this.noGradebookFound , 'no')

      this.totalcurrentGrade = this.gradeBookData.current_grade_all
      this.totalfinalGrade = this.gradeBookData.final_grade_all
    })
  }

  ngOnInit() {


  }

  ngOnDestroy() {
    //this.isLoading = true;
  }

  /**
 * @description click function to show student quiz result
 * @method showStudentQuizResult
 * @param event, quizId
 * @memberof GradeBookComponent
 */
  showStudentQuizResult(event, quizId) {
    event.preventDefault()
    const userId = this.commonService.getUserId()
    const url: any = '/courses/' + this.courseSlug + '/' + this.sectionSlug + '/' + quizId + '/' + userId + '/gradebookquizresult';
    window.open(url, '_blank');
  }

}
