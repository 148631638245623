import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { QuizService } from './quiz.service';

import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';
import { SessionExpiredComponent } from '../dialogBox/session-expired/session-expired.component';
import { ApiService } from './api.service';
import { CourseSectionExpiredComponent } from '../dialogBox/course-section-expired/course-section-expired.component';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { MethodService } from './method.service';
import { LogoutConfirmationComponent } from '../dialogBox/logout-confirmation/logout-confirmation.component';
import { LessonAvailableComponent } from '../dialogBox/lesson-available/lesson-available.component';
import { NotPermissionComponent } from '../dialogBox/not-permission/not-permission.component';
import { LogoutReasonComponent } from '../dialogBox/logout-reason/logout-reason.component';
import { ChangePasswordComponent } from '../dialogBox/change-password/change-password.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  userData: any;
  userId: any;
  sectionId: any;

  containerData: any;
  conId: any;
  username: any;
  private isDialogShown: boolean = false;
  private isPermissionBoxShown: boolean = false;

  private logoutpopup: boolean = false;
  private changePassword: boolean = false;

  //Quiz Section..............................

  quizType: any;

  lessonQuizType: any = 'cyk'
  defaultQuizType: any = 'default'

  quizReferenceType: any = '1';
  assessmentReferenceType: any = '2';

  quizTypeArr = {
    CYK: '1',
    Module: '2',
    Evidence: '3',
    Midterm: '4',
    Final: '5',
    Practice: '6',
    Peerceptiv: '7'
  }




  //contain type................................

  containTypeArr = {
    quiz: '1',
    assessment: '2',
    poll: '3',
    grossary: '4',
    flashcard: '5'
  }

  //user roles.............
  userRolesArr = {
    GroupLeader: '5',
    Grader: '6',
    Parents: '7',
    Instructor: '8',
    Student: '11',
    Host: '17'
  }

  lang = {
    en: 'en',
    ger: 'ger'
  };

  langId = {
    ger: '1',
    en: '2'
  }

  getEngFeedBackTopic: any = [
    {
      "id": "1",
      "name": "General"
    },
    {
      "id": "2",
      "name": "Course"
    }
  ];

  getGerFeedBackTopic: any = [
    {
      "id": "1",
      "name": "Allgemein"
    },
    {
      "id": "2",
      "name": "Kurs"
    }
  ];


  dialogRef: any;
  jwt: any;

  private containerIdSource = new BehaviorSubject<any>(0)
  currentContainerId = this.containerIdSource.asObservable()
  dashboardValue: string;



  changeContainerId(containerId: string) {
    this.containerIdSource.next(containerId);
  }

  constructor(private dataService: DataService, private router: Router, private quizService: QuizService,
    private dialog: MatDialog, private apiServer: ApiService, private http: HttpClient, private methodService: MethodService) {

  }

  getRescueWorldLMSCode() {
    return '**LMSRES2020RTRWBR';
  }

  allowedLiveStreamTestingUsers = [
    'shaktiman420at@gmail.com', 'mohan@yopmail.com', 'leitstelle@rescue-digital.de'
  ]

  allowedLiveStreamTestingHost = [
    'host3@yopmail.com', 'host4@yopmail.com'
  ]


  getDashBoardRoutes(roleId) {
    switch (roleId) {
      case this.userRolesArr['Student']:
        this.dashboardValue = 'dashboard'
        break;
      case this.userRolesArr['Host']:
        this.dashboardValue = 'create-room'
        break;
    }
    return this.dashboardValue;
  }

  getUserId() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userId = this.userData.id;
    } else {
      return '0';
    }
  }

  getCentralUserId() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userId = this.userData.center_user_id;
    } else {
      return '0';
    }
  }

  getSiteDomain() {
    const url = environment.BASE_SITE_DOMAIN;
    return url;
  }



  getStudentGraderId() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userId = this.userData.grader_id;
    } else {
      return '0';
    }
  }


  getUserRole() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userData.role_id;
    } else {
      return '0';
    }
  }


  getUserName() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.username = this.userData.username;
    } else {
      return '0';
    }
  }

  getUserFullName() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userData.first_name + ' ' + this.userData.last_name;
    } else {
      return '';
    }
  }


  getUserLang() {
    this.userData = JSON.parse(localStorage.getItem('loggedUser'));
    if (this.userData) {
      return this.userData.user_lang;
    } else {
      return '1';
    }
  }


  getDomainContainerId() {
    this.containerData = JSON.parse(localStorage.getItem('theme_style'));
    const containerId: any = localStorage.getItem('container_id');
    if (this.containerData) {
      return this.conId = this.containerData.id;
    } else {
      if (containerId > 0) {
        return containerId;
      } else {
        return '0';
      }
      return '0';
    }

  }

  getQuizType(quizT) {
    return quizT == 'cyk' ? '1' : quizT == 'apl' ? '3' : 0;
  }
  // getContainType(containT) {
  //   return containT == 'cyk' ? '1' : quizT == 'apl' ? '3' : 0;
  // }
  getInstituteLogo(logoName: any) {
    return this.apiServer.getSiteLogoPath() + logoName;
  }

  routeToLessonDetail(courseSlug, sectionSlug, moduleSlug, lessonSlug, lessond_id, currentModule?) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/courses/' + courseSlug + '/' + sectionSlug + '/' + moduleSlug + '/' + lessonSlug + '/' + lessond_id]));
    this.callDataService(moduleSlug, lessonSlug, currentModule);
  }

  routeToModuleDetail(courseSlug, sectionSlug, moduleSlug, lessonSlug: null, currentModule?) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/courses/' + courseSlug + '/' + sectionSlug + '/' + moduleSlug]));

    //  this.router.navigate(['/courses/' + courseSlug + '/' + sectionSlug + '/' + moduleSlug]);
    this.callDataService(moduleSlug, lessonSlug, currentModule);
  }

  routeToCourse(courseSlug, sectionSlug, moduleSlug: null, lessonSlug: null, currentModule?) {
    this.router.navigate(['/courses/' + courseSlug + '/' + sectionSlug])
    this.callDataService(moduleSlug, lessonSlug, currentModule)

  }

  callDataService(moduleSlug, lessonSlug, currentModule) {
    localStorage.setItem('lessonId', lessonSlug);
    localStorage.setItem('currentModule', currentModule);
    this.dataService.changeMessage(moduleSlug, lessonSlug, currentModule);
    this.dataService.changeLessonSlug(lessonSlug);
    this.quizService.resetAll();
  }


  // openSessionExpireDialog() {
  //   this.dialogRef = this.dialog.open(SessionExpiredComponent);
  //   this.dialogRef.afterClosed().subscribe(res => {
  //     if (res == 'true') {
  //       this.redirectToLogin();
  //     }
  //   });
  // }

  openSessionExpireDialog() {
    if (!this.isDialogShown) {
      this.isDialogShown = true;
      console.log(this.isDialogShown);
      this.dialogRef = this.dialog.open(SessionExpiredComponent);
      this.dialogRef.afterClosed().subscribe((res: any) => {
        console.log(res);
        console.log(this.isDialogShown);
        this.isDialogShown = false;
        console.log(this.isDialogShown);
        if (res == 'true') {
          if (res) {
            if (this.changePassword) {
              window.location.reload();
            }
            this.redirectToLogin();
          }
        }
      });
    }
  }

  openChangePasswordDialog() {
    console.log("open change password pop here.......");
    if (!this.changePassword) {
      this.changePassword = true;
      console.log(this.isPermissionBoxShown);
      this.dialogRef = this.dialog.open(ChangePasswordComponent,  { disableClose: true });
      this.dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          // this.redirectToLogin();
          console.log(res);

        }
      });
    }

  }

  openLogoutReasonDialog(message?) {
    console.log("openLogoutReasonDialog open here......");
    if (!this.logoutpopup) {
      this.logoutpopup = true;
      console.log(this.logoutpopup);
      this.dialogRef = this.dialog.open(LogoutReasonComponent, {
        disableClose: true,
        data: { message: message }
      });
      this.dialogRef.afterClosed().subscribe((res: any) => {
        console.log(res);
        this.logoutpopup = false;
        if (res) {
          if (this.changePassword) {
            window.location.reload();
          }
          this.redirectToLogin();
        }
      });
    }
  }

  openNotPermissionDialog() {
    if (!this.isPermissionBoxShown) {
      this.isDialogShown = true;
      console.log(this.isPermissionBoxShown);
      this.dialogRef = this.dialog.open(NotPermissionComponent);
      this.dialogRef.afterClosed().subscribe((res: any) => {
        console.log(res);

        if (res) {
          if (this.changePassword) {
            window.location.reload();
          }
          this.redirectToLogin();
        }
      });
    }
  }

  openLessonAvailable() {
    this.dialogRef = this.dialog.open(LessonAvailableComponent);
    this.dialogRef.afterClosed().subscribe((res) => {
      console.log(res);

    })
  }


  openLogoutConfirmationDialog() {
    this.dialogRef = this.dialog.open(LogoutConfirmationComponent);
    this.dialogRef.afterClosed().subscribe(res => {
      if (res == 'true') {
        if (res) {
          if (this.changePassword) {
            window.location.reload();
          }
          this.redirectToLogin();
        }
      }
    });
  }

  clearCookie(name) {
    const domain = ";domain=.retteracademy.com";
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/" + domain;
  }

  redirectToLogin() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('rw_jwt');
    localStorage.removeItem('user_img');
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('containerId');
    localStorage.removeItem('paging_count');
    localStorage.removeItem('user_policy');
    localStorage.removeItem('lessonId');
    localStorage.removeItem('quizData');
    localStorage.removeItem('userNotificationCounter')
    localStorage.removeItem('sent_notification_paging_count')
    localStorage.removeItem('received_notification_paging_count')
    localStorage.removeItem('ungraded_assignment_paging_count')
    localStorage.removeItem('graded_assignment_paging_count')
    localStorage.removeItem('escalated_assignment_paging_count')
    localStorage.removeItem('group_leaders_paging_count')
    localStorage.removeItem('group_members_paging_count')
    localStorage.removeItem('groupLeader_student_grades_paging_count')
    localStorage.removeItem('sidemenuModuleId');
    localStorage.removeItem('currentModule');
    localStorage.removeItem('lessonCYK');
    localStorage.removeItem('headerFullName');
    localStorage.removeItem('panelCollapse');
    localStorage.removeItem('courseSideBarcollapse');
    localStorage.removeItem('video_pagination_counter');
    localStorage.removeItem('studentFeedbackPaginationCounter');
    localStorage.removeItem('articleLstPaginationCounter');
    localStorage.removeItem('uid');
    localStorage.removeItem('user_img');
    localStorage.removeItem('complete');
    localStorage.removeItem('change_password');
    // this.removeCookie('rw_jwt')
    this.clearCookie('rw_jwt')

    this.router.navigate(['/login']);
    //window.location.reload()
  }

  redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }


  /**
 *
 * @Method name: getQuizOpenById
 * @param:  data{quiz_id: number, courseSlug: any, sectionSlug: any, moduleSlug: any}
 * @description: Service for open quiz by "Id"
 * @access:  public
 */
  private getModuleQuizDataMessage = new BehaviorSubject<string>("quiz-module-data-message");
  getModuleQuizIdSubject;
  getLessonQuizOpenById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug + '/' + data.moduleSlug + '/' + data.lessonSlug])
    this.setQuizDataMessage(data);
  }

  getModuleQuizOpenById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug + '/' + data.moduleSlug]);
    this.setQuizDataMessage(data);
  }

  getCourseQuizOpenById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug])
    this.setQuizDataMessage(data)
  }

  private getQuizDataMessage = new BehaviorSubject<string>("quiz-data-message")
  getQuizIdSubject = this.getQuizDataMessage.asObservable()
  setQuizDataMessage(data: string) {

    this.getQuizDataMessage.next(data);
  }

  openCourseSectionExpireDialog(data?) {
    this.dialogRef = this.dialog.open(CourseSectionExpiredComponent, {
      data: data
    });
    this.dialogRef.afterClosed().subscribe(res => {
      if (res['redirect']) {
        this.redirectToDashboard()
      }
    });
  }

  /**
 *
 * @Method name: getLessonAssignmentById, getModuleAssignmentById, getCourseAssignmentById
 * @description: Service for open assignment by "Id"
 * @access:  public
 */
  getLessonAssignmentById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug + '/' + data.moduleSlug + '/' + data.lessonSlug])
    this.setAssignmentDataMessage(data)
  }
  getModuleAssignmentById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug + '/' + data.moduleSlug])
    this.setAssignmentDataMessage(data)
  }
  getCourseAssignmentById(data: any) {
    this.router.navigate(['/courses/' + data.courseSlug + '/' + data.sectionSlug])
    this.setAssignmentDataMessage(data)
  }

  private getAssignmentDataMessage = new BehaviorSubject<string>("assignment-data-message")
  getAssignmentIdSubject = this.getAssignmentDataMessage.asObservable()
  setAssignmentDataMessage(data: string) {
    this.getAssignmentDataMessage.next(data);
  }

  setPrivacyPolicySession(data) {
    this.jwt = this.apiServer.getJWT()
    return this.http.post<any>(this.apiServer.getApiURL() + this.apiServer.getUsers(), JSON.stringify(data),
      { headers: this.apiServer.setHeaderValJWT(this.jwt) }
    )
  }


  getDemoContainerId() {
    const containerId = 25;
    return containerId;
  }



  getCurrentSiteDetail() {
    const siteD = JSON.parse(localStorage['theme_style']);
    return siteD;
  }

  lmsEncode(val: any) {
    return this.methodService.str_rot13(btoa(val + this.getRescueWorldLMSCode()));
  }

  setSelectedValueByLang() {
    let selectedValue;
    if (localStorage.getItem('locale') == 'ger') {
      selectedValue = 1
    } else if (localStorage.getItem('locale') == 'en') {
      selectedValue = 2
    }
    return selectedValue;
  }

  getLMSGeneralRoomName() {
    return 'lms_general'
  }


}
