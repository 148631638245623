import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

 //config = new Config;
 logUser = localStorage.getItem('loggedUser');
 jwt;

 constructor(private http: HttpClient, private apiService: ApiService) { }

 private _refreshNeeded$ = new Subject<void>();

 getStdentWiseFeedback(data) {
  this.jwt = this.apiService.getJWT();
  return this.http.post(this.apiService.getApiURL() + this.apiService.getFeedBack(), JSON.stringify(data),
    { headers: this.apiService.setHeaderValJWT(this.jwt) }
  ).pipe(
    tap(() => {
      this._refreshNeeded$.next();
    })
  )
}

getFeedbackTopic(data) {
  this.jwt = this.apiService.getJWT();
  return this.http.post(this.apiService.getApiURL() + this.apiService.getFeedBack(), JSON.stringify(data),
    { headers: this.apiService.setHeaderValJWT(this.jwt) }
  ).pipe(
    tap(() => {
      this._refreshNeeded$.next();
    })
  )
}

getUserAssignedCourses(data) {
  this.jwt = this.apiService.getJWT();
  return this.http.post(this.apiService.getApiURL() + this.apiService.getFeedBack(), JSON.stringify(data),
    { headers: this.apiService.setHeaderValJWT(this.jwt) }
  ).pipe(
    tap(() => {
      this._refreshNeeded$.next();
    })
  )
}

saveFeedback(data) {
  this.jwt = this.apiService.getJWT();
  return this.http.post(this.apiService.getApiURL() + this.apiService.getFeedBack(), JSON.stringify(data),
    { headers: this.apiService.setHeaderValJWT(this.jwt) }
  ).pipe(
    tap(() => {
      this._refreshNeeded$.next();
    })
  )
}

}
