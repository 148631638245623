import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CourseGroupService {
  jwt: any;
  constructor(private http: HttpClient  , private apiService: ApiService) { }

  getSectionWiseGroupList(data : any){
  this.jwt = localStorage.getItem('jwt');
  return this.http.post(
    this.apiService.getApiURL() + this.apiService.getCourseGroup() , 
    data , {
      headers: this.apiService.setHeaderValJWT(this.jwt)
    }
  )
  }






  



}
