import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { SocketService } from './../../stream/service/socket.service'
@Component({
  selector: 'app-socket-reconnect',
  templateUrl: './socket-reconnect.component.html',
  styleUrls: ['./socket-reconnect.component.scss']
})
export class SocketReconnectComponent implements OnInit {
  @ViewChild("userVideo", { static: true }) userVideo: ElementRef<HTMLVideoElement>;
  constraints = {
    audio: {
      sampleRate: 48000,
      channelCount: {
        ideal: 2,
        min: 1
      },
      volume: 1,
      echoCancellation: true,
      noiseSuppression: false,
      googAutoGainControl: false
    },
    video: {
        width: { min: 640, ideal: 720, max: 1920 },
        height: { min: 480, ideal: 510, max: 1080 },
        frameRate: {
            ideal: 50,
            min: 10
        }
    },
    // audio: true,
    // video: true
  };

  constructor(private socketService:SocketService) { 
  }

  ngOnInit() {
   

  }

  reconnect() {
    let userVideoID = this.userVideo.nativeElement;
    console.log('reconnect')
    navigator.mediaDevices.getUserMedia(this.constraints)
      .then((stream: MediaStream) => {
        userVideoID.srcObject = stream;  
      }).catch((error) => {
        console.log("%c Error " + "%c " + error, 'color:#fff;background:#f00', 'color:#f00;background:#ffeded')
      })

  }

}
