import {
  Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef,
  ComponentFactoryResolver, ComponentFactory,
  OnDestroy, ElementRef, Renderer2
} from '@angular/core';
import { Location } from "@angular/common";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleService } from './../../service/module.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { DataService } from './../../service/data.service';
import { CommonService } from './../../service/common.service';
import { LessonService } from './../../service/lesson.service';
import { Subscription } from 'rxjs';
import { ApiService } from './../../service/api.service';
import { QuizMultipleComponent } from '../../component/quiz-multiple/quiz-multiple.component';
import { StudentTimeService } from './../../service/student-time.service';
import { CourseService } from 'src/app/service/course.service';


@Component({
  selector: 'app-module-detail',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.scss']
})
export class ModuleDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  moduleListPost;
  moduleId;

  moduleDetail;
  moduleLangData;
  sectionId;
  moduleParam;


  courseSlug: any;
  startLessonStatus: boolean = false
  startLessonSlug: any;
  lessonid : any ; 

  isLoading: boolean = false;
  moduleImg: any;

  selectedIndex: any;
  nextModuleIndex: any;
  nextModuleSlug: any;

  currentMessageContainer: Subscription;
  quizIdSubject: Subscription;
  routerLinkSubscription: Subscription;
  sitePath: any;
  getInstituteUrl: any;
  getModuleDocuments: any;


  moduleQuizzes: any;
  quizPanelStatus: boolean = false;
  quizContainer: boolean = false;
  quizcmpdata: any;
  moduleGettingStartedWords: any;
  readMoreStatus: any;
  bindClickEvent: any
  element: any;
  launchURL: any;
  quizSubjectData: any;

  charLimit: number = 400;

  lessonId: any;
  currentModule: any;

  showPanelStatus: false;
  showTitle: boolean = true;

  panelBgCol: any;
  panelTxtCol: any;
  notfound: boolean = false;

  /************************************** */
  @ViewChild("quizeDynamicComponent", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;

  @ViewChild("eassyDynamicComponent", { read: ViewContainerRef, static: true }) eassyContainer;
  lm: boolean;
  timestamp: string;
  seconds: number;
  timer: any;
  moduleGettingStartedLength: any;
  moduleIntroductionLength: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  gettingStartedImgStyle: boolean;
  introductionImgStyle: boolean;

  constructor(private location: Location, private moduleService: ModuleService, private router: Router, private route: ActivatedRoute,
    private dataService: DataService, private commonService: CommonService,
    private lessonService: LessonService, private apiService: ApiService,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2, private sanitizer: DomSanitizer, private studentTimeService: StudentTimeService,
    private courseService: CourseService
  ) {
    window.scrollTo(0, 0);
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
     // console.log(message)
      this.buttonTheme = message;
    }, error => error);
    this.moduleId = this.route.snapshot.paramMap.get('moduleId');
    this.courseSlug = this.route.snapshot.paramMap.get('courseId');
    this.sectionId = this.route.snapshot.paramMap.get('sectionId');
    this.lessonId = localStorage.getItem('lessonId');
    this.currentModule = this.moduleId;

    this.dataService.changeMessage(this.moduleId, this.lessonId, this.currentModule)
    this.currentMessageContainer = this.dataService.currentMessage.subscribe(message => {
      this.moduleId = message;
      //this.getModuleList(this.moduleId)
      //this.getLessons(this.moduleId)
    })

    this.routerLinkSubscription = this.route.params.subscribe((res) => {
      this.moduleId = res['moduleId'];
      this.router.navigate(['/courses/' + res['courseId'] + '/' + res['sectionId'] + '/' + res['moduleId']])
      this.getModuleList(res['moduleId'])
      // this.getLessons(res['moduleId'])
      this.moduleParam = res['moduleId'];
      this.charLimit = 400;
      const lessonElm = document.querySelector('.img-content');
      const lessonBtn = document.querySelector('.readMoreBtn .btn')
      if (lessonElm != null) {
        if (lessonElm.classList.contains('open')) {
          lessonElm.classList.remove('open');
          lessonBtn.innerHTML = 'Read More'
        }
      }

    })
    this.quizIdSubject = this.commonService.getQuizIdSubject.subscribe(
      data => {

        if (data) {
          if (data['level_type'] == 2 && data['quiz_id']['quiz_reference_type'] != '7') {
            this.quizSubjectData = data['quiz_id'];
            this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, data['quiz_id']['gradebook_section_type'])
          }
        }
      }
    )

  }


  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }


  startTimer() {

    this.seconds = 0;
    //clearInterval(this.timer);
    this.timer = setInterval(() => {
      const value = Math.abs(this.seconds);
      const days = Math.floor(value / 1440);
      const hours = Math.floor((value - (days * 1440)) / 3600);
      const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
      const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
      // tslint:disable-next-line: max-line-length

      // tslint:disable-next-line: max-line-length
      this.timestamp = `${this.seconds < 0 ? '-' : ''}${days > 0 ? days + '.' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
      this.seconds++;

    }, 1000);
  }

  startLessonClick() {
    const data = {
      id: this.commonService.getUserId(),
      method: 'savingStudentTime',
      container_id: this.commonService.getDomainContainerId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionId,
      module_slug: this.moduleId,
      module_time: this.timestamp,
      page: 'M'
    };
    this.studentTimeService.saveStudentTime(data).subscribe(res => {
    });
    clearInterval(this.timer);
this.getLessons(this.moduleId);
    
  }


  ngOnInit() {
    this.startTimer();
    this.getPanelVisuals();
    if (this.moduleId == this.lessonService.lastCourseModule) {
      this.lm = true;
    } else {
      this.lm = false;
    }

    this.sitePath = this.apiService.getSiteFolderPath();
    this.getInstituteUrl = this.apiService.getApiSitePath()
  }

  toggleContainer(event) {
    event.target.parentElement.parentElement.classList.toggle('open');
    let parentElm = event.target.parentElement.parentElement;
    if (parentElm.className === 'col-sm-12 img-content pl-0 open') {
      event.target.innerText = "Less";
      this.charLimit = 5000000;
    } else {
      event.target.innerText = "Read More";
      this.charLimit = 400;
    }

  }

  getDocType(filename : string){
    return this.courseService.getfileIcon(filename)
  }

  ngAfterViewInit() {
    if (this.quizSubjectData) {
      if (this.quizSubjectData.quiz_reference_type != '7') {
        setTimeout(() => {
          // tslint:disable-next-line: max-line-length
          this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
        }, 1000)
      }
    }

  }
  /**
  * @description Toggle section
  * @date 2019-11-13
  * @method togglePanel
  * @memberof ModuleDetailComponent
  */
  togglePanel(togglePanelElement: HTMLElement, event) {
    const getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }


  }
  /**
   * @description Reset toggle panel section
   * @date 2019-11-13
   * @method resetPanelCollapse
   * @memberof ModuleDetailComponent
   */
  resetPanelCollapse() {
    if (this.showPanelStatus) {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header.active');
        const getContentPan = document.querySelectorAll('.pc-content.open');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.removeClass(getHeaderPan[i], 'active');
          this.renderer.removeClass(getContentPan[i], 'open');
          this.renderer.setStyle(getContentPan[i], 'height', '0');
        }
      }, 200)
    } else {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header');
        const getContentPan = document.querySelectorAll('.pc-content');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.addClass(getHeaderPan[i], 'active');
          this.renderer.addClass(getContentPan[i], 'open');
          this.renderer.setStyle(getContentPan[i], 'height', 'auto');
        }
      }, 200)
    }

  }


  /**
   * @description get module details by module slug
   * @date 2019-09-16
   * @param {*} moduleId
   * @memberof ModuleDetailComponent
   */
  getModuleList(moduleId) {
    this.moduleListPost = {
      course_slug: this.courseSlug,
      slug: moduleId,
      section_slug: this.sectionId,
      lesson_slug: this.lessonId,
      currentModule: this.currentModule,
      user_id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      method: "getModuleBySlug",
      lang_id: localStorage.getItem('locale') == 'ger' ? 1 : 2,
    }
    this.isLoading = true;
    this.moduleService.getModuleList(this.moduleListPost).subscribe(res => {
      if (res['status']) {
        this.isLoading = false;
        this.moduleDetail = res['data'];
        console.log(this.moduleDetail.getting_started);
        
        this.moduleLangData = res['data'].module_lang_data

        if(localStorage.getItem('locale') == 'ger') {
          this.moduleDetail.name = this.moduleDetail.name
          this.moduleDetail.introduction = this.moduleDetail.introduction
          this.moduleDetail.description = this.moduleDetail.description
          this.moduleDetail.getting_started = this.moduleDetail.getting_started

          this.moduleDetail.outcomes = this.moduleDetail.outcomes
        } else {
          if (this.moduleLangData[1]) {

            this.moduleDetail.name = this.moduleDetail.name
            this.moduleDetail.introduction = this.moduleDetail.introduction
            this.moduleDetail.description = this.moduleDetail.description
            this.moduleDetail.getting_started = this.moduleDetail.getting_started
            this.moduleDetail.outcomes = this.moduleDetail.outcomes

          } else {
            this.moduleDetail.name = this.moduleDetail.name
            this.moduleDetail.introduction = this.moduleDetail.introduction
            this.moduleDetail.description = this.moduleDetail.description
            this.moduleDetail.getting_started = this.moduleDetail.getting_started
            this.moduleDetail.outcomes = this.moduleDetail.outcomes
          }
        }

        // getting started Section Img
        if (this.moduleDetail.getting_started != null) {
          this.moduleGettingStartedLength = (this.moduleDetail.getting_started.match(/img/g) || []).length;
        }
        if (this.moduleDetail.introduction != null) {
          this.moduleIntroductionLength = (this.moduleDetail.introduction.match(/img/g) || []).length;
        }

        this.getModuleDocuments = res['data']['documents']
        console.log(this.getModuleDocuments);
        
        if (this.moduleDetail != undefined) {

          this.resetPanelCollapse();
          //read more
          if (this.moduleDetail.getting_started != null) {
            this.moduleGettingStartedWords = this.moduleDetail.getting_started.split('').length;
          }
          if (this.moduleGettingStartedWords > 400) {
            this.readMoreStatus = true
          } else {
            this.readMoreStatus = false
          }
          if (this.moduleDetail.quiz_data.length > 0) {
            this.quizPanelStatus = true;
            this.moduleQuizzes = this.moduleDetail.quiz_data;
          } else {
            this.quizPanelStatus = false;
          }

          this.moduleImg = this.moduleDetail.module_img != 'undefined' ? this.moduleDetail.module_img : null;
          console.log(this.moduleImg);
          

          this.gettingStartedImgStyle = (this.moduleDetail.getting_started_img_style == 1) ? true : false;
          this.introductionImgStyle = (this.moduleDetail.introduction_img_style == 1) ? true : false;

          this.isLoading = false
        }
        if (this.componentRef != undefined) {
          this.componentRef.destroy()
        }

      } else {
        this.notfound = true
        this.isLoading = false
        // this.commonService.redirectToLogin()
      }


    })
  }

  /**
   * @description get starting lesson detail for module
   * @date 2019-09-16
   * @param {*} moduleId
   * @memberof ModuleDetailComponent
   */
  getLessons(moduleId) {
    const lessonPostData = {
      method: 'getStartLessonByModule',
      id: this.commonService.getUserId(),
      module_slug: moduleId,
      course_slug: this.courseSlug,
      container_id: this.commonService.getDomainContainerId()
    }

    this.lessonService.getLessonsByModuleContainer(lessonPostData).subscribe(res => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog()
      } else {
        if (res['status']) {
          this.startLessonSlug = res['data'].slug;
          this.lessonid = btoa(res['data'].lesson_id)
          // this.startLessonStatus = true;
          this.router.navigate([`/courses/${this.courseSlug}/${this.sectionId}/${this.moduleId}/${this.startLessonSlug}/${this.lessonid}`]);

          
        } else {
          // this.startLessonStatus = false;
          // this.selectedIndex = this.lessonService.courseMenu.findIndex(item => item == this.moduleId);
          // this.nextModuleIndex = this.selectedIndex + 1;
          // this.nextModuleSlug = this.lessonService.courseMenu[this.nextModuleIndex];
          this.commonService.openLessonAvailable();
        }
      }

    })
  }



  /**************************************/
  getQuizStartById(quizeId: any, quizTitle: string, quizInstruction: any, isCompleted: any, attempts?: null, view_btn?: null, quiz_time?: null, quiz_ref_type?: any, gradebook_section_type?: any) {


    const course_slug_id = this.route.snapshot.paramMap.get('courseId')
    const module_slug_id = this.route.snapshot.paramMap.get('moduleId')
    const section_slug_id = this.route.snapshot.paramMap.get('sectionId')
    this.quizcmpdata = quizeId;
    const quizDetails = {
      id: quizeId,
      name: quizTitle,
      instruction: quizInstruction,
      course_slug: course_slug_id,
      module_slug: module_slug_id,
      section_slug: section_slug_id,
      is_completed: isCompleted,
      allow_attempts: attempts,
      view_result_btn: view_btn,
      quiz_time: quiz_time,
      quiz_reference_type: quiz_ref_type,
      gradebook_section_type: gradebook_section_type,
      pageType: 2
    }
    if (this.container != undefined) {
      this.container.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(QuizMultipleComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.type = quizDetails;
      this.componentRef.instance.output.subscribe(event => {
        if (event.action_type == 1) {
          document.getElementById(event.quizId).classList.add('is-complete');
          const element = document.getElementById(event.quizId) as HTMLElement;
          const elementModule = document.getElementById('quiz_module_' + event.quizId) as HTMLElement;
          this.getQuizRefresh(this.moduleParam);

          if (elementModule != null) {
            this.renderer.addClass(elementModule, 'active');
          }

        }
        if (event.action_type == 2) {
          this.getQuizStartById(event.quizeId, event.quizTitle, event.quizInstruction, null)
        }
        this.quizIdSubject.unsubscribe();

      });
    }
  }




  /**
   * @description get refresh module quiz details
   * @date 2019-09-16
   * @param {*} moduleId
   * @memberof ModuleDetailComponent
   */
  getQuizRefresh(moduleId) {
    this.moduleListPost = {
      course_slug: this.courseSlug,
      container_id: this.commonService.getDomainContainerId(),
      slug: moduleId,
      section_slug: this.sectionId,
      user_id: this.commonService.getUserId(),
      method: "getModuleBySlug",
      lang_id: localStorage.getItem('locale') == 'ger' ? 1 : 2,
    }
    this.moduleService.getModuleList(this.moduleListPost).subscribe(res => {
      if (res['data']['quiz_data']) {
        this.moduleQuizzes = res['data']['quiz_data']
      }

    })
  }

  ngOnDestroy() {
    this.isLoading = true;
    this.currentMessageContainer.unsubscribe()
    this.routerLinkSubscription.unsubscribe();
    this.quizIdSubject.unsubscribe();
    // this.eassyContainer.clear();
    this.currentBtnThemeContainer.unsubscribe();
  }

  /**************************************/
  /****  Create Dynamic Component   */

}
