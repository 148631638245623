// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player {
  border-radius: 10px;
  padding: 0;
  position: relative;
  overflow: hidden;
  margin-top: 8px;
}
.video-player video {
  border-radius: 10px;
  background: #ddd;
}
.video-player video:focus {
  outline: none;
}
.video-player video::-internal-media-controls-download-button {
  display: none !important;
}
.video-player .video-overly {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 55%, rgba(0, 0, 0, 0.2) 100%);
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.video-player .video-overly div {
  display: grid;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  align-content: center;
  padding: 105px;
  color: #fff;
}
.video-player .video-overly div button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #dc3545;
  border: 0;
  color: #fff;
  box-shadow: 0 0 0 9px rgba(220, 53, 69, 0.2);
  margin-top: 25px;
  font-size: 27px;
  padding: 11px 18px;
  outline: none;
  position: relative;
  /* triangle */
  /* pulse wave */
}
.video-player .video-overly div button::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.video-player .video-overly div button:before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  animation-delay: 0s;
  animation: pulsate1 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -25%;
  left: -25%;
  background: rgba(198, 16, 0, 0);
}
.video-player .video-overly.hide {
  opacity: 0;
  z-index: -1;
}

.video-content-section {
  background: #ffffff;
  border-radius: 8px;
  padding: 0 30px;
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.video-heading-section {
  margin-top: 16px;
}
.video-heading-section h4 {
  font-size: 26px;
  color: #000;
  margin: 0 0 8px;
}
.video-heading-section h5 {
  font-size: 16px;
  margin: 0 0 25px;
}

.video-category-heading {
  margin-bottom: 15px !important;
}
.video-category-heading h4 {
  font-size: 19px;
  font-weight: bold;
  margin: 5px 0;
}
.video-category-heading .btn {
  padding: 0px 15px;
  font-size: 14px;
}

.video-library-list {
  margin-top: 30px !important;
}
.video-library-list .card {
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.125);
}
.video-library-list .card:hover .card-img a i {
  background: #121314;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
}
.video-library-list .card:hover .card-img-doc a i {
  background: #121314;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
}
.video-library-list .card .card-img a {
  display: block;
  height: 150px;
  position: relative;
  cursor: pointer;
  background: url("/assets/images/no-image-big.jpg") #ddd center center;
  background-size: cover;
}
.video-library-list .card .card-img a img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video-library-list .card .card-img a i {
  position: absolute;
  bottom: -17px;
  width: 35px;
  height: 35px;
  background: #dc3545;
  border-radius: 50%;
  right: 20px;
  padding: 10px 11px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.2);
}
.video-library-list .card .card-img-doc a {
  display: block;
  height: 150px;
  position: relative;
  cursor: pointer;
  background: url("/assets/images/no-image-big.jpg") #ddd center center;
  background-size: cover;
}
.video-library-list .card .card-img-doc a img {
  max-width: 100%;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.video-library-list .card .card-img-doc a i {
  position: absolute;
  bottom: -17px;
  width: 35px;
  height: 35px;
  background: #dc3545;
  border-radius: 50%;
  right: 20px;
  padding: 10px 11px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.2);
}
.video-library-list .card-body {
  padding: 0;
}
.video-library-list .card-body a {
  display: block;
  padding: 15px;
  text-decoration: none;
  height: 110px;
}
.video-library-list .card-body a .card-title {
  font-size: 19px;
  font-weight: 600;
  color: #121314;
}
.video-library-list .card-body a .card-text {
  color: #525252;
  font-size: 15px;
}
@keyframes pulsate1 {
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}
@media (max-width: 767px) {
  .m-pl-0 {
    padding-left: 0 !important;
  }
  .m-pr-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 1920px) and (min-width: 1800px) {
  .video-library-item {
    flex: 0 0 25%;
  }
}
@media (min-width: 1800px) {
  .video-library-item {
    flex: 0 0 25%;
  }
}
@media (max-width: 1799px) and (min-width: 1600px) {
  .video-library-item {
    flex: 0 0 25%;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
