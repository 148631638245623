import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../service/auth.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { CommonService } from './../../service/common.service';
import { UserService } from './../../service/user.service';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';
import { ChangePasswordLogoutConfirmationComponent } from 'src/app/dialogBox/change-password-logout-confirmation/change-password-logout-confirmation.component';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit, OnDestroy {
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService,
    private formBuilder: FormBuilder, private commonService: CommonService, private userService: UserService,
    private dialog: MatDialog , private dataService: DataService) {
      this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
        this.buttonTheme = message;
      }, error => error);
     }
  newPasswordForm: FormGroup;
  currentPassword: FormControl;
  newPassword: FormControl;
  cnfPassword: FormControl;
  message: any = '';
  dialogRef: any;
  actStatus: boolean = false;
  messageCode:any;
  auth_token : string;
  language : string ; 
  msg : boolean = false ; 
  updatePswrd : boolean = false ; 
  logoutStatus : string = 'no';


  ngOnInit() {
    this.auth_token = localStorage.getItem('rw_jwt');
    this.language = localStorage.getItem('locale')
    this.currentPassword = this.formBuilder.control('', [Validators.required])
    this.newPassword = this.formBuilder.control('', [Validators.required])
    this.cnfPassword = this.formBuilder.control('', Validators.required)
    this.newPasswordForm = this.formBuilder.group({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      cnfPassword: this.cnfPassword,
      method: 'changePasswordforStudent',
      id: this.commonService.getUserId(),
      username: this.commonService.getUserName(),
      auth_token : this.auth_token,
      language: this.language == 'ger' ? "de" : "en",
      logout : this.logoutStatus
    });
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }

  /**
   * @description update user password
   * @date 2019-09-16
   * @memberof ChangeUserPasswordComponent
   */
  newPasswordEvent() {
    this.messageCode = null;
    this.message = '';
    this.newPasswordForm.patchValue({ logout: this.logoutStatus }); // Ensure logoutStatus is up-to-date
    if (this.newPasswordForm.status == 'VALID') {
      this.userService.changeUserPassword(this.newPasswordForm.value).subscribe(res => {
        this.messageCode = res['messageCode'];
        if (res['status']) {
          this.msg = true;
          this.updatePswrd = true;
          this.message = res['message'];
          this.newPasswordForm.reset();
          this.ngOnInit();
          // this.openGeneralDialogBox();
          if(this.newPasswordForm.value.logout == "yes"){
            setTimeout(() => {
              this.commonService.redirectToLogin()
            }, 3000);
          }
        } else {
          this.actStatus = res['status'];
          this.msg = true;
          this.message = res['message'];
          this.updatePswrd = false;
        }
        setTimeout(() => {
          this.msg = false;
        }, 4000);
        
      });
    } else {
      this.message = 'Please fill all fields';
    }
  }


  /**
   * @description open general messages dialog box
   * @date 2019-09-16
   * @memberof ChangePasswordLogoutConfirmationComponent
   */
 
  openChangePasswordLogoutConfirmation() {
    this.dialogRef = this.dialog.open(ChangePasswordLogoutConfirmationComponent, {
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe((res: any) => {
      if (res === '') {
        return;
      }
      
      this.logoutStatus = res ? 'yes' : 'no';
      this.newPasswordForm.patchValue({ logout: this.logoutStatus });
      this.newPasswordEvent();
    });
  }

}
