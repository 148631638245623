import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { courseAssignedList, allCourses, courseDetail } from './../model/course';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class MarketPlaceService {
  //config = new Config;
  logUser = localStorage.getItem('loggedUser');
  jwt;

  constructor(private http: HttpClient, private apiService: ApiService) { }

  // tslint:disable-next-line: variable-name
  private _refreshNeeded$ = new Subject<void>();

  getMarketPlace(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getMarketPlace(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getVerifyRedeemCode(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getMarketPlace(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getPurchaseHistory(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getMarketPlace(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

}
