import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { CommonService } from 'src/app/service/common.service';
import { SiteDomainService } from 'src/app/service/sitedomain.service';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrl: './autologin.component.scss'
})
export class AutologinComponent implements OnInit {
  site_domain: any;
  briefing_id: any;
  user_id: any;
  code: any;
  status: any;
  roleIdRes: any;
  allowedUserRoles = [
    { id: 11, name: 'Student' },
    // { id: 17, name: 'Host' }
  ];
  isPresent: any;
  language : any ; 
  notAllowedLoginErr: boolean = false;



  constructor(private domain: SiteDomainService, private route: ActivatedRoute, private authService: AuthService, private commonService: CommonService, private router: Router) {
    this.site_domain = domain.getDomainName();

    route.params.subscribe(res => {
      this.briefing_id = res.breifing_id;
      this.code = res.code;
      this.status = res.login_status;
      this.user_id = res.userId;
    })
  }

  ngOnInit(): void {
    if(localStorage.getItem('locale') == 'ger'){
      this.language = "de"
    }else{
      this.language = 'en'
    }

    // localStorage.setItem("uid",this.user_id),
    // console.log(localStorage.getItem('uid'));
    
    console.log("application language ",this.language);
    
    this.autoLogin()

  
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      //   date.setTime(date.getTime() + 60000);
      expires = "; expires=" + date.toUTCString();
    }
    const domain = ";domain=.retteracademy.com";
    document.cookie = name + "=" + (value || "") + expires + "; path=/" + domain;
  }

  autoLogin() {
    const postData = {
      user_id: this.user_id,
      autologin_code: this.code,
      method: "autologin",
      domain: this.site_domain,
      autologin_status: this.status,
      presafety_briefing_id: this.briefing_id,
      language : this.language  
    }
    console.log(postData);
    
    this.authService.briefingAutoLogin(postData).subscribe((res: any) => {
      if (res['status']) {
        localStorage.setItem('uid', btoa(res.data.id))
        this.roleIdRes = res['data'].role_id
        const roleId = this.roleIdRes
        const presentRoleArr = this.allowedUserRoles.filter(
          item => {
            item.id == roleId ? this.isPresent = true : this.isPresent = false;
            return this.isPresent;
          }
        )

        // Set User Policy Status param in local storage
        if (res['data']['privacy_policy_tracking'] == 1) {
          localStorage.setItem("user_policy", "true")
        } else if (res['data']['privacy_policy_tracking'] == 0) {
          localStorage.setItem("user_policy", "false")
        }
        if (presentRoleArr.length) {
          localStorage.setItem('user_img', res['data'].profile_image_url);
          localStorage.setItem('headerFullName', res['data'].first_name + ' ' + res['data'].last_name);
          localStorage.setItem('loggedUser', JSON.stringify(res['data']));

          localStorage.setItem('jwt', res['token'])
          localStorage.setItem('rw_jwt', res['data'].rw_auth_token)
          this.setCookie("rw_jwt", res['data'].rw_auth_token, 365);
          this.authService.setLoggenIn(true)
          // this.getUserRoleWiseRoutes(roleId)
          this.router.navigate([`pre-safety-detail/${res.presafety_briefing_id}`])
        } else {
          this.notAllowedLoginErr = true;
        }
      } else {
        if (res.code == 500) {
          this.commonService.openLogoutReasonDialog(res.message)
          this.router.navigate(['/login'])
        } else if (res.code == 300) {
          this.router.navigate(['/login'])
        }
      }
    })
  }
}
