// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/css/variables.scss");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sitefooter {
  background-color: #000;
  padding: 15px 0;
  color: #fff;
}

#sitefooter .tel-content {
  padding-bottom: 0px;
}

#sitefooter .tel-content h1 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
#sitefooter .tel-content h1 p {
  margin-bottom: 10px !important;
}

#sitefooter .tel-content p {
  display: inline-block;
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
}

#sitefooter .copy-right {
  font-size: 14px;
  font-weight: 400;
}

#sitefooter .footer-links {
  text-align: right;
}

#sitefooter .footer-links .btn {
  font-size: 25px;
  cursor: pointer;
}

#sitefooter .footer-links .btn.btn-question {
  color: #fff;
  font-size: 15px;
}

.cookies-footer {
  position: relative;
  height: 58px;
}
.cookies-footer .cookies-footer-inner {
  background: #2b323a;
  color: #fff;
  position: fixed;
  width: 100%;
  padding: 16px;
  bottom: 0;
  left: 0;
  z-index: 99999;
}
.cookies-footer .cookies-footer-inner h3 {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.cookies-footer .cookies-footer-inner h3 a {
  color: #fff;
  text-decoration: underline;
}
.cookies-footer .cookies-footer-inner .btn-group-section {
  display: inline-block;
  margin: 0 45px;
}
.cookies-footer .cookies-footer-inner .btn-group-section .btn {
  font-size: 13px;
  padding: 3px 16px;
}
.cookies-footer .cookies-footer-inner .btn-group-section .btn + .btn {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  #sitefooter {
    text-align: center;
  }
  #sitefooter .tel-content {
    margin: 0 0 10px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
