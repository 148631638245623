import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VideoService } from '../../../service/video.service';
import { CommonService } from '../../../service/common.service';
import { ApiService } from '../../../service/api.service';
import { DataService } from './../../../service/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-category',
  templateUrl: './video-category.component.html',
  styleUrls: ['./video-category.component.scss']
})
export class VideoCategoryComponent implements OnInit, OnDestroy {

  /** @var videoDataContainer store all video data  */
  videoDataContainer: any;

  /** @var getVideoUploadPath store video image path  */
  getVideoUploadPath: any;

  /** @var notfound For error when data is not found  */
  notfound: boolean = false;

  /** @var isLoading For loading when data featching  */
  isLoading: boolean = false;

  /** @var totalRecorde Counting total record of data  */
  totalRecorde: any;

  /** @var categoryList store all feating category list data  */
  categoryList: any;

  /** @var categoryForm form group for searching form field  */
  categoryForm: FormGroup;

  /** @var searchByTitle form field for title  */
  searchByTitle: FormControl;

  /** @var selectCategory form field for category  */
  selectCategory: FormControl;
  // sortCategory : FormControl;

  /** @var selectCategory category form field error status handler  */
  selectCategoryError: boolean = false;
  selectedOption: string;
  showPlaceholder: boolean = true;
  mediaCatIdbyroute : any ; 

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  categoryListitem : any ; 
  mediaCatId : any ; 

  arrayOfSortObject = [
    {
      value: "alphabetically",
      name: "Alphabetically"
    },
    {
      value: "reversed_alphabetically",
      name: "Reversed alphabetically"
    },
    {
      value: "by_latest",
      name: "By latest"
    },
    {
      value: "by_oldest",
      name: "By oldest"
    },
  ]

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private videoService: VideoService, private commonService: CommonService, private apiService: ApiService, private renderer: Renderer2, private formBuilder: FormBuilder , private dataService: DataService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
  }

  ngOnInit() {
    this.getVideoUploadPath = this.apiService.getCourseImgPath();
    this.setCounterLink(0);
    // this.getVideoListing(0, "", "");
    // this.getVideoCategories(0, "", "");
    // this.getVideoCategoriesDropdownlist()

    //this.searchByTitle = new FormControl('');
    this.selectCategory = new FormControl(null, Validators.required);
    this.categoryForm = this.formBuilder.group({
      //searchByTitle: this.searchByTitle,
      selectCategory: this.selectCategory,
      // sortCategory :  this.sortCategory 

    })
    this.selectedOption = null; // Initialize selectedOption
    this.mediaCategoryId()    
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }


  /**
   * @description Get video categories for filter
   * @date 2020-05-22
   * @method  getVideoCategories
   * @memberof VideoCategoryComponent
   */
  getVideoCategories(pageCounter, search_title, search_cat) {
    this.isLoading = true;
    const paramCat = {
      method: "videoCategoryListDropDown",
      id: this.commonService.getUserId(),
      containerId:this.commonService.getDomainContainerId(),
      search_by_title: search_title,
      search_by_category: search_cat,
      pageNumber: pageCounter,
    }
    this.videoService.getVideoListData(paramCat).subscribe((res) => {      
      console.log(res);
      
      this.isLoading = false;
      if (!res['jwt_status']) {
       this.commonService.openSessionExpireDialog();
       return;
     }
     if (res['status']) {
       if (res['data'] != null || res['data'] != undefined) {
         this.notfound = false
        //  this.videoDataContainer = res['data']
         this.categoryList = res['data']
       } else {
         this.notfound = true
       }
     } else {
       this.notfound = true
     }
    })
  }


  getVideoCategoriesDropdownlist() {
    const paramCat = {
      method: "videoCategoryListDropDown",
      id: this.commonService.getUserId(),
      containerId:this.commonService.getDomainContainerId(),
      search_by_title: 0,
      search_by_category: 0,
      pageNumber: 0
    }
    this.videoService.getVideoListData(paramCat).subscribe((res) => {      
      if (res['status']) {
        if (res['data']) {
          this.categoryListitem = res['data']          
        }
      }
    })
  }




  /**
   * @description Get all videos listing category wise
   * @date 2020-05-22
   * @param pageCounter, search_title, search_cat
   * @method  getVideoListing
   * @memberof VideoCategoryComponent
   */
  // getVideoListing(pageCounter, search_title, search_cat) {
  //   this.isLoading = true;
  //   const videoData = {
  //     method: "videoCategoryList",
  //     id: this.commonService.getUserId(),
  //     container_id: this.commonService.getDomainContainerId(),
  //     search_by_title: search_title,
  //     search_by_category: search_cat,
  //     pageNumber: pageCounter
  //   }
  //   this.videoService.getVideoListData(videoData).subscribe((res) => {
  //     this.isLoading = false;
  //      if (!res['jwt_status']) {
  //       this.commonService.openSessionExpireDialog();
  //       return;
  //     }
  //     if (res['status']) {
  //       if (res['data'] != null || res['data'] != undefined) {
  //         this.notfound = false
  //         this.videoDataContainer = res['data']
  //         console.log("videoDataContainer",this.videoDataContainer);
  //       } else {
  //         this.notfound = true
  //       }
  //     } else {
  //       this.notfound = true
  //     }

  //   })
  // }


  /**
   * @description Navigate to the category wise listing
   * @date 2020-05-22
   * @param catId
   * @method  gotoCategoryWiseView
   * @memberof VideoCategoryComponent
   */
  gotoCategoryWiseView(catId) {
    this.dataService.mediaCategory(catId)
    this.dataService.mediaCat.next(catId) 
    this.router.navigate(['video-category/' + btoa(catId)]);
  }


  /**
   * @description Search by category id
   * @date 2020-05-22
   * @method  searchByCategory
   * @memberof VideoCategoryComponent
   */
  searchByCategory() {
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && (this.categoryForm.value.selectCategory && this.categoryForm.value.sortCategory)){
      this.getVideoCategories(0, "", this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory);      
    }else if(this.categoryForm.status == 'INVALID'){
      this.selectCategoryError = true
      console.log(this.categoryForm)  
    } else {
      this.getVideoCategories(0, "", this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory);
    }    
  }


  /**
 * @description Check vallidation manualy
 * @date 2020-05-22
 * @method  checkValidate
 * @memberof VideoCategoryComponent
 */
  checkValidate() {
    if (this.categoryForm.status == 'VALID' && this.selectCategory.valid) {
      this.selectCategoryError = false;
    } else {
      this.selectCategoryError = true
    }
  }


  /**
 * @description On change category filter
 * @date 2020-05-22
 * @method  onCategoryChange
 * @memberof VideoCategoryComponent
 */
  onCategoryChange(option) {
    console.log('Searching for:', option);
    if (option !== null) {
      this.showPlaceholder = false;
    }
    if (option === 'all') {
      this.getVideoCategories(0, '', ''); 
    } else {
      this.getVideoCategories(0, "", option)
    }
    this.checkValidate()
  }


  /**
 * @description Reset all filter fields
 * @date 2020-05-22
 * @method  resetForm
 * @memberof VideoCategoryComponent
 */
  resetForm() {
    this.selectCategoryError = false;
    this.selectedOption = null;

    if (this.categoryForm.value.selectCategory != null || this.categoryForm.value.sortCategory != null) {
      this.getVideoCategories(0,'','');
    }

  }


  /**
 * @description Set counter in localstorage for pagination
 * @date 2020-05-22
 * @param counterId
 * @method  setCounterLink
 * @memberof VideoCategoryComponent
 */
  setCounterLink(counterId: any) {
    localStorage.setItem('video_pagination_counter', counterId)
  }


  /**
 * @description Navigate to the details page
 * @date 2020-05-22
 * @method  videoDetails
 * @memberof VideoCategoryComponent
 */
  videoDetails(video_id, categoryId) {

    console.log(video_id , categoryId)

    const paraData = video_id + ',' + categoryId;
    this.router.navigate(['video-detail/' + btoa(paraData)]);
  }

  getImgIcon(file , fileType){
    return this.videoService.getImgIcon(file , fileType)
  }

  onChangeSorting(event){
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && this.selectCategory.valid ) {
      this.getVideoCategories(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory);
    }else{
      this.selectCategoryError = false
      this.getVideoCategories(0, '', '');
    }    
  }


  mediaCategoryId() {
    this.dataService.mediaCategoryId.subscribe((res: any) => {    
      console.log(res);
      
      this.mediaCatId = res.id
      
      if (res === 'all' || this.mediaCatId === undefined) {
        this.getVideoCategories(0, '', '');
      }

      if(this.mediaCatId){
        this.gotoCategoryWiseView(this.mediaCatId)
      }
    })
  }
}
