import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from './../../service/common.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  userRole: any;

  constructor(private commonService : CommonService) { }

  ngOnInit() {
    this.userRole = this.commonService.getUserRole()
  }

}
