import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-necessary-quiz',
  templateUrl: './necessary-quiz.component.html',
  styleUrls: ['./necessary-quiz.component.scss']
})
export class NecessaryQuizComponent implements OnInit {
  quizData: any;

  constructor(public dialogRef: MatDialogRef<NecessaryQuizComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.quizData = this.data.quiz_data;
  }

}
