import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-lesson-completed-dg-box',
  templateUrl: './lesson-completed-dg-box.component.html'
})
export class LessonCompletedDgBoxComponent implements OnInit {

  constructor() { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit() {
  }

}
