import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';
import { ChangePasswordLogoutConfirmationComponent } from '../change-password-logout-confirmation/change-password-logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  auth_token: string;
  language: string;
  logoutStatus: string = 'no';
  dialogRef: any;
  updatedPassword: boolean = false;
  msg: string = '';
  old_password_show: boolean = false;
  new_password_show: boolean = false;
  confirm_password_show: boolean = false;
  spinner: boolean = true;
  message_code: any;

  constructor(private userService: UserService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.auth_token = localStorage.getItem('rw_jwt');
    this.language = localStorage.getItem('locale');
  }

  changePassword = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[!@#$%^&*])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
    ]),
    cnfPassword: new FormControl('', Validators.required),
    logout: new FormControl(this.logoutStatus)
  })

  changeUserPassword() {
    if (this.changePassword.invalid) {
      this.changePassword.markAllAsTouched();
      return;
    }

    this.changePassword.patchValue({ logout: this.logoutStatus });

    const postData = {
      currentPassword: this.changePassword.value.currentPassword,
      newPassword: this.changePassword.value.newPassword,
      cnfPassword: this.changePassword.value.cnfPassword,
      method: 'changePasswordforStudent',
      id: this.commonService.getUserId(),
      username: this.commonService.getUserName(),
      auth_token: this.auth_token,
      language: this.language == 'ger' ? "de" : "en",
      logout: this.logoutStatus,
      change_password: JSON.parse(localStorage.getItem('change_password'))
    }
    this.spinner = false;
    console.log("change password popup", postData);

    this.userService.changeUserPassword(postData).subscribe({
      next: (response: any) => {
        this.spinner = true;
        this.message_code = response.messageCode;
        console.log(response);
        if (response['status']) {
          if (response.messageCode == 1) {
            this.updatedPassword = true;
            if (this.language == 'ger') {
              this.msg = "Passwort erfolgreich aktualisiert"
            } else {
              this.msg = "Password updated successfully";
            }

            if (this.changePassword.value.logout == 'no') {
              setTimeout(() => {
                this.spinner = false;
              }, 1000);
              setTimeout(() => {
                this.updatedPassword = false;
                localStorage.setItem('change_password', JSON.stringify(0))
                window.location.reload();
              }, 200);
            }
            else if (this.changePassword.value.logout == "yes") {
              setTimeout(() => {
                this.spinner = false;
              }, 1000);
              setTimeout(() => {
                localStorage.setItem('change_password', JSON.stringify(0))
                window.location.reload();
                this.commonService.redirectToLogin()
              }, 200);
            }
          }
        } else {
          if (response.messageCode == 5) {
            this.updatedPassword = true;

            if (this.language == 'ger') {
              this.msg = "Das Passwort wurde bereits aktualisiert"
            } else {
              this.msg = "Password already has been updated";
            }
            setTimeout(() => {
              this.spinner = false;
            }, 1000);

            localStorage.setItem('change_password', JSON.stringify(0))
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
          else if (response.messageCode == 3) {
            console.log(response.messageCode);
            this.updatedPassword = true;


            if (this.language == 'ger') {
              this.msg = "Neues Passwort stimmt nicht überein. Bitte versuchen Sie es erneut !"
            } else {
              this.msg = "New password does not match. Please try again !";
            }

            setTimeout(() => {
              this.updatedPassword = false;
            }, 3000);
          }
          else if (response.messageCode == 2) {
            console.log(response.messageCode);
            this.updatedPassword = true;

            if (this.language == 'ger') {
              this.msg = "Altes Passwort stimmt nicht überein"
            } else {
              this.msg = "Old password does not match";
            }

            setTimeout(() => {
              this.updatedPassword = false;
            }, 3000);
          }
        }
        console.log("message code here....", this.msg);

      },
      error: (error: any) => {

      }
    })
  }

  openChangePasswordLogoutConfirmation() {
    if (this.changePassword.invalid) {
      this.changePassword.markAllAsTouched();
      return;
    }
    this.dialogRef = this.dialog.open(ChangePasswordLogoutConfirmationComponent, {
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe((res: any) => {
      if (res === '') {
        return;
      }

      this.logoutStatus = res ? 'yes' : 'no';
      this.changePassword.patchValue({ logout: this.logoutStatus });
      this.changeUserPassword();
    });
  }

  OldPasswordShow() {
    this.old_password_show = !this.old_password_show;
  }

  newPasswordShow() {
    this.new_password_show = !this.new_password_show;
  }

  confirmPasswordShow() {
    this.confirm_password_show = !this.confirm_password_show;
  }

}
