import { Component, OnInit, AfterViewInit, ViewEncapsulation, OnDestroy, Renderer2, HostListener, Directive, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { SidebarService } from './../../service/sidebar.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';
import { QuizService } from './../../service/quiz.service';
import { CourseService } from './../../service/course.service';
import { CommonService } from './../../service/common.service';
import { LessonService } from './../../service/lesson.service';
// import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GroupLeaderService } from './../../service/group-leader.service';
import { LiveChatService } from './../../../app/live-chat/service/live-chat.service';

@Component({
  selector: 'app-course-sidebar',
  templateUrl: './course-sidebar.component.html',
  styleUrls: ['./course-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  public courseSlug: string;
  public domain: string;
  public courseMenu: { modules: string | any[]; };
  public courseMenuPost: { method: string; course_slug?: any; section_slug: any; id: any; item_id?: any; slug?: any; };
  private childParamSubscription: Subscription;
  private paramSubscription: Subscription;

  lessonId: string;
  sectionSlug: string;
  userData: { id: any; };
  userId: any;
  isLoading: boolean = true;

  courseCompletionValue: any = 0;
  courseCompPostData: any;
  currentLessonSlugContainer: Subscription;

  menuModuleArr: any = []
  courseMenuArr: any;

  completeCourseSlug: any;
  moduleSlug: string;
  lessonSlug: string;
  routeParamValues: any;

  containerId: any;
  getLessonActive: any;
  getModuleActive: any;
  getLessonMenuItem: any;
  moduleMenuItemListNew: any;
  public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };
  courseName: any;
  instructorgroupLeaderLinkStatus: boolean = false;

  // For Menu Section

  submenuLoading: boolean = false;


  @ViewChild("moduleMenuItemList", { read: ViewContainerRef, static: false }) moduleMenuItemList: any;
  moduleMenuItemListCMPRef: ComponentRef<any>;
  showGroupsNav: boolean;
  lastMenuArrIndex: number;
  lessonPermission: any;
  showChatMenu: any;
  courseChatActiveContainer: Subscription;


  constructor(private sidebarService: SidebarService, private route: ActivatedRoute, private router: Router,
    private dataService: DataService, private quizService: QuizService, private courseService: CourseService,
    private commonService: CommonService, private lessonService: LessonService, private renderer: Renderer2,
      private groupLeaderService: GroupLeaderService, private liveChatService: LiveChatService) {


    this.courseChatActiveContainer =
      this.dataService.currentCourseChatActiveTems.subscribe((res) => {
        this.showChatMenu = res
      });


    this.userData = JSON.parse(localStorage.getItem('loggedUser'));

    if (this.userData != null) {
      this.userId = this.userData.id;
    }


    this.childParamSubscription = this.route.firstChild.paramMap.subscribe(
      (params: ParamMap): void => {
        this.getLessonActive = params.get('lessonId');
        this.getModuleActive = params.get('moduleId');
        this.lessonId = params.get('lessonId')
        this.courseSlug = params.get('courseId')
        this.moduleSlug = params.get('moduleId')
        this.sectionSlug = params.get('sectionId')
      }, error => error
    )

    this.paramSubscription = this.route.paramMap.subscribe(
      (params: ParamMap): void => {
        this.domain = params.get('domain')
      }, error => error
    )

    this.currentLessonSlugContainer = this.dataService.currentLessonSlug.subscribe(lessonSlug => {
      if (lessonSlug != null) {
        this.getLessonActive = lessonSlug;
        this.setActiveLink();
        this.lessonId = lessonSlug['lesson_slug']
        this.getCourseCompletionValue()
        //this.getCourseMenu()
        const elLesson = document.querySelectorAll("#lesson_" + lessonSlug['lesson_id']);
        const elModule = document.getElementById("module_" + lessonSlug['module_id']);
        if (elLesson) {
          elLesson.forEach(element => {
            this.renderer.addClass(element, "active");
          });
          this.getSideMenuItem(localStorage.getItem("sidemenuModuleId"))
        }
        if (elModule) {
          this.renderer.addClass(elModule, "active");
        }
      }

    }, error => error)

  }

  ngOnInit() {
    this.getInitialAPIData()
    const collapseVar: any = localStorage.getItem('courseSideBarcollapse');
    const pageelement = document.getElementById('pageContainer');

    if (collapseVar === null) {
      this.renderer.removeClass(pageelement, 'collapse-panel');
    } else if (collapseVar == 1) {
      this.renderer.removeClass(pageelement, 'collapse-panel');
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel');
    }

    if (this.commonService.getUserRole() == this.commonService.userRolesArr['Instructor']) {
      this.instructorgroupLeaderLinkStatus = true;
    }
    this.containerId = this.commonService.getDomainContainerId()
    this.getCourseCompletionValue()
    this.getCourseMenu()
    setTimeout(() => {
      const elmData = document.querySelectorAll(".sidebar-child-menu h4.active");
      elmData;
    }, 1000)
  }

  getInitialAPIData() {
    this.getChatRoomActiveStatus();
  }

  getChatRoomActiveStatus() {
    const data = {
      method: "getCourseSectionRoomName",
      id: this.commonService.getUserId(),
      section: this.sectionSlug,
      course: this.courseSlug
    }
    this.liveChatService.getCourseSectionRoomName(data).subscribe((res) => {
      let showChat: Boolean = (res['data'] !== undefined) ? true : false;
      this.dataService.changeCourseChatTermsSource(showChat)
    })
  }

  getUserChatTermsAcceptStatus() {
    const data = {
      method: "getUserChatTermsAcceptStatus",
      roomId: 1,
      userId: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    };
    this.liveChatService.getUserChatTermsAcceptStatus(data).subscribe((res) => {
      console.log(res, 'tempo')
      if (res["data"] === undefined || res["data"] === "") {
        this.showChatMenu = false;
        this.dataService.changeUserGeneralChatTermsSource(false);
      } else {
        this.showChatMenu = res["data"];
        this.dataService.changeUserGeneralChatTermsSource(res["data"]);
      }
    });
  }

  ngAfterViewInit() {
    // this.mScrollbarService.initScrollbar('#scrollMenuSection', {
    //   axis: 'y', theme: 'dark-thick', scrollButtons: { enable: true }, advanced: {
    //     autoScrollOnFocus: true
    //   }
    // });
  }

  goToHome() {
    this.router.navigate(['/dashboard'])
  }

  goToCourseGroupSet() {
    this.router.navigate(['group-leader-course-groupset'])
  }

  ngOnDestroy() {
    this.currentLessonSlugContainer.unsubscribe();
    this.courseChatActiveContainer.unsubscribe();
  }

  toggleMenuSection(element: HTMLElement, event: any, module_id?: any, index?: any) {
    localStorage.setItem("sidemenuModuleId", module_id)

    // -----------------------------------------------------------------------------//

    const isOpen = element.classList.contains('open');

    if (isOpen) {
      // Close the panel
      this.renderer.removeClass(element, 'open');
      this.renderer.setStyle(element, 'height', 0);
    }
    else {
      this.submenuLoading = true;
      this.renderer.setProperty(document.getElementById("loading_arrow_" + index), 'hidden', true);
      this.renderer.setProperty(document.getElementById("loading_menu_" + index), 'hidden', false);
      const elmentClass = document.querySelectorAll(".sidebar-child-menu");
      elmentClass.forEach((elm) => {
        this.renderer.setValue(elm, "");
        this.renderer.removeClass(elm, 'open');
        this.renderer.setStyle(elm, 'height', 0);
      })
      this.courseMenuPost = {
        method: 'sideBarDetail_new',
        course_slug: this.courseSlug,
        section_slug: this.sectionSlug,
        id: this.userId,
        item_id: module_id
      }
      this.sidebarService.getCourseMenuDetail(this.courseMenuPost).subscribe(res => {
        console.log("course sidebar response", res);
        
        this.submenuLoading = false;
        this.renderer.setProperty(document.getElementById("loading_arrow_" + index), 'hidden', false);
        this.renderer.setProperty(document.getElementById("loading_menu_" + index), 'hidden', true);
        if (res['data']) {

          this.getLessonMenuItem = res['data'];

          if (localStorage.getItem('locale') == 'ger') {
            for (let i = 0; i < this.getLessonMenuItem.lessons.length; i++) {
              this.getLessonMenuItem.lessons[i].name = this.getLessonMenuItem.lessons[i].lang_data[0].lang_lessons_name;
            }
          }
          if (localStorage.getItem('locale') == 'en') {
            for (let i = 0; i < this.getLessonMenuItem.lessons.length; i++) {
              this.getLessonMenuItem.lessons[i].name = (this.getLessonMenuItem.lessons[i].lang_data.length == 2) ? this.getLessonMenuItem.lessons[i].lang_data[1].lang_lessons_name : this.getLessonMenuItem.lessons[i].lang_data[0].lang_lessons_name;
            }
          }
          this.renderer.addClass(element, 'open');
          setTimeout(() => {
            const childElmHeight = `${element.children[0].clientHeight}px`;
            this.renderer.setStyle(element, 'height', childElmHeight);
            const scrollElm = element.offsetTop;
            // this.mScrollbarService.scrollTo(document.getElementById("scrollMenuSection"), scrollElm, {
            //   scrollEasing: "easeOut",
            //   scrollInertia: 1000,
            //   timeout: 500
            // })

          }, 100)
        }
      })
    }
  }

  getSideMenuItem(module_id: string) {
    this.courseMenuPost = {
      method: 'sideBarDetail_new',
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug,
      id: this.userId,
      item_id: module_id
    }


    this.sidebarService.getCourseMenuDetail(this.courseMenuPost).subscribe(res => {
      this.submenuLoading = false;
      if (res['data']) {
        this.getLessonMenuItem = res['data'];
      }
    })
  }


  checkIfUserAssignedGroupSet() {
    const data = {
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      method: 'checkIfUserAssignedGroupSet'
    }
    this.groupLeaderService.checkIfUserAssignedGroupSet(data).subscribe((res) => {
      this.showGroupsNav = (res['status']) ? true : false
    }), (error: any) => {
      console.log(error)
    }
  }

  lessonClick(courseSlug: any, sectionSlug: any, moduleSlug: any, lessonSlug: any, lessonid:any) {
    this.commonService.routeToLessonDetail(courseSlug, sectionSlug, moduleSlug, lessonSlug, btoa(lessonid));
  }
  moduleClick(courseSlug: any, sectionSlug: any, moduleSlug: any, lessonSlug = null) {
    this.commonService.routeToModuleDetail(courseSlug, sectionSlug, moduleSlug, lessonSlug);
  }

  routeParams() {
    const paramArr: Array<{ courseSlug: any, moduleSlug: any, lessonSlug: any }> = [];
    this.courseSlug = this.route.snapshot.paramMap.get('courseId')
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId')
    this.lessonSlug = this.route.snapshot.paramMap.get('lessonId')
    paramArr.push({
      courseSlug: this.courseSlug, moduleSlug: this.moduleSlug, lessonSlug: this.lessonSlug
    })
    return paramArr;
  }

  getCourseMenu() {
    this.courseMenuPost = {
      method: 'getUserCourseActivityDetail',
      slug: this.courseSlug,
      id: this.userId,
      section_slug: this.sectionSlug
    }
    this.sidebarService.getCourseMenuDetail(this.courseMenuPost).subscribe(res => {
      if (!res['jwt_status']) {
      } else {
        this.lessonPermission = res['lesson_permission'][0].is_lesson_permission;
        // module order Code
        this.courseMenuArr = res['data']['modules'];
        this.courseMenu = res['data'];
        this.courseName = res['data']['course_name'];

        if (localStorage.getItem('locale') == 'ger') {
          for (let i = 0; i < this.courseMenu.modules.length; i++) {
            this.courseMenu.modules[i].name = this.courseMenu.modules[i].lang_data[0].lang_module_name;
          }
        } else {
          for (let i = 0; i < this.courseMenu.modules.length; i++) {
            if (this.courseMenu.modules[i].lang_data[1]) {
              this.courseMenu.modules[i].name = this.courseMenu.modules[i].lang_data[1].lang_module_name;
            } else {
              this.courseMenu.modules[i].name = this.courseMenu.modules[i].lang_data[0].lang_module_name;
            }
          }
        }

        if (this.courseMenuArr) {
          for (let i = 0; i < this.courseMenuArr.length; i++) {
            this.menuModuleArr[i] = this.courseMenuArr[i].slug;
          }
          this.lessonService.courseMenu = this.menuModuleArr;
          this.lastMenuArrIndex = this.menuModuleArr.length - 1;
          this.lessonService.lastCourseModule = this.menuModuleArr[this.lastMenuArrIndex];
        }
        this.isLoading = false;
        this.setActiveLink();
      }
    }, error => error);
  }
  setActiveLink() {
    setTimeout(() => {
      const allLessonElment = document.querySelectorAll('.sidebar-inner-container h4');
      allLessonElment.forEach(element => {
        if (element.id === "lesson_" + this.getLessonActive) {
          this.renderer.addClass(element, 'active');
        } else {
          this.renderer.removeClass(element, 'active');
        }
      });
    }, 100)
  }

  getCourseCompletionValue() {
    this.courseCompPostData = {
      method: "getUserCourseCompletionValue",
      id: this.commonService.getUserId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug
    }

    this.courseService.getCourseCompletionValue(this.courseCompPostData).subscribe(res => {
      this.courseCompletionValue = res['percentage'];
    }, error => error)
  }

  getQuizLesson(quizId: number, courseSlug: any, sectionSlug: any, moduleSlug: any, lessonSlug: any, type: any) {
    const paramQuery = {
      quiz_id: quizId,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug,
      moduleSlug: moduleSlug,
      lessonSlug: lessonSlug,
      level_type: type
    }
    this.commonService.getLessonQuizOpenById(paramQuery)
  }
  getQuizModule(quizId: number, courseSlug: any, sectionSlug: any, moduleSlug: any, type: any) {
    const paramQuery = {
      quiz_id: quizId,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug,
      moduleSlug: moduleSlug,
      level_type: type
    }
    this.commonService.getModuleQuizOpenById(paramQuery)
  }
  getQuizCourse(quizId: any, courseSlug: any, sectionSlug: any, type: any) {
    const paramQuery = {
      quiz_id: quizId,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug,
      level_type: type
    }



    this.commonService.getCourseQuizOpenById(paramQuery)
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById('pageContainer');
    if (pageelement.classList.contains('collapse-panel')) {
      this.renderer.removeClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '1');
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '0');
    }
  }

  // Assignments function for Course, Module And Lesson
  getAssignmentsByLesson(assignmentItems: any, courseSlug: any, sectionSlug: any, moduleSlug: any, lessonSlug: any) {
    const assignmentData = {
      assignment_data: assignmentItems,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug,
      moduleSlug: moduleSlug,
      lessonSlug: lessonSlug
    }
    this.commonService.getLessonAssignmentById(assignmentData)
  }
  getAssignmentsByModule(assignmentItems: any, courseSlug: any, sectionSlug: any, moduleSlug: any) {
    const assignmentData = {
      assignment_data: assignmentItems,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug,
      moduleSlug: moduleSlug
    }
    this.commonService.getModuleAssignmentById(assignmentData)
  }

  getAssignmentsByCourses(assignmentItems: any, courseSlug: any, sectionSlug: any) {
    const assignmentData = {
      assignment_data: assignmentItems,
      courseSlug: courseSlug,
      sectionSlug: sectionSlug
    }
    this.commonService.getCourseAssignmentById(assignmentData)
  }

  goToCourseGroup() {
    this.router.navigate(['course-group-main'])
  }


  goToCourseChat() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/course-chat/" + this.courseSlug + "/" + this.sectionSlug]);
    });

  }

}


@Directive({
  selector: '[activeLink]'
})
export class ActiveLinkBiding {
  constructor(private renderer: Renderer2) { }
  @HostListener('click', ['$event.target'])
  onClick(event: { classList: { add: (arg0: string) => void; }; }) {
    const element = document.querySelectorAll(".sidebar-child-menu h4");
    for (let i = 0; i < element.length; i++) {
      this.renderer.removeClass(element[i], "active")
    }
    event.classList.add('active')
  }
}
