import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './service/data.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SiteDomainService } from './service/sitedomain.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './service/app.service';
import { CommonService } from './service/common.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ConsoleToggleServiceService } from './service/console-toggle-service.service';
import { LanguageService } from './service/language.service';

export enum KEY_CODE {
  PRINTSCREEN = 44

}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'LMS 15 to 16';
  params: any;
  siteDomain: any;
  postDomainData: any;
  siteHostName: any;
  // tslint:disable-next-line: no-inferrable-types
  isLoading: boolean = true;
  institutLogo: string;
  currentLangContainer: Subscription;

  appLanguageVar: any;
  browserLanguage: string;

  previewLayoutPathArr: any = [
    // tslint:disable-next-line: max-line-length
    'course-detail-preview', 'module-detail-preview', 'lesson-detail-preview'
  ];

  urlPath: any;
  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: max-line-length


  // tslint:disable-next-line: typedef-whitespace
  // tslint:disable-next-line: max-line-length

  constructor(private domainName: SiteDomainService, private router: Router, private dataService: DataService, public dialog: MatDialog, private loc: Location, public translate: TranslateService, private appService: AppService, private commonService: CommonService, private titleService: Title, private consoleToggleService: ConsoleToggleServiceService, private languageService: LanguageService) {
    this.consoleToggleService.disableConsoleInProduction();
    this.urlPath = location.pathname.split('/');
    this.browserLanguage = languageService.getBrowserLanguage()
    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.appLanguageVar = message;
      this.langOption();
    }, error => error);

    //this.setFavicon();

    this.langOption();
  }

  langOption() {
    this.translate.addLangs(['en', 'ger']);
    if (localStorage.getItem('locale')) {
      // this.setDefaultLanguage();
      const browserLang = localStorage.getItem('locale');
      //const browserLang = this.appLanguageVar;
      this.translate.use(browserLang.match(/en|ger/) ? browserLang : 'ger');
    } else {
      this.setDefaultLanguage();
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  setDefaultLanguage() {
    this.browserLanguage = this.languageService.getBrowserLanguage();
    console.log(this.browserLanguage);

    if (localStorage.getItem('locale') == null) {
      if (this.browserLanguage == 'de' || this.browserLanguage == 'de-DE' || this.browserLanguage == 'de_DE'){
        localStorage.setItem('locale', 'ger');
        this.translate.setDefaultLang('ger');
      }else{
        localStorage.setItem('locale', 'en');
        this.translate.setDefaultLang('en');
      }
    }
  }

  siteLogo(logoname: any) {
    if (logoname) {
      if (this.commonService.getInstituteLogo(logoname)) {
        this.institutLogo = this.commonService.getInstituteLogo(logoname)
      } else {
        this.institutLogo = null;
      }
    } else {
      this.institutLogo = null;
    }
    // this.appService.setAppFavicon(this.institutLogo)
  }

  // setFavicon() {
  //   const siteD: any  = this.commonService.getCurrentSiteDetail()
  //   this.siteLogo(siteD.theme_style.logo);
  // }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  ngOnInit() {
    // window.onpopstate = function (e) { window.history.forward(); }
    this.siteHostName = this.domainName.getDomainName();
    //added alert here to check sitedomain
    // alert(`siteHostName => ${this.siteHostName}`)
    if (this.previewLayoutPathArr.indexOf(this.urlPath[1]) == -1) {
      this.verifySiteDomain(this.siteHostName);
    }
  }

  ngOnDestroy() {
    this.currentLangContainer.unsubscribe()
  }


  verifySiteDomain(domain) {
    // alert(domain)
    localStorage.setItem('domain', domain)
    this.postDomainData = {
      // tslint:disable-next-line: object-literal-shorthand
      domain: domain,
      method: 'verifySiteDomain'
    };
    this.dataService.verifySiteDomain(this.postDomainData).subscribe(res => {
      this.isLoading = false;
      // tslint:disable-next-line: no-string-literal
      if (!res['status']) {
        this.router.navigate(['not-found'])
      }
    });
  }

}
