import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { courseMenu, course } from './../model/course';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private _refreshNeeded$ = new Subject<void>();
  jwt;
  constructor(private apiService: ApiService, private http: HttpClient) { }

  getCourseMenuDetail(data): Observable<courseMenu[]> {
    this.jwt = this.apiService.getJWT()
    return this.http.post<courseMenu[]>(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data), { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

}
