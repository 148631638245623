import { Component, OnInit } from '@angular/core';
import { CourseService } from './../../service/course.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../../service/common.service';
import { ApiService } from './../../service/api.service';


@Component({
  selector: 'app-course',
  templateUrl: './course.component.html'
})
export class CourseComponent implements OnInit {
  allCoursePost: any;
  courses: any;
  isLoading: boolean = true;
  sitePath: any;
  constructor(private courseService: CourseService, private router: Router,
    private route: ActivatedRoute, private commonService: CommonService, private apiService: ApiService) { }

  ngOnInit() {
    this.sitePath = this.apiService.getSiteFolderPath();
    this.allCoursePost = {
      method: 'getAllCourses'
    }
    this.getAllCourses(this.allCoursePost)
  }
  /**
   * @description Get All Courses
   * @date 2019-09-16
   * @param {*} postData
   * @memberof CourseComponent
   */
  getAllCourses(postData) {
    this.courseService.getAllCourses(postData).subscribe(res => {
      this.courses = res['data'];
      this.isLoading = false;
    })
  }

  /**
   * @description : Navigate to Particular Course Detail
   * @date 2019-09-16
   * @param {*} courseId
   * @memberof CourseComponent
   */
  routeToCourseDetail(courseId) {
    this.router.navigate(['course-detail/' + courseId], { relativeTo: this.route })
  }

}
