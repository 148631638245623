import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if((value != undefined ) || (value != null)){
      return value.replace('\n', '<br>');
    }
  }
}
