import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss']
})
export class UserMessageComponent implements OnInit {
  message: any;
  constructor(private commonService: CommonService , private router: Router) { }

  ngOnInit() {
    if(localStorage.getItem('userMessage')){
      this.message = localStorage.getItem('userMessage');
    } else {
      this.message = 3
    }
  }
/**
 * @description Navigate to Login Page
 * @date 2019-09-16
 * @memberof UserMessageComponent
 */
redirectToLogin() {
   // this.commonService.redirectToLogin()
  this.router.navigate(['login']);
  }

}
